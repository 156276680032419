import React from 'react'
import styled from 'styled-components'
import {colors} from '@get-wrecked/simple-components'
import {pageContentWidth} from '../../components/styles'
import TextLogo from '../../components/Icons/TextLogo'
import FacebookIcon from '../../components/Icons/Socials/FacebookIcon'
import InstagramIcon from '../../components/Icons/Socials/InstagramIcon'
import TwitterXIcon from '../../components/Icons/Socials/TwitterXIcon'
import YouTubeIcon from '../../components/Icons/Socials/YouTubeIcon'
import RedditIcon from '../../components/Icons/Socials/RedditIcon'
import DiscordIcon from '../../components/Icons/Socials/DiscordIcon'
import TikTokIcon from '../../components/Icons/Socials/TikTokIcon'
import useMediaQuery from '../../hooks/useMediaQuery'

const year = 1900 + new Date().getYear()
const copyright = `© ${year} Medal B.V. All rights reserved.`
const collapseMediaQuery = 'max-width: 660px'

const Footer = () => {
  const isCollapsedWidth = useMediaQuery(collapseMediaQuery)
  return (
    <Container>
      <PageContainer>
        <LeftColumn>
          <TextLogo width={170} height={35}/>
          <CallToAction>
            <CallToActionTitle>
              Like what you see?
            </CallToActionTitle>
            <CallToActionDescription>
              <a href="https://calendly.com/medaltrends/medal-trends-demo" target="_blank" rel="noopener noreferrer">Book a demo</a> to discover even more.
            </CallToActionDescription>
          </CallToAction>
          {!isCollapsedWidth && <Copyright>{copyright}</Copyright>}
        </LeftColumn>
        <RightColumn>
          <Socials>
            <Social href="https://www.instagram.com/medal.tv" target="_blank" rel="noopener noreferrer">
              <InstagramIcon size={32}/>
            </Social>
            <Social href="https://www.facebook.com/Medal.tv" target="_blank" rel="noopener noreferrer">
              <FacebookIcon size={32}/>
            </Social>
            <Social href="https://twitter.com/Medal_TV" target="_blank" rel="noopener noreferrer">
              <TwitterXIcon size={28}/>
            </Social>
            <Social href="https://www.tiktok.com/@medal.tv" target="_blank" rel="noopener noreferrer">
              <TikTokIcon size={32}/>
            </Social>
            <Social href="https://www.reddit.com/r/MedalTV" target="_blank" rel="noopener noreferrer">
              <RedditIcon size={32}/>
            </Social>
            <Social href="https://discord.gg/ZBfMRxBsvT" target="_blank" rel="noopener noreferrer">
              <DiscordIcon size={32}/>
            </Social>
            <Social href="https://www.youtube.com/channel/UCs1xWFLN__Txt6QqFkxRpRA/videos" target="_blank" rel="noopener noreferrer">
              <YouTubeIcon size={32}/>
            </Social>
          </Socials>
          <Links>
            <a href="https://medal.tv/terms" target="_blank" rel="noopener noreferrer">Terms</a>
            <a href="https://medal.tv/privacy" target="_blank" rel="noopener noreferrer">Privacy</a>
            <a href="https://medal.tv/dmca" target="_blank" rel="noopener noreferrer">DMCA</a>
          </Links>
          {isCollapsedWidth && <Copyright>{copyright}</Copyright>}
        </RightColumn>
      </PageContainer>
    </Container>
  )
}

export default Footer

// local styles
const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  border-top: 1px solid ${colors.stroke['0A8']};
  margin-top: 32px;
`

const PageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${pageContentWidth};
  padding: 32px 20px;
  gap: 32px;
  flex-wrap: wrap;

  @media (${collapseMediaQuery}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const LeftColumn = styled(Column)`
  gap: 32px;

  @media (${collapseMediaQuery}) {
    align-items: center;
    justify-content: center;
  }
`

const RightColumn = styled(Column)`
  gap: 24px;
  justify-content: center;
  align-items: flex-end;

  @media (${collapseMediaQuery}) {
    align-items: center;
    gap: 32px;
  }
`

const CallToAction = styled(Column)`
  @media (${collapseMediaQuery}) {
    align-items: center;
    justify-content: center;
  }
`

const CallToActionTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.text['0']};
`

const CallToActionDescription = styled.span`
  font-size: 15px;
  font-weight: normal;
  color: ${colors.text['30']};
  
  a, a:visited {
    color: ${colors.text['30']};
    text-decoration: underline;
    
    &:hover, &:active {
      color: ${colors.text['0']};
    }
  }
`

const Socials = styled(Row)`
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
`

const Social = styled.a`
  display: flex;
  text-decoration: none;
  transition: transform 100ms ease;
  
  &:hover {
    transform: scale(1.1);
  }
`

const Copyright = styled.span`
  font-size: 15px;
  font-weight: normal;
  color: ${colors.text['30']};
`

const Links = styled(Row)`
  gap: 24px;
  
  a {
    font-size: 15px;
    font-weight: 500;
    color: ${colors.text['30']};
    text-decoration: none;
    
    &:hover {
      color: ${colors.text['0']};
      text-decoration: underline;
    }
  }
`
