import React, {Fragment} from "react";
import { Redirect } from "react-router-dom";

import { getCategoryById, getReportPeriods, getReportingPeriodQueryString, getReportingPeriodSingular, groupMultiSummed, groupSummed, isEnterprise, joinLookup, setSubgameData, subgameData } from "../../data/App";
import { loadCategoryData, getGamesList, getSubgameSessions, calculatePeriodShares, extractColorList } from "../../data/Data";
import CompoundPieList from '../../components/Charts/CompoundPieList.jsx'
import EmbedAvatarList from '../../components/Embed/EmbedAvatarList'
import { combineOtherRows } from "../Servers/ServersLandingPage";
import {PageHeader} from '../../components/styles'
import SectionLoadingPlaceholder from '../../components/Dashboard/SectionLoadingPlaceholder'

const sectionDescriptions = {
	"shares": "How many times clips are shared on each platform. The higher the number, the more popular a platform is for sharing clips. The number shown is a percentage of all clips shared across all platforms.",
	"viewers": "How many times clips shared on a given platform were viewed. It's a good indication of where people are going to view clips. The number shown is a percentage of all clips viewed across all platforms.",
	"attention": "How many views a shared clip is expected to see after being shared on the platform in question. These are average counts of views per share, rather than a percentage.",
	"sessionCount": "Percent breakdown of clip subgame counts, among the 50 most popular subgames for the game in question.",
	"likes": "Percent breakdown of subgame clip likes, among the 50 most popular subgames for the game in question.",
	"views": "Percent breakdown of subgame clip views, among the 50 most popular subgames for the game in question.",
}

export const loadSubgameData = (id) => {
	return new Promise((resolve, reject) => {
		// TODO: also cache by id
		if (id && subgameData && subgameData[id] && subgameData[id].dataLoaded) {
			// console.log("subgameData already loaded", id, subgameData[id])
			resolve(subgameData[id]);
			return;
		}

		Promise.all([
			getSubgameSessions(id)
		]).then((values) => {
			const period = getReportingPeriodSingular().toLowerCase();
			const data = values[0].data;
			data.forEach(r => r[period] = r.period);

			// Ditch emojis
			data.forEach(t => { t.subgameName = t.subgameName.replace(/[^0-9a-z_ ]/gi, '') })

			var td = {
				...transformSubgames(data),
				dataLoaded: true
			}

			// TODO: here too
			if (id) {
				setSubgameData(id, td);
			}

			// console.log("subgameData", id, td)
			resolve(td);
		})
	});
}

// Date + ONE additional column
// TODO: extract
export const zeroFillMissingSubgameDays = (rows, dateColumn, keyColumn, valueColumns, categoryId) => {
	console.time("zeroFill")

	// NOTE: This allows string keys, so it doesn't work the same
	// as the version in ServersLandingPage
	var findUniques = (rows, col) => Object.keys(rows.reduce((c, v) => {
		c[v[col]] = true;
		return c;
	}, {}))

	var dates = getReportPeriods();
	var keys = findUniques(rows, keyColumn);

	var emptyRow = valueColumns.reduce((c, v) => { c[v] = 0; return c }, { "categoryId": categoryId});
	//console.log("prep", rows, emptyRow, keys, dates, categoryId)

	var newRows = dates.reduce((c, date) => {
		// filtering down before the loop drops us from 12ms to 2ms
		var working = rows.filter(r => r[dateColumn] === date);

		keys.forEach(k => {
			if (!working.some(r => r[dateColumn] === date && r[keyColumn] === parseInt(k))) {
				var row = JSON.parse(JSON.stringify(emptyRow));
				row[dateColumn] = date;
				row[keyColumn] = parseInt(k);
				c.push(row);
			}
		});

		return c;

	}, []);

	console.log("filling", dates, keys, newRows, rows)
	console.timeEnd("zeroFill")
	return rows.concat(newRows);
}

export const transformSubgames = (subgameData, loadCount = 50, otherThreshold = 10, includeNoneSubgame = false) => {
	console.time("transformSubgameData")
	subgameData = subgameData.filter(t =>
		(includeNoneSubgame || t.subgameName !== "")
	);

	var period = getReportingPeriodSingular()

	const subgameNames = subgameData.reduce((c, v) => { c.find(s => s.subgameId === v.subgameId) || c.push({ subgameId: v.subgameId, subgameName: v.subgameName }); return c; }, [])
	subgameNames.filter(s => s.subgameName === "").forEach(emptySubgame => {
		emptySubgame.subgameName = `(no subgame)`
	});

	const countColumns = ["serverCount", "sessionCount", "totalSeconds", "userCount"];

	// We'll give every subgame a global color
	const groupedBySubgame = groupSummed(subgameData, "subgameId", countColumns);
	const subgameColorLookup = extractColorList(groupedBySubgame, "subgameId", "subgameId");
	subgameColorLookup[0] = {
		"id": 0,
		"name": "Other",
		"avatar": "./.png",
		"data": [],
		"color": "hsl(0, 0%, 40%)"
	}
	//console.log("top subgame colors?", groupedBySubgame, subgameColorLookup)

	// Process things across all categories first.
	// Build the Most Subgameged Games list. I'm not fixing that awesome search & replace artifact.
	const groupedByCategoryId = groupSummed(subgameData, "categoryId", countColumns)
	const groupedByCategoryIdAsPercents = calculatePeriodShares(groupedByCategoryId, countColumns, "");
	const groupedByCategoryIdSubgame = groupMultiSummed(subgameData, ["categoryId", "subgameId"], countColumns);
	const groupedByCategoryIdSubgameJoined = joinLookup(groupedByCategoryIdSubgame, subgameNames, "subgameId", "subgameId");

	//console.log("subgame groups", subgameData, groupedByCategoryId, groupedByCategoryIdAsPercents, groupedByCategoryIdSubgame, groupedByCategoryIdSubgameJoined)

	// Note: Here, we pull in extra categories in case our top subgame games aren't
	// in the top 300 popular games.  Then we filter to ones that are and slice again.
	var topCategoryIds = groupedByCategoryId
		.sort((a, b) => b.sessionCount - a.sessionCount)
		.slice(0, loadCount * 2).map(g => g.categoryId)
		.filter(id => getCategoryById(id) !== undefined)
		.slice(0, loadCount);

	var topGamesWithSubgames = topCategoryIds.map(topId => {
		const g = getCategoryById(topId);
		if (g) {
			const game = JSON.parse(JSON.stringify(g));
			const subgames = groupedByCategoryIdSubgameJoined.filter(r => r.categoryId === topId);
			const topSubgameIds = subgames.slice(0, otherThreshold).map(s => s.subgameId);

			const otherGroupedSubgames = combineOtherRows(subgames, topSubgameIds, "subgameId", "subgameName", [period], countColumns)
			const otherGroupedSubgamesAsPercents = calculatePeriodShares(otherGroupedSubgames, countColumns, "");

			game.subgames = otherGroupedSubgamesAsPercents;

			game.platforms = game.subgames.map(t => {
				t.color = subgameColorLookup[t.subgameId].color;

				// We're still rendering this as a "platform list", so
				// we'll use definitions for that component.
				return { ...t,
					category_id: t.categoryId,
					platform: t.subgameName,
					shares: t.sessionCount,
					viewers: t.views,
					color: t.color,
				};
			});

			game.subgamePercents = [groupedByCategoryIdAsPercents.find(r => r.categoryId === topId).sessionCount];

			return game;
		}
		console.log("missing game", topId)
		return null;
	}).filter(r => r !== null);

	console.log("topGamesWithSubgames", topGamesWithSubgames)
	console.timeEnd("transformSubgameData")


	return {
		// for landing page
		topCategoryIds,
		topGamesWithSubgames,

		// for onward processing
		subgameData,
		subgameNames,
		countColumns,
		subgameColorLookup,
		groupedBySubgame,
	}
}

class SubgamesLandingPage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			dataLoaded: false,
			search: false,
			games: new Map(),
			platforms: new Map(),
			categories: [],
		}
	}

	componentDidMount() {
		if (!isEnterprise()) {
			this.setState({ redirectTo: '/trends/' });
			return;
		}

		loadCategoryData().then(() => {
			this.populateTop();
		});
	}

	componentDidUpdate() {
		if (this.state.dataLoaded && this.state.lastReportingDescription !== this.buildUrl()) {
			this.populateTop();
		}
	}

	buildQueryString = () => {
		return getReportingPeriodQueryString(window.location.search, { r: this.state.retentionPeriod });
	}

	isEmbed() {
		return window.location.pathname.indexOf("/embed/") === 0;
	}

	populateTop() {
		this.setState({ dataLoaded: false })

		getGamesList().then(games => {
			loadSubgameData().then((processed) => {
				this.setState({
					...processed,
					dataLoaded: true,
					lastReportingDescription: this.buildUrl(),
				});
			});
		});
	}

	buildUrl() {
		if (this.isEmbed()) {
			return `/embed/subgames/${this.props.match.params.section}?${this.buildQueryString()}`;
		}
		return `/subgames/?${this.buildQueryString()}`;
	}

	buildTitle(ids) {
		return "Subgames | Medal Trends";
	}

	renderGameList(metric) {
		// console.log("renderGameList", this.state.topGamesWithSubgames, this.state.categoryPlatforms)
		console.log("renderGameList", this.state.topIds, this.state.topCategoryIds)
		return <CompoundPieList
			data={this.state.topGamesWithSubgames}
			gameOrder={this.state.topCategoryIds}
			metric={metric}
			linkPath="subgame-compare"
			isEmbed={this.isEmbed()}
			section={metric}
			reportingColumn="sessionCount"
			title="Most Popular Games with Subgames"
			subtitle="Relative subgame representation across the top games with subgames. Percentages represent the fraction of subgames across all games for the game in question."
			toolTipHeader="Subgames by game"
			toolTipTotal="of total clips for this subgame"
		/>
	}

	renderPlatformList(metric) {
		return <CompoundPieList
			// games={this.state.games}
			data={this.state.platformCategories}
			gameOrder={this.state.platformOrder}
			hideIndex={true}
			dataMember="games"
			valueField="category_name"
			nameField="name"
			toolNameField="category_name"
			linkPath="platform-detail"
			metric={metric}
			isEmbed={this.isEmbed()}
			section={metric}
			reportingColumn="shares"
			title="TOP PLATFORMS"
			subtitle="Relative percentages of clip shares across the top sharing platforms. Game percentages represent the fraction of shares across all games for the platform in question."
			pageSize={10}
		/>
	}

	renderEmbedded() {
		var section = this.props && this.props.match && this.props.match.params
			&& this.props.match.params.section && this.props.match.params.section.toLowerCase
			&& this.props.match.params.section.toLowerCase();	// Javascript!


		switch (section) {
			case "shares":
			case "viewers":
			case "attention":
				return this.renderComparisonChart(section);

			case "platform-shares":
				return this.renderPlatformList("platform-shares");

			case "game-shares":
				return this.renderGameList("game-shares");

			default:
				return <div></div>;
		}
	}


	renderEmbeddedWithGames() {
		return (
			<div>
				<EmbedAvatarList ids={this.state.ids} />
				{this.renderEmbedded()}
			</div>
		)
	}

	render() {
		if (this.state.redirectTo !== undefined) {
			return <Redirect push to={this.state.redirectTo} />;
		}

		if (!this.state.dataLoaded) {
			return (
				<SectionLoadingPlaceholder/>
			)
		}

		if (this.isEmbed()) {
			return this.renderEmbedded();
		}

		return (
      <Fragment>
        <PageHeader>
          Subgame Trends
          <span>
            NOTE: Percentage shares shown below represent observations within the Medal.tv platform, and therefore may differ from numbers generated from other sources.
          </span>
        </PageHeader>
        {this.renderGameList("subgamePercents")}
      </Fragment>
		);
	}
}

export default SubgamesLandingPage
export {
	sectionDescriptions
}
