import React from 'react'

/**
 * The React Context used for handling auth, current user, etc.
 * @type {React.Context<{}>}
 */
export const AuthContext = React.createContext({})

/**
 * Context used for handling opening and closing modals.
 * @type {React.Context<{}>}
 */
export const ModalContext = React.createContext({})

/**
 * Context used for loading and enriching categories.
 * @type {React.Context<{}>}
 */
export const CategoryContext = React.createContext({})

/**
 * Context used for loading and saving bookmarks.
 * @type {React.Context<{}>}
 */
export const BookmarkContext = React.createContext({})
