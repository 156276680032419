import React from 'react'
import styled from 'styled-components'
import {colors} from '@get-wrecked/simple-components'
import {LoadingPlaceholder} from '../styles'
import PropTypes from 'prop-types'

const propTypes = {
  minHeight: PropTypes.string
}

const SectionLoadingPlaceholder = ({minHeight}) => {
  return (
    <Container $minHeight={minHeight}/>
  )
}

SectionLoadingPlaceholder.propTypes = propTypes

export default SectionLoadingPlaceholder

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.background['second-layer']};
  border-radius: 8px;
  min-height: ${({$minHeight}) => $minHeight ?? '300px'};
  
  ${LoadingPlaceholder}
`
