import React from 'react';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from '@material-ui/core/Hidden';
import Tooltip from '@material-ui/core/Tooltip';

import { dateFromWeek, getISOWeekFromDate, getISOYearFromDate, getReportingPeriod, getReportingPeriodSingular } from "../../../data/App";
import DashboardSection from '../../../components/Dashboard/DashboardSection'
import styled from 'styled-components'
import {colors} from '@get-wrecked/simple-components'

const styles = {
	card: {
		flex: 1,
		padding: 0,
		margin: 10,
		backgroundColor: '#1F1F1F',
		color: '#ffffff',
	},
	cardContent: {
		padding: 0,
		margin: 0,
	},
	cardHeader: {
		borderBottom: '0.01em solid grey',
		marginBottom: '20px',
	},

}

const HtmlTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(14),
		border: '1px solid #dadde9',
	},
}))(Tooltip);

class RetentionChart extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			title: "",
			games: new Map(),
		}
	}

	getfirstPeriod = () => {
		return this.props.data.reduce((c, v) => Math.min(c, v.firstPeriod), 1000000)
	}

	getDateString = (date) => {
		switch (getReportingPeriod()) {
			case "daily":
				return date.toString().substr(4, 11);
			case "monthly":
				return `${date.toLocaleDateString("en-US", { month: 'long' })} ${date.getYear() + 1900}`;
			default:
				return `${this.getPeriodName(true)} ${getISOWeekFromDate(date)}, ${getISOYearFromDate(date)}`
		}
	}

	getPluralizedPeriod = (count) => {
		return `${count} ${this.getPeriodName(false)}${count !== 1 ? "s" : ""}`
	}

	getPeriodDate = (periodIndex) => {
		var date = new Date(1970, 0, 1);
		switch (getReportingPeriod()) {
			case "daily":
				// 1/1/1970 was the 1st of the year, so add one extra
				date.setDate(periodIndex + 1);
				return date;
			case "monthly":
				// 1/1/1970 was month 0, and it should remain so when adding the first month to it.
				date.setMonth(periodIndex - 1);
				return date;
			default:
				return dateFromWeek(periodIndex);
		}
	}

	getAbbreviatedDate = (firstPeriod, index) => {
		var date = this.getPeriodDate(firstPeriod + index);
		switch (getReportingPeriod()) {
			case "daily":
				return `${date.getMonth() + 1}/${date.getDate()}`;
			case "monthly":
				return date.toString().substr(4, 3).toUpperCase();
			default:
				return `W${(getISOWeekFromDate(date) - 1) % 52 + 1}`
		}
	}

	getPeriodName = (capitalized) => {
		return capitalized ? getReportingPeriodSingular() : getReportingPeriodSingular().toLowerCase();
	}

	componentDidMount() {

	}

	renderTooltip(item) {
		var date = this.getPeriodDate(item.firstPeriod)
		if (item.retentionPeriod === 0) {
			// date will be a Thursday (because 1/1/1970 was a Thursday)
			var monday = new Date(date.getTime())
			monday.setDate(monday.getDate() - 3);

			var sunday = new Date(date.getTime())
			sunday.setDate(sunday.getDate() + 3);

			return <React.Fragment>
				<strong>{this.getDateString(date)}</strong>
				<p>
					{getReportingPeriod() === "weekly" && `${(monday + "").substr(0, 15)} - ${(sunday + "").substr(0, 15)}`}
				</p>
			</React.Fragment>;
		}

		return <React.Fragment>
			<strong>{item.userCount / 100}%</strong> of
			players who were first seen
			{getReportingPeriod() === "daily" ? " on " : " in "}
			<strong>{this.getDateString(date)}</strong> were
			still active <strong>{this.getPluralizedPeriod(item.retentionPeriod)}</strong> later
		</React.Fragment>

	}

	render() {
		const hslAlpha = (hsl, alpha) => {
			return hsl.replace(")", `, ${alpha})`);
		}

		const w = 100 / 11;
		const wp = w + "%";
		const firstPeriod = this.getfirstPeriod();

    return (
      <DashboardSection
        title={this.props.title}
        description={this.props.subtitle}
        gap="0px"
        style={{
          minWidth: 'calc(50% - 20px)'
        }}
      >
        <Body>
          {
            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => {
              const x = (item + 1) * w + "%";
              const style = {
                position: "absolute", "width": wp, "height": wp, left: x, top: 0, border: "none 1px #ccc",
                display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center",
              }
              return (
                <div style={style} key={index}>
                  <Hidden xsDown>{this.getPeriodName(true)}</Hidden> {item}
                </div>
              )
            })
          }
          {
            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => {
              const y = (item+1) * w + "%";
              const style = {
                position: "absolute", "width": wp, "height": wp, left: 0, top: y, border: "none 1px #ccc",
                display: "flex", alignItems: "center", justifyContent: "center",

              }
              return (
                <div style={style} key={index}>
                  {this.getAbbreviatedDate(firstPeriod, item)}
                </div>
              )
            })
          }
          {
            this.props.data.map((item, index) => {
              const x = (item.retentionPeriod+1) * w + "%";
              const y = (item.firstPeriod - firstPeriod + 1) * w + "%";
              const style = {
                position: "absolute", "width": wp, "height": wp, left: x, top: y,
                display:"flex", alignItems:"center", justifyContent:"center",
                background: hslAlpha(this.props.category.color, item.userCount / 10000) }
              return (
                <HtmlTooltip key={index}
                             title={this.renderTooltip(item, this.props.category)}>
                  <div style={style}>
                    <Hidden smDown>{Math.round(item.userCount)/100}%</Hidden>
                    <Hidden mdUp xsDown>{Math.round(item.userCount/100)}%</Hidden>
                  </div>
                </HtmlTooltip>
              )
            })
          }
        </Body>
      </DashboardSection>
		)
	}
}

export default withStyles(styles)(RetentionChart)

const Body = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 100%;
  color: ${colors.text['0']};
  font-size: 14px;
  font-weight: 500;
`
