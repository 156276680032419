import queryString from 'query-string'
import {getCachedUser} from '../utils/user/user'
import moment from 'moment';
import {getStoredAuth} from '../utils/user/auth'

//const medalApiUrl = 'https://' + (['api-v2'].concat(window.location.host.split('.').slice(1))).join('.') + "/";
//const medalApiUrl = window.location.host === "localhost:3000" ? "http://127.0.0.1:8080/" : "https://api-v2.medal.tv/";
const medalApiUrl = "https://medal.tv/api/";
//const medalApiUrl = "http://medal.test:8080/";
const localApiUrl = "http://localhost:8080";
const medalMetricsApiUrl = "https://4ksp7ijmw5.execute-api.us-east-1.amazonaws.com/prod/trends"
const allowApiResultCaching = true;
const dataMembers = {
	"CLIP_PERCENTS": "clipPercents",
	"SESSION_PERCENTS": "sessionPercents",
	"SHARE_PERCENTS": "sharePercents",
	"USAGE_PERCENTS": "usagePercents",
	"AVG_SESSION_DURATION": "avgSessionDurationMinutes" };
const trendingGamesCount = 300;
const maxExtrapolationPeriods = 6;
const trendsAccessCookieName = "trendsEmailRegistered";

let reportingPeriod = "daily"
const reportingPeriods = { "DAILY": "daily", "WEEKLY": "weekly", "MONTHLY": "monthly" };
const reportingPeriodSingular = { "daily": "Day", "weekly": "Week", "monthly": "Month" };

const shareCalculations = [
	{ key: "up", dataMember: dataMembers.SESSION_PERCENTS, sectionKey: "unique_players", title: "Unique Players", subtitle: "The amount of unique Medal users that played this. This can indicate overall interest in the game, and whether you should think about creating content for it." },
	{ key: "tg", dataMember: dataMembers.USAGE_PERCENTS, sectionKey: "time_in_game", title: "Total Time In-Game", subtitle: "The total time Medal users spent playing this game. If a game is high in time played but not unique players, they probably love the game." },
	{ key: "cc", dataMember: dataMembers.CLIP_PERCENTS, sectionKey: "clips_created", title: "Clips Taken", subtitle: "Users taking lots of clips means they are experiencing lots of moments they want to save" },
	{ key: "cs", dataMember: dataMembers.SHARE_PERCENTS, sectionKey: "clips_shared", title: "Clips Shared (Virality)", subtitle: "Higher sharing means faster organic growth" },
	{ key: "sd", dataMember: dataMembers.AVG_SESSION_DURATION, sectionKey: "session_duration", title: "Daily Time Spent", subtitle: "The average time that a gamer spends playing this game over the course of a day. (h:mm:ss)" },
];

const tagDataMembers = {"CONTENT_COUNT": "contentCount", "VIEWS": "views", "LIKES": "likes"}
const tagRankingMethods = [
	{ key: "c", dataMember: tagDataMembers.CONTENT_COUNT, title: "Content Count", subtitle: "The number of times the tag was assigned to a clip." },
	{ key: "v", dataMember: tagDataMembers.VIEWS, title: "Views", subtitle: "How many people viewed clips with this tag." },
	{ key: "l", dataMember: tagDataMembers.LIKES, title: "Likes", subtitle: "How many likes were registered for clips using this tag." },
];

const subgameDataMembers = { "SESSION_COUNT": "sessionCount", "USER_COUNT": "userCount", "TOTAL_SECONDS": "totalSeconds", "SERVER_COUNT": "serverCount" }
const subgameRankingMethods = [
	{ key: "s", dataMember: subgameDataMembers.SESSION_COUNT, title: "Session Count", subtitle: "The number of sessions played for this subgame." },
	{ key: "u", dataMember: subgameDataMembers.USER_COUNT, title: "Unique Players", subtitle: "The amount of unique Medal users that played this subgame." },
	{ key: "t", dataMember: subgameDataMembers.TOTAL_SECONDS, title: "Total Time In-Game", subtitle: "The total time Medal users spent playing this subgame." },
	{ key: "g", dataMember: subgameDataMembers.SERVER_COUNT, title: "Server Count", subtitle: "The number of game servers seen for this subgame." },
];

const dataMetrics = {
	shares: { title: "Sharing" },
	viewers: { title: "Shared Clip Viewing" },
	userCount: { title: "User Count" },
	sessionCount: { title: "Game Session Count" },
	sessionDuration: { title: "Game Session Duration" },
	attention: { title: "Viewers/Share" },
	contentCount: { title: "Content Count" },
	views: { title: "Views" },
	likes: { title: "Likes" },
	totalSeconds: { title: "Total Seconds In-Game" },
	serverCount: { title: "Game Server Count" },
}


const autoTags = {
	"10": ["goal", "save", "epicsave"],
	"11": ["enemyslain", "triplekill", "quadrakill", "pentakill", "doublekill", "ace", "playerslain", "baronsteal", "baronkill", "turretkill", "inhibitorkill", "dragonsteal", "dragonkill", "heraldsteal", "heraldkill", "assist"],
	"62": ["eliminatedplayer", "knockeddownplayer", "victoryroyale", "battleroyaledeath", "victory", "doubleelimination", "multielimination", "defeat", "headshot", "longdistanceshot", "ludicrousshot"],
	"103": ["kill", "death"],
	"351": ["eliminated", "knockeddown", "champion"],
	"494": ["kill", "doublekill", "triplekill", "assist", "killingspree", "killectionagency", "headshot", "portalkill"],
	"988": ["kill", "spikeplanted"],
	"4190": ["victory", "defeat"],
	"4854": ["enemykilled", "headshot", "playerdeath"]
};

const regressionModelNames = {
	"linear": "Linear",
	"exp": "Exponential",
	"poly2": "Polynomial (quadratic)",
	"poly3": "Polynomial (cubic)",
};

let games = { "daily": null, "weekly": null, "monthly": null };
let categoryData = []
let mediumData = []
let gameServerData = {}
let tagData = {}
let subgameData = {}
let medalUser = null;

let chartParamDefaults = {
	"daily": { "periodCount": 30, "apiSpan": "7d", "maxCount": 31, dateFormat:"YYYY-MM-DD" },
	"weekly": { "periodCount": 6, "apiSpan": "6w", "maxCount": 52, dateFormat: "YYYY-[W]WW" },
	"monthly": { "periodCount": 6, "apiSpan": "180d", "maxCount": 24, dateFormat: "YYYY-MM" },
};

const noFormat = (v)=>v;
const durationMinutesFormatter = value => {
	var seconds = ("" + (Math.floor(value * 60) % 60 + 100)).substring(1, 3);
	var minutes = ("" + (Math.floor(value) % 60 + 100)).substring(1, 3);
	var hours = Math.floor(value / 60)

	return `${hours}:${minutes}:${seconds}`
}
const valueFormatMap = { "sessionCount": noFormat,
	"clipCount": noFormat,
	"shareCount": noFormat,
	"userCount": noFormat,
	"sessionDurationMinutes": noFormat,
	"avgSessionDurationMinutes": durationMinutesFormatter
};

const displayUnitMap = {
	"avgSessionDurationMinutes": ""
};


let chartParams = JSON.parse(JSON.stringify(chartParamDefaults));

const gameHues = {};
// const namedColors = { "BLUE": "hsl(194,100%,43%)", "ORANGE": "hsl(16, 100%, 50%)", "GOLD": "hsl(44, 100%, 50%)", "GREEN": "hsl(101, 100%, 37%)", "VIOLET": "hsl(289, 100%, 37%)", "GREY": "hsl(0, 0%, 79%)" };
// const orderedColors = [namedColors.BLUE, namedColors.GOLD, namedColors.ORANGE, namedColors.GREEN, namedColors.VIOLET, namedColors.GREY];
const orderedHues = [
  194, // blue
  35, // orange
  101, // green
  44, // gold
  289, // violent
  13, // red
  56, // yellow
  174, // cyan
];


const getMedalUser = () => {
	return medalUser
}


const getPeriodCount = (period) => {
	return chartParams[period || reportingPeriod].periodCount;
}

const setPeriodCount = (period, value) => {
	chartParams[period].periodCount = value;
}

const getApiSpan = (period) => {
	return chartParams[period || reportingPeriod].apiSpan;
}

const getEndDate = (period) => {
	return chartParams.endDate || getDateStringYesterday();
}

const setEndDate = (value) => {
	chartParams.endDate = value;
}

const isCustomEndDate = () => {
  console.log('is custom end date:', chartParams.endDate, getDateStringYesterday())
	return chartParams.endDate !== getDateStringYesterday();
}

const getReportPeriods = () => {
	return getReportDates().map(d => {
		switch (reportingPeriod) {
			case reportingPeriods.WEEKLY:
				return weekIndexFromDate(d);
			case reportingPeriods.MONTHLY:
				return monthIndexFromDate(d);
			case reportingPeriods.DAILY:
			default:
				return dayIndexFromDate(d);
		}
	});
}

// build a list of dates we expected to receive in the data
const buildReportDates = () => {
	const dates = [];
	var m = moment(getEndDate());
	var startPeriodOffset = 1;

	// 20230223: We never ask for `includeCurrentPeriod` when getting comparison chart data,
	// so we'll want to build this date list to exclude it except when we're in daily mode.
	if (getReportingPeriodSingular() !== "Day") {
		startPeriodOffset = 0;
		m.add(-1, getReportingPeriodSingular().toLowerCase())
	}
	for (var i = 0; i <= getPeriodCount() + startPeriodOffset; i++) {
		dates.unshift(m.toDate());
		m.add(-1, getReportingPeriodSingular().toLowerCase())
	}
	return dates;
}

const getReportDates = () => {
	var dates = chartParams[reportingPeriod].reportDates;
	if (!dates) {
		dates = buildReportDates();
		setReportDates(dates);
	}
	return dates;
}

const setReportDates = (dates) => {
	chartParams[reportingPeriod].reportDates = dates;
}

const getDateFormat = () => {
	return chartParams[reportingPeriod].dateFormat;
}

const getReportingPeriod = () => {
	return reportingPeriod;
}

const getReportingPeriodSingular = () => {
	return reportingPeriodSingular[reportingPeriod];
}

const setReportingPeriod = (value) => {
	return reportingPeriod = value;
}

const setCategoryData = (value) => {
	return categoryData = value;
}

const setGameServerData = (value) => {
	return gameServerData = value;
}

const setTagData = (id, value) => {
	return tagData[id] = value;
}

const setSubgameData = (id, value) => {
	return subgameData[id] = value;
}

const getMediumData = () => {
	return mediumData;
}

const setMediumData = (value) => {
	return mediumData = value;
}

const invalidateGameData = (reportingPeriod) => {
	games[reportingPeriod] = null;
}


const retreiveReportingPeriodFromQueryString = () => {
	const test = queryString.parse(window.location.search).p;
	switch (test) {
		case reportingPeriods.DAILY:
		case reportingPeriods.WEEKLY:
		case reportingPeriods.MONTHLY:
			return test;
		default:
			return getReportingPeriod();
	}
}

const retrievePeriodCountFromQueryString = () => {
	const test = parseInt(queryString.parse(window.location.search).n);
	return sanitizePeriodCount(test);
}

const retrieveEndDateFromQueryString = () => {
	const test = new Date(queryString.parse(window.location.search).e);
	return sanitizeEndDate(test);
}


const retreiveShareCalculationFromQueryString = () => {
	const test = queryString.parse(window.location.search).c;
	if (test !== undefined) {
		const calc = shareCalculations.find(c => c.key === test);
		if (calc !== undefined) {
			return calc.dataMember;
		}
	}
	return dataMembers.USAGE_PERCENTS;
}

const retreiveTagRankingMethodFromQueryString = () => {
	const test = queryString.parse(window.location.search).tr;
	if (test !== undefined) {
		const calc = tagRankingMethods.find(c => c.key === test);
		if (calc !== undefined) {
			return calc.dataMember;
		}
	}
	return tagDataMembers.CONTENT_COUNT;
}

const retreiveTagIncludeAutoFromQueryString = () => {
	const test = queryString.parse(window.location.search).ti;
	return (test === "1");
}

const retreiveSubgameRankingMethodFromQueryString = () => {
	const test = queryString.parse(window.location.search).sr;
	if (test !== undefined) {
		const calc = subgameRankingMethods.find(c => c.key === test);
		if (calc !== undefined) {
			return calc.dataMember;
		}
	}
	return subgameDataMembers.SESSION_COUNT;
}

const retreiveIncludeNoneSubgameFromQueryString = () => {
	const test = queryString.parse(window.location.search).si;
	return (test === "1");
}



const sanitizePeriodCount = (value) => {
	value = parseInt(value);
	if (!isNaN(value) && value > 0 && value < 32) {
		return value;
	}
	return getPeriodCount();
}

const sanitizeEndDate = (value) => {
	if (!isNaN(value.getTime()) && value < new Date() && value > new Date("2019-06-01")) {
		return value.toISOString().split('T')[0];
	}
	return getEndDate();
}

const getCategoryData = () => {
	return categoryData.sort((a, b) => (a.name > b.name ? 1 : -1));
}

const enterpriseHosts = [
  'localhost',
  'medal.test',
  'business.medal.tv',
]
const isEnterprise = () => {
  const auth = getStoredAuth()
  const user = getCachedUser()
  if (auth?.userId && auth.userId === user?.userId && user?.isStaff) {
    return true
  }
  for (const host of enterpriseHosts) {
    if (window.location.host.includes(host)) {
      return true
    }
  }
	return false
}

const allowCustomDateRange = () => {
	return true;
}

const getCategoryById = (id) => {
	return categoryData.find(c => c.id === id);
}

const getCategoryByName = (name) => {
	return categoryData.find(c => c.name === name);
}

const checkIds = (ids) => {
	return ids.reduce((c, id) => {
		if (getCategoryById(parseInt(id)) !== undefined) {
			c.push(id);
		} return c;
	}, [])
}

const checkId = (id) => {
	return (getCategoryById(parseInt(id)) !== undefined);
}

const getTopNGames = (metric, count) => {
	const sorted = [...categoryData.values()].sort((a, b) => b[metric][b[metric].length - 1] - a[metric][a[metric].length - 1]);
	return sorted.slice(0, count).map(g => g.id);
}

// Improved hash function to create a more unique number from the game name
const hashString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = Math.imul(31, hash) + str.charCodeAt(i) | 0;
  }
  return hash;
};

const colorFromName = (name, luma = 0.5) => {
  // Use the hash of the name to influence color generation
  const hash = hashString(name);
  let hue = gameHues[name];

  if (hue === undefined) {
    // Use a prime number to spread the hues more evenly around the color wheel
    const prime = 137;
    hue = Math.abs(hash * prime) % 360;
    gameHues[name] = hue;
  }

  // Adjust saturation and lightness based on the hash
  const saturation = 60 + Math.abs(hash) % 41; // Saturation between 50% and 100%
  const lightness = 60 + Math.abs(hash) % 26; // Lightness between 60% and 85%
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

const colorFromHue = (hue, sat = 65, lum = 65) => {
  return `hsl(${hue}, ${sat}%, ${lum}%)`;
};

const adjustGameHues = (games, ids) => {
	// If we throw "Red" in at the start, we can avoid it for free.
	// (Because we've been told by a customer never to show red on an upward trending thing)
	let usedHues = [0];

  const tooClose = (hue, threshold) => {
		return usedHues.reduce((c, v) => {
			let diff = Math.abs(hue - v);
			return c
				|| diff < threshold
				|| diff > (360 - threshold)
		}, false);
	}

	for (let id of ids) {
    // @todo string IDs support?
    // eslint-disable-next-line eqeqeq
    const game = games instanceof Map ? games.get(id) : games.find(game => game.id == id)
    let hue = gameHues[game.name];

    let threshold = 20;
    let limit = 10;
		while (tooClose(hue, threshold) && limit-- > 0) {
			hue = (hue + threshold) % 360;
		}
		if (limit <= 1) {
			console.log("hit nudge limit", game, hue)
		}
		game.color = colorFromHue(hue);
		usedHues.push(hue);
	}
}

///
/// Returns the querystring, updated with correct reportingPeriod
///
/// p: reporting period
/// c: calculation type / dataMember (trends)
/// t: popularity threshold (trends)
/// n: period count (custom range)
/// e: end date (custom range)
/// b: basis (prediction)
/// m: model (prediction)
/// x: extrapolation period count (prediction)
/// r: retention period (retention)
///
const getReportingPeriodQueryString = (qs, custom = {}) => {
	if (qs === undefined) {
		qs = window.location.search;
	}
	var q = queryString.parse(qs);
	q.p = reportingPeriod;
	if (allowCustomDateRange()) {
		q.n = getPeriodCount();
		q.e = getEndDate() === getDateStringYesterday() ? "latest" : getEndDate();

	}

	if (custom) {
		q = Object.assign(q,custom)
	}
	return queryString.stringify(q)
}


const buildCompareUrl = (section, ids) => {
	if (!Array.isArray(ids)) {
		ids = [ids];
	}
	const qs = getReportingPeriodQueryString(window.location.search);

	return `/${section}/${ids.join(",")}/?${qs}`;
}


const getMarketShareCalculationKey = (marketShareMethod) => {
	const key = shareCalculations.find(c => c.dataMember === marketShareMethod);
	if (key !== undefined) {
		return key.key;
	}

	return "up"
}

const getMarketShareCalculationTitle = (marketShareMethod) => {
	const key = shareCalculations.find(c => c.dataMember === marketShareMethod);
	if (key !== undefined) {
		return key.title;
	}

	return shareCalculations[0].title;
}

const getMarketShareCalculationDescription = (marketShareMethod) => {
	const key = shareCalculations.find(c => c.dataMember === marketShareMethod);
	if (key !== undefined) {
		return key.subtitle;
	}

	return shareCalculations[0].subtitle;
}

const getTagRankingMethodKey = (tagRankingMethod) => {
	const key = tagRankingMethods.find(c => c.dataMember === tagRankingMethod);
	if (key !== undefined) {
		return key.key;
	}

	return "c"
}

const getSubgameRankingMethodKey = (subgameRankingMethod) => {
	const key = subgameRankingMethods.find(c => c.dataMember === subgameRankingMethod);
	if (key !== undefined) {
		return key.key;
	}

	return "s"
}

const getTagRankingTitle = (tagRankingMethod) => {
	const key = tagRankingMethods.find(c => c.dataMember === tagRankingMethod);
	if (key !== undefined) {
		return key.title;
	}

	return "c"
}
const getSubgameRankingTitle = (tagRankingMethod) => {
	const key = subgameRankingMethods.find(c => c.dataMember === tagRankingMethod);
	if (key !== undefined) {
		return key.title;
	}

	return "s"
}

const getGameOrPlaceholder = (games, id) => {
	return typeof games.get(id) !== 'undefined' ? games.get(id) : {
		id: 0,
		name: "",
		color: "",
		abv: "",
		data: []
	}
}




// Fun History Lesson: The prototype of this app passed state to the ComparePage as a fully constructed
// line chart options object, and never stored it in a way that could be used to reconstruct the page
// on refresh.  It also didn't put anything on the URL that could be used to construct that page.
// We've repurposed it to pull the ids out of a landing page chart to build a more sensible link to the compare page.
const retreiveIdsFromChartOption = (option) => {
	var ids = [];

	if (option) {
		if (option.series) {
			option.series.forEach(data => {
				if (typeof data.id !== "undefined") {
					ids.push(data.id);
				}
			})
		}
		else if (option.ids !== "undefined") {
			ids = option.ids;
		}
	}

	return ids;
}

/*
// data utilities
*/


// determine if the cache is over 24 hours old and refresh as needed
const shouldRefreshData = (arg) => {
	const lastFetchedDate = new Date(arg.lastFetchedDate).toISOString().split('T')[0];
	if (lastFetchedDate <= new Date(getUTCYesterday()).toISOString().split('T')[0]) {
		return true
	}
	return false
}

// cast user's time to utc
const getUTCToday = () => {
	var now = new Date()
	return now.getTime() + (now.getTimezoneOffset() * 60000)
}

// get yesterday's date in utc
const getUTCYesterday = () => {
	var yesterday = new Date()
	yesterday.setDate(yesterday.getDate() - 1)
	return yesterday.getTime() + (yesterday.getTimezoneOffset() * 60000)
}

const dateFromDay = (day) => {
	return new Date(Date.UTC(1970, 0, day + 1));
}

const dateFromWeek = (week) => {
	return new Date(week * 7 * 24 * 60 * 60 * 1000);
}

const dateFromMonth = (month) => {
	return new Date(Date.UTC(1970, month - 1, 1));
}

const dateStringFromDate = (date) => {
	return date.toISOString().split("T")[0];
}

const dateStringFromDay = (day) => {
	return dateStringFromDate(dateFromDay(day));
}

const dateStringFromWeek = (week) => {
	// TODO: 2023-W15
	return dateStringFromDate(dateFromWeek(week));
}

const dateStringFromMonth = (month) => {
	return dateFromMonth(month).toISOString().substring(0, 7);
}

const dayIndexFromDate = (date) => {
	return Math.ceil((date - Date.UTC(1970, 0, 1)) / 24 / 60 / 60 / 1000)
}

const weekIndexFromDate = (date) => {
	return Math.ceil((date - Date.UTC(1970, 0, 1)) / 7 / 24 / 60 / 60 / 1000)
}

const monthIndexFromDate = (date) => {
	return yearIndexFromDate(date) * 12 + date.getMonth() + 1;
}

const yearIndexFromDate = (date) => {
	return ((new Date(date)).getYear() + 1900 - 1970)
}


const getISOWeekFromDate = (date) => {
	// from https://weeknumber.net/how-to/javascript
	var d = new Date(date.getTime());
	d.setHours(0, 0, 0, 0)
	d.setDate(d.getDate() + 3 - (d.getDay() + 6) % 7);
	// January 4 is always in week 1.
	var week1 = new Date(d.getFullYear(), 0, 4);
	// Adjust to Thursday in week 1 and count number of weeks from date to week1.
	return 1 + Math.round(((d.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
}

const getISOYearFromDate = (date) => {
	var d = new Date(date.getTime());
	d.setDate(d.getDate() + 3 - (d.getDay() + 6) % 7);
	return d.getFullYear();
}

const getDateStringYesterday = () => {
	var yesterday = new Date()
	yesterday.setDate(yesterday.getDate() - 1)
	return yesterday.toISOString().split('T')[0];
}

const getDateStringToday = () => {
	var today = new Date()
	return today.toISOString().split('T')[0];
}

const array0toN = (N) => {
	// return [0,1,2,3...N]
	return Array(N + 1).fill().map((v, i) => i);
}

// Group a list of objects by a single column, summing values in the supplied fields.
const groupSummed = (arrayOfObjects, groupByField, sumFields) => {
	return arrayOfObjects.reduce((c, v) => {
		var group = c.find(i => i[groupByField] === v[groupByField]);
		if (!group) {
			group = {};
			group[groupByField] = v[groupByField];
			sumFields.forEach(f => group[f] = 0);
			c.push(group);
		}
		sumFields.forEach(f => group[f] += v[f]);
		return c;
	}, [])
}

// Group a list of objects by a more than one column, summing values in the supplied fields.
// (slower than the above, so use that if possible)
const groupMultiSummed = (arrayOfObjects, groupByFields, sumFields) => {
	return arrayOfObjects.reduce((c, v) => {
		var group = c.find(i => groupByFields.reduce((cc,vv) => cc && i[vv]===v[vv], true));
		if (!group) {
			group = {};
			groupByFields.forEach(f => group[f] = v[f]);
			sumFields.forEach(f => group[f] = 0);
			c.push(group);
		}
		sumFields.forEach(f => group[f] += v[f]);
		return c;
	}, [])
}

// const groupUnique = (arrayOfObjects, groupByField, fieldsToKeep) => {

// 	return arrayOfObjects.reduce((c, v) => {
// 		c.find(s => s[groupByField] === v[groupByField]) || c.push({ subgameId: v.subgameId, subgameName: v.subgameName });
// 		return c;
// 	}, [])
// }


// Given a list of objects and a list of lookups, join any rows
// where the supplied list and lookup column are equal.
// Assumes zero or one lookup record per row.  Will not join more.
// Modifies the supplied list, and returns it as a result.
const joinLookup = (list, lookup, listColumn, lookupColumn) => {
	return list.map(row => {
		const match = lookup.find(test => test[lookupColumn] === row[listColumn]);
		if (match) {
			Object.assign(row, match)
		}

		return row;
	});
}


export {
	medalApiUrl,
	localApiUrl,
	medalMetricsApiUrl,
	allowApiResultCaching,
	dataMembers,
	//medalUser,
	trendingGamesCount,
	maxExtrapolationPeriods,
	trendsAccessCookieName,

	reportingPeriod,
	reportingPeriods,
	reportingPeriodSingular,
	shareCalculations,
	dataMetrics,
	getCategoryData,
	getCategoryById,
	getCategoryByName,
	setCategoryData,
	setGameServerData,
	setTagData,
	setSubgameData,
	getMediumData,
	setMediumData,
	checkIds,
	checkId,
	getTopNGames,
	colorFromName,
	adjustGameHues,
	regressionModelNames,
	categoryData,
	gameServerData,
	tagData,
	subgameData,
	games,
	chartParams,
	getPeriodCount,
	setPeriodCount,
	getApiSpan,
	getEndDate,
	isCustomEndDate,
	setEndDate,
	buildReportDates,
	getReportDates,
	setReportDates,
	getReportPeriods,
	getReportingPeriod,
	getReportingPeriodSingular,
	getDateFormat,
	setReportingPeriod,
	invalidateGameData,
	allowCustomDateRange,
	retreiveReportingPeriodFromQueryString,
	retrievePeriodCountFromQueryString,
	retrieveEndDateFromQueryString,
	retreiveShareCalculationFromQueryString,
	retreiveTagRankingMethodFromQueryString,
	retreiveTagIncludeAutoFromQueryString,
	retreiveSubgameRankingMethodFromQueryString,
	retreiveIncludeNoneSubgameFromQueryString,
	sanitizePeriodCount,
	sanitizeEndDate,
	getReportingPeriodQueryString,
	getMarketShareCalculationKey,
	getMarketShareCalculationTitle,
	getMarketShareCalculationDescription,
	getGameOrPlaceholder,
	retreiveIdsFromChartOption,
	shouldRefreshData,
	getUTCToday,
	getUTCYesterday,
	dateFromDay,
	dateFromWeek,
	dateFromMonth,
	dateStringFromDay,
	dateStringFromWeek,
	dateStringFromMonth,
	dayIndexFromDate,
	weekIndexFromDate,
	monthIndexFromDate,
	yearIndexFromDate,
	getISOWeekFromDate,
	getISOYearFromDate,
	getDateStringToday,
	getDateStringYesterday,
	array0toN,
	isEnterprise,
	getMedalUser,
	orderedHues,
	colorFromHue,
	gameHues,
	buildCompareUrl,
	groupSummed,
	groupMultiSummed,
	joinLookup,
	tagDataMembers,
	tagRankingMethods,
	autoTags,
	subgameRankingMethods,
	getTagRankingMethodKey,
	getTagRankingTitle,
	getSubgameRankingMethodKey,
	getSubgameRankingTitle,
	valueFormatMap,
	displayUnitMap,
}
