import React from 'react';
import ReactEcharts from 'echarts-for-react'

import { getReportingPeriod, getPeriodCount, dataMetrics } from "../../data/App";
import { getReportingPeriodNames, buildGameDataSetComplex } from "../../data/Chart";
import DashboardSection from '../Dashboard/DashboardSection'

class StackableCompareChart extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			ids: this.props.ids,
			title: "",
			games: new Map(),
		}
	}

	getfirstPeriod = () => {
		// Will fail in 2162. Refactor before then:
		return this.props.data.reduce((c, v) => Math.min(c, v.firstPeriod), 10000)
	}

	buildChartOption = (stacked) => {
		const idField = this.props.idField || "platform";
		const toolTipThreshold = this.props.toolTipThreshold || -1000000;
		const preserveTooltipOrder = this.props.preserveTooltipOrder || false;

		this.props.seriesList.forEach(series => {
			series.data = [];
		})

		var retentionCalculator = (gameSet) => {

			//console.log("retentionCalculator", gameSet, this.props.data, idField)
			var getMetric = (row, metric) => {
				return row[metric]
			}
			// this.props.data is an array of records that looks like this:
			// [{categoryId: 11, retentionPeriod: 4, userCount: 3522},...].
			// From that array, we'll return userCount for each record that matches our category,
			// as in: [10000, 6382, 3522, 4659]
			// They should be ordered by retentionPeriod, but we'll sort anyway.
			var result = this.props.data && this.props.data
				.filter(d => d[idField] === gameSet.id)
				.sort((a, b) => a.date > b.date ? 1 : -1)	// 1:-1 sorts in date order
				.map(d => getMetric(d, this.props.metric));

			//console.log("calc result", idField, this.props.data, gameSet)

			// In case we were given more records than we asked for...
			result = result.splice(result.length - getPeriodCount());

			return result;
		}

		const chartType = stacked ? "compareStacked" : "compare";

		const option = buildGameDataSetComplex(this.props.seriesList, retentionCalculator, chartType);

		option.tooltip.formatter = (params) => {
			const title = this.props.tooltipTitle || dataMetrics[this.props.metric].title;
			const altTitle = getReportingPeriodNames(false);
			const colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + color + '"></span>';

			let rez = `<b>${altTitle[params[0].dataIndex]}<br> ${title}</b><br>`;
			let i = 0;
			let percent = this.props.metric === "attention" ? "" : "%"

			// create default params object to account for missing data points and prepopulate it
			for (i = 0; i < params.length; i++) {
				if (typeof params[i] === "undefined") {
					params[i] = { "seriesName": "N/A", "data": [""], "value": "", "color": "#eee" }
				}
			}

			// Default is to list series in descending value order, rather than the order they were declared.
			var filtered = params.filter(p => p.componentSubType === "line" && !isNaN(p.value) && p.value > toolTipThreshold);
			var ordered;
			if (preserveTooltipOrder) {
				ordered = filtered.sort((a, b) => b.seriesIndex - a.seriesIndex);
			} else {
				ordered = filtered.sort((a, b) => b.value - a.value);
			}

			for (i = 0; i < ordered.length; i++) {
				const param = ordered[i];
				// console.log("tooltip item", param, param.seriesName)
				const found = params.find(p => p.componentSubType === "custom" && p.seriesName === param.seriesName);
				const arrow = (found || {}).data;
				rez += colorSpan(param.color) + ' '
					+ (param.seriesName.length > 20 ? `${param.seriesName.substring(0, 20)}...` : param.seriesName)
					+ ': ' + param.data.toFixed(2) + percent + ' <span style="font-weight: bolder">' + arrow + '</span></br>'

			}
			return rez;
		};

		this.setState({ option, ids: this.props.ids })
	}

	componentDidMount() {
		this.buildChartOption(this.props.stacked)
	}

	componentDidUpdate(props, state) {
		if (props.ids !== this.state.ids || props.emphasisLineIndex !== this.props.emphasisLineIndex) {
			this.buildChartOption(this.props.stacked)
		}
	}

	onChartReady = (chart) => {
		var o = {
			grid: { height: 330 }
		}
		chart.setOption(o);

		const handleClick = this.props.onClick;
		chart.on("click", (params) => {
			handleClick && handleClick(params.dataIndex);
		})
	}

	render() {
		const {  children } = this.props;
		if (!this.state.option) {
			return <div></div>
		}

		let title = `${getReportingPeriod()} platform ${dataMetrics[this.props.metric].title}`;
		// if (isCustomEndDate()) {
		// 	title = `${getReportingPeriod()} platform ${dataMetrics[this.props.metric].title.toUpperCase()} as of  ${new Date(getEndDate()).toString().substr(4, 11)}`
		// } else if (this.props.title) {
			title = this.props.title;
		// }

    return (
      <DashboardSection
        title={title}
        description={this.props.subtitle}
      >
        <ReactEcharts
          option={this.state.option}
          notMerge={true}
          lazyUpdate={true}
          theme={"medal_chart_theme"}
          onChartReady={this.onChartReady}
          style={{ height: 350 }}
          key={new Date().getTime()}
        />
        {children}
      </DashboardSection>
    )
	}
}

export default StackableCompareChart
