import React, {Fragment} from "react";
// nodejs library that concatenates classes
import queryString from 'query-string'
import styled from 'styled-components'
import {colors} from '@get-wrecked/simple-components'

// @material-ui/core components
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import TrendingGameList from "./Sections/TrendingGameList.jsx";
import { loadS3Data, getGamesList } from '../../data/Data';

import {
	getReportingPeriod,
	getReportingPeriodQueryString,
	getMarketShareCalculationKey,
	getMarketShareCalculationTitle,
	retreiveShareCalculationFromQueryString,
	getReportingPeriodSingular,
	getPeriodCount,
	buildCompareUrl,
	dataMembers,
} from "../../data/App";
import CompareGames from "./Sections/CompareGames";
import MarketShareMethodPicker from "../../components/CommonControls/MarketShareMethodPicker.jsx";
import DashboardSection from '../../components/Dashboard/DashboardSection'
import SectionLoadingPlaceholder from '../../components/Dashboard/SectionLoadingPlaceholder'

const popularityThresholds = [1, 0.5, 0.25, 0.1, 0];

const retreivePopularityThresholdFromQueryString = () => {
  const test = parseFloat(queryString.parse(window.location.search).t);
  if (!isNaN(test) && popularityThresholds.indexOf(test) > -1) {
    return test;
  }
  return 0.1;
}

// // @todo WIP
// // eslint-ignore-no-unused-vars
// const TrendsPageComponent = ({}) => {
//   const history = useHistory()
//   const [loaded, setLoaded] = useState(false)
//   const [games, setGames] = useState(new Map())
//   const [marketShareMethod, setMarketShareMethod] = useState(() => retreiveShareCalculationFromQueryString())
//   const [popularityThreshold, setPopularityThreshold] = useState(() => retreivePopularityThresholdFromQueryString())
//   const [lastReportingDescription, setLastReportingDescription] = useState(() => getReportingPeriodQueryString(""))
//
//   const onChangeMarketShareMethod = event => {
//     setMarketShareMethod(event.target.value)
//   }
//
//   const onChangePopularityThreshold = event => {
//     setPopularityThreshold(event.target.value)
//   }
//
//   const getGameListSize = useCallback((popThreshold) => {
//     const isPopularEnough = (item, threshold) => {
//       // For average session duration, filter popularity by total session duration
//       const marketShare = marketShareMethod !== dataMembers.AVG_SESSION_DURATION ? marketShareMethod : dataMembers.USAGE_PERCENTS;
//       const popularityChange = item[marketShare].slice().sort((a, b) => a > b ? 1 : -1);
//       return popularityChange[0] > threshold || popularityChange[popularityChange.length - 1] > threshold
//     }
//     const popularGames = [...games.values()].filter(item => isPopularEnough(item, popThreshold));
//     return popularGames.length;
//   }, [marketShareMethod, games])
//
//   const updateGameList = async () => {
//     try {
//       const gamesList = await getGamesList()
//       setGames(gamesList)
//       setLoaded(true)
//     } catch (err) {
//       console.error('Error updating game list:', err)
//     }
//   }
//
//   const updateUrl = () => {
//     history.replace(this.buildUrl());
//     window.document.title = "Popular, Trending & Newest Games Analytics | Medal Trends";
//   }
//
//   useEffect(() => {
//     const load = async () => {
//       try {
//         await loadS3Data()
//         await updateGameList()
//       } catch (err) {
//         console.error('Error loading trends page:', err)
//       }
//     }
//
//     document.body.scrollTop = document.documentElement.scrollTop = 0;
//     updateUrl()
//     load()
//   }, [])
//
//   return (
//     <Fragment>
//       <PageFilters>
//         <MarketShareMethodPicker
//           value={marketShareMethod}
//           onChange={onChangeMarketShareMethod}
//         />
//         <FormControl>
//           <InputLabel htmlFor="popularityThreshold">Popularity Threshold</InputLabel>
//           <Select
//             native
//             style={{ color: "#eeeeee" }}
//             value={popularityThreshold}
//             onChange={onChangePopularityThreshold}
//             inputProps={{
//               name: 'popularityThreshold',
//               id: 'popularityThreshold',
//             }}
//           >
//             {popularityThresholds.map((t,i) => {
//               return <option style={{ background: "#303030" }} value={t} key={i}>{t}% ({getGameListSize(t)} Games Eligible)</option>
//             })}
//
//           </Select>
//         </FormControl>
//       </PageFilters>
//       {this.renderSection("rising")}
//       {this.renderSection("falling")}
//       <CompareGames
//         dataMember={marketShareMethod}
//       />
//     </Fragment>
//   )
// }

class TrendsPage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			dataLoaded: false,
			games: new Map(),
			marketShareMethod: retreiveShareCalculationFromQueryString(),
			popularityThreshold: retreivePopularityThresholdFromQueryString(),
			lastReportingDescription: getReportingPeriodQueryString(""),
		}
		//this.marketShareMethodChange = this.marketShareMethodChange.bind(this);
		this.popularityThresholdChange = this.popularityThresholdChange.bind(this);
	}

	componentDidMount() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
		loadS3Data().then(() => {
        this.updateGameList();
        this.updateUrl();
		  })
      .catch(err => {
        console.error(err)
      })
	}

	updateGameList() {
		getGamesList().then(games => {
			this.setState({ games, dataLoaded: true })
		})
	}

	updateUrl() {
		this.props.history.replace(this.buildUrl());
		window.document.title = "Popular, Trending & Newest Games Analytics | Medal Trends";
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.dataLoaded) {
			//console.log("should update?", this.state.lastReportingDescription, getReportingPeriodQueryString())
			//if (this.state.lastReportingPeriod !== getReportingPeriod()) {
			if (this.state.lastReportingDescription !== getReportingPeriodQueryString()) {
				//this.setState({ lastReportingPeriod: getReportingPeriod(), dataLoaded: false, })
				this.setState({ lastReportingDescription: getReportingPeriodQueryString(), dataLoaded: false, })
				this.updateGameList();
			}
			else if (prevState.marketShareMethod !== this.state.marketShareMethod || prevState.popularityThreshold !== this.state.popularityThreshold) {
				this.updateUrl();
			}
		}
	}

	searchHandler = (compareId) => {
    console.log('compare:', buildCompareUrl('compare-page', compareId))
		this.props.history.push(buildCompareUrl('compare-page', compareId))
	}

	marketShareMethodChange = event => {
		this.setState({ marketShareMethod: event.target.value });
	}

	popularityThresholdChange = name => event => {
		this.setState({ popularityThreshold: event.target.value });
	}

	isEmbed() {
		return window.location.pathname.indexOf("/embed/") === 0;
	}

	buildUrl() {
		var q = queryString.parse(window.location.search);
		q.p = getReportingPeriod();
		q.c = getMarketShareCalculationKey(this.state.marketShareMethod);
		q.t = this.state.popularityThreshold;
		const qs = queryString.stringify(q)

		if (this.isEmbed()) {
			return `/embed/trends/${this.props.match.params.section}/?${qs}`;
		}
		return `/trends/?${qs}`;
	}

	getGameListSize = (popularityThreshold) => {
		const isPopularEnough = (item, threshold) => {
			// For average session duration, filter popularity by total session duration
			var marketShareMethod = this.state.marketShareMethod !== dataMembers.AVG_SESSION_DURATION ? this.state.marketShareMethod : dataMembers.USAGE_PERCENTS;

			var popularityChange = item[marketShareMethod].slice().sort((a, b) => a > b ? 1 : -1);
			return popularityChange[0] > threshold || popularityChange[popularityChange.length - 1] > threshold
		}

		const popularGames = [...this.state.games.values()].filter(item => isPopularEnough(item, popularityThreshold));

		return popularGames.length;
	}

	renderSection(section) {
    const isEmbed = this.isEmbed()
		const title = (section === "rising")
      ? `Fastest Rising: ${getMarketShareCalculationTitle(this.state.marketShareMethod)}`
      : `Declining: ${getMarketShareCalculationTitle(this.state.marketShareMethod)}`
    const periodCount = getPeriodCount()
    const description = `in the last ${periodCount} ${getReportingPeriodSingular().toLowerCase()}${periodCount > 1 ? 's' : ''}`
		const ascending = section !== "rising"
    const calc = getMarketShareCalculationTitle(this.state.marketShareMethod)
    const iconColor = section === 'rising'
      ? colors.success['30']
      : colors.error['10']

    // @todo restore embed message
    // const description = (isEmbed ? `Player Share is based on ${calc} observed within the Medal.tv platform, and may therefore differ from numbers generated from other sources.` : "")

    return (
      <DashboardSection
        icon={
          section === 'rising'
            ? <svg width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.0801 11.8319H11.7301L11.9201 10.1719H10.2701L10.0801 11.8319Z" fill={iconColor}/>
              <path
                d="M21.97 7.21C21.77 4.13 19.87 2.23 16.79 2.03C16.59 2.01 16.39 2 16.19 2H7.81C7.61 2 7.41 2.01 7.21 2.03C4.13 2.23 2.23 4.13 2.03 7.21C2.01 7.41 2 7.61 2 7.81V16.19C2 16.39 2.01 16.59 2.03 16.79C2.23 19.87 4.13 21.77 7.21 21.97C7.41 21.99 7.61 22 7.81 22H13.5C14.05 22 14.5 21.55 14.5 21V18.03C14.5 16.08 16.08 14.5 18.03 14.5H21C21.55 14.5 22 14.05 22 13.5V7.81C22 7.61 21.99 7.41 21.97 7.21ZM16 10.17H13.43L13.24 11.83H15.47C15.89 11.83 16.22 12.17 16.22 12.58C16.22 12.99 15.89 13.33 15.47 13.33H13.08L12.8 15.82C12.76 16.2 12.43 16.49 12.05 16.49C12.03 16.49 12 16.49 11.97 16.48C11.56 16.44 11.26 16.07 11.31 15.65L11.57 13.33H9.92L9.64 15.82C9.6 16.2 9.27 16.49 8.9 16.49C8.87 16.49 8.84 16.49 8.81 16.48C8.4 16.44 8.1 16.07 8.15 15.65L8.41 13.33H6C5.59 13.33 5.25 12.99 5.25 12.58C5.25 12.17 5.59 11.83 6 11.83H8.57L8.76 10.17H6.53C6.11 10.17 5.78 9.83 5.78 9.42C5.78 9.01 6.11 8.67 6.53 8.67H8.92L9.2 6.18C9.25 5.77 9.62 5.47 10.03 5.52C10.44 5.56 10.74 5.93 10.69 6.35L10.43 8.67H12.08L12.36 6.18C12.41 5.77 12.78 5.47 13.19 5.52C13.6 5.56 13.9 5.93 13.85 6.35L13.59 8.67H16C16.41 8.67 16.75 9.01 16.75 9.42C16.75 9.83 16.41 10.17 16 10.17Z"
                fill={iconColor}
              />
              <path
                d="M20.97 16H18.03C16.76 16 16 16.76 16 18.03V20.97C16 22.24 16.76 23 18.03 23H20.97C22.24 23 23 22.24 23 20.97V18.03C23 16.76 22.24 16 20.97 16ZM21.19 19.31C21.07 19.43 20.91 19.49 20.75 19.49C20.59 19.49 20.43 19.43 20.31 19.31L20.13 19.13V21.37C20.13 21.72 19.85 22 19.5 22C19.15 22 18.87 21.72 18.87 21.37V19.13L18.69 19.31C18.45 19.55 18.05 19.55 17.81 19.31C17.57 19.07 17.57 18.67 17.81 18.43L19.06 17.18C19.11 17.13 19.18 17.09 19.25 17.06C19.27 17.05 19.29 17.05 19.31 17.04C19.36 17.02 19.41 17.01 19.47 17.01C19.49 17.01 19.51 17.01 19.53 17.01C19.6 17.01 19.66 17.02 19.73 17.05C19.74 17.05 19.74 17.05 19.75 17.05C19.82 17.08 19.88 17.12 19.93 17.17C19.94 17.18 19.94 17.18 19.95 17.18L21.2 18.43C21.44 18.67 21.44 19.07 21.19 19.31Z"
                fill={iconColor}
              />
            </svg>
            : <svg width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.0801 11.8319H11.7301L11.9201 10.1719H10.2701L10.0801 11.8319Z" fill={iconColor}/>
              <path
                d="M21.97 7.21C21.77 4.13 19.87 2.23 16.79 2.03C16.59 2.01 16.39 2 16.19 2H7.81C7.61 2 7.41 2.01 7.21 2.03C4.13 2.23 2.23 4.13 2.03 7.21C2.01 7.41 2 7.61 2 7.81V16.19C2 16.39 2.01 16.59 2.03 16.79C2.23 19.87 4.13 21.77 7.21 21.97C7.41 21.99 7.61 22 7.81 22H13.5C14.05 22 14.5 21.55 14.5 21V18.03C14.5 16.08 16.08 14.5 18.03 14.5H21C21.55 14.5 22 14.05 22 13.5V7.81C22 7.61 21.99 7.41 21.97 7.21ZM16 10.17H13.43L13.24 11.83H15.47C15.89 11.83 16.22 12.17 16.22 12.58C16.22 12.99 15.89 13.33 15.47 13.33H13.08L12.8 15.82C12.76 16.2 12.43 16.49 12.05 16.49C12.03 16.49 12 16.49 11.97 16.48C11.56 16.44 11.26 16.07 11.31 15.65L11.57 13.33H9.92L9.64 15.82C9.6 16.2 9.27 16.49 8.9 16.49C8.87 16.49 8.84 16.49 8.81 16.48C8.4 16.44 8.1 16.07 8.15 15.65L8.41 13.33H6C5.59 13.33 5.25 12.99 5.25 12.58C5.25 12.17 5.59 11.83 6 11.83H8.57L8.76 10.17H6.53C6.11 10.17 5.78 9.83 5.78 9.42C5.78 9.01 6.11 8.67 6.53 8.67H8.92L9.2 6.18C9.25 5.77 9.62 5.47 10.03 5.52C10.44 5.56 10.74 5.93 10.69 6.35L10.43 8.67H12.08L12.36 6.18C12.41 5.77 12.78 5.47 13.19 5.52C13.6 5.56 13.9 5.93 13.85 6.35L13.59 8.67H16C16.41 8.67 16.75 9.01 16.75 9.42C16.75 9.83 16.41 10.17 16 10.17Z"
                fill={iconColor}
              />
              <path
                d="M20.97 23H18.03C16.76 23 16 22.24 16 20.97V18.03C16 16.76 16.76 16 18.03 16H20.97C22.24 16 23 16.76 23 18.03V20.97C23 22.24 22.24 23 20.97 23ZM21.19 19.69C21.07 19.57 20.91 19.51 20.75 19.51C20.59 19.51 20.43 19.57 20.31 19.69L20.13 19.87V17.63C20.13 17.28 19.85 17 19.5 17C19.15 17 18.87 17.28 18.87 17.63V19.87L18.69 19.69C18.45 19.45 18.05 19.45 17.81 19.69C17.57 19.93 17.57 20.33 17.81 20.57L19.06 21.82C19.11 21.87 19.18 21.91 19.25 21.94C19.27 21.95 19.29 21.95 19.31 21.96C19.36 21.98 19.41 21.99 19.47 21.99C19.49 21.99 19.51 21.99 19.53 21.99C19.6 21.99 19.66 21.98 19.73 21.95C19.74 21.95 19.74 21.95 19.75 21.95C19.82 21.92 19.88 21.88 19.93 21.83C19.94 21.82 19.94 21.82 19.95 21.82L21.2 20.57C21.44 20.33 21.44 19.93 21.19 19.69Z"
                fill={iconColor}
              />
            </svg>
        }
        title={title}
        description={description}
      >
        <TrendingGameList
          games={this.state.games}
          dataMember={this.state.marketShareMethod}
          popularityThreshold={this.state.popularityThreshold}
          isEmbed={isEmbed}
          renderShareIcon={true}
          section={section}
          ascending={ascending}
          title={title}
          calc={calc}
        />
      </DashboardSection>
    )

	}


	renderEmbedded() {
		var sectionKey = this.props && this.props.match && this.props.match.params
			&& this.props.match.params.section && this.props.match.params.section.toLowerCase
			&& this.props.match.params.section.toLowerCase();	// Javascript!

		if (["rising", "falling"].indexOf(sectionKey) > -1) {
			return this.renderSection(sectionKey);
		}

		return null;
	}


	render() {
		if (!this.state.dataLoaded) {
			return (
				<Fragment>
          <SectionLoadingPlaceholder minHeight={'500px'}/>
          <SectionLoadingPlaceholder minHeight={'500px'}/>
          <SectionLoadingPlaceholder minHeight={'500px'}/>
        </Fragment>
			)
		}

		if (this.isEmbed()) {
			return this.renderEmbedded();
		}

		return (
      <Fragment>
        <PageFilters>
          <MarketShareMethodPicker
            value={this.state.marketShareMethod}
            onChange={this.marketShareMethodChange}
          />
          <FormControl>
            <InputLabel htmlFor="popularityThreshold">Popularity Threshold</InputLabel>
            <Select
              native
              style={{ color: "#eeeeee" }}
              value={this.state.popularityThreshold}
              onChange={this.popularityThresholdChange()}
              inputProps={{
                name: 'popularityThreshold',
                id: 'popularityThreshold',
              }}
            >
              {popularityThresholds.map((t,i) => {
                return <option style={{ background: "#303030" }} value={t} key={i}>{t}% ({this.getGameListSize(t)} Games Eligible)</option>
              })}

            </Select>
          </FormControl>
        </PageFilters>
        {this.renderSection("rising")}
        {this.renderSection("falling")}
        <CompareGames dataMember={this.state.marketShareMethod}/>
      </Fragment>
		);
	}
}

export default TrendsPage;

const PageFilters = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`
