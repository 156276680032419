import React, {Fragment} from "react";

// @material-ui/core components
import { getCategoryData, isEnterprise, gameServerData } from "../../data/App";
import { loadCategoryData, getGamesList, calculatePeriodShares } from "../../data/Data";

import { getReportingPeriodQueryString, getCategoryById } from "../../data/App";
import EmbedAvatarList from '../../components/Embed/EmbedAvatarList'
import CompoundPieList from '../../components/Charts/CompoundPieList.jsx'
import { Redirect } from 'react-router-dom';
import { combineOtherRows, populateServerData, sumFields } from "./ServersLandingPage";
import StackableCompareChart from "../../components/Charts/StackableCompareChart.jsx";
import SectionLoadingPlaceholder from '../../components/Dashboard/SectionLoadingPlaceholder'

class ServerDetailPage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			dataLoaded: false,
			search: false,
			games: new Map(),
			categories: [],
		}
	}

	componentDidMount() {
		if (!isEnterprise()) {
			this.setState({ redirectTo: '/trends/' });
			return;
		}

    document.body.scrollTop = document.documentElement.scrollTop = 0;

		loadCategoryData().then(() => {
			// These are Server ids, so we can't check them here yet.
			const ids = this.retreiveIds();

			if (ids.length === 0) {
				this.setState({ redirect: true });
				return;
			}

			this.setState({ ids, lastReportingDescription: this.buildUrl(ids) }, () => {
				this.populateFromIds(false);
			});
		})
	}

	componentDidUpdate(lastProps) {
		if (this.state.ids !== undefined
			&& this.state.dataLoaded
			&& !this.state.redirect
			&& this.state.lastReportingDescription !== this.buildUrl(this.state.ids)) {

			this.populateFromIds(false);
		}
	}

	removeCategory = (category) => {
		// build a new array of active ids
		var ids = this.state.ids.slice();
		ids = ids.filter(id => id !== category.id);

		this.setState({ ids }, () => {
			this.populateFromIds(true);
		});
	}

	searchHandler = (compareId) => {
		var ids = this.state.ids.slice();
		ids.push(compareId);
		this.setState({ ids }, () => {
			this.populateFromIds(true);
		});
	}

	showSearch = () => {
		this.setState({ search: true })
	}

	isEmbed() {
		return window.location.pathname.indexOf("/embed/") === 0;
	}

	retreiveIds() {
		var loc = this.props.location;
		if (loc.ids) {
			return loc.ids;
		}

		// NOTE: Ids survive page reloads by nature of us having pushed our state into the browser history.
		if (loc.state && loc.state.ids) {
			return loc.state.ids;
		}

		if (this.props && this.props.match && this.props.match.params && this.props.match.params.ids) {
			const ids = this.props.match.params.ids.split(",");
			return ids;
		}

		console.warn("NO IDs FOUND. Redirecting to landing page.")
		return [];
	}

	populateFromIds() {
		// TODO:
		//var period = "day";
		var otherThreshold = 10;
		var loadCount = 50;

		let ids = this.state.ids
		if (ids && ids.length > 0) {
			this.setState({ dataLoaded: false })

			getGamesList().then(games => {
				populateServerData().then(() => {

					// filter raw data by the categories we care about, then rebuild server (and game share?) lists.
					// - recalc %s
					// - other group

					const { serverCompareData, categoryDataAsPercents, serverDataAsPercents, serverLookup } = gameServerData;

					// Filter the dataset by the categories specified.
					// Leave the category chart with its %'s of total share.
					// Re-calculate %'s for servers within the categories specified.
					var filteredServerCompareData = serverCompareData.filter(r => ids.indexOf(r.gameServerId) > -1)

					// Filter by category (after first figuring out which categories our server ids correspond to)
					const categoriesInUse = ids.map(id => serverLookup[id] && serverLookup[id].categoryId);
					const filteredCategoryDataAsPercents = categoryDataAsPercents.filter(r => categoriesInUse.indexOf(r.categoryId) > -1)

					// For the % over time chart on a single server, we can just filter the % breakdown by server data to just this one
					var filteredServerDataAsPercents = serverDataAsPercents.filter(r => ids.indexOf(r.gameServerId) > -1)

					const filteredServerCompareDataAsPercents = calculatePeriodShares(filteredServerCompareData, ["sessionCount", "userCount", "totalSeconds"], "")

					// top 50 ids
					const filteredTopIds = filteredServerCompareData
						.sort((a, b) => b.sessionCount - a.sessionCount)
						.slice(0, loadCount).map(g => g.gameServerId);

					// top 10 ids
					const topServerSlice = filteredTopIds.slice(0, otherThreshold);

					const otherGroupedFilteredServerDataAsPercents = combineOtherRows(filteredServerDataAsPercents, topServerSlice, "gameServerId", "name", ["date"], sumFields)


					// We lost this game info, so add it back
					filteredServerDataAsPercents.forEach(s => {
						var game = getCategoryById(s.categoryId);
						if (game) {
							// copy the game info so we don't overwrite it globally
							game = JSON.parse(JSON.stringify(game));
							game.sessionCount = 100;
							s.avatar = game.avatar;
							s.color = game.color;

							// a list of games for this server.  (Yeah, there's only ever one, but the data structure wants a list)
							s.games = [game];
						}

						// daily counts for this server
						var days = filteredServerDataAsPercents.filter(ss => ss.gameServerId === s.gameServerId);
						s.sessionCounts = days.map(d => d.sessionCount);

					});

					this.setState({
						...gameServerData,
						filteredServerData: otherGroupedFilteredServerDataAsPercents,
						filteredCategoryDataAsPercents,
						filteredServerCompareDataAsPercents,
						filteredServerDataAsPercents,
						filteredTopIds,
						topServerSlice,
						dataLoaded: true,
						lastReportingDescription: this.buildUrl(ids),
					});
				});
			});

		} else {
			this.setState({ redirect: true });
		}

		this.props.history.replace(this.buildUrl(ids));
	}

	buildUrl(ids) {
		if (!ids) {
			return "";
		}
		if (this.isEmbed()) {
			return `/embed/server-detail/${ids.join(",")}/${this.props.match.params.section}/?${getReportingPeriodQueryString()}`;
		}
		return `/server-detail/${ids.join(",")}/?${getReportingPeriodQueryString()}`;
	}

	buildTitle(ids) {
		return ids.reduce((title, id) => {
			const game = getCategoryById(id);
			if (game !== undefined) {
				title += (title === "" ? "" : " vs. ") + game.name;
			}
			return title
		}, "") + " Retention | Medal Trends";
	}

	renderServerComparisonChart = (metric) => {
		return <StackableCompareChart
			data={this.state.filteredServerDataAsPercents}
			seriesList={Object.values(this.state.serverLookup)}
			ids={this.state.ids}
			isEmbed={this.isEmbed()}
			metric={metric}
			idField="gameServerId"
			title="Server Session Counts"
			subtitle="Relative percentages of game sessions across all game servers."
			section={metric}
			useLogScale={false}
			stacked={true}
		/>
	}

	renderGameComparisonChart = (metric) => {
		return <StackableCompareChart
			//data={this.state.filteredServerDataAsPercents}
			data={this.state.filteredCategoryDataAsPercents}
			seriesList={getCategoryData()}
			ids={this.state.ids}
			isEmbed={this.isEmbed()}
			metric={metric}
			idField="categoryId"
			title="Server Session Counts by Game"
			subtitle="Relative percentages of game sessions across all games."
			section={metric}
			useLogScale={false}
			stacked={false}
		/>
	}

	renderServerList(metric) {
		return <CompoundPieList
			// games={this.state.games}
			//data={this.state.filteredServerCompareDataAsPercents}
			data={this.state.filteredServerDataAsPercents}
			gameOrder={this.state.filteredTopIds}
			hideIndex={true}
			dataMember="games"
			idField="gameServerId"
			toolIdField="categoryId"
			nameField="name"
			toolNameField="name"

			//valueField="category_name"
			reportingColumn="sessionCount"
			linkPath="server-detail"
			metric={metric}
			isEmbed={this.isEmbed()}
			section={metric}
			showTrend={true}
			title="Top Server"
			subtitle="Relative percentages of server sessions across all games during the selected date range."
			toolTipHeader=" "
			toolTipTotal="of total server sessions across all games"
			pageSize={10}
		/>
	}

	renderEmbedded() {
		var section = this.props && this.props.match && this.props.match.params
			&& this.props.match.params.section && this.props.match.params.section.toLowerCase
			&& this.props.match.params.section.toLowerCase();	// Javascript!

		switch (section) {

			case "platforms":
				return this.renderPlatformList();

			case "sharepercents":
				return this.renderComparisonChart("sharePercents");

			default:
				return <div></div>;
		}

	}

	renderEmbeddedWithGames() {
		return (
			<div>
				<EmbedAvatarList ids={this.state.ids} />
				{this.renderEmbedded()}
			</div>
		)
	}

	render() {
		if (this.state.redirect) {
			return <Redirect push to={`/servers/?${getReportingPeriodQueryString()}`} />
		}
		if (this.state.redirectTo !== undefined) {
			return <Redirect push to={this.state.redirectTo} />;
		}

		if (!this.state.dataLoaded) {
			return (
				<Fragment>
          <SectionLoadingPlaceholder minHeight="183px"/>
          <SectionLoadingPlaceholder minHeight="350px"/>
          <SectionLoadingPlaceholder minHeight="350px"/>
        </Fragment>
			)
		}

		if (this.isEmbed()) {
			return this.renderEmbeddedWithGames();
		}

		return (
			<Fragment>
        {this.renderServerList("sessionCounts")}
        {this.renderServerComparisonChart("sessionCount")}
        {this.renderGameComparisonChart("sessionCount")}
      </Fragment>
		);
	}
}

export default ServerDetailPage;
