import React, {Fragment} from "react";

import { buildCompareUrl, getCategoryById, getReportPeriods, getReportingPeriodQueryString, getReportingPeriodSingular, groupMultiSummed, groupSummed, isEnterprise, setTagData, tagData } from "../../data/App";
import { loadCategoryData,  getGamesList, getTagContentViewers, calculatePeriodShares, extractColorList } from "../../data/Data";
import CompoundPieList from '../../components/Charts/CompoundPieList.jsx'
import EmbedAvatarList from '../../components/Embed/EmbedAvatarList'


import { Redirect } from "react-router-dom";
import {PageHeader} from '../../components/styles'
import SectionLoadingPlaceholder from '../../components/Dashboard/SectionLoadingPlaceholder'

const sectionDescriptions = {
	"shares": "How many times clips are shared on each platform. The higher the number, the more popular a platform is for sharing clips. The number shown is a percentage of all clips shared across all platforms.",
	"viewers": "How many times clips shared on a given platform were viewed. It's a good indication of where people are going to view clips. The number shown is a percentage of all clips viewed across all platforms.",
	"attention": "How many views a shared clip is expected to see after being shared on the platform in question. These are average counts of views per share, rather than a percentage.",
	"contentCount": "Percent breakdown of clip tag counts, among the 50 most popular tags for the game in question.",
	"likes": "Percent breakdown of tagged clip likes, among the 50 most popular tags for the game in question.",
	"views": "Percent breakdown of tagged clip views, among the 50 most popular tags for the game in question.",
}

export const loadTagData = (id) => {
	return new Promise((resolve, reject) => {
		// TODO: also cache by id
		if (id && tagData && tagData[id] && tagData[id].dataLoaded) {
			// console.log("tagData already loaded", id, tagData[id])
			resolve(tagData[id]);
			return;
		}

		Promise.all([
			getTagContentViewers(id)
		]).then((values) => {
			const period = getReportingPeriodSingular().toLowerCase();
			const data = values[0].data;
			data.forEach(r => r[period] = r.period);

			// Ditch emojis
			data.forEach(t => { t.tag = t.tag.replace(/[^0-9a-z_ ]/gi, '') })

			var td = {
				...transformTags(data),
				dataLoaded: true
			}

			// TODO: here too
			if (id) {
				setTagData(id,td);
			}

			// console.log("tagData", id, td)
			resolve(td);
		})
	});
}

// Date + ONE additional column
// TODO: extract
export const zeroFillMissingTagDays = (rows, dateColumn, keyColumn, valueColumns) => {
	console.time("zeroFill")

	// NOTE: This allows string keys, so it doesn't work the same
	// as the version in ServersLandingPage
	var findUniques = (rows, col) => Object.keys(rows.reduce((c, v) => {
		c[v[col]] = true;
		return c;
	}, {}))

	// This always returns strings, so in this case we'll need
	// to turn our days back to int.
	var dates = getReportPeriods();
	var keys = findUniques(rows, keyColumn);

	var emptyRow = valueColumns.reduce((c, v) => { c[v] = 0; return c }, {});
	//console.log("prep", rows, emptyRow, keys, dates)

	var newRows = dates.reduce((c, date) => {
		// filtering down before the loop drops us from 12ms to 2ms
		var working = rows.filter(r => r[dateColumn] === date);

		keys.forEach(k => {
			if (!working.some(r => r[dateColumn] === date && r[keyColumn] === k)) {
				var row = JSON.parse(JSON.stringify(emptyRow));
				row[dateColumn] = date;
				row[keyColumn] = k;
				c.push(row);
			}
		});

		return c;
	}, []);

	//console.log("filling", dates, keys, newRows)
	console.timeEnd("zeroFill")
	return rows.concat(newRows);
}

export const transformTags = (tagData, loadCount = 50, otherThreshold = 10) => {
	// NOTE: This is only used for the landing page.  We might be able
	// to skip calling it for other tag pages.

	// TODO
	//const period = "day";

	// TODO: zero fill all tag/game combos?
	//console.log("zeroFilled", serverData);

	// TODO: do this for each game, or on demand in the detail page
	// TODO: we only want to do this per game, so it doesn't make sense to not filter by a single game.
	// HACK:
	//tagData = tagData.filter(t => t.categoryId === 4854)
	//const categoryFilteredTagData = tagData.filter(t => t.categoryId === 62)

	console.time("transformTagDataNew")

	const countColumns = ["contentCount", "views", "likes"];

	// We'll give every tag a global color
	const groupedByTag = groupSummed(tagData, "tag", countColumns);
	const tagColorLookup = extractColorList(groupedByTag, "tag", "tag");
	tagColorLookup["Other"] = {
		"id": "Other",
		"name": "Other",
		"avatar": "./.png",
		"data": [],
		"color": "hsl(0, 0%, 40%)"
	}
	//console.log("top tag colors?", groupedByTag, tagColorLookup)

	// Process things across all categories first.
	// Build the Most Tagged Games list
	const groupedByCategoryId = groupSummed(tagData, "categoryId", countColumns)
	const groupedByCategoryIdTag = groupMultiSummed(tagData, ["categoryId", "tag"], countColumns)
	const groupedByCategoryIdTagAsPercents = calculatePeriodShares(groupedByCategoryIdTag, countColumns, "categoryId");

	// Note: Here, we pull in extra categories in case our top tagged games aren't
	// in the top 300 popular games.  Then we filter to ones that are and slice again.
	var topCategoryIds = groupedByCategoryId
		.sort((a, b) => b.contentCount - a.contentCount)
		.slice(0, loadCount * 2).map(g => g.categoryId)
		.filter(id => getCategoryById(id) !== undefined)
		.slice(0, loadCount);

	var topGamesWithTags = topCategoryIds.map(topId => {
		const g = getCategoryById(topId);
		if (g) {
			const game = JSON.parse(JSON.stringify(g));
			game.tags = groupedByCategoryIdTagAsPercents.filter(r => r.categoryId === topId);
			game.platforms = game.tags.map(t => {
				t.color = tagColorLookup[t.tag].color;

				// We're still rendering this as a "platform list", so
				// we'll use definitions for that component.
				return {
					category_id: t.categoryId,
					platform: t.tag,
					shares: t.contentCount,
					viewers: t.views,
					color:t.color,
				};
			});
			return game;
		}
		console.log("missing game", topId)
		return null;
	}).filter(r => r !== null);
	//console.log("topGamesWithTags", topGamesWithTags, groupedByCategoryIdTag)
	console.timeEnd("transformTagDataNew")

	// Top Tags over time stacked line chart
	// Below here, this can all be moved to the compare page,
	// since we don't
/*
	// Top tags by session count
	const topTags = groupedByTag
		.sort((a, b) => b.sessionCount - a.sessionCount)
		.slice(0, loadCount).map(g => g.tag);

	// Note that we grouped by tag & content before zero filling.
	tagData = zeroFillMissingTagDays(tagData, period, "tag", countColumns);
	console.log("zero filled", tagData)

	const groupedByTagPeriod = groupMultiSummed(tagData, ["tag", period], countColumns);
	const groupedByCategoryIdPeriod = groupMultiSummed(tagData, ["categoryId", period], countColumns);


	// TODO: join games in here somewhere...

	// const tagDataAsPercents = calculatePeriodShares(tagData, countColumns, period)
	// 	.sort((a, b) => a.date > b.date ? 1 : -1);	// 1:-1 sorts in date order

	const groupedByTagPeriodAsPercents = calculatePeriodShares(groupedByTagPeriod, countColumns, period);

	const topTagSlice = topTags.slice(0, otherThreshold);

	//const otherGroupedTagDataAsPercents = combineOtherRows(tagDataAsPercents, topTagSlice, "tag", "tag", ["date"], countColumns)
	const otherGroupedTagPeriodAsPercents = combineOtherRows(groupedByTagPeriodAsPercents, topTagSlice, "tag", "tag", ["day"], countColumns)
		.sort((a, b) => a.day < b.day ? 1 : -1);	// 1:-1 sorts in date order

	// For Servers, we needed an extra round trip to get game mappings.  Here we have everything we need.

	// do this later after we've grouped
	// joinLookup(tagData, categoryData, "categoryId", "id");
	// console.log("join games into tag data", categoryData, tagData)


	console.log("topIds & grouped", topTags, topCategoryIds, groupedByTag, groupedByCategoryId)
	console.log("pair groups", groupedByTagPeriod, groupedByCategoryIdPeriod, groupedByTagPeriodAsPercents)

	console.log("other grouped", otherGroupedTagPeriodAsPercents)

*/

	// We'll want to include some of the expensive groupings
	// that we did above in the data we return, because
	// we'll still need to build on it when we do further
	// processing on the compare page.

	return {
		// for landing page
		topCategoryIds,
		topGamesWithTags,

		// for onward processing
		tagData,
		countColumns,
		tagColorLookup,
		groupedByTag,
	}
}

class TagsLandingPage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			dataLoaded: false,
			search: false,
			games: new Map(),
			platforms: new Map(),
			categories: [],
		}
	}

	componentDidMount() {
		if (!isEnterprise()) {
			this.setState({ redirectTo: '/trends/' });
			return;
		}
		loadCategoryData().then(() => {
			this.populateTop();
		});
	}

	componentDidUpdate() {
		if (this.state.dataLoaded && this.state.lastReportingDescription !== this.buildUrl()) {
			this.populateTop();
		}
	}

	searchHandler = (compareId) => {
		this.props.history.push(buildCompareUrl('platform-compare', compareId))
	}

	buildQueryString = () => {
		return getReportingPeriodQueryString(window.location.search, { r: this.state.retentionPeriod });
	}

	isEmbed() {
		return window.location.pathname.indexOf("/embed/") === 0;
	}

	populateTop() {
		this.setState({ dataLoaded: false })
		getGamesList().then(games => {
			loadTagData().then((processed) => {
				this.setState({
					...processed,
					dataLoaded: true,
					lastReportingDescription: this.buildUrl(),
				});
			});
		});
	}

	buildUrl() {
		if (this.isEmbed()) {
			return `/embed/platforms/${this.props.match.params.section}?${this.buildQueryString()}`;
		}
		return `/platforms/?${this.buildQueryString()}`;
	}

	buildTitle(ids) {
		return "Platforms | Medal Trends";
	}

	renderGameList(metric) {
		// console.log("renderGameList", this.state.topGamesWithTags, this.state.categoryPlatforms)
		console.log("renderGameList", this.state.topIds, this.state.topCategoryIds)
		return <CompoundPieList
			data={this.state.topGamesWithTags}
			gameOrder={this.state.topCategoryIds}
			metric={metric}
			linkPath="tag-compare"
			isEmbed={this.isEmbed()}
			section={metric}
			reportingColumn="shares"
			title="Most Tagged Games"
			subtitle="Relative platform representation for clip tagging across the top tagged games. Percentages represent the fraction of tags across all games for the game in question."
			toolTipHeader="Tags by game"
			toolTipTotal="of total clips tagged"
		/>
	}

	renderPlatformList(metric) {
		return <CompoundPieList
			// games={this.state.games}
			data={this.state.platformCategories}
			gameOrder={this.state.platformOrder}
			hideIndex={true}
			dataMember="games"
			valueField="category_name"
			nameField="name"
			toolNameField="category_name"
			linkPath="platform-detail"
			metric={metric}
			isEmbed={this.isEmbed()}
			section={metric}
			reportingColumn="shares"
			title="Top Platforms"
			subtitle="Relative percentages of clip shares across the top sharing platforms. Game percentages represent the fraction of shares across all games for the platform in question."
			pageSize={10}
		/>
	}

	renderEmbedded() {
		var section = this.props && this.props.match && this.props.match.params
			&& this.props.match.params.section && this.props.match.params.section.toLowerCase
			&& this.props.match.params.section.toLowerCase();	// Javascript!


		switch (section) {
			case "shares":
			case "viewers":
			case "attention":
				return this.renderComparisonChart(section);

			case "platform-shares":
				return this.renderPlatformList("platform-shares");

			case "game-shares":
				return this.renderGameList("game-shares");

			default:
				return <div></div>;
		}
	}

	renderEmbeddedWithGames() {
		return (
			<div>
				<EmbedAvatarList ids={this.state.ids} />
				{this.renderEmbedded()}
			</div>
		)
	}

	render() {
		if (this.state.redirectTo !== undefined) {
			return <Redirect push to={this.state.redirectTo} />;
		}

		if (!this.state.dataLoaded) {
			return (
				<SectionLoadingPlaceholder minHeight="400px"/>
			)
		}

		if (this.isEmbed()) {
			return this.renderEmbedded();
		}

		return (
      <Fragment>
        {/*<Search*/}
        {/*  itemsList={getCategoryData().map((game) => ({ label: game.name, value: game.id }))}*/}
        {/*  handler={this.searchHandler}*/}
        {/*/>*/}
        <PageHeader>
          Tag Trends
          <span>
            NOTE: Percentage shares shown below represent observations within the Medal.tv platform, and therefore may differ from numbers generated from other sources.
          </span>
        </PageHeader>
        {this.renderGameList("tagPercents")}
      </Fragment>
		);
	}
}

export default TagsLandingPage
export {
	sectionDescriptions
}
