import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {colors} from '@get-wrecked/simple-components'
import LoadMoreButton from '../LoadMoreButton'

const propTypes = {
  children: PropTypes.node,
  description: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  onLoadMore: PropTypes.func,
  gap: PropTypes.string,
  style: PropTypes.object,
}

const DashboardSection = ({title, description, icon, children, onLoadMore, gap, style}) => {
  return (
    <Container $gap={gap} style={style}>
      {
        (title || description) &&
        <Header>
          {
            icon && icon
          }
          <Text>
            {
              title &&
              <Title>
                {title}
              </Title>
            }
            {
              description &&
              <Description>
                {description}
              </Description>
            }
          </Text>
        </Header>
      }
      {children}
      {
        typeof onLoadMore === 'function' &&
        <LoadMoreButton onClick={onLoadMore}/>
      }
    </Container>
  )
}

DashboardSection.propTypes = propTypes

export default DashboardSection

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.background['second-layer']};
  border-radius: 8px;
  border: 1px solid ${colors.stroke['0A8']};
  padding: 16px;
  gap: ${({$gap}) => $gap ?? '16px'};
  flex: 1;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid ${colors.stroke['0A8']};
  flex: 1;
  gap: 12px;
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h2`
  font-size: 15px;
  font-weight: 600;
  color: ${colors.text['0']};
  padding: 0;
  margin: 0;
  font-family: "DM Sans", sans-serif !important;
  display: flex;
  text-transform: capitalize;
`

const Description = styled.span`
  font-size: 13px;
  font-weight: normal;
  color: ${colors.text['30']};
  margin-top: -2px;
  line-height: 1.5;
`
