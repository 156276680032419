let _auth = null

/**
 * Get the auth from the stored cookie and cache it.
 * @returns {{userId: string, key: string, expires: number}|null}
 */
export const getStoredAuth = () => {
  if (!_auth) {
    _auth = getAuthFromMedalAuthCookie()
  }
  return _auth
}

/**
 * Update the stored auth cookie.
 * @param {object} auth
 * @param {string} auth.userId
 * @param {string} auth.key
 * @param {number} auth.expires
 * @param {boolean} registeredUser
 */
export const updateStoredAuth = (auth, registeredUser) => {
  _auth = auth
  updateMedalAuthCookie(auth, registeredUser)
}

/**
 * Delete the stored auth in localStorage.
 */
export const deleteStoredAuth = () => {
  _auth = null
  deleteMedalAuthCookie()
}

//// LEGACY COOKIE INTERACTIONS ////

const getCookie = (name) => {
  return document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`))?.at(2);
}

/**
 * Get the auth from the medal-web auth cookie.
 * @returns {object|undefined}
 */
const getAuthFromMedalAuthCookie = () => {
  const cookie = getCookie('medal-auth')
  return cookie ? JSON.parse(atob(decodeURI(cookie))) : undefined
}

const getCookieDomain = () => {
  const host = window.location.host
  if (host.startsWith('localhost')) {
    return ''
  }

  const hostWithoutPort = host.split(':')[0]
  const hasSubdomain = hostWithoutPort.endsWith('.medal.tv') ||
    hostWithoutPort.endsWith('.test-medal.tv') ||
    hostWithoutPort.endsWith('.staging-medal.tv')

  if (hasSubdomain) {
    const domainArgs = hostWithoutPort.split('.')
    domainArgs.shift()
    return ` domain=.${domainArgs.join('.')};`
  }
  return ` domain=.${hostWithoutPort};`
}

/**
 * Update the medal-web auth cookie.
 * @param auth
 * @param registeredUser
 */
export const updateMedalAuthCookie = (auth, registeredUser) => {
  // const date = new Date()
  // date.setTime(auth.expires)

  const oneYearFromNow = new Date()
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)

  const medalAuthCookie = {
    ...auth,
    id: auth.userId,
    auth,
    registeredUser
  }

  const base64Auth = encodeURI(btoa(JSON.stringify(medalAuthCookie)))
  const isSecure = !window.location.host.startsWith('medal.test')
  const secureFlag = isSecure ? ' Secure;' : ''
  const sameSiteFlag = isSecure ? ' SameSite=None;' : ' SameSite=Lax;'
  const cookie = `medal-auth=${base64Auth}; expires=${oneYearFromNow.toUTCString()}; path=/;${getCookieDomain()}${secureFlag}${sameSiteFlag}`

  const beforeCookies = document.cookie
  document.cookie = cookie;
  if (beforeCookies === document.cookie) {
    console.error('cookies didnt update')
  }
}

/**
 * Delete the medal-web auth cookie.
 */
const deleteMedalAuthCookie = () => {
  document.cookie = `medal-auth=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;${getCookieDomain()}`
}
