import React from 'react';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import basicsStyle from "../../../assets/jss/material-kit-react/views/componentsSections/basicsStyle.jsx";

import Card from "../../../components/Card/Card.jsx";
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import PieChart from '../../../components/Charts/PieChart'
import EmbedOverlay from '../../../components/Embed/EmbedOverlay'

import { getCategoryById } from '../../../data/App'

// enhance the style object
let style = Object.assign({
	card: {
		flex: 1,
		padding: 0,
		margin: 10,
		backgroundColor: '#212121',
	}
},
	{
		cardContent: {
			padding: 0,
			margin: 0,
		}
	},
	{
		cardHeader: {
			borderBottom: '0.01em solid grey',
		}
	},
	{
		gridContainer: {
			flexGrow: 1
		}
	},
	basicsStyle);


class PlatformViewing extends React.Component {

	getSmallColspan() {
		return this.props.isEmbed ? 6 : 8;
	}

	render() {
		if (this.props.views.length === 0) {
			return (
				<div></div>
			)
		}
		const { classes } = this.props;
		return (
			<div>
				<EmbedOverlay {...this.props} />
				<Card className={classes.card + " sectionCard"}>
					<CardHeader title={"PLATFORMS: VIEWING"}
						subheader="Viewing percentages represent the fraction of total clip views for each viewing platform.  So, for the game in question, X% of all clip views happened via the specified platform or source."
					/>
					<CardContent className={classes.cardContent}>
						<Grid container spacing={16} justify={'center'}>
							{
								this.props.views.map((views, index) => {
									return (
										<Grid item xs={8} sm={this.getSmallColspan()} md={6} key={index}>
											<PieChart
												option={views}
												name={
													typeof getCategoryById(views.id * 1) !== "undefined"
														? getCategoryById(views.id * 1).abv
														: ""
												}
												avatar={
													typeof getCategoryById(views.id * 1) !== "undefined"
														? getCategoryById(views.id * 1).avatar
														: "./62.png"
												}
												key={`${views.id}_${new Date().getTime()}`}
												id={views.id}
											/>
										</Grid>
									)
								})
							}
						</Grid>
					</CardContent>
				</Card>
			</div>
		)
	}
}

export default withStyles(style)(PlatformViewing)
