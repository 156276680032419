import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import basicsStyle from "../../assets/jss/material-kit-react/views/componentsSections/basicsStyle.jsx";

// @material-ui/icons
import Typography from '@material-ui/core/Typography';

import Avatar from '../Avatar/Avatar'
import echarts from 'echarts';
import Grid from '@material-ui/core/Grid';
import ReactEcharts from 'echarts-for-react'

echarts.registerTheme('medal_chart_theme', {
	// Full map default background
	backgroundColor: 'rgba(33,33,33,1)',
});

class PieChart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pieChart: this.props.option
		};
		this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
	}

	handleChange = name => event => {
		this.setState({ [name]: event.target.checked });
	};

	handleChangeEnabled(event) {
		this.setState({ selectedEnabled: event.target.value });
	}

	handleToggle(value) {
		const { checked } = this.state;
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		this.setState({
			checked: newChecked
		});
	}

	// only update the ui if the pieChart data has changed
	shouldComponentUpdate(nextProps, nextState) {
		if (this.state.pieChart !== nextState.pieChart) {
			return true;
		}
		return false;
	}

	render() {
		return (
			<div style={{ paddingTop: 10 }}>
				<Grid container direction='column' justify='flex-start'>
					<Grid item xs={12}>
						<Grid container justify="flex-start" alignItems={"center"} spacing={16}>
							<Grid item style={{ paddingLeft: 25 }}>
								<Avatar
									name={this.props.name}
									size={'small'}
									src={this.props.avatar}
									id={this.props.id}
								/>
							</Grid>
							<Grid item>
								<Typography variant="title" component="h1" style={{
									fontFamily: 'Rajdhani',
									color: 'white',
									fontWeight: 700,
									textShadow: '-1px 0 DimGray, 0 1px DimGray, 1px 0 DimGray, 0 -1px DimGray'
								}}>{this.props.name.toUpperCase()}</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<hr style={{ borderColor: '#575757' }} />
					</Grid>
					<Grid item xs={12} sm={12}>
						<ReactEcharts
							option={this.state.pieChart}
							notMerge={true}
							lazyUpdate={true}
							theme={"medal_chart_theme"}
							// onChartReady={this.onChartReadyCallback}
						/>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default withStyles(basicsStyle)(PieChart);
