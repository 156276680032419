import React, {Fragment, useContext, useMemo, useState} from 'react'
import styled from 'styled-components'
import {colors, Avatar, Button, ContextMenu} from '@get-wrecked/simple-components'
import Logo from '../../components/Icons/Logo'
import {Link, useLocation} from 'react-router-dom'
import {getReportingPeriodQueryString, isEnterprise} from '../../data/App'
import TopRankingIcon from '../../components/Icons/TopRankingIcon'
import {AuthContext, ModalContext} from '../../context'
import {ErrorText, pageContentWidth} from '../../components/styles'
import useMediaQuery from '../../hooks/useMediaQuery'
import ReportingPeriodSelector from '../../components/Header/ReportingPeriodSelector'
import GameSelection from '../../components/ComparisonSwitcher/GameSelection'
import GameTrendsIcon from '../../components/Icons/GameTrendsIcon'
import CalendarAddIcon from '../../components/Icons/CalendarAddIcon'

const collapseWidthQuery = 'max-width: 910px'

const TopNavigation = (props) => {
  const {isLoading, user, logout} = useContext(AuthContext)
  const {openModal} = useContext(ModalContext)
  const location = useLocation()
  const [isMenuExpanded, setMenuExpanded] = useState(false)
  const isCollapsedWidth = useMediaQuery(collapseWidthQuery)
  const showGameSelection = useMemo(() => {
    return location.pathname.startsWith('/trends') ||
      location.pathname.startsWith('/compare-page/') ||
      location.pathname.startsWith('/player-share')
  }, [location])

  const menuItems = useMemo(() => {
    return [
      {
        label: <ErrorText>Logout</ErrorText>,
        onClick: () => logout()
      }
    ]
  }, [logout])

  return (
    <Container>
      <PageWidthContainer>
        <LogoLink>
          <Link to={{ pathname: `/` }}>
            <Logo width={43} height={28}/>
          </Link>
        </LogoLink>
        {
          !isCollapsedWidth &&
          <NavigationLinks onClickLink={() => setMenuExpanded(false)}/>
        }
        <Right>
          <ReportingPeriodSelector
            reportingPeriod={props.reportingPeriod}
            endDate={props.endDate}
            periodCount={props.periodCount}
            onScopeChange={props.onScopeChange}
            onPeriodChange={props.onPeriodChange}
            onPeriodCountChange={props.onPeriodCountChange}
            onEndDateChange={props.onEndDateChange}
            allowCustomRange={props.allowCustomRange}
          />
          <Divider $margin="0 8px 0px 8px"/>
          {/*<BookmarkButton/>*/}
          {/*<Divider $margin="0 8px 0px 0px"/>*/}
          <UserContainer>
            {
              !isLoading && !user && !isCollapsedWidth &&
              <Fragment>
                <Button
                  variant="primary"
                  size="large"
                  onClick={() => openModal('login-modal')}
                >
                  Login
                </Button>
              </Fragment>
            }
            {
              !isLoading && user?.userId && !isCollapsedWidth &&
              <Fragment>
                <ContextMenu
                  triggerId="avatar-menu"
                  alignment="right"
                  position="bottom"
                  items={menuItems}
                >
                  <Avatar
                    id="avatar-menu"
                    isLoading={isLoading}
                    image={user.thumbnail}
                    clickable={true}
                    size="36px"
                  />
                </ContextMenu>
              </Fragment>
            }
            {
              isCollapsedWidth &&
              <Fragment>
                <Hamburger
                  className={isMenuExpanded ? 'active' : ''}
                  onClick={() => setMenuExpanded(!isMenuExpanded)}
                >
                  <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d={isMenuExpanded ? 'M6 6L18 18' : 'M3 7H21'} stroke={'white'} strokeWidth="1.5" strokeLinecap="round"/>
                    <path d={isMenuExpanded ? 'M7 7L18 18' : 'M3 12H21'} stroke={'white'} strokeWidth="1.5" strokeLinecap="round"/>
                    <path d={isMenuExpanded ? 'M6 18L18 6' : 'M3 17H21'} stroke={'white'} strokeWidth="1.5" strokeLinecap="round"/>
                  </svg>
                </Hamburger>
              </Fragment>
            }
          </UserContainer>
        </Right>
      </PageWidthContainer>
      {
        showGameSelection &&
        <GameSelection/>
      }
      {
        isCollapsedWidth && isMenuExpanded &&
        <Menu $offset={showGameSelection}>
          <NavigationLinks
            isOverflow={true}
            onClickLink={() => setMenuExpanded(false)}
          />
        </Menu>
      }
    </Container>
  )
}

export default TopNavigation

// local components
const NavigationLinks = ({isOverflow, onClickLink = () => {}}) => {
  const {isLoading, user, logout} = useContext(AuthContext)
  const {openModal} = useContext(ModalContext)
  return (
    <Links>
      <Link
        to={{ pathname: `/trends/?${getReportingPeriodQueryString()}` }}
        onClick={onClickLink}
      >
        <GameTrendsIcon size={22}/>
        Game Trends
      </Link>
      {
        isEnterprise() &&
        <Link
          to={{ pathname: `/servers` }}
          onClick={onClickLink}
        >
          <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20.2083 7.82141L12.5083 12.2814C12.1983 12.4614 11.8083 12.4614 11.4883 12.2814L3.78826 7.82141C3.23826 7.50141 3.09826 6.75141 3.51826 6.28141C3.80826 5.95141 4.13826 5.68141 4.48826 5.49141L9.90826 2.49141C11.0683 1.84141 12.9483 1.84141 14.1083 2.49141L19.5283 5.49141C19.8783 5.68141 20.2083 5.96141 20.4983 6.28141C20.8983 6.75141 20.7583 7.50141 20.2083 7.82141Z"
              fill={'white'}
            />
            <path
              d="M11.4305 14.1389V20.9589C11.4305 21.7189 10.6605 22.2189 9.98047 21.8889C7.92047 20.8789 4.45047 18.9889 4.45047 18.9889C3.23047 18.2989 2.23047 16.5589 2.23047 15.1289V9.9689C2.23047 9.1789 3.06047 8.6789 3.74047 9.0689L10.9305 13.2389C11.2305 13.4289 11.4305 13.7689 11.4305 14.1389Z"
              fill={'white'}
            />
            <path
              d="M12.5703 14.1389V20.9589C12.5703 21.7189 13.3403 22.2189 14.0203 21.8889C16.0803 20.8789 19.5503 18.9889 19.5503 18.9889C20.7703 18.2989 21.7703 16.5589 21.7703 15.1289V9.9689C21.7703 9.1789 20.9403 8.6789 20.2603 9.0689L13.0703 13.2389C12.7703 13.4289 12.5703 13.7689 12.5703 14.1389Z"
              fill={'white'}
            />
          </svg>
          Server Trends
        </Link>
      }
      <Link
        to={{ pathname: `/player-share/?${getReportingPeriodQueryString()}` }}
        onClick={onClickLink}
      >
        <TopRankingIcon size={18}/>
        Top Ranking
      </Link>
      <a
        href="https://calendly.com/medaltrends/medal-trends-demo"
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClickLink}
      >
        <CalendarAddIcon size={22}/>
        Book Demo
      </a>
      {
        !isLoading && !user && isOverflow &&
        <Button
          variant="primary"
          size="large"
          onClick={() => openModal('login-modal')}
        >
          Login
        </Button>
      }
      {
        !isLoading && user && isOverflow &&
        <Button
          variant="danger"
          size="large"
          onClick={() => logout()}
        >
          Logout of {user.userName}
        </Button>
      }
    </Links>
  )
}

// local style

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.background['first-layer']};
  position: sticky;
  top: 0;
  z-index: 2;
`

const PageWidthContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
  width: ${pageContentWidth};
  justify-content: space-between;
  border-bottom: 1px solid ${colors.stroke['0A16']};
  transition: padding 200ms ease-in-out;
  
  @media (max-width: 1420px) {
    padding: 20px;
  }
`

const Divider = styled.div`
  width: 1px;
  height: 36px;
  background-color: ${colors.stroke['0A16']};
  margin: ${({$margin}) => $margin ?? '0 8px'};
`

const LogoLink = styled.div`
  a {
    display: flex;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
`

const Links = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  border-inline-start: 1px solid ${colors.stroke['0A16']};
  padding-inline-start: 16px;
  margin-inline-start: 20px;
  margin-inline-end: auto;

  a, a:visited {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    color: ${colors.text['0']};
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    padding: 6px 6px;
    border-radius: 8px;
    gap: 6px;
    
    &:active {
      color: ${colors.brand.primary['50']};
    }
    
    &:hover {
      background-color: ${colors.neutral['0A16']};
      color: ${colors.brand.primary['50']};
      
      svg {
        path {
          fill: ${colors.brand.primary['50']};
        }
      }
    }
  }
`

const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Hamburger = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  padding: 3px;
  border-radius: 8px;
  cursor: pointer;
  
  &:hover, &.active {
    background-color: ${colors.neutral['0A16']};
    color: ${colors.brand.primary['50']};

    svg {
      path {
        stroke: ${colors.brand.primary['50']};
      }
    }
  }
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: absolute;
  top: ${({$offset}) => $offset ? 'calc(100% - 74px)' : '100%'};
  background-color: ${colors.background['first-layer']};
  border-top: 1px solid ${colors.stroke['0A16']};
  border-bottom: 1px solid ${colors.stroke['0A16']};
  width: 100%;
  
  ${Links} {
    margin: 0;
    padding: 0;
    gap: 16px;
    flex-direction: column;
    border: none;
    align-items: flex-end;
    
    a {
      padding: 8px 12px;
    }
  }
`
