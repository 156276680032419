import React, {useEffect, useState} from 'react'
import {AuthContext} from '../index'
import {MedalApi} from '../../utils/api/medal'
import {deleteStoredAuth, getStoredAuth, updateStoredAuth} from '../../utils/user/auth'
import {deleteCachedUser, getCachedUser, isMedalAdmin, isMedalStaff, updateCachedUser} from '../../utils/user/user'

const AuthProvider = ({children}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [user, setUser] = useState(() => {
    const storedAuth = getStoredAuth()
    if (storedAuth?.userId) {
      const cachedUser = getCachedUser()
      if (cachedUser?.userId) {
        return cachedUser
      }
    }
    return null
  })
  const [auth, setAuth] = useState(() => getStoredAuth())

  const login = async (username, password) => {
    try {
      setIsLoggingIn(true)
      const resp = await MedalApi.post('/authentication', {
        userName: username,
        password: password
      }, {
        includeAuthenticationHeader: false
      })
      if (resp.data?.userId && resp?.data?.key) {
        console.log('[AuthProvider] User logged in:', resp.data)
        setAuth(resp.data)
      } else if (resp.data?.errorMessage) {
        // @todo handle errors better
        throw new Error(resp.data.errorMessage)
      }
    } catch (err) {
      console.error('[AuthProvider] User login failed', err)
      throw new Error(err.response?.data?.errorMessage ?? err.message)
    } finally {
      setIsLoggingIn(false)
    }
  }

  const logout = async () => {
    if (auth?.userId) {
      try {
        await MedalApi.delete(`/users/${auth.userId}/sessions/@current`)
      } catch (err) {
        if (err.response?.status === 401) {
          console.warn('[AuthProvider] Could not end already expired session!')
        } else {
          console.error('[AuthProvider] Unexpected error ending session:', err)
        }
      }
    }
    deleteStoredAuth()
    deleteCachedUser()
    setUser(null)
    setIsLoggingIn(false)
    setIsLoading(false)
  }

  useEffect(() => {
    updateCachedUser(user ?? {})
  }, [user])

  useEffect(() => {
    const loadUser = async (auth) => {
      try {
        setIsLoading(true)
        const resp = await MedalApi.get(`/users/${auth.userId}`)
        if (resp.data?.errorMessage) {
          throw new Error(resp.data.errorMessage)
        }
        const currentUser = {
          ...resp.data,
          isPremium: resp.data.premiumType !== 'NONE',
          isStaff: isMedalStaff(resp.data),
          isAdmin: isMedalAdmin(resp.data),
        }
        console.log('[AuthProvider] Loaded user:', currentUser)
        setUser(currentUser)
      } catch (err) {
        if (err.response?.status === 401) {
          console.warn(`[AuthProvider] Authentication expired or invalid, logging out: ${err.response.data?.errorMessage ?? err.message}`)
          // @todo re-assign a guest id after logout?
          await logout()
        } else {
          console.error('[AuthProvider] Load user failed unexpectedly:', auth, err)
          throw new Error(err.response?.data?.errorMessage ?? err.message)
        }
      } finally {
        setIsLoading(false)
      }
    }

    const loadUserOnAuthUpdate = async () => {
      if (auth?.userId) {
        const storedAuth = getStoredAuth()
        if (storedAuth && auth && storedAuth.userId === auth.userId && storedAuth.key === auth.key) {
          console.log('skipping updating stored auth, already stored')
        } else {
          updateStoredAuth(auth, true)
        }
        console.log('[AuthProvider] Auth loaded, loading current user:', auth)
        await loadUser(auth)
      } else {
        setIsLoading(false)
      }
    }

    loadUserOnAuthUpdate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  return (
    <AuthContext.Provider value={{isLoading, isLoggingIn, auth, user, login, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
