import React, {Fragment} from "react";
import equal from 'fast-deep-equal'
import {Redirect, withRouter} from 'react-router-dom';

// Sections for this page
import PlatformSharing from './Sections/PlatformSharing';
import PlatformViewing from './Sections/PlatformViewing';
import ViewershipByRegion from './Sections/ViewershipByRegion';
import Popularity from './Sections/Popularity';
import EmbedAvatarList from '../../components/Embed/EmbedAvatarList'
import MarketShareMethodPicker from '../../components/CommonControls/MarketShareMethodPicker';

import {
  dataMembers,
  getGameOrPlaceholder,
  getReportingPeriod,
  getReportingPeriodQueryString,
  getMarketShareCalculationKey,
  getMarketShareCalculationTitle,
  getMarketShareCalculationDescription,
  retreiveShareCalculationFromQueryString,
  getCategoryById,
  checkIds,
  adjustGameHues, array0toN, isEnterprise, getTagRankingMethodKey, retreiveTagRankingMethodFromQueryString, retreiveTagIncludeAutoFromQueryString,
} from "../../data/App";

import {
  backfillDayZeroRetention,
  getGamesList,
  holefillRetentionChartData, holefillRetentionData,
  loadCategoryData,
  loadKnownDataSet,
  processOverlapData,
  purgeFromCache
} from "../../data/Data";
import RetentionComparisonChart from "../Retention/Sections/RetentionComparisonChart";
import RetentionChart from "../Retention/Sections/RetentionChart";
import OverlapSection from "./Sections/OverlapSection";
import TagsGameCompareSection from "../Tags/Sections/TagsGameCompareSection";
import TagSectionContainer from "../Tags/TagSectionContainer";
import { buildCompareBarChart, buildGameDataSetComplex, buildGameDataSet, buildGameDataSetComplexFromBase, getChartPresets, getChartTooltipFormatter } from "../../data/Chart";
import DashboardSection from '../../components/Dashboard/DashboardSection'
import SectionLoadingPlaceholder from '../../components/Dashboard/SectionLoadingPlaceholder'
import styled from 'styled-components'

class Compare extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      compareBar: {},
      option: {},
      clipabilityOption: {},
      loaded: false,
      search: false,
      shares: null,
      views: null,
      redirect: false,
      authenticated: false,
      ids: [],
      overlapData: {},
      lastReportingPeriod: getReportingPeriod(),
      lastReportingDescription: "",
      marketShareMethod: retreiveShareCalculationFromQueryString(),
      tagRankingMethod: retreiveTagRankingMethodFromQueryString(),
      includeAutoTags: retreiveTagIncludeAutoFromQueryString(),
    }
    this.removeCategory = this.removeCategory.bind(this)
    this.showSearch = this.showSearch.bind(this)
    this.searchHandler = this.searchHandler.bind(this)
  }

  removeCategory = (category) => {
    // build a new array of active ids
    var ids = this.state.ids.slice();
    ids = ids.filter(id => id !== category.id);

    this.setState({ ids }, () => {
      this.populateFromIds(true);
    });
  }

  showSearch = () => {
    this.setState({ search: true })
  }

  searchHandler = (compareId) => {
    var ids = this.state.ids.slice();
    ids.push(compareId);
    this.setState({ ids }, () => {
      this.populateFromIds(true);
    });
  }

  marketShareMethodChange = event => {
    this.setState({ marketShareMethod: event.target.value });
  }

  tagRankingMethodChange = (tagRankingMethod, includeAutoTags) => {
    console.log("setting state", tagRankingMethod, includeAutoTags, true)
    this.setState({ tagRankingMethod, includeAutoTags });
  }

  isEmbed() {
    return window.location.pathname.indexOf("/embed/") === 0;
  }

  populateOverlapFromIds(ids, retryIndex) {
    retryIndex = retryIndex || 0;

    const path = "trends/overlap";
    const params = { retryIndex: retryIndex++ };
    loadKnownDataSet(path, ids, params).then(res => {
      const overlapData = this.state.overlapData || {};
      let foundRows = false;
      let pendingUpdate = false;
      res.data.forEach(c => {
        if (c.rows && c.rows.length > 0) {
          overlapData[c.categoryId] = c.rows;
          foundRows = true;
        }

        if (c.pendingUpdate) {
          pendingUpdate = true;
          // Don't cache the placeholder data
          purgeFromCache(path);
          setTimeout(() => { this.populateOverlapFromIds([c.categoryId], retryIndex)}, 3000);
        }
      });
      const processedOverlapData = processOverlapData(overlapData);
      // console.log("overlap data should set state?", foundRows, pendingUpdate, res.data, this.state, overlapData, processedOverlapData)
      const foundUpdate = foundRows || !pendingUpdate
      const hasChanges = !equal(this.state.overlapData, overlapData) || !equal(this.state.processedOverlapData, processedOverlapData)
      if (!foundUpdate || !hasChanges) {
        return
      }
      console.log('new overlap data processed')
      this.setState({ overlapData, processedOverlapData, overlapDataLoaded: true })
    }).catch(e => {
      //console.log("overlap error. falling back", e);
      // New API endpoint might not be live yet.
      // fall back on the old single-id version.
      console.log('fallback overlap endpoint')
      ids.forEach(id => {
        //console.log("loading overlap for", id)
        loadKnownDataSet("trends/overlap", id, { periods: array0toN(9).join() }).then(res => {
          var overlapData = this.state.overlapData || {};
          overlapData[id] = res.data;
          const processedOverlapData = processOverlapData(overlapData);
          //console.log("overlap data (single)", res.data, id, this.state, overlapData, processedOverlapData)
          this.setState({ overlapData, processedOverlapData, overlapDataLoaded: true })
        });
      });
    });
  }

  populateFromIds(pushHistory) {
    let ids = this.state.ids
    //console.log("populateFromIds", ids, this.state.lastPopulatedIds);

    this.setState({ compareDataLoaded: false })

    loadKnownDataSet("retention/cohort", ids, ).then(res => {
      backfillDayZeroRetention(res.data);
      holefillRetentionChartData(res.data);
      this.setState({ retentionData: res.data, dataLoaded: true })
    });

    loadKnownDataSet("retention/compare", ids, { periods: array0toN(9).join() }).then(res => {
      var data = holefillRetentionData(res.data);
      backfillDayZeroRetention(data);
      this.setState({ compareData: data, compareDataLoaded: true, compareIds: ids })
    });

    if (ids !== this.state.lastPopulatedIds) {
      this.populateOverlapFromIds(ids);
    } else {
      //console.log("already populated this ids", ids)
    }

    // build array of active categories
    var gameSet = ids.map(id => getCategoryById(id));
    this.setState({categories:gameSet})

    window.document.title = this.buildTitle(ids);

    this.setState({ search: false, lastReportingDescription: this.buildUrl(ids), lastPopulatedIds:ids })
    // build array of active categories
    getGamesList().then(games => {
      // Build a smaller collection with just the games we asked for on the url
      games = ids.reduce((collection, id) => {
        var game = games.get(id);
        if (game !== undefined) {
          const copy = JSON.parse(JSON.stringify(game))
          collection.set(game.id, copy);
        }

        return collection;
      }, new Map());

      adjustGameHues(games, ids);
      var gameSet = ids.map(id => getGameOrPlaceholder(games, id));

      const option = buildGameDataSet(gameSet, this.state.marketShareMethod);

      let categories = [];
      option.series.forEach(series => {
        if (typeof series.abv !== "undefined") {
          categories.push({
            id: series.id,
            name: series.abv,
            color: series.color,
            avatar: series.avatar ?? `./${series.id}.png`
          });
        }
      })

      // This is for the Clipability: Avg clips per session section
      var clipabilityCalculator = (gameSet) => {
        var clipPercents = typeof gameSet[dataMembers.CLIP_PERCENTS] === "object" ? gameSet[dataMembers.CLIP_PERCENTS].slice() : [];
        var sessionPercents = typeof gameSet[dataMembers.SESSION_PERCENTS] === "object" ? gameSet[dataMembers.SESSION_PERCENTS].slice() : [];

        var data = [];
        for (var i = 0; i < clipPercents.length; i++){
          data[i] = (sessionPercents[i] === 0) ? 0 : (clipPercents[i] / sessionPercents[i]).toFixed(2) * 1;
        }
        return data;
      }

      console.log("building clipabilityOption", gameSet)
      const clipabilityOption = buildGameDataSetComplex(gameSet, clipabilityCalculator);

      // This is for the Average Session Duration section
      var sessionDurationCalculator = (gameSet) => {
        var durations = typeof gameSet[dataMembers.AVG_SESSION_DURATION] === "object" ? gameSet[dataMembers.AVG_SESSION_DURATION].slice() : [];
        return durations;
      }

      console.log("building sessionDurationOption", gameSet)
      let sessionDurationBaseOption = JSON.parse(JSON.stringify(getChartPresets()["compare"].baseOption));

      sessionDurationBaseOption.tooltip.formatter = getChartTooltipFormatter(value => {
        var seconds =  ("" + (Math.floor(value * 60) % 60 + 100)).substring(1,3);
        var minutes = ("" + (Math.floor(value) % 60 + 100)).substring(1, 3);
        var hours = Math.floor(value / 60)

        return `${hours}:${minutes}:${seconds}`
      });

      const sessionDurationOption = buildGameDataSetComplexFromBase(gameSet, sessionDurationCalculator, sessionDurationBaseOption, "compare", "");
      //const sessionDurationOption = buildGameDataSetComplex(gameSet, sessionDurationCalculator);

      // build the comparison bar chart
      // 20231215: These don't seem to get used anywhere
      var compareBar = buildCompareBarChart(option);
      var compareBarClipability = buildCompareBarChart(clipabilityOption);
      // var compareBarSessionDuration = buildCompareBarChart(sessionDurationOption);

      this.setState({
          option,
          clipabilityOption,
          sessionDurationOption,
          categories,
          compareBar,
          compareBarClipability,
          //compareBarSessionDuration,
          search: false,
          lastReportingDescription:
            this.buildUrl(ids) },
        () => {
          // This causes componentDidUpdate to fire, so let's make sure we wait until the state change finishes before calling it:
          this.props.history.replace(this.buildUrl(ids));
          window.document.title = this.buildTitle(ids);
        })

      // data("buildCompareBarChart", option)
      // 	.then(res => {
      // 		this.setState({ compareBar: res });
      // 	})

      // 20200309: these are currently disabled.  No need to load their data.
      // // build the shares by platform
      // data("sharesByPlatform", ids).then((shares) => {
      // 	this.setState({ shares });
      // })

      // // build the views by platform
      // data("viewsByPlatform", ids).then((views) => {
      // 	this.setState({ views });
      // })
    })
  }

  buildQueryString = () => {
    return getReportingPeriodQueryString(window.location.search, {
      c: getMarketShareCalculationKey(this.state.marketShareMethod),
      tr: getTagRankingMethodKey(this.state.tagRankingMethod),
      ti: this.state.includeAutoTags ? 1 : 0,
    });
  }

  buildUrl(ids) {
    if (this.isEmbed()) {
      return `/embed/compare-page/${ids.join(",")}/${this.props.match.params.section}?${this.buildQueryString()}`;
    }
    return `/compare-page/${ids.join(",")}?${this.buildQueryString()}`;
  }

  buildTitle(ids) {
    return ids.reduce((title, id) => {
      const game = getCategoryById(id);
      if (game !== undefined) {
        title += (title === "" ? "" : " vs. ") + game.name;
      }
      return title
    }, "") + " Analytics | Medal Trends";
  }

  buildChartTitle(ids) {
    return getCategoryById(ids[0]).name;
  }


  retreiveIds() {
    var loc = this.props.location;
    if (loc.ids) {
      // console.log("found ids on location", this.props.location)
      return loc.ids;
    }

    // NOTE: Ids survive page reloads by nature of us having pushed our state into the browser history.
    if (loc.state && loc.state.ids) {
      // console.log("found ids in state", loc.state)
      return loc.state.ids;
    }

    if (this.props && this.props.match && this.props.match.params && this.props.match.params.ids) {
      const ids = this.props.match.params.ids.split(",").map(s => parseInt(s, 10) || 0); // @todo string id support?
      // console.log("found ids in props", JSON.stringify(this.props.match.params))
      return ids;
    }

    // 20191107: We should no longer ever make it this far, now that we pass ids around instead
    // of (yikes!) eCharts option objects.
    console.warn("NO IDs FOUND. Redirecting to landing page.")
    return [];
  }


  componentDidMount() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    loadCategoryData().then((res) => {
      const retrievedIds = this.retreiveIds()
      const ids = checkIds(retrievedIds);

      if (!ids || ids.length === 0) {
        this.setState({ redirect: true })
        return;
      }

      this.setState({ ids, loaded:true }, () => {
        this.populateFromIds(false);
      })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("should update?", this.state.lastReportingDescription !== this.buildUrl(this.state.ids))
    const urlFromState = this.buildUrl(this.state.ids)

    const regex = /compare-page\/([^?]+)\/?(?=\?|$)/

    let currentUrlIds = []
    const match = this.props.location.pathname.match(regex);
    if (match && match[1]) {
      currentUrlIds = match[1]
        .split(',')
        .map(id => parseInt(id, 10)) // @todo string ID support?
    }

    const currentUrl = this.buildUrl(currentUrlIds)
    if (this.state.loaded && urlFromState !== currentUrl) {
      console.log('[compare] re-calculate state:', urlFromState, currentUrl)
      console.log('[compare] state vs new:', this.state.ids, currentUrlIds)
      this.setState({
        ids: currentUrlIds
      })
    } else if (this.state.loaded && this.state.lastReportingDescription !== "" && this.state.lastReportingDescription !== urlFromState) {
      console.log('[compare] populating from ids:', this.state.ids)
      this.populateFromIds(false);
    } else if (prevProps.location.pathname !== this.props.location.pathname && !this.state.ids.every(id => this.props.location.pathname.includes(id))) {
      console.log('[compare] path update, repopulating from IDs:', prevProps.location.pathname, this.props.location.pathname)
      this.populateFromIds(false);
    }
  }

  renderPopularity() {
    var units = this.state.marketShareMethod !== "avgSessionDurationMinutes" ? "(%)" : "";
    const title = `Popularity on Medal by ${getMarketShareCalculationTitle(this.state.marketShareMethod)} ${units}`
    return <Popularity
      option={this.state.option}
      compareBar={this.state.compareBar}
      isPercent={true}
      isEmbed={this.isEmbed()}
      section="popularity"
      title={title}
      explanation={getMarketShareCalculationDescription(this.state.marketShareMethod)}
      // explanation="Popularity compares the amount of time played per game title. Numbers are total player share percentages and, naturally, bigger is better."
    />
  }

  renderClipability() {
    return <Popularity
      option={this.state.clipabilityOption}
      compareBar={this.state.compareBarClipability} // 20231215: this doesn't seem to get used, but we've gone to the trouble of generating it just to pass it along.
      isPercent={false}
      isEmbed={this.isEmbed()}
      section="clipability"
      title="Clipability: Average clips per session"
      explanation="Clipability looks at the number of clips produced per game session. Higher numbers are indicative that the game is in some way more likely to generate visual moments worth sharing."
    />
  }

  renderSessionDuration() {
    //console.log("compareBarSessionDuration", this.state.compareBarSessionDuration, this.state.sessionDurationOption)
    return <Popularity
      option={this.state.sessionDurationOption}
      //compareBar={this.state.compareBarSessionDuration}
      isPercent={false}
      isEmbed={this.isEmbed()}
      section="session_duration"
      title="DAILY TIME SPENT"
      explanation="The average time that a gamer spends playing this game over the course of a day. (h:mm:ss)"
    />
  }

  renderViewershipByRegion() {
    return (
      <DashboardSection
        title="Viewing popularity by country"
        description="Percent of total clip views by Game and Country, across all games in the comparison. Color shows which game is more popular in a country, intensity shows how popular it is."
      >
        <ViewershipByRegion
          categories={this.state.categories}
          isEmbed={this.isEmbed()}
          section="viewership_by_region"
        />
      </DashboardSection>
    )
  }

  renderPlatformSharing() {
    return <PlatformSharing
      isEmbed={this.isEmbed()}
      section="platform_sharing"
      shares={this.state.shares} />
  }

  renderPlatformViewing() {
    return <PlatformViewing
      isEmbed={this.isEmbed()}
      section="platform_viewing"
      views={this.state.views} />
  }

  renderOverlap() {
    return <OverlapSection
      title={`Other Games that Users Play`}
      isEmbed={this.isEmbed()}
      section="overlap"
      overlapData={this.state.processedOverlapData} />
  }

  renderEmbedded() {
    var section = this.props && this.props.match && this.props.match.params
      && this.props.match.params.section && this.props.match.params.section.toLowerCase
      && this.props.match.params.section.toLowerCase();	// Javascript!

    switch (section) {
      case "popularity":
        return this.renderPopularity();
      case "clipability":
        return this.renderClipability();
      case "session_duration":
        return this.renderSessionDuration();
      case "viewership_by_region":
        return this.renderViewershipByRegion();
      case "platform_sharing":
        return this.renderPlatformSharing();
      case "platform_viewing":
        return this.renderPlatformViewing();
      default:
        return <div></div>;
    }

  }

  renderComparisonChart = () => {
    return <RetentionComparisonChart
      data={this.state.compareData}
      categories={this.state.categories}
      ids={this.state.compareIds}
      isEmbed={this.isEmbed()}
      section="comparison"
    />
  }


  renderTagSection = () => {
    // console.log("renderTagSection", this.state.includeAutoTags, true)
    return <TagSectionContainer
      tagRankingMethod = {this.state.tagRankingMethod}
      includeAutoTags={this.state.includeAutoTags}
      onTagRankingMethodChange = {this.tagRankingMethodChange}
    >
      {this.state.compareIds.map(id =>
        <TagsGameCompareSection
          key={id}
          id={id}
          tagRankingMethod={this.state.tagRankingMethod}
          includeAutoTags={this.state.includeAutoTags}
        />
      )}
    </TagSectionContainer>
  }

  renderRetentionCharts = () => {
    return (
      <RetentionCharts>
        {
          this.state.ids.map((id, index) => {
            if (!this.isEmbed()){
              return this.renderRetentionChart(id)
            }
            return <div style={{position:"relative",width:"100%",height:"100%"}}>
              <div style={{ position: "absolute", right: 0, bottom: 8,zIndex:1 }}>
                <EmbedAvatarList ids={[id]} />
              </div>
              {this.renderRetentionChart(id)}
            </div>
          })
        }
      </RetentionCharts>
    )
  }

  renderRetentionChart = (id) => {
    const chartData = this.state.retentionData.filter(d => d.categoryId === id)
    const category = getCategoryById(id);

    const getEmbedSrc = () => {
      return `${window.location.origin}/embed/retention-compare/${id}/retention${window.location.search}`;
    }

    return <RetentionChart
      key={id}
      title={`${category.name} Retention`}
      subtitle={`How Medal creators return to ${category.name} over time`}
      category={category}
      data={chartData}
      isEmbed={this.isEmbed()}
      section="retention"
      getEmbedSrc={getEmbedSrc}
    />
  }

  renderEmbeddedWithGames() {
    return (
      <div>
        <EmbedAvatarList ids={this.state.ids}/>
        {this.renderEmbedded() }
      </div>
    )
  }

  render() {
    if (this.state.redirect) {
      if (this.props.location.pathname !== "/") {
        console.log('redirecting')
        return <Redirect push to="/" />;
      }
    }

    if (typeof this.state.option.series === "undefined"
      || typeof this.state.compareBar.series === "undefined"
      // || this.state.shares === null
      // || this.state.views === null
    ) {
      return (
        <Fragment>
          <SectionLoadingPlaceholder minHeight="400px"/>
          <SectionLoadingPlaceholder minHeight="400px"/>
          <SectionLoadingPlaceholder minHeight="400px"/>
        </Fragment>
      )
    }

    if (this.isEmbed()) {
      return this.renderEmbeddedWithGames();
    }

    const enterprise = isEnterprise()

    return (
      <Fragment>
        <div>
          <MarketShareMethodPicker
            value={this.state.marketShareMethod}
            onChange={this.marketShareMethodChange}
          />
        </div>
        {this.renderPopularity()}
        {this.renderClipability()}
        {/*this.renderSessionDuration()*/}
        {enterprise && this.state.compareDataLoaded && this.renderComparisonChart()}
        {enterprise && this.state.retentionData && this.renderRetentionCharts()}
        {this.state.overlapDataLoaded && this.renderOverlap()}
        {enterprise && this.state.compareDataLoaded && this.renderTagSection()}
        {this.renderViewershipByRegion()}
      </Fragment>
    )
  }
}

export default withRouter(Compare);

const RetentionCharts = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(650px, 1fr));
  gap: 20px;
  
  @media (max-width: 692px) {
    grid-template-columns: 1fr;
  }
`
