import React, {useEffect, useMemo, useState} from 'react'
import {CategoryContext} from '../'
import {colorFromName, getDateStringToday, trendingGamesCount} from '../../data/App'
import {getCacheableData, purgeLocalStorage} from '../../data/Data'

const CategoryProvider = ({children}) => {
  const [isLoading, setLoading] = useState(true)
  const [trendingCategories, setTrendingCategories] = useState([])

  const dataMappedCategories = useMemo(() => {
    const dataMappedCategories = trendingCategories.map((c) => {
      return {
        id: c.categoryId,
        name: c.categoryName,
        abv: c.categoryName,
        color: colorFromName(c.categoryName),
        thumb: c.categoryThumbnail,
        avatar: `./${c.categoryId}.png`
      }
    })

    // sort by name
    dataMappedCategories.sort((a, b) => (a.name > b.name ? 1 : -1))

    // @todo deprecate + remove this legacy function
    // @todo unravel the mess that is the Data class, because
    // calling this from here breaks a lot of stuff,
    // because categoryData is mutated by other async functions
    // setCategoryData(dataMappedCategories)

    // console.log('Mapped data categories:', dataMappedCategories)

    return dataMappedCategories
  }, [trendingCategories])

  const loadTrendingCategories = async () => {
    // Minor hack: Every page starts off by loading this data.  So if we clear it here we'll be sure it goes away at least once per day.
    // @todo revisit necessity of this
    purgeLocalStorage()

    try {
      setLoading(true)
      // @todo write better getCacheableData replacement
      const resp = await getCacheableData(`/trends/categoriesInUse?date=${getDateStringToday()}&categoryCount=${trendingGamesCount}`)
      if (resp.data) {
        const categories = resp.data
        console.log('Loaded trending categories:', categories)
        setTrendingCategories(categories)
      }
    } catch (err) {
      console.error('Error fetching trending categories:', err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadTrendingCategories()
  }, [])

  return (
    <CategoryContext.Provider value={{isLoading, trendingCategories, dataMappedCategories}}>
      {children}
    </CategoryContext.Provider>
  )
}

export default CategoryProvider
