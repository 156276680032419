import React from "react";
import ReactEcharts from 'echarts-for-react'


class Compare extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			orientText: "center",
			orientSymbol: "lowerRight",
		};
	}

	render() {
		if (typeof this.props.option.series === "undefined") {
			return <div />
		}
    // @todo sadly required rn
		this.props.option.grid.height = 330;
		return (
      <ReactEcharts
        option={this.props.option}
        notMerge={false}
        lazyUpdate={true}
        theme={"medal_chart_theme"}
        style={{ height: 350 }}
        key={new Date().getTime()}
      />
		);
	}
}

export default Compare;
