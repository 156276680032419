import React from "react";
import PropTypes from 'prop-types'
import TagRankingMethodPicker from "./Sections/TagRankingMethodPicker";
import styled from 'styled-components'
import {colors} from '@get-wrecked/simple-components'

const propTypes = {
  children: PropTypes.node,
  onTagRankingMethodChange: PropTypes.func,
  tagRankingMethod: PropTypes.string,
  includeAutoTags: PropTypes.bool
}

const TagSectionContainer = ({children, onTagRankingMethodChange, tagRankingMethod, includeAutoTags}) => {
  const tagRankingMethodChange = (tagRankingMethod, includeAutoTags) => {
    if (typeof onTagRankingMethodChange === 'function') {
      onTagRankingMethodChange(tagRankingMethod, includeAutoTags)
    }
  }

  return (
    <Container>
      <TagRankingMethodPicker
        value={tagRankingMethod}
        includeAutoTags={includeAutoTags}
        onChange={tagRankingMethodChange}
      />

      {children}
    </Container>
  )
}

TagSectionContainer.propTypes = propTypes

export default TagSectionContainer;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 8px;
  padding-bottom: 32px;
  margin-bottom: 8px;
  border-top: 1px solid ${colors.stroke['0A16']};
  border-bottom: 1px solid ${colors.stroke['0A16']};
`
