import React, {Fragment, useContext} from 'react'
import {ModalContext} from '../../context'
import LoginModal from '../Modals/LoginModal'

const ModalContainer = () => {
  const {modals} = useContext(ModalContext)
  return modals.map((modal, index) => {
    return <Fragment key={`${modal.id}-${index}`}>{renderModal(modal)}</Fragment>
  })
}

const renderModal = ({id, context}) => {
  switch (id) {
    case 'login-modal':
      return <LoginModal/>
    default:
      console.error('Unexpected modal id:', id)
      return null
  }
}

export default ModalContainer
