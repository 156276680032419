import React, {Fragment} from 'react';
import styled from 'styled-components'
import {colors} from '@get-wrecked/simple-components'

import { buildCompareUrl, dataMembers } from '../../data/App';
import LoadMoreButton from '../LoadMoreButton'
import ChartListItem from '../Dashboard/ChartListItem'
import HashtagIcon from '../Icons/HashtagIcon'
import {ChartList} from '../styles'

class PieList extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			title: "",
			dataMember: dataMembers.USAGE_PERCENTS,
			loading: true,

			moreButtonDisplay: true,
			pageIndex: 1,
			showCount: this.props.pageSize || 8,
		}
	}

	static defaultProps = {
		blurOnSelect: false,
	};

	componentDidMount() {
		if (this.state.showCount >= this.props.orderedIds.length) {
			this.setState({ moreButtonDisplay: false });
		}
	}

	loadMore = (e) => {
		const index = this.state.pageIndex + 1;
		this.setState({ pageIndex: index })
		if (index * this.state.showCount >= this.props.orderedIds.length) {
			this.setState({ moreButtonDisplay: false });
		}
	}

	isGameNew = (item) => {
		return false;
		//return item[this.props.dataMember][0] === 0;
	}

	displayPercent = (item) => {
		// NOTE: For server trends, this will return the average across the whole period.
		// If we comment it out, we'll return the latest single value.
		if (this.props.reportingColumn && item[this.props.reportingColumn]) {
			//console.log("item has reportingcolumn", item, item[this.props.reportingColumn], this.latestPercent(item))
			return item[this.props.reportingColumn];
		}

		return this.latestPercent(item);
	}

	latestPercent = (item) => {
		const metric = item[this.props.metric];
		if (metric) {
			return metric[metric.length - 1];
		}
		return item.sharePercents && item.sharePercents[item.sharePercents.length - 1];
	};

	changePercent = (item) => {
		//console.log("changePercent", this.props.metric, item[this.props.metric])
		if (!item[this.props.metric]) {
			return 0;
		}

		const oldestPercent = (item) => {
			return item[this.props.metric][0]
		}
		const latestPercent = (item) => {
			return item[this.props.metric][item[this.props.metric].length - 1]
		}


		// zero safen the divide
		let oldestValue = oldestPercent(item);
		if (oldestValue === 0) {
			//console.log("changePercent", item, this.props.metric, item[this.props.metric], latestPercent(item), oldestPercent(item))
			oldestValue = 0.001;
			//console.log("safen", latestPercent(item) / oldestValue, latestPercent(item) / oldestValue - 1)
		}
		let changeValue = (((latestPercent(item) / oldestValue - 1) * 100).toFixed(1)) * 1; // relative percentage, whole period
		if (changeValue > 999) {
			changeValue = 999;	// don't show 0 plays -> 5 plays as 5,000,000,000%
		}
		if (changeValue < -999) {
			changeValue = -999;
		}

		// if (changeValue === -100) {
		// 	console.log("dropped to zero?", item, this.props.metric, item[this.props.metric], latestPercent(item), oldestPercent(item))
		// }

		// result.series[seriesCounter].data[index - 1] < value ? '% Of Total (↑)' : '% Of Total (↓)'

		return changeValue;
	}

	changeColor = (item) => {
		const percent = this.changePercent(item);
		if (percent > 0) {
			return "#0f0";
		} else if (percent < 0) {
			return "#f00";
		}
		return "#aaa"
	}

	changeString = (item) => {
		const percent = this.changePercent(item);
		return `${(percent < 0 ? '↓' : '↑')} ${percent}%`;
	}

	barColor = (item) => {
		const lookup = this.props.colorLookup;
		if (lookup && lookup[item[this.props.idField]]) {
			return lookup[item[this.props.idField]].color;
		}

		return "rgba(255,255,255,0.5)";
	}

	borderStyle = (item) => {
		const lookup = this.props.colorLookup;
		if (lookup && lookup[item[this.props.idField]]) {
			return "none";
		}

		return "solid 2px #fff";
	}

	renderTooltip(item) {
    const nameField = this.props.toolNameField ||  this.props.nameField || "platform";
    return (
      <ChartTooltip>
        <b>{item[nameField]} {this.props.toolTipHeader || `Clip sharing by platform`}</b>
        {this.displayPercent(item)}% {this.props.toolTipTotal || `of total clip shares`}
      </ChartTooltip>
    )
	}

	render() {
		const items = this.props.data;
		const nameField = this.props.nameField || "abv";
		const idField = this.props.idField || "id";
		const linkPath = this.props.linkPath || "compare-page"
		const showIds = this.props.orderedIds.slice(0, this.state.pageIndex * this.state.showCount);
		const linkBuilder = this.props.linkBuilder || buildCompareUrl;
    return (
      <Fragment>
        <ChartList>
          {
            showIds.map((id, index) => {
              const item = items.find(g => g[idField] === id);
              if (item === undefined) {
                return null
              }
              return (
                <ChartListItem
                  key={id}
                  icon={
                    idField === 'tag' &&
                    <HashtagIcon/>
                  }
                  chart={{
                    border: this.borderStyle(item),
                    color: this.barColor(item),
                    percentage: this.displayPercent(item),
                    label: (
                      <Fragment>
                        {
                          this.props.showTrend &&
                          <Percentage>
                            {this.changeString(item)}
                          </Percentage>
                        }
                        <Percentage>
                          {this.displayPercent(item)}%
                        </Percentage>
                      </Fragment>
                    )
                  }}
                  clickable={!this.props.linkDisabled}
                  isNew={this.isGameNew(item)}
                  index={index}
                  label={(item[nameField] || "")}
                  linkTo={{
                    pathname: linkBuilder(linkPath, item[idField])
                  }}
                  showRank={!this.props.hideIndex}
                  tooltip={this.renderTooltip(item)}
                />
              )
            })
          }
        </ChartList>
        {
          this.state.moreButtonDisplay &&
          <LoadMoreButton onClick={this.loadMore}/>
        }
      </Fragment>
    )
	}
}

export default PieList

const Percentage = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.text['0']};
`

const ChartTooltip = styled.div`
  display: flex;
  flex-direction: column;
`
