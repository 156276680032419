import React, {useContext, useEffect, useMemo, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import styled from 'styled-components'
import equal from 'fast-deep-equal'
import {CloseButton, colors, Tooltip} from '@get-wrecked/simple-components'
import {CategoryContext} from '../../context'
import {mobileWidthMediaQuery, pageContentWidth} from '../styles'
import GameSelectionModal from '../../presentations/Modals/GameSelectionModal'
import {adjustGameHues} from '../../data/App'

const GameSelection = () => {
  const location = useLocation()
  const history = useHistory()
  const {dataMappedCategories} = useContext(CategoryContext)
  const [selectedCategoryIds, setSelectedCategoryIds] = useState(() => {
    const isOnComparePage = location.pathname.startsWith('/compare-page/')
    if (isOnComparePage) {
      return location.pathname
        .split('/')
        .pop()
        .split(',')
    }
    return []
  })
  const [isSelecting, setIsSelecting] = useState(false)

  const selectedCategories = useMemo(() => {
    if (!dataMappedCategories?.length) {
      return []
    }

    // @todo string IDs?
    // eslint-disable-next-line eqeqeq
    const categories = selectedCategoryIds.map(id => dataMappedCategories.find(cat => cat.id == id))

    // adjust game hues to prevent collision (if necessary)
    adjustGameHues(categories, selectedCategoryIds)
    return categories
  }, [dataMappedCategories, selectedCategoryIds])

  const addCategory = (...ids) => {
    const newIds = [...selectedCategoryIds, ...ids]
    history.push(`/compare-page/${newIds.join(',')}${location.search}`)
  }

  const removeCategory = (id) => {
    // @todo string ID support?
    // eslint-disable-next-line eqeqeq
    const newIds = selectedCategoryIds.filter(catId => catId != id)
    history.push(`/compare-page/${newIds.join(',')}${location.search}`)
  }

  useEffect(() => {
    const isOnComparePage = location.pathname.startsWith('/compare-page/')
    const urlPath = location.pathname.split('?')[0]
    const categoryIdsInUrl = isOnComparePage
      ? urlPath.split('/').pop().split(',')
      : []

    if (!equal(categoryIdsInUrl, selectedCategoryIds)) {
      setSelectedCategoryIds(categoryIdsInUrl)
    }
  }, [selectedCategoryIds, location, history])

  if (!location.pathname.startsWith('/trends') && !location.pathname.startsWith('/compare-page/') && !location.pathname.startsWith('/player-share')) {
    return ''
  }

  return (
    <Container>
      {
        selectedCategories.map(category => {
          const onClickRemove = selectedCategoryIds.length > 1
            ? () => removeCategory(category.id)
            : undefined
          return (
            <Tooltip
              key={category.id}
              tooltip={category.name}
              position="bottom"
            >
              <GameThumbnail
                category={category}
                showColor={true}
                onClickRemove={onClickRemove}
              />
            </Tooltip>
          )
        })
      }
      <Tooltip
        tooltip={
          isSelecting
            ? undefined
            : (selectedCategoryIds?.length > 0
                ? undefined
                : 'View trends by game'
            )
        }
        position="right"
      >
        <AddCategoryContainer
          // $selecting={isSelecting}
          // className={isSelecting ? 'selecting' : ''}
          onClick={() => setIsSelecting(!isSelecting)}
        >
          <AddCategory>
            +
          </AddCategory>
        </AddCategoryContainer>
      </Tooltip>
      {
        isSelecting &&
        <GameSelectionModal
          id="game-selection-modal"
          excludedIds={selectedCategoryIds}
          onClose={() => setIsSelecting(false)}
          onClickAdd={selectedIds => {
            setIsSelecting(false)
            addCategory(selectedIds)
          }}
        />
      }
    </Container>
  )
}

export default GameSelection

export const GameThumbnail = ({category, onClickRemove, showColor, size}) => {
  return (
    <Game
      $clickable={typeof onClickRemove === 'function'}
      $size={size}
    >
      <img src={category.thumb} alt={`${category.name} thumbnail`}/>
      {
        category.color && showColor !== false &&
        <ColorIndicator $color={category.color}/>
      }
      {
        typeof onClickRemove === 'function' &&
        <CloseButton
          className="close-button"
          onClick={onClickRemove}
        />
      }
    </Game>
  )
}

const Container = styled.div`
  display: flex;
  padding: 16px 0;
  gap: 12px;
  align-items: center;
  width: ${pageContentWidth};
  border-bottom: 1px solid ${colors.stroke['0A16']};
  transition: padding 200ms ease-in-out;
  overflow-x: auto;

  @media (max-width: 1420px) {
    padding: 12px;
  }
`

const Game = styled.div`
  display: flex;
  width: ${({$size}) => $size ?? '64px'};
  height: ${({$size}) => $size ?? '64px'};
  border-radius: 12px;
  border: 1px solid ${colors.stroke['50']};
  // cursor: ${({$clickable}) => $clickable ? 'pointer' : 'normal'};
  position: relative;
  flex-shrink: 0;
  
  .close-button {
    display: none;
    position: absolute;
    top: -5px;
    right: -5px;
  }
  
  img {
    border-radius: 12px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    opacity: ${({$clickable}) => $clickable ? '0.9' :  '1'};
  }
  
  &:hover {
    ${({$clickable}) => $clickable ? `border-color: ${colors.text['30']};` :  ''};
    
    .close-button {
      display: flex;
    }
    
    img {
      opacity: 1;
    }
  }

  @media (${mobileWidthMediaQuery}) {
    width: 48px;
    height: 48px;
  }
`

const ColorIndicator = styled.div`
  width: 12px;
  height: 12px;
  position: absolute;
  bottom: 4px;
  right: 4px;
  border-radius: 4px;
  // border: 1px solid ${colors.stroke['0A16']};
  outline: 2px solid black;
  opacity: 1;
  
  background-color: ${({$color}) => $color};
  z-index: 1;
`

const AddCategory = styled(Game)`
  border: 1px solid ${colors.stroke['0A16']};
  background-color: ${colors.neutral['0A4']};
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: 500;
  color: ${colors.text['50']};
  opacity: 1;
  transition: width 100ms ease, height 100ms ease, border-radius 100ms ease;
`

const AddCategoryContainer = styled.div`
  padding: 0;
  border-radius: 12px;
  cursor: pointer;
  transition: padding 100ms ease;
  position: relative;
  
  &.selecting {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  &:hover, &.selecting {
    padding: 8px;
    background-color: ${colors.background['third-layer']};
    
    ${AddCategory} {
      background-color: ${colors.background['third-layer']};
      border: 1px solid ${colors.brand.primary['70']};
      border-radius: 8px;
      color: ${colors.brand.primary['70']};
      width: 48px;
      height: 48px;
    }
  }
  
  @media (${mobileWidthMediaQuery}) {
    
    &:hover, &.selecting {
      padding: 6px;
      
      ${AddCategory} {
        border-radius: 8px;
        width: 36px;
        height: 36px;
      }
    }
  }
`
