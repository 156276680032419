import React from 'react'

const YouTubeIcon = ({size = 32, color = '#ffffff'}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_68_166" style={{maskType: 'luminance'}} maskUnits="userSpaceOnUse" x="0" y="0" width="256" height="256">
        <path d="M0 -7.62939e-06H256V256H0V-7.62939e-06Z" fill={color}/>
      </mask>
      <g mask="url(#mask0_68_166)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M228.274 43.0211C239.291 45.984 247.964 54.7142 250.908 65.8007C256.258 85.8938 256.258 127.818 256.258 127.818C256.258 127.818 256.258 169.741 250.908 189.837C247.964 200.922 239.291 209.652 228.274 212.617C208.312 218 128.258 218 128.258 218C128.258 218 48.2044 218 28.2408 212.617C17.2255 209.652 8.55061 200.922 5.60661 189.837C0.258243 169.741 0.25824 127.818 0.25824 127.818C0.25824 127.818 0.258243 85.8938 5.60661 65.8007C8.55061 54.7142 17.2255 45.984 28.2408 43.0211C48.2044 37.6364 128.258 37.6364 128.258 37.6364C128.258 37.6364 208.312 37.6364 228.274 43.0211ZM168.985 127.82L102.076 165.883V89.7546L168.985 127.82Z"
          fill={color}
        />
      </g>
    </svg>

  )
}

export default YouTubeIcon
