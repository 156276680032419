import {useEffect} from "react";
import {useHistory} from "react-router-dom";

const LandingPage = () => {
  const history = useHistory()
  useEffect(() => {
    history.push('/trends')
  })
  return ''
}

export default LandingPage;
