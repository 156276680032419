import React, {Fragment} from "react";
import { checkIds, getReportingPeriodQueryString, getTagRankingMethodKey, isEnterprise, retreiveTagIncludeAutoFromQueryString, retreiveTagRankingMethodFromQueryString } from "../../data/App";
import { Redirect } from "react-router-dom";
import TagsGameCompareSection from "./Sections/TagsGameCompareSection";
import { loadCategoryData } from "../../data/Data";
import TagSectionContainer from "./TagSectionContainer";
import {PageHeader} from '../../components/styles'
import SectionLoadingPlaceholder from '../../components/Dashboard/SectionLoadingPlaceholder'

class TagsGameComparePage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			dataLoaded: false,
			search: false,
			games: new Map(),
			platforms: new Map(),
			categories: [],
			tagRankingMethod: retreiveTagRankingMethodFromQueryString(),
			includeAutoTags: retreiveTagIncludeAutoFromQueryString(),
		}
	}

	retreiveIds() {
		var loc = this.props.location;
		if (loc.ids) {
			console.log("found ids on location", this.props.location)
			return loc.ids;
		}

		// NOTE: Ids survive page reloads by nature of us having pushed our state into the browser history.
		if (loc.state && loc.state.ids) {
			console.log("found ids in state", loc.state)
			return loc.state.ids;
		}

		if (this.props && this.props.match && this.props.match.params && this.props.match.params.ids) {
			const ids = this.props.match.params.ids.split(",").map(s => parseInt(s, 10) || 0);
			console.log("found ids in props", this.props.match.params)
			return ids;
		}

		// 20191107: We should no longer ever make it this far, now that we pass ids around instead
		// of (yikes!) eCharts option objects.
		console.warn("NO IDs FOUND. Redirecting to landing page.")
		return [];
	}

	componentDidMount() {
		if (!isEnterprise()) {
			this.setState({ redirectTo: '/trends/' });
			return;
		}

		loadCategoryData().then(() => {
			const ids = checkIds(this.retreiveIds());

			if (!ids || ids.length === 0) {
				this.setState({ redirect: true })
				return;
			}

			this.setState({ ids, loaded: true }, () => {
				//this.populateFromIds(false);
				this.populateTop(ids);
			})
		})
	}

	componentDidUpdate() {
		if (this.state.dataLoaded && this.state.lastReportingDescription !== this.buildUrl()) {
			this.populateTop();
		}
	}

	buildQueryString = () => {
		return getReportingPeriodQueryString(window.location.search, {
			tr: getTagRankingMethodKey(this.state.tagRankingMethod),
			ti: this.state.includeAutoTags ? 1 : 0,
		});
	}

	isEmbed() {
		return window.location.pathname.indexOf("/embed/") === 0;
	}

	populateTop(ids) {
		this.setState({
			dataLoaded: true,
			lastReportingDescription: this.buildUrl(),
		},
		() => {
			// This causes componentDidUpdate to fire, so let's make sure we wait until the state change finishes before calling it:
			this.props.history.replace(this.buildUrl());
			//window.document.title = this.buildTitle(ids);
		});
	}

	buildUrl() {
		var ids = this.state.ids;
		if (this.isEmbed()) {
			return `/embed/tag-compare/${ids.join(",")}/${this.props.match.params.section}?${this.buildQueryString()}`;
		}
		return `/tag-compare/${ids.join(",")}/?${this.buildQueryString()}`;
	}

	buildTitle(ids) {
		return "Tags | Medal Trends";
	}

	tagRankingMethodChange = (tagRankingMethod, includeAutoTags) => {
		console.log("setting state", tagRankingMethod, includeAutoTags, true)
		this.setState({ tagRankingMethod, includeAutoTags });
	}

	searchHandler = (key) => {
	}

	render() {
		if (this.state.redirectTo !== undefined) {
			return <Redirect push to={this.state.redirectTo} />;
		}

		//console.log("comparepage", this.state.ids, this.props.ids, this.props, this.state)
		if (!this.state.ids) {
			return <SectionLoadingPlaceholder minHeight="400px"/>
		}

		return (
      <Fragment>
        {/* <Search
					itemsList={getCategoryData().map((game) => ({ label: game.name, value: game.id }))}
					handler={this.searchHandler}
				/> */}

        <PageHeader style={{paddingBottom: '0', borderBottom: 'none', marginBottom: '-10px'}}>
          Tag Trends
          <span>
            NOTE: Percentage shares shown below represent observations within the Medal.tv platform, and therefore may differ from numbers generated from other sources.
          </span>
        </PageHeader>

        <TagSectionContainer
          tagRankingMethod={this.state.tagRankingMethod}
          includeAutoTags={this.state.includeAutoTags}
          onTagRankingMethodChange={this.tagRankingMethodChange}
        >
          {this.state.ids.map(id =>
            <TagsGameCompareSection
              key={id}
              id={id}
              tagRankingMethod={this.state.tagRankingMethod}
              includeAutoTags={this.state.includeAutoTags}
            />
          )}
        </TagSectionContainer>

        {/* {this.state.ids.map(id =>
					<TagsGameCompareSection
						key={id}
						id={id}

					/>
				)} */}
      </Fragment>
    )
	}
}

export default TagsGameComparePage
