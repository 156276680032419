import React from 'react';

import PieChart from '../../../components/Charts/PieChart'

import { getCategoryById } from '../../../data/App'
import DashboardSection from '../../../components/Dashboard/DashboardSection'

class PlatformSharing extends React.Component {
	render() {
		if (this.props.shares.length === 0) {
			return (
				<div></div>
			)
		}

    return (
      <DashboardSection
        title="Platforms: Sharing"
        description="Sharing percentages represent a given platform's fraction of total shares for a game.  So, for all clip shares of this game, these are the percentages for each platform."
      >
        {
          this.props.shares.map((shares, index) => {
            return (
              <PieChart
                option={shares}
                name={
                  typeof getCategoryById(shares.id * 1) !== "undefined"
                    ? getCategoryById(shares.id * 1).abv
                    : ""
                }
                avatar={
                  typeof getCategoryById(shares.id * 1) !== "undefined"
                    ? getCategoryById(shares.id * 1).avatar
                    : "./62.png"
                }
                key={`${shares.id}_${new Date().getTime()}`}
                id={shares.id}
              />
            )
          })
        }
      </DashboardSection>
    )
	}
}

export default PlatformSharing
