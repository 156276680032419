import React, {useContext, useMemo} from 'react'
import styled from 'styled-components'
import {colors} from '@get-wrecked/simple-components'
import PropTypes from 'prop-types'
import {CategoryContext} from '../../context'

const propTypes = {
  games: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    isNew: PropTypes.bool,
    unit: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  })),
  children: PropTypes.node,
}

const GameCompareCard = ({ games, children }) => {
  const {dataMappedCategories} = useContext(CategoryContext)

  const categories = useMemo(() => {
    const categoryMap = {}
    for (let series of games) {
      if (!categoryMap[series.id]) {
        const currentValue = series.data[series.data.length - 1]
        const startingValue = series.data[0]
        const percentageChange = ((((currentValue + .0001) / (startingValue + .0001) - 1) * 100).toFixed(1)) * 1; // relative percentage, whole period
        categoryMap[series.id] = {
          ...dataMappedCategories.find(cat => cat.id === series.id),
          percentageChange,
          currentValue,
          startingValue,
        }
      }
    }
    return categoryMap
  }, [dataMappedCategories, games])

  return (
    <Container>
      {
        games.map((game, index) => {
          const percentageChange = categories[game.id]?.percentageChange
          return (
            <Header key={game.id}>
              <GameArt>
                <img src={game.thumb ?? categories[game.id]?.thumb} alt={`${game.name} cover`}/>
                <ColorIndicator $color={game.color}/>
              </GameArt>
              <HeaderPadded>
                <Title>
                  <NameRow>
                    <Name>{game.name.toLowerCase()}</Name>
                    {
                      game.isNew &&
                      <NewBadge>New</NewBadge>
                    }
                  </NameRow>
                  {
                    percentageChange !== undefined &&
                    <Subtext>
                      <span style={{color: percentageChange > 0 ? colors.success['10'] : colors.error['10']}}>{percentageChange > 0 ? `+${percentageChange}` : percentageChange}%</span>
                    </Subtext>
                  }
                </Title>
                {
                  game.rank &&
                  <Rank $rank={game.rank}>
                    {game.rank}
                  </Rank>
                }
              </HeaderPadded>
              {
                index > 0 &&
                <Versus>
                  VS
                </Versus>
              }
            </Header>
          )
        })
      }
      {children}
    </Container>
  )
}

GameCompareCard.propTypes = propTypes

export default GameCompareCard

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.neutral['0A4']};
  border: 1px solid ${colors.stroke['0A8']};
  border-radius: 8px;
  flex: 1;
  cursor: pointer;
  
  &:hover {
    border-color: ${colors.neutral['0A24']};
  }
`

const GameArt = styled.div`
  display: flex;
  width: auto;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-inline-end: 1px solid ${colors.stroke['0A8']};
  height: 100%;
  position: relative;
  border-top-left-radius: 8px;
  flex-shrink: 0;
  
  img {
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: 100%;
  }
`

const HeaderPadded = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  height: 100%;
  flex: 1;
  line-height: 1;
  min-width: 0;
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: bold;
  color: ${colors.text['0']};
  text-transform: capitalize;
  gap: 5px;
  min-width: 0;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.stroke['0A8']};
  height: 60px;
  position: relative;

  // Apply flex-direction row to odd children
  &:nth-child(odd) {
    flex-direction: row;
  }
  // Apply flex-direction row-reverse to even children
  &:nth-child(even) {
    flex-direction: row-reverse;
    ${HeaderPadded} {
      justify-content: flex-end;
    }
    ${GameArt} {
      border-radius: 0;
    }
    ${Title} {
      align-items: flex-end;
    }
  }
`

const Versus = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
  border-radius: 8px;
  font-size: 13px;
  font-weight: bold;
  color: ${colors.text['100']};
  line-height: 1;
  background-color: ${colors.neutral['30']};
  border: 1px solid ${colors.stroke['0A16']};
  position: absolute;
  top: -10px;
  left: calc(50% - 13px);
`

const NameRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const Name = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const NewBadge = styled.span`
  display: flex;
  align-items: center;
  padding: 4px 6px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: bold;
  background-color: ${colors.success['70']};
  color: ${colors.success['10']};
  height: fit-content;
  flex-shrink: 0;
`

const Subtext = styled.span`
  font-size: 13px;
  font-weight: normal;
  text-transform: none;
  color: ${colors.text['30']};
`

const Rank = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  height: 24px;
  width: 24px;
  font-size: 14px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.64);
  border: 1px solid ${colors.neutral['0A24']};
  backdrop-filter: blur(4px);
  color: ${colors.text['0']};
  margin-inline-start: auto;

  ${({$rank}) => {
    if ($rank <= 3) {
      return `
        background-color: ${colors.brand.primary['100']};
        color: ${colors.brand.primary['10']};
        border: 1px solid ${colors.brand.primary['70']};
      `
    }
  }}
`

const ColorIndicator = styled.div`
  width: 12px;
  height: 12px;
  position: absolute;
  bottom: 4px;
  right: 4px;
  border-radius: 4px;
  // border: 1px solid ${colors.stroke['0A16']};
  outline: 2px solid black;
  opacity: 1;
  
  background-color: ${({$color}) => $color};
  z-index: 1;
`
