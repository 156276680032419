import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {Button} from '@get-wrecked/simple-components'

const propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

const LoadMoreButton = ({label, onClick}) => {
  return (
    <Container>
      <Button
        variant="ghost"
        size="large"
        onClick={onClick}
        style={{
          flex: 1
        }}
      >
        {label ?? 'Load More'}
      </Button>
    </Container>
  )
}

LoadMoreButton.propTypes = propTypes

export default LoadMoreButton

const Container = styled.div`
  display: flex;
  width: 100%;
`
