import styled, {css, keyframes} from 'styled-components'
import {colors} from '@get-wrecked/simple-components'

export const pageContentWidth = 'min(1370px, 100%)'

export const mobileWidthMediaQuery = `max-width: 575px`

export const InputLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  color: ${colors.text['30']};
  font-size: 12px;
  font-weight: 500;
`

export const ErrorText = styled.span`
  color: ${colors.error['10']};
`

export const SuccessText = styled.span`
  color: ${colors.success['10']};
`

const loadingPlaceholderAnimation = keyframes`
  0% { background-position: -650px 0; }
  100% { background-position: 650px 0; }
`

export const LoadingPlaceholder = css`
  animation-duration: 1.7s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: ${loadingPlaceholderAnimation};
  background: linear-gradient(to right, ${({$backgroundColor}) => $backgroundColor || colors.neutral['0A4']} 2%, ${({$loadingOverlayColor}) => $loadingOverlayColor || colors.stroke['0A8']} 18%, ${({$backgroundColor}) => $backgroundColor || colors.neutral['0A4']} 33%);
  background-size: ${({$loadingSpace}) => $loadingSpace ?? '1300px'}; // Animation Area
`

export const NewBadge = styled.span`
  display: flex;
  align-items: center;
  padding: 4px 6px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: bold;
  background-color: ${colors.success['70']};
  color: ${colors.success['10']};
  height: fit-content;
  max-height: 22px;
  flex-shrink: 0;
`

export const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-size: 18px;
  font-weight: bold;
  color: ${colors.text['0']};
  padding-bottom: 22px;
  border-bottom: 1px solid ${colors.stroke['0A16']};
  
  span {
    color: ${colors.text['30']};
    font-size: 14px;
    font-weight: normal;
  }
  
  a {
    color: white;
    
    &:hover {
      text-decoration: underline;
    }
  }
`

export const ChartList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  
  > a, > div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
    flex: 1;
    padding: 8px;
    cursor: pointer;
    border-radius: 0;
    border: 1px solid rgba(255, 255, 255, 0);

    &:not(:last-child) {
      border-bottom: 1px solid ${colors.stroke['0A8']}; /* Adjust the border as needed */
    }

    &:hover {
      border-radius: 8px;
      background-color: ${colors.neutral['0A4']};
      border-color: ${colors.stroke['0A8']};
    }
  }
`

export const Rank = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  height: 24px;
  width: 24px;
  font-size: 14px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.64);
  border: 1px solid ${colors.neutral['0A24']};
  backdrop-filter: blur(4px);
  color: ${colors.text['0']};
  margin-inline-start: auto;
  flex-shrink: 0;

  ${({$rank}) => {
    if ($rank <= 3) {
      return `
        background-color: ${colors.brand.primary['100']};
        color: ${colors.brand.primary['10']};
        border: 1px solid ${colors.brand.primary['70']};
      `
    }
  }}
`
