import React from 'react'

const DiscordIcon = ({size = 32, color = '#ffffff'}) => {
  return (
    <svg width={size} height={size * 0.7578125} viewBox="0 0 128 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_68_171)">
        <path
          d="M107.7 8.07C99.3467 4.24568 90.5282 1.53258 81.4699 0C80.2304 2.21588 79.1088 4.49573 78.1099 6.83C68.461 5.37602 58.6488 5.37602 48.9999 6.83C48.0005 4.49597 46.8789 2.21615 45.6399 0C36.5758 1.54552 27.7515 4.26506 19.3899 8.09C2.7899 32.65 -1.7101 56.6 0.539904 80.21C10.2613 87.3926 21.1423 92.8551 32.7099 96.36C35.3146 92.8568 37.6194 89.1403 39.5999 85.25C35.8382 83.8451 32.2076 82.1117 28.7499 80.07C29.6599 79.41 30.5499 78.73 31.4099 78.07C41.4709 82.8014 52.4519 85.2546 63.5699 85.2546C74.6879 85.2546 85.6689 82.8014 95.7299 78.07C96.5999 78.78 97.4899 79.46 98.3899 80.07C94.9256 82.115 91.2882 83.8517 87.5199 85.26C89.498 89.1485 91.8029 92.8619 94.4099 96.36C105.987 92.8691 116.877 87.4092 126.6 80.22C129.24 52.84 122.09 29.11 107.7 8.07ZM42.4499 65.69C36.1799 65.69 30.9999 60 30.9999 53C30.9999 46 35.9999 40.26 42.4299 40.26C48.8599 40.26 53.9999 46 53.8899 53C53.7799 60 48.8399 65.69 42.4499 65.69ZM84.6899 65.69C78.4099 65.69 73.2499 60 73.2499 53C73.2499 46 78.2499 40.26 84.6899 40.26C91.1299 40.26 96.2299 46 96.1199 53C96.0099 60 91.0799 65.69 84.6899 65.69Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_68_171">
          <rect width="127.14" height="96.36" fill={color}/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default DiscordIcon
