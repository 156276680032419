import ComparePage from "../views/Compare/ComparePage.jsx"
import LandingPage from "../views/LandingPage/LandingPage.jsx";
import MarketSharePage from "../views/MarketShare/MarketSharePage.jsx";
import TrendsPage from "../views/Trends/TrendsPage.jsx";
import TagsLandingPage from "../views/Tags/TagsLandingPage.jsx";
import TagsGameComparePage from "../views/Tags/TagsGameComparePage.jsx";
import TagDetailPage from "../views/Tags/TagDetailPage.jsx";
import ServersLandingPage from "../views/Servers/ServersLandingPage.jsx";
import ServerGameComparePage from "../views/Servers/ServerGameComparePage.jsx";
import ServerDetailPage from "../views/Servers/ServerDetailPage.jsx";
import SubgamesGameComparePage from "../views/Subgames/SubgamesGameComparePage.jsx";
import SubgameDetailPage from "../views/Subgames/SubgameDetailPage.jsx";
import SubgamesLandingPage from "../views/Subgames/SubgamesLandingPage.jsx";

var indexRoutes = [
	{ exact: true, path: "/", name: "LandingPage", component: LandingPage },
	{ exact: false, path: "(/embed|)/compare-page/:ids/:section?", name: "ComparePage", component: ComparePage },
	{ exact: false, path: "(/embed|)/player-share/:section?", name: "MarketSharePage", component: MarketSharePage },
	{ exact: false, path: "(/embed|)/trends/:section?", name: "TrendsPage", component: TrendsPage },
	//{ exact: false, path: "(/embed|)/prediction/:ids?/:section?", name: "PredictionPage", component: PredictionPage },
	//{ exact: false, path: "(/embed|)/platforms/:section?", name: "PlatformsPage", component: PlatformsPage },
	//{ exact: false, path: "(/embed|)/platform-detail/:platforms/:section?", name: "PlatformComparePage", component: PlatformComparePage },
	//{ exact: false, path: "(/embed|)/platform-compare/:ids?/:section?", name: "PlatformGameComparePage", component: PlatformGameComparePage },
	//{ exact: false, path: "(/embed|)/retention-compare/:ids?/:section?", name: "RetentionPage", component: RetentionPage },
	//{ exact: false, path: "(/embed|)/retention/", name: "RetentionLandingPage", component: RetentionLandingPage },
	//{ exact: false, path: "(/embed|)/hardware/", name: "HardwareLandingPage", component: HardwareLandingPage },
	{ exact: false, path: "(/embed|)/tags/:section?", name: "TagsLandingPage", component: TagsLandingPage },
	{ exact: false, path: "(/embed|)/tag-compare/:ids?/:section?", name: "TagsGameComparePage", component: TagsGameComparePage },
	{ exact: false, path: "(/embed|)/tag-detail/:id?/:tags?", name: "TagDetailPage", component: TagDetailPage },
	{ exact: false, path: "(/embed|)/servers/:section?", name: "ServersLandingPage", component: ServersLandingPage },
	{ exact: false, path: "(/embed|)/server-compare/:ids?/:section?", name: "ServerGameComparePage", component: ServerGameComparePage },
	{ exact: false, path: "(/embed|)/server-detail/:ids?/:section?", name: "ServerDetailPage", component: ServerDetailPage },
	{ exact: false, path: "(/embed|)/subgames/:section?", name: "SubgamesLandingPage", component: SubgamesLandingPage },
	{ exact: false, path: "(/embed|)/subgame-compare/:ids?/:section?", name: "SubgamesGameComparePage", component: SubgamesGameComparePage },
	{ exact: false, path: "(/embed|)/subgame-detail/:id?/:subgames?", name: "SubgameDetailPage", component: SubgameDetailPage },
	//{ exact: false, path: "(/embed|)/insights/", name: "InsightsPage", component: InsightsPage },
	//{ exact: false, path: "(/embed|)/retention-landing/:section?", name: "RetentionLandingPage", component: RetentionLandingPage },
];

export default indexRoutes;
