import {useState, useEffect, useRef} from "react";
import equal from 'fast-deep-equal'

function getStorageValue(key, defaultValue) {
  try {
    const saved = localStorage.getItem(key);
    if (!saved) {
      return defaultValue
    }
    return JSON.parse(saved) ?? defaultValue;
  } catch (err) {
    console.error('localStorage get error:', err)
    return defaultValue
  }
}

export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue)
  })
  const currentValueRef = useRef(value)

  useEffect(() => {
    if (!equal(currentValueRef.current, value)) {
      currentValueRef.current = value
      try {
        localStorage.setItem(key, JSON.stringify(value))
      } catch (err) {
        console.error('localStorage set error:', err)
      }
    }
  }, [key, value])

  return [value, setValue]
}

export default useLocalStorage
