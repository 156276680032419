import { container, title } from "../../material-kit-react.jsx";
import {colors} from '@get-wrecked/simple-components'

const landingPageStyle = {
	container: {
		// zIndex: "12",
		color: "#FFFFFF",
		...container
	},
	card: {
		flex: 1,
		padding: 0,
		margin: 10,
		backgroundColor: '#1F1F1F',
		color: '#ffffff',
	},
	cardContent: {
		padding: 0,
		margin: 0,
	},
	cardHeader: {
		borderBottom: '0.01em solid grey',
		marginBottom: '20px',
	},
	title: {
		...title,
		display: "inline-block",
		position: "relative",
		marginTop: "30px",
		minHeight: "32px",
		color: "#FFFFFF",
		textDecoration: "none"
	},
	subtitle: {
		fontSize: "1.313rem",
		maxWidth: "500px",
		margin: "10px auto 0"
	},
	main: {
		background: "#FFFFFF",
		position: "relative",
		// zIndex: "3"
	},
	mainRaised: {
    backgroundColor: colors.background['first-layer']
		// margin: "-60px 30px 0px",
		// margin: "0px 30px 0px",
		// borderRadius: "6px",
		// boxShadow:
		// 	"0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
	},
	loading: {
		display: 'flex',
		margin: '0 auto',
		paddingTop: 125,
		paddingBottom: 150,
		justifyContent: 'center'
	},
};

export default landingPageStyle;
