import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from '@material-ui/core/Tooltip';

import { buildCompareUrl, dataMembers } from '../../data/App.js';
import DashboardSection from '../Dashboard/DashboardSection'
import LoadMoreButton from '../LoadMoreButton'
import {ChartList} from '../styles'
import ChartListItem from '../Dashboard/ChartListItem'
import GameThumbnailAndRank from '../Game/GameThumbnailAndRank'
import styled from 'styled-components'
import {colors} from '@get-wrecked/simple-components'
import {GameThumbnail} from '../ComparisonSwitcher/GameSelection'

const HtmlTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: 'rgba(255,255,255,1)',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(14),
		border: '1px solid #dadde9',
	},

}))(Tooltip);

class CompoundPieList extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			title: "",
			games: new Map(),
			dataMember: dataMembers.USAGE_PERCENTS,
			loading: true,

			moreButtonDisplay: true,
			pageIndex: 1,
			showCount: this.props.pageSize || 8,
		}
	}

	static defaultProps = {
		blurOnSelect: false,
	};

	componentDidMount() {
		if (this.state.showCount >= this.props.gameOrder.length) {
			this.setState({ moreButtonDisplay: false });
		}
	}

	loadMore = (e) => {
		const index = this.state.pageIndex + 1;
		this.setState({ pageIndex: index })
		if (index * this.state.showCount >= this.props.gameOrder.length) {
			this.setState({ moreButtonDisplay: false });
		}
	}

	isGameNew = (item) => {
		return false;
		//return item[this.props.dataMember][0] === 0;
	}

	displayPercent = (item) => {
		// NOTE: For server trends, this will return the average across the whole period.
		// If we comment it out, we'll return the latest single value.
		if (this.props.reportingColumn && item[this.props.reportingColumn]) {
			//console.log("item has reportingcolumn", item, item[this.props.reportingColumn], this.latestPercent(item))
			return item[this.props.reportingColumn];
		}

		return this.latestPercent(item);
	}

	latestPercent = (item) => {
		const metric = item[this.props.metric];
		if (metric) {
			if (item.gameServerId === 49) {
				console.log("latest", item, metric, metric[metric.length - 1])
			}
			return metric[metric.length - 1];
		}
		return item.sharePercents && item.sharePercents[item.sharePercents.length - 1];
	};

	changePercent = (item) => {
		//console.log("changePercent", this.props.metric, item[this.props.metric])
		if (!item[this.props.metric]) {
			return 0;
		}

		const oldestPercent = (item) => {
			return item[this.props.metric][0]
		}
		const latestPercent = (item) => {
			return item[this.props.metric][item[this.props.metric].length - 1]
		}


		// zero safen the divide
		let oldestValue = oldestPercent(item);
		if (oldestValue === 0) {
			//console.log("changePercent", item, this.props.metric, item[this.props.metric], latestPercent(item), oldestPercent(item))
			oldestValue = 0.001;
			//console.log("safen", latestPercent(item) / oldestValue, latestPercent(item) / oldestValue - 1)
		}
		let changeValue = (((latestPercent(item) / oldestValue - 1) * 100).toFixed(1)) * 1; // relative percentage, whole period
		if (changeValue > 999) {
			changeValue = 999;	// don't show 0 plays -> 5 plays as 5,000,000,000%
		}
		if (changeValue < -999) {
			changeValue = -999;
		}

		// if (changeValue === -100) {
		// 	console.log("dropped to zero?", item, this.props.metric, item[this.props.metric], latestPercent(item), oldestPercent(item))
		// }

		// result.series[seriesCounter].data[index - 1] < value ? '% Of Total (↑)' : '% Of Total (↓)'

		return changeValue;
	}

	changeColor = (item) => {
		const percent = this.changePercent(item);
		if (percent > 0) {
			return colors.success['10'];
		} else if (percent < 0) {
			return colors.error['10'];
		}
		return colors.text['30']

	}
	changeString = (item) => {
		const percent = this.changePercent(item);
		return `${(percent < 0 ? '' : '+')}${percent}%`;
	}

	renderTooltip(item) {
		var nameField = this.props.toolNameField || "platform";
		const idField = this.props.toolIdField || "platform";
		var dataMember = this.props.dataMember || "platforms";
		const reportingColumn = this.props.reportingColumn || "shares";

		return <React.Fragment>
			<b>{item.name}<br /> {this.props.toolTipHeader || `Clip sharing by platform`}</b>
			<div style={{ margin: '10px 0px' }}>
				{item[dataMember].map(m => {
					if (m[reportingColumn] > 0) {
						return <div key={m[idField]+Math.random()}>
							<span style={{ display: 'inline-block', marginRight: 5, borderRadius: 10, width: 9, height: 9, backgroundColor: m.color }}></span>
							{m[nameField]}: {m[reportingColumn].toFixed(2)}%
						</div>
					}
					return null;
				})}
			</div>
			<b>{this.displayPercent(item)}% {this.props.toolTipTotal || `of total clip shares`}</b>
		</React.Fragment>

	}

	render() {
		const gameDataDisplay = this.props.data;
		var dataMember = this.props.dataMember || "platforms";
		const valueField = this.props.valueField || "platform";
		const nameField = this.props.nameField || "abv";
		const idField = this.props.idField || "id";
		const linkPath = this.props.linkPath || "compare-page"
		const showIds = this.props.gameOrder.slice(0, this.state.pageIndex * this.state.showCount);
		const reportingColumn = this.props.reportingColumn || "shares";
    return (
      <DashboardSection
        title={this.props.title}
        description={this.props.subtitle}
        gap={showIds.length > 1 ? '0' : undefined}
      >
        <ChartList>
          {
            showIds.map((id, index) => {
              const item = gameDataDisplay.find(g => g[idField] === id);
              if (!item) {
                return null
              }
              var pos = 0;
              return (
                <ChartListItem
                  key={id}
                  icon={
                    this.props.hideIndex
                      ? <GameThumbnail
                        category={{
                          ...item,
                          thumb: item.games?.[0]?.thumb ?? item.thumb
                        }}
                        size="48px"
                      />
                      : <GameThumbnailAndRank
                        game={{
                          ...item,
                          thumb: item.games?.[0]?.thumb ?? item.thumb
                        }}
                        rank={index + 1}
                        size="48px"
                      />
                  }
                  index={index}
                  isNew={this.isGameNew(item)}
                  label={
                    <ItemLabel>
                      {item[nameField] ?? ''}
                      <span>
                        {this.displayPercent(item)}% {this.props.toolTipTotal || `of total clip shares`}
                        {
                          this.props.showTrend &&
                          <span style={{color: this.changeColor(item)}}>
                            {this.changeString(item)}
                          </span>
                        }
                      </span>
                    </ItemLabel>
                  }
                  linkTo={{
                    pathname: buildCompareUrl(linkPath, item[idField])
                  }}
                  showRank={false}
                >
                  <Chart>
                    <HtmlTooltip
                      key={index}
                      className="opacityOne"
                      style={{ background: 'blue' }}
                      title={this.renderTooltip(item)}
                    >
                      <div style={{ position: 'absolute', right: 0, width: '100%', height: '100%', overflow: 'hidden' }}>
                        {/* "Pie Size" bar goes partial width */}
                        <div style={{ position: 'absolute', right: 0, width: this.displayPercent(item) + '%', height: '100%', margin: 0, background: 'rgba(255,255,255,0.5)', border: 'solid 2px #fff', overflow: 'hidden' }}></div>

                        {/* "Pie Slice" Bars go full width */}
                        <div style={{ position: 'absolute', right: 0, width: '100%', top:'15%', height: '70%', margin: 0, border: 'solid 1px #fff', overflow: 'hidden' }}>
                          {item[dataMember].map((m,i) => {
                            var oldPos = pos;
                            var value = m[reportingColumn];
                            pos += value;
                            return <div key={`${m[valueField]}${i}`} style={{ position: 'absolute', left: `${oldPos}%`, width: `${value}%`, height: '100%', margin: '0', background: m.color, border: 'solid 1px #fff' }}></div>
                          })}
                        </div>
                      </div>
                    </HtmlTooltip>
                  </Chart>
                </ChartListItem>
              )
            })
          }
        </ChartList>
        {
          this.state.moreButtonDisplay &&
          <LoadMoreButton onClick={this.loadMore}/>
        }
      </DashboardSection>
    )
	}
}

export default CompoundPieList

const Chart = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  position: relative;
  width: min(100%, 500px);
  height: 48px;
  font-size: 14px;
  font-weight: 500;
  color: ${colors.text['0']};
`

const ItemLabel = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1;
  gap: 6px;
  flex: 1;
  
  span {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    color: ${colors.text['30']};
    font-weight: normal;
    flex-wrap: wrap;
  }
`
