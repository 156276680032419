import React from 'react'

const RedditIcon = ({size = 32, color = '#ffffff'}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_68_169)">
        <path
          d="M218.656 90.5136C210.097 90.5136 202.214 93.3997 195.917 98.2473C178.775 87.64 157.136 80.9683 133.46 80.0812C133.46 80.0437 133.46 80.0187 133.46 79.9813C133.46 64.114 145.254 50.9454 160.547 48.784C163.32 60.5408 173.878 69.299 186.484 69.299C201.202 69.299 213.133 57.3673 213.133 42.6495C213.133 27.9317 201.202 16 186.484 16C173.615 16 162.883 25.1205 160.384 37.2521C138.832 39.5635 122.003 57.8421 122.003 79.9938C122.003 80.0437 122.003 80.0812 122.003 80.1312C98.5644 81.1182 77.1623 87.7899 60.1706 98.3223C53.8612 93.4372 45.9401 90.5261 37.3443 90.5261C16.7168 90.5261 0 107.243 0 127.87C0 142.838 8.7957 155.732 21.502 161.691C22.7389 205.045 69.9783 239.916 128.087 239.916C186.197 239.916 233.499 205.008 234.673 161.616C247.279 155.619 256 142.763 256 127.883C256 107.255 239.283 90.5386 218.656 90.5386V90.5136Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_68_169">
          <rect width="256" height="256" fill={color}/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default RedditIcon
