import React from "react";


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';

// core components
import landingPageStyle from "../../assets/jss/material-kit-react/views/landingPage.jsx";

import { shareCalculations } from "../../data/App";

class MarketShareMethodPicker extends React.Component {


	render() {
		const onChange = this.props.onChange || function() { console.log("onChange not registered")};

		return (
			<FormControl>
				<InputLabel htmlFor="marketShareMethod">Calculation Type</InputLabel>
				<Select
					native
					style={{ color: "#eeeeee" }}
					value={this.props.value}
					onChange={onChange}
					inputProps={{
						name: 'marketShareMethod',
						id: 'marketShareMethod',
					}}
				>
					{shareCalculations.map((c, i) => {
						return <option style={{ background: "#303030" }} value={c.dataMember} key={i}>{c.title}</option>
					})}
				</Select>
			</FormControl>
		);
	}
}


export default withStyles(landingPageStyle)(MarketShareMethodPicker);
