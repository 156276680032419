import React, {Fragment} from "react";
import { checkIds, getReportingPeriodQueryString, getSubgameRankingMethodKey, isEnterprise, retreiveIncludeNoneSubgameFromQueryString, retreiveSubgameRankingMethodFromQueryString } from "../../data/App";

import { Redirect } from "react-router-dom";
import SubgamesGameCompareSection from "./Sections/SubgamesGameCompareSection";
import { loadCategoryData } from "../../data/Data";
import SubgamesSectionContainer from "./SubgamesSectionContainer";
import SectionLoadingPlaceholder from '../../components/Dashboard/SectionLoadingPlaceholder'
import {PageHeader} from '../../components/styles'

class SubgamesGameComparePage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			dataLoaded: false,
			search: false,
			games: new Map(),
			platforms: new Map(),
			categories: [],
			subgameRankingMethod: retreiveSubgameRankingMethodFromQueryString(),
			includeNoneSubgame: retreiveIncludeNoneSubgameFromQueryString(),
		}
	}

	retreiveIds() {
		var loc = this.props.location;
		if (loc.ids) {
			console.log("found ids on location", this.props.location)
			return loc.ids;
		}

		// NOTE: Ids survive page reloads by nature of us having pushed our state into the browser history.
		if (loc.state && loc.state.ids) {
			console.log("found ids in state", loc.state)
			return loc.state.ids;
		}

		if (this.props && this.props.match && this.props.match.params && this.props.match.params.ids) {
			const ids = this.props.match.params.ids.split(",").map(s => parseInt(s, 10) || 0);
			console.log("found ids in props", this.props.match.params)
			return ids;
		}

		// 20191107: We should no longer ever make it this far, now that we pass ids around instead
		// of (yikes!) eCharts option objects.
		console.warn("NO IDs FOUND. Redirecting to landing page.")
		return [];
	}

	componentDidMount() {
		if (!isEnterprise()) {
			this.setState({ redirectTo: '/trends/' });
			return;
		}
		loadCategoryData().then(() => {
			const ids = checkIds(this.retreiveIds());
			if (!ids || ids.length === 0) {
				this.setState({ redirect: true })
				return;
			}
			this.setState({ ids, loaded: true }, () => {
				//this.populateFromIds(false);
				this.populateTop(ids);
			})
		})
	}

	componentDidUpdate() {
		if (this.state.dataLoaded && this.state.lastReportingDescription !== this.buildUrl()) {
			this.populateTop();
		}
	}

	buildQueryString = () => {
		return getReportingPeriodQueryString(window.location.search, {
			sr: getSubgameRankingMethodKey(this.state.subgameRankingMethod),
			si: this.state.includeNoneSubgame ? 1 : 0,
		});
	}

	isEmbed() {
		return window.location.pathname.indexOf("/embed/") === 0;
	}

	populateTop(ids) {
		this.setState({
			dataLoaded: true,
			lastReportingDescription: this.buildUrl(),
		},
			() => {
				// This causes componentDidUpdate to fire, so let's make sure we wait until the state change finishes before calling it:
				this.props.history.replace(this.buildUrl());
				//window.document.title = this.buildTitle(ids);
			});
	}

	buildUrl() {
		var ids = this.state.ids;
		if (this.isEmbed()) {
			return `/embed/subgame-compare/${ids.join(",")}/${this.props.match.params.section}?${this.buildQueryString()}`;
		}
		return `/subgame-compare/${ids.join(",")}/?${this.buildQueryString()}`;
	}

	buildTitle(ids) {
		return "Subgames | Medal Trends";
	}

	subgameRankingMethodChange = (subgameRankingMethod, includeNoneSubgame) => {
		console.log("setting state", subgameRankingMethod, includeNoneSubgame, true)
		this.setState({ subgameRankingMethod, includeNoneSubgame });
	}

	searchHandler = (key) => {

	}

	render() {
		if (this.state.redirectTo !== undefined) {
			return <Redirect push to={this.state.redirectTo} />;
		}

		//console.log("comparepage", this.state.ids, this.props.ids, this.props, this.state)
		if (!this.state.ids) {
			return <SectionLoadingPlaceholder/>
		}
		return <Fragment>
      <PageHeader>
        Subgame Trends
        <span>
          NOTE: Percentage shares shown below represent observations within the Medal.tv platform, and therefore may differ from numbers generated from other sources.
        </span>
      </PageHeader>

      <SubgamesSectionContainer
        subgameRankingMethod={this.state.subgameRankingMethod}
        includeNoneSubgame={this.state.includeNoneSubgame}
        onSubgameRankingMethodChange={this.subgameRankingMethodChange}
      >
        {this.state.ids.map(id =>
          <SubgamesGameCompareSection
            key={id}
            id={id}
            subgameRankingMethod={this.state.subgameRankingMethod}
            includeNoneSubgame={this.state.includeNoneSubgame}
          />
        )}
      </SubgamesSectionContainer>
		</Fragment>

	}
}

export default SubgamesGameComparePage
