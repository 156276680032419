import React from "react";

import {
  autoTags,
  buildCompareUrl,
  getCategoryById,
  getReportingPeriodQueryString,
  getTagRankingMethodKey,
  groupMultiSummed,
  groupSummed,
  isEnterprise,
} from "../../../data/App";
import {loadCategoryData, calculatePeriodShares, extractColorList} from "../../../data/Data";
import EmbedAvatarList from '../../../components/Embed/EmbedAvatarList'


import {Redirect} from "react-router-dom";
import {loadTagData, zeroFillMissingTagDays} from "../TagsLandingPage";
import {combineOtherRows} from "../../Servers/ServersLandingPage";
import PieList from "../../../components/Charts/PieList";
import StackableCompareChart from "../../../components/Charts/StackableCompareChart.jsx";
import SectionLoadingPlaceholder from '../../../components/Dashboard/SectionLoadingPlaceholder'
import LoadMoreButton from '../../../components/LoadMoreButton'

const sectionDescriptions = {
  "shares": "How many times clips are shared on each platform. The higher the number, the more popular a platform is for sharing clips. The number shown is a percentage of all clips shared across all platforms.",
  "viewers": "How many times clips shared on a given platform were viewed. It's a good indication of where people are going to view clips. The number shown is a percentage of all clips viewed across all platforms.",
  "attention": "How many views a shared clip is expected to see after being shared on the platform in question. These are average counts of views per share, rather than a percentage.",
  "contentCount": "Percent breakdown of clip tag counts, among the 50 most popular tags.",
  "views": "Percent breakdown of clip tag counts, among the 50 most popular tags.",
  "likes": "Percent breakdown of clip tag counts, among the 50 most popular tags.",
}

export const transformTagCompare = (processed, id, metric = "contentCount", includeAutoTags = false, loadCount = 50, otherThreshold = 10) => {


  // TODO
  const period = "day";


  const {
    countColumns,
  } = processed;
  //const categoryFilteredTagData = processed.tagData.filter(t => t.categoryId === id)
  var tagData = processed.tagData.filter(t => t.categoryId === id)

  // Ditch emojis
  tagData.forEach(t => {
    t.tag = t.tag.replace(/[^0-9a-z_ ]/gi, '')
  })

  // Filter garbage tags out
  const game = getCategoryById(id);
  const gameNameFilter = game.name.toLowerCase().replace(/ /g, '')
  const autos = autoTags[id];
  tagData = tagData.filter(t =>
    t.tag.toLowerCase() !== gameNameFilter
    && (includeAutoTags || !autos || autos.indexOf(t.tag.toLowerCase()) === -1),
  );

  // Tag popularity list over the period, expandable
  const groupedByTag = groupSummed(tagData, "tag", countColumns)
    .sort((a, b) => b[metric] - a[metric]);
  const groupedByTagAsPercents = calculatePeriodShares(groupedByTag, countColumns, "")

  // TODO: sort by id or something to do colors, so that they stay consistent when we switch metric

  // create a new color lookup just for these tags
  // NOTE: We sort by contentCount here so that colors are consistent between metrics.
  const tagColorLookup = extractColorList(groupedByTag.sort((a, b) => b.contentCount - a.contentCount), "tag", "tag");
  tagColorLookup["Other"] = {
    "id": "Other",
    "name": "Other",
    "avatar": "./.png",
    "data": [],
    "color": "hsl(0, 0%, 40%)",
  }


  // Top tags by session count
  // NOTE that we sort again to account for the above.
  const topTags = groupedByTag
    .sort((a, b) => b[metric] - a[metric])
    .slice(0, loadCount).map(g => g.tag);


  // Note that we grouped by tag & content before zero filling.
  tagData = zeroFillMissingTagDays(tagData, period, "tag", countColumns, id);
  // console.log("zero filled tags", tagData)

  const groupedByTagPeriod = groupMultiSummed(tagData, ["tag", period], countColumns);
  //const groupedByCategoryIdPeriod = groupMultiSummed(tagData, ["categoryId", period], countColumns);

  // Top tags per day chart
  var groupedByTagPeriodAsPercents = calculatePeriodShares(groupedByTagPeriod, countColumns, period)
  const topTagSlice = topTags.slice(0, otherThreshold);

  //console.log("top tags?", topTagDebug, topTags, topTagSlice)

  const otherGroupedTagPeriodAsPercents = combineOtherRows(groupedByTagPeriodAsPercents, topTagSlice, "tag", "tag", ["day"], countColumns)
    .sort((a, b) => a.day < b.day ? 1 : -1)	// 1:-1 sorts in date order

  // Here, we're rearranging our data so that it'll make it into a stacked chart in the right order.
  const tagChartOrderWithColors = topTagSlice.map(t => tagColorLookup[t]).reverse();
  //const tagChartOrderWithColors = Object.values(tagColorLookup).filter(v => topTagSlice.indexOf(v.id) > -1).reverse();
  //const tagChartOrderWithColorsThatMixesUpTheOrder = Object.values(tagColorLookup).filter(v => topTagSlice.indexOf(v.id) > -1).reverse();
  tagChartOrderWithColors.unshift(tagColorLookup["Other"]);


  //console.log("for stacking", otherGroupedTagPeriodAsPercents, topTagSlice, tagChartOrderWithColors, topTagDebug)

  // console.log("topIds & grouped", topTags, topCategoryIds, groupedByTag)
  // console.log("pair groups", groupedByTagPeriod, groupedByCategoryIdPeriod, groupedByTagPeriodAsPercents)

  // console.log("other grouped", otherGroupedTagPeriodAsPercents)


  return {
    groupedByTagAsPercents,
    tagChartOrderWithColors,
    otherGroupedTagPeriodAsPercents,
    tagColorLookup,
  }
}


class TagsGameCompareSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dataLoaded: false,
      search: false,
      games: new Map(),
      platforms: new Map(),
      categories: [],
      expandTags: false,
    }

  }

  componentDidMount() {
    if (!isEnterprise()) {
      this.setState({redirectTo: '/trends/'});
      return;
    }

    //console.log("section mount", this.props.id, this.props.ids, this.props, this.state)

    loadCategoryData().then(() => {
      this.populateById(this.props.id);
    });
  }

  componentDidUpdate() {
    //console.log("section update", this.state.dataLoaded, this.props.id, this.props, this.state, this.state.lastReportingDescription,this.buildUrl(), this.state.lastReportingDescription !== this.buildUrl())
    if (this.state.dataLoaded && this.state.lastReportingDescription !== this.buildUrl()) {
      //console.log("should update")
      this.populateById(this.props.id);
    } else {
      //console.log("SHOULD NOT UPDATE")
    }

  }

  searchHandler = (compareId) => {
    this.props.history.push(buildCompareUrl('platform-compare', compareId))
  }

  expandTagsClick = () => {
    this.setState({expandTags: true})
  }

  buildQueryString = () => {
    //console.log("tag section build query", this.state.includeAutoTags, this.props.includeAutoTags, this.props.includeAutoTags ? 1 : 0, true, false)
    return getReportingPeriodQueryString(window.location.search, {
      tr: getTagRankingMethodKey(this.props.tagRankingMethod),
      ti: this.props.includeAutoTags ? 1 : 0,
    });
  }

  isEmbed() {
    return window.location.pathname.indexOf("/embed/") === 0;
  }

  populateById(id) {
    this.setState({dataLoaded: false})

    loadTagData(id).then((processed) => {

      const {includeAutoTags, tagRankingMethod} = this.props;

      //console.log("populateById", includeAutoTags, tagRankingMethod, this.props)

      const compare = transformTagCompare(processed, id, tagRankingMethod, includeAutoTags)

      //console.log("compare", id, compare)

      this.setState({
        ...processed,
        ...compare,
        dataLoaded: true,
        lastReportingDescription: this.buildUrl(),
      });
    });

  }

  buildUrl() {
    if (this.isEmbed()) {
      return `/embed/platforms/${this.props.match.params.section}?${this.buildQueryString()}`;
    }
    return `/platforms/?${this.buildQueryString()}`;
  }

  buildTitle(ids) {
    return "Platforms | Medal Trends";
  }

  renderTagExpander() {
    return (
      <LoadMoreButton
        label="View List"
        onClick={this.expandTagsClick}
      />
    )
  }

  renderTagList(metric) {
    //console.log("tag list", this.state.groupedByTagAsPercents, this.state.topIds, this.state)
    const orderedIds = this.state.groupedByTagAsPercents.map(t => t.tag);

    return <PieList
      data={this.state.groupedByTagAsPercents}
      orderedIds={orderedIds}
      hideIndex={true}
      dataMember="games"
      idField="tag"

      colorLookup={this.state.tagColorLookup}

      toolIdField="categoryId"
      nameField="tag"
      toolNameField="tag"

      //valueField="category_name"
      reportingColumn={metric}
      linkDisabled={false}
      linkPath="tag-detail"
      linkBuilder={(path, tag, qs) => `/${path}/${this.props.id}/${tag}/?${this.buildQueryString()}`}
      metric={metric}
      isEmbed={this.isEmbed()}
      section={metric}
      showTrend={false}
      toolTipHeader=" "
      toolTipTotal="of total tags for this game"
      pageSize={10}
    />

  }

  renderEmbedded() {
    var section = this.props && this.props.match && this.props.match.params
      && this.props.match.params.section && this.props.match.params.section.toLowerCase
      && this.props.match.params.section.toLowerCase();	// Javascript!

    switch (section) {
      default:
        return <div></div>;
    }
  }


  renderEmbeddedWithGames() {
    return (
      <div>
        <EmbedAvatarList ids={this.state.ids}/>
        {this.renderEmbedded()}
      </div>
    )
  }

  render() {
    const {tagRankingMethod} = this.props;

    if (this.state.redirectTo !== undefined) {
      return <Redirect push to={this.state.redirectTo}/>;
    }

    if (!this.state.dataLoaded) {
      return (
        <SectionLoadingPlaceholder minHeight="400px"/>
      )
    }

    if (this.isEmbed()) {
      return this.renderEmbedded();
    }

    const game = getCategoryById(this.props.id);
    const title = `${game.name} Top Tags`

    return (
      <StackableCompareChart
        data={this.state.otherGroupedTagPeriodAsPercents}
        seriesList={this.state.tagChartOrderWithColors}
        ids={this.state.ids}
        isEmbed={this.isEmbed()}
        metric={tagRankingMethod}
        idField="tag"
        title={title}
        subtitle={sectionDescriptions[tagRankingMethod]}
        section={tagRankingMethod}
        useLogScale={false}
        stacked={true}
        toolTipThreshold={0.5}
        preserveTooltipOrder={true}
      >
        {this.state.expandTags ? this.renderTagList(tagRankingMethod) : this.renderTagExpander()}
      </StackableCompareChart>
    )
  }
}

export default TagsGameCompareSection
export {
  sectionDescriptions,
}
