import React from 'react';

import PopularityChart from '../../../components/Charts/PopularityChart'
import DashboardSection from '../../../components/Dashboard/DashboardSection'

class Popularity extends React.Component {

	render() {
    return (
      <DashboardSection
        title={this.props.title}
        description={this.props.explanation}
      >
        <PopularityChart {...this.props} />
      </DashboardSection>
    )
	}
}

export default Popularity
