import React from 'react';
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from "classnames";

import { withStyles } from '@material-ui/core/styles';
import Avatar from '../Avatar/Avatar'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close'

const styles = theme => ({
	root: {
		flexGrow: 1,
		padding: 30
	},
	flex: {
		flexGrow: 1,
		justifyContent: 'flex-start'
	},
	category: {
		position: 'relative',
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		[theme.breakpoints.down('xs')]: {
			minWidth: 120,
		},
		[theme.breakpoints.up('sm')]: {
			minWidth: 250,
		},
	},
	dotLocation: {
		position: 'absolute',
		top: 40,
		[theme.breakpoints.down('xs')]: {
			right: 10,
		},
	},
	noAvatarDotLocation: {
		position: 'absolute',
		top: 14,
		right: 10,
	},
	menuButton: {
		color: 'gray'
	},
	plusTitle: {
		color: 'white',
		fontFamily: 'Rajdhani',
		fontSize: 45,
		fontWeight: 500,
	},
	rightBorder: {
		borderRight: '0.01em solid grey',
	},
	title: {

		[theme.breakpoints.down('lg')]: {
			maxWidth: '180px',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		[theme.breakpoints.down('md')]: {
			maxWidth:'80vw',
		},
	},
	toCompare: {
		[theme.breakpoints.down('xs')]: {
		},
	},
	toolbar: {
		backgroundColor: '#212121'
	}
});

function MedalAppBar(props) {
	const { classes, removeCategory, hideAddButton, hideAvatar } = props;

	return (
		<div className={classes.root}>
			<Grid container direction="row" spacing={16} justify={'flex-start'}>
				{
					props.categories.map((item, key) => {
						return (
							<Grid item xs={12} sm={12} lg={4} key={key}>
								<Grid container
									direction="row"
									spacing={0}
									style={{minHeight:60}}
									justify={'space-between'}>
									<Grid style={{ position: 'relative' }}>
										<div
											style={{ display: 'flex', justifyContent: 'center', alignJustify: 'center' }}>
											{hideAvatar ? 
												<div className={classes.noAvatarDotLocation}>
													<div className={classNames("dot")}
														style={{
															backgroundColor: item.color,
														}} />
												</div>
												:
												<Avatar
													name={item.name}
													size={'medium'}
													src={item.avatar}
													id={item.id}
												/>
											}
											<div style={{ paddingLeft: '15px' }}>
												<div style={{ position: 'absolute', top: '25%' }}>
													<Typography className={classes.title}
														variant="title"
														component="h1">{item.name.toUpperCase().replace(/ /g, "\u00a0")}</Typography>
												</div>
												{!hideAvatar && <div className={classes.dotLocation}>
													<div className={classNames("dot")}
														style={{
															backgroundColor: item.color,
														}} />
												</div>}
											</div>
										</div>
									</Grid>
									<Grid style={{ paddingLeft: 10, paddingRight: 10 }}>
										{
											!props.allowRemoveAll && props.categories.length < 2
												? <div></div>
												: <IconButton
													className={classes.menuButton}
													aria-label="Close"
													onClick={e => removeCategory(item, e)}>
													<CloseIcon />
												</IconButton>
										}
									</Grid>

								</Grid>
							</Grid>
						)
					})
				}

				{!hideAddButton && <Grid item xs={12} sm={12} md={4}
					container
					direction="row"
					justify="flex-start"
					alignItems="center">
					<IconButton className={classNames(classes.menuButton)}
						aria-label="Compare"
						style={{
							background: 'linear-gradient( #f79c55, #fec854)',
							width: 60,
							height: 60
						}}
						onClick={props.addCategory}
					>
						<Typography variant="title"
							component="h1"
							style={{ color: "white", fontSize: 30, paddingBottom: 5 }}>+</Typography>
					</IconButton>
					<div className={classes.toCompare} style={{ color: "white", padding: 10 }}>To Compare</div>
				</Grid>}
			</Grid>
		</div>
	);
}

MedalAppBar.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MedalAppBar);
