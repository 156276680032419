import React, {Fragment} from 'react';
import styled from 'styled-components'
import { buildCompareUrl, dataMembers } from '../../../data/App';
import DashboardSection from '../../../components/Dashboard/DashboardSection'
import SectionLoadingPlaceholder from '../../../components/Dashboard/SectionLoadingPlaceholder'
import {ChartList} from '../../../components/styles'
import ChartListItem from '../../../components/Dashboard/ChartListItem'
import GameThumbnailAndRank from '../../../components/Game/GameThumbnailAndRank'
import LoadMoreButton from '../../../components/LoadMoreButton';

class OverlapSection extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			title: "",
			games: new Map(),
			dataMember: dataMembers.USAGE_PERCENTS,
			loading: true,

			moreButtonDisplay: true,
			pageIndex: 1,
			showCount: 5
		}
	}

	static defaultProps = {
		blurOnSelect: false,
	};

	loadMore = (e) => {
		const index = this.state.pageIndex + 1;
		this.setState({ pageIndex: index })
		if (index * this.state.showCount >= Object.keys(this.props.overlapData)) {
			this.setState({ moreButtonDisplay: false });
		}
	}

	isGameNew = (item) => {
		return false;
		//return item[this.props.dataMember][0] === 0;
	}

	renderTooltip(item, overlap) {
    return (
      <ChartTooltip>
        <span><b>{this.getOverlapPercent(overlap)}%</b> of <b>{item.name}</b> players also play <b>{overlap.name}</b>.</span>
        <span>On average, these players spend <b>{this.getActivityPercent(overlap)}%</b> of their time playing <b>{overlap.name}</b></span>
      </ChartTooltip>
    )
	}

	renderGroupTooltip(item) {
		return <React.Fragment>
			<p>
				People who primarily play <b>{item.name}</b> also play the following games.
			</p>
			<p>
				On average, the players surveyed here spend <b>{this.getActivityPercent(item)}%</b> of
				their time playing <b>{item.name}</b>.
			</p>
		</React.Fragment>
	}

	getOverlapPercent(item) {
		return parseFloat((item.userCount / 100).toFixed(2));
	}

	getActivityPercent(item) {
		return (item.activityFraction * 100).toFixed(2)
	}

	getDaysPlayedPercent(item) {
		return (item.dayFraction * 100).toFixed(2)
	}

	renderOverlap(overlapData) {
		const linkPath = this.props.linkPath || "compare-page"
    return (
      <Fragment>
        <ChartList>
          {
            overlapData.map((item, index) => {
              return (
                <Fragment key={`${item.id}-breakdown`}>
                  <ChartListItem
                    // key={`${item.id}-breakdown`}
                    icon={
                      <GameThumbnailAndRank
                        game={item}
                        rank={index + 1}
                      />
                    }
                    id={`overlap-chart-${item.id}`}
                    index={index}
                    isNew={this.isGameNew(item)}
                    label={<span style={{fontSize: '18px', fontWeight: 'bold'}}>{item.name}</span>}
                    linkTo={{
                      pathname: buildCompareUrl(linkPath, item.id)
                    }}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  />
                  {
                    item.overlapData.map((overlap, index) => {
                      if (index >= this.state.pageIndex * this.state.showCount) {
                        return null;
                      }
                      return (
                        <ChartListItem
                          key={`overlap-item-${item.id}-${overlap.id}`}
                          chart={{
                            color: overlap.color,
                            label: `${this.getOverlapPercent(overlap)}%`,
                            percentage: this.getOverlapPercent(overlap),
                          }}
                          icon={
                            <GameThumbnailAndRank
                              game={overlap}
                              rank={index + 1}
                              size="42px"
                            />
                          }
                          id={`overlap-item-${item.id}-${overlap.id}`}
                          isNew={this.isGameNew(overlap)}
                          label={overlap.name}
                          linkTo={{
                            pathname: buildCompareUrl(linkPath, [item.id, overlap.id])
                          }}
                          tooltip={this.renderTooltip(item, overlap)}
                        />
                      )
                    })
                  }
                </Fragment>
              )
            })
          }
        </ChartList>
      </Fragment>
    )
	}

	render() {
		const overlapData = this.props.overlapData;
    if (!overlapData.length) {
      return <SectionLoadingPlaceholder/>
    }
    return (
      <DashboardSection
        title={this.props.title}
        description={this.props.subtitle}

      >
        {this.renderOverlap(overlapData)}
		{
			this.state.moreButtonDisplay &&
			<LoadMoreButton onClick={this.loadMore} />
		}
      </DashboardSection>
    )
	}
}

export default OverlapSection

const ChartTooltip = styled.div`
  display: flex;
  flex-direction: column;
`
