import React from 'react'

const TopRankingIcon = ({size = 24, color = 'white'}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 22.75H21C21.41 22.75 21.75 22.41 21.75 22C21.75 21.59 21.41 21.25 21 21.25H3C2.59 21.25 2.25 21.59 2.25 22C2.25 22.41 2.59 22.75 3 22.75Z" fill={color}/>
      <path d="M18.4 8.37891H20C20.55 8.37891 21 8.82891 21 9.37891V17.9989C21 18.5489 20.55 18.9989 20 18.9989H18.4C17.85 18.9989 17.4 18.5489 17.4 17.9989V9.37891C17.4 8.81891 17.85 8.37891 18.4 8.37891Z" fill={color}/>
      <path d="M11.2008 5.19141H12.8008C13.3508 5.19141 13.8008 5.64141 13.8008 6.19141V18.0014C13.8008 18.5514 13.3508 19.0014 12.8008 19.0014H11.2008C10.6508 19.0014 10.2008 18.5514 10.2008 18.0014V6.19141C10.2008 5.64141 10.6508 5.19141 11.2008 5.19141Z" fill={color}/>
      <path d="M3.99961 2H5.59961C6.14961 2 6.59961 2.45 6.59961 3V18C6.59961 18.55 6.14961 19 5.59961 19H3.99961C3.44961 19 2.99961 18.55 2.99961 18V3C2.99961 2.45 3.44961 2 3.99961 2Z" fill={color}/>
    </svg>
  )
}

export default TopRankingIcon
