import React, {useContext, useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import Modal from '../../components/Modal'
import TrendingGamesInput from '../../components/SelectInput/TrendingGamesInput'
import {CategoryContext} from '../../context'
import styled from 'styled-components'
import {GameThumbnail} from '../../components/ComparisonSwitcher/GameSelection'
import {Button, colors} from '@get-wrecked/simple-components'

const propTypes = {
  id: PropTypes.string.isRequired,
  excludedIds: PropTypes.array,
  multiSelect: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onClickAdd: PropTypes.func.isRequired,
}

const GameSelectionModal = ({id, excludedIds, multiSelect, onClickAdd, onClose}) => {
  const {dataMappedCategories} = useContext(CategoryContext)
  const [selectedIds, setSelectedIds] = useState([])

  const selectedCategories = useMemo(() => {
    if (!dataMappedCategories?.length) {
      return []
    }
    return selectedIds.map(id => dataMappedCategories.find(cat => cat.id === id))
  }, [dataMappedCategories, selectedIds])

  const onInputSelect = (selection) => {
    setSelectedIds([
      ...selectedIds,
      selection.value
    ])
  }

  const remove = (id) => {
    setSelectedIds(selectedIds.filter(_id => _id !== id))
  }

  return (
    <Modal
      id={id}
      header="Choose Games"
      size="small"
      position="top"
      gap="16px"
      onClose={onClose}
    >
      <TrendingGamesInput
        autoFocus={true}
        excludedIds={[...excludedIds, ...selectedIds]}
        onSelect={onInputSelect}
      />
      {
        selectedCategories.length > 0 &&
        <Games>
          {
            selectedCategories.map(category => {
              return (
                <GameThumbnail
                  key={category.id}
                  category={category}
                  onClickRemove={() => remove(category.id)}
                  showColor={false}
                />
              )
            })
          }
        </Games>
      }
      {
        selectedCategories.length > 0 &&
        <Actions>
          <Button
            variant="secondary"
            size="large"
            onClick={() => onClickAdd(selectedIds)}
          >
            Add{selectedCategories.length > 1 ? ` ${selectedCategories.length} games` : ` ${selectedCategories[0].name}`} to comparison
          </Button>
        </Actions>
      }
    </Modal>
  )
}

GameSelectionModal.propTypes = propTypes

export default GameSelectionModal

const Games = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding-top: 20px;
  border-top: 1px solid ${colors.stroke['0A8']};
`
