/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ShareIcon from '@material-ui/icons/Share';
import LinkIcon from '@material-ui/icons/Link';
import CodeIcon from '@material-ui/icons/Code';
import CloseIcon from '@material-ui/icons/Close';
import ImageIcon from '@material-ui/icons/Image';

import {toBlob} from 'html-to-image';
import saveAs from 'file-saver';
import { isMobile } from 'react-device-detect';

class EmbedOverlay extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			open: false,
			saved: false,
			includeFrame: false,
			isScreenShotWindow: window.location.search.includes("ss=1"),
			ref: React.createRef()
		}
	}

	setOpen(val) {
		this.setState({ open: val })
	}

	handleClickOpen = (e) => {


		this.setOpen(true);
	};

	handleClose = (e) => {
		this.setOpen(false);
	};

	handleClickLink = (e) => {
		this.setState({ includeFrame: false })
	}

	handleClickCode = (e) => {
		this.setState({ includeFrame: true })
	}

	handleClickImage = (e) => {
		window.open(this.getEmbedCode(false, true), "_blank", "width=800,height=700")
	}

	handleClickCapture = (e) => {
		var node = document.getElementById("root");
		var options = {
			width: window.innerWidth,
			height: window.innerHeight,
			filter: (el) => {
				if (el.tagName === "NOSCRIPT") {
					return false;
				}
				return !el.className || !el.className.includes || !el.className.includes("embed-overlay");
			}
		}
		toBlob(node, options)
			.then(function (blob) {
				saveAs(blob, 'medal-embed.png');
			});
		this.setState({ saved: true });
	}

	handleCloseClick = (e) => {
		window.close()
	}

	handleTextFocus = (e) => {
		e.target.select();
		document.execCommand('copy')
	}

	getEmbedCode = (includeFrame, includeSnapshotButton) => {

		var sectionSeparator = window.location.pathname.endsWith("/") ? "" : "/"
		var section = this.props.section ? `${sectionSeparator}${this.props.section}` : "";
		var src = `${window.location.origin}/embed${window.location.pathname}${section}${window.location.search}`;

		if (includeSnapshotButton) {
			src += `&ss=1`
		}

		if (this.props.getEmbedSrc) {
			src = this.props.getEmbedSrc();
		}

		if (!includeFrame) {
			return src;
		}
		return `<div style="position:relative;overflow:hidden;padding-top:75%;border: none;box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12);border-radius: 6px;margin:10px 0"><iframe src="${src}" frameborder="0" width="100%" style="position:absolute;width:100%;height:100%;left:0;top:0;"></iframe></div>`
	}


	renderScreenShotDialog() {


		return (
			<div className="embed-overlay" ref={this.state.ref}>
				<Dialog open={!this.state.saved} aria-labelledby="form-snapshot-title" className="embed-overlay">
					<DialogTitle id="form-snapshot-title">
						Capture Image
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Resize this window if necessary, then
							<br />
							click below to capture its contents.
						</DialogContentText>

					</DialogContent>
					<DialogActions style={{ textAlign: 'center' }}>
						<div style={{ width: '100%', textAlign: 'center' }}>
							<Button onClick={this.handleClickCapture}>
								<ImageIcon style={{ fontSize: 100 }} />
							</Button>
						</div>
					</DialogActions>
				</Dialog>
				<Dialog open={this.state.saved} aria-labelledby="form-snapshot-title" className="embed-overlay">
					<DialogTitle id="form-snapshot-title">
						Saved
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							You may close this window now.
						</DialogContentText>

					</DialogContent>
					<DialogActions style={{ textAlign: 'center' }}>
						<div style={{ width: '100%', textAlign: 'center' }}>
							<Button onClick={this.handleCloseClick}>
								Close
							</Button>
						</div>
					</DialogActions>
				</Dialog>

			</div>
		);
	}

	render() {
		if (this.state.isScreenShotWindow) {
			return this.renderScreenShotDialog();
		}
		if (this.props.isEmbed) {
			return null;
		}
		const open = this.state.open;


		return (
			<div>
				<div style={{ float: "right", position: "relative", zIndex: 1 }}>
					<Button style={{ position: "absolute", left: -54, padding: 16 }} onClick={this.handleClickOpen}>
						<ShareIcon />
					</Button>
				</div>
				<Dialog open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
					<DialogTitle id="form-dialog-title">
						{this.state.includeFrame ? " Embed " : " Share "}
						<a onClick={this.handleClose} className="embedLink" style={{ float: "right" }}>
							<CloseIcon />
						</a>
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							To
							{this.state.includeFrame ? " embed " : " share "}
							this chart, copy the
							{this.state.includeFrame ? " code " : " link "}
							below:
						</DialogContentText>
						<TextField
							autoFocus
							margin="dense"
							id="name"
							label={this.state.includeFrame ? "Embed Code" : "Share Link"}
							type="text"
							fullWidth
							multiline
							rows={4}
							onFocus={this.handleTextFocus}
							value={this.getEmbedCode(this.state.includeFrame)}
						/>
					</DialogContent>
					<DialogActions>
						{!isMobile && <a onClick={this.handleClickImage} title="Save a Shareable Image" className="embedLink">
							<ImageIcon />
						</a>}
						<a onClick={this.handleClickLink} title="Get a Shareable Link" className="embedLink">
							<LinkIcon />
						</a>
						<a onClick={this.handleClickCode} title="Get Embed Code" className="embedLink" style={{ marginRight: 16 }}>
							<CodeIcon />
						</a>
					</DialogActions>
				</Dialog>

			</div>
		);
	}

}

export default EmbedOverlay;
