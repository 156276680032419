import axios from 'axios'
import moment from 'moment'
import regression from 'regression';
import {compress, decompress} from 'compress-json';

import {
  medalApiUrl,
  medalMetricsApiUrl,
  allowApiResultCaching,
  dataMembers,
  reportingPeriods,
  trendingGamesCount,
  maxExtrapolationPeriods,
  categoryData,
  games,
  getCategoryById,
  orderedHues,
  colorFromHue,
  gameHues,
  getApiSpan,
  getPeriodCount,
  getEndDate,
  setReportDates,
  getReportingPeriod,
  setCategoryData,
  getMediumData,
  setMediumData,
  colorFromName,
  getDateStringToday,
  getReportingPeriodSingular,
  getTopNGames,
  checkIds,
  getDateFormat,
  buildReportDates,
  getCategoryData,
} from "./App"
import {MedalApi} from '../utils/api/medal'

const USER_AGENT = 'Medal-Metrics/1.0';

const api_data = {
	// NOTE: We're asking for data up to 9 days ago even though the chart only displays 8 days worth.  That
	// handles the case where we're asking at 12:00:01am and there's no "yesterday" data for us yet.
	"gameUsageByDay": "trends/dailyCategoryStatistic?dayCount=9&categoryCount=" + trendingGamesCount,
	"gameUsageByWeek": "trends/weeklyCategoryStatistic?weekCount=10&categoryCount=" + trendingGamesCount,
	"gameUsageByMonth": "trends/monthlyCategoryStatistic?monthCount=9&categoryCount=" + trendingGamesCount,
}

const metrics_data = {
	"viewsByPlatform": "?stat=views",
	"viewsByCategoryByPlatform": "?stat=views&category_id=",
	"sharesByPlatform": "?stat=shares",
	"sharesByCategoryByPlatform": "?stat=shares&category_id=",
};


const loadKnownDataSet = async (request, args = '', params, withCredentials = false) => {
  console.log('loading known dataset:', request, JSON.stringify(params))

  // 20200310: This is a mess, but for now we're refactoring structure rather than functionality.
  var fullPath = "";
  var apiPath = api_data[request];
  var metricsQuery = metrics_data[request];
  if (metricsQuery) {
    // Hit the lambda metrics api
    // Found one of our known medal API calls.  Hit that.
    var resolution, shortPeriod;
    switch (getReportingPeriod()) {
      case "daily":
        resolution = "1d";
        shortPeriod = "d"
        break;
      case "monthly":
        resolution = "1m";
        shortPeriod = "m"
        break;
      default:
        resolution = "1w";
        shortPeriod = "w"
        break;
    }

    var offset = Math.floor(Math.abs(moment.duration(moment(getEndDate()).diff(moment())).as(getReportingPeriodSingular() + 's')))
    // TODO: offset in periods from enddate

    // @todo remove this logic completely?
    console.error('DEPRECATED MEDAL METRICS API URL REACHED')

    fullPath = `${medalMetricsApiUrl}${metricsQuery}${args}&span=${getApiSpan()}&endDate=${getEndDate()}&resolution=${resolution}&offset=${offset}${shortPeriod}`;
  } else if (apiPath) {
    fullPath = `/${apiPath}&span=${getApiSpan()}&periodCount=${parseInt(getPeriodCount()) + 1}&endDate=${getEndDate()}`;
  } else {
    // Couldn't look up a known request.  Assume we want the medal API using the request as the path.
    fullPath = `/${request}?category_id=${args}&period=${getReportingPeriod()}&periodCount=${parseInt(getPeriodCount()) + 1}&endDate=${getEndDate()}`;
    //fullPath = apiUrl + request + "?category_id=" + args + "&span=" + getApiSpan();
  }

  console.log('getting cacheable data:', fullPath, params)

  // console.log('response data:', resp.data)

  return getCacheableData(fullPath, params, {}, false)
}

const purgeFromCache = (apiPath) => {

	Object.keys(localStorage).forEach((key) => {
		if (key.indexOf(apiPath) > -1) {
			delete localStorage[key];
		}
	})
}

const getCacheableData = async (path, params, headers = {}) => {
  // localStorage key for cached data
  const storageKey = `${getDateStringToday()}_${path}${params ? JSON.stringify(params) : ""}`;

  if (allowApiResultCaching) {
    try {
      const json = localStorage[storageKey]
      if (json !== undefined) {
        const data = decompress(JSON.parse(json))
        const compressed = JSON.stringify(compress(data))
        console.log("Fetched from cache", storageKey, json.length, compressed.length, data)
        return { data }
      }
    } catch (e) {
      console.warn("Error retrieving from Localstorage", storageKey, e)
    }
  }

  let resp
  if (path.startsWith('data/mediumData.json')) {
    resp = await axios.get(`/${path}`, {
      params,
      headers,
    })
  } else {
    try {
      resp = await MedalApi.get(path, {
        params,
        headers,
        useLegacyUserAgent: true
      })
    } catch (err) {
      console.error(err)
      // if (err.response?.status === 401) {
      //   deleteStoredAuth()
      //   deleteCachedUser()
      // } else {
      //   console.error(err)
      // }
    }
  }

  // if there was data, compress + cache it in localStorage
  if (resp?.data) {
    try {
      localStorage[storageKey] = JSON.stringify(compress(resp.data))
    } catch {
      // Too big maybe. No worries.
      console.warn("Exceeded Localstorage", storageKey, JSON.stringify(resp.data))
    }
  }

  return resp
}


const postData = (path, data, headers) => {
	headers = headers || {};
	headers['medal-user-agent'] = USER_AGENT;
	return new Promise((resolve, reject) => {
		var fullPath = medalApiUrl + path;
		axios.post(fullPath, data, {
			headers: headers
		}).then(res => {
			resolve(res);
		}).catch(err => {
			console.log(err, err.response, err.request, err.message, err.config);
			resolve(err)
		})
	})
}


const loadS3Data = async (url) => {
  if (categoryData.length === 0 || getMediumData().length === 0) {
    await loadCategoryData()
    const resp = await getCacheableData(`data/mediumData.json?date=${getDateStringToday()}`)
    setMediumData(resp.data[0])
  }
}

const loadCategoryData = async () => {
	// Minor hack: Every page starts off by loading this data.  So if we clear it here we'll be sure it goes away at least once per day.
	purgeLocalStorage();

  if (categoryData.length === 0) {
    const resp = await getCacheableData(`/trends/categoriesInUse?date=${getDateStringToday()}&categoryCount=${trendingGamesCount}`)
    if (resp.data) {
      // console.log('loadCategoryData resp:', resp.data)
      const processed = resp.data.map((c) => {
        return {
          id: c.categoryId,
          name: c.categoryName,
          abv: c.categoryName,
          color: colorFromName(c.categoryName),
          thumb: c.categoryThumbnail,
          avatar: `./${c.categoryId}.png`
        }
      })
      // console.log('processed data:', processed)
      setCategoryData(processed);
    }
  }

  return getCategoryData()
}

/// Purge everything from localstorage that doesn't begin with the supplied prefix
export const purgeLocalStorage = () => {
  const current = getDateStringToday()
	for (let key in localStorage) {
    // only purge keys with dates like 2024_02_12_,
    // excluding the current prefix
    if (/\d{4}-\d{2}-\d{2}_/.test(key) && key.indexOf(current) !== 0) {
      console.log('purging local storage:', key)
      localStorage.removeItem(key)
    }
	}
}

///
/// Data Transformations -----------------------------------------------
///


/// Given a data table and a list of columns, calculate 00.00 percent values
/// per period (in the "date" column if not supplied) per specified column.
/// NOTE: If periodColumn does not exist, percents will be calculated over the entire dataset.
/// Replaces the data in place, and returns it.

const calculatePeriodShares = (data, columns, periodColumn = "date") => {
	// Make a copy so we don't monch the original:
	data = JSON.parse(JSON.stringify(data));

	// This will look like {"2020-W10":[100,200,300,400],"2020-W11":[100,200,300,400],...}
	var periodTotals = {};
	data.forEach(row => {
		var pt = periodTotals[row[periodColumn]] || columns.map(() => 0);
		columns.forEach((c, i) => {
			pt[i] += row[c]
		})
		periodTotals[row[periodColumn]] = pt;
	})

	data.forEach(row => {
		var pt = periodTotals[row[periodColumn]];
		columns.forEach((c, i) => {
			if (pt[i]===0) {
				row[c] = 0;
			} else {
				row[c] = ((row[c] / pt[i] * 100).toFixed(2)) * 1
			}
		})
	})

	return data;
}

const holeFillGameData = (categoryId, rows, expectedDates, workingColumns) => {

	const getOffsetRow = (date, offset) => {
		const offsetDate = moment(date).add(offset, getReportingPeriodSingular().toLowerCase()).format(getDateFormat());
		return rows.find(r => r.date === offsetDate);
	};

	const findRowInDirection = (date, incrementOffset) =>
	{
		for (var i=1; i<getPeriodCount(); i++) {
			const candidate = getOffsetRow(date, incrementOffset * i);
			if (candidate) {
				return candidate;
			}
		}

		return null;
	}

	const interpolateRow = (date, before, after) => {
		const missing = { date: date, categoryId: categoryId };
		workingColumns.forEach(col => {
			missing[col] = parseFloat(((before[col] + after[col]) / 2).toFixed(2));
		})

		return missing;
	}

	const zeroFillRows = (startDate, endDate, incrementOffset) => {
		var start = moment(startDate);
		var end = moment(endDate);

		var sanity = 30;
		while (!start.isSame(end) && sanity-- > 0) {
			rows.push(zeroRow(start.format(getDateFormat())));
			start.add(incrementOffset, getReportingPeriodSingular().toLowerCase()).format(getDateFormat());
		}

		return null;
	}

	const zeroRow = (date) => {
		const missing = { date: date, categoryId: categoryId };
		workingColumns.forEach(col => {
			missing[col] = 0;
		})

		return missing;
	}

	// If we got no rows at all, return all zeros.
	if (rows.length === 0)
	{
		expectedDates.forEach(date => {
			rows.push(zeroRow(date));
		});
	}

	// Fill in holes with data from closest neighbors
	expectedDates.forEach(date => {
		var row = rows.find(r => r.date === date);
		if (!row) {
			const before = findRowInDirection(date, -1);
			const after = findRowInDirection(date, 1);

			if (before && after) {
				rows.push(interpolateRow(date, before, after))
			}
			else if (before) {
				rows.push(interpolateRow(date, before, before))
			}
			else if (after) {
				// Only found data after this.  Maybe it's a new category?
				zeroFillRows(date, after.date, 1)
			}
		}
	});
}



// TODO: Research whether this affects us for weekly data,
// since it might off-by-one our week numbers in 2022-W48 strings.
// const extractReportDates = (data) => {
// 	var last = "";
// 	var dates = data
// 		.sort((a, b) => a.date > b.date ? 1 : -1)	// 1:-1 sorts in date order
// 		.filter(r => {
// 			var dupe = (r.date === last);
// 			last = r.date;
// 			return !dupe;
// 		})								// Pick out the first row for each date.
// 		.map(r => r.date)				// Throw away everything but the date string.
// 		.sort()							// Sort by string, since that'll get us ordered right.
// 		.map(d => moment(d).toDate())	// Parse each record into a Date object.


// 	// 20230116: HACK! We need to have the same number of dates as we have values.
// 	// We've likely got into this situation by pre-padding with zeros.
// 	// For today, let's pre-pad with the 1st date we know about
// 	var count = getPeriodCount();
// 	while (dates.length > 0 && dates.length < count) {
// 		dates.unshift(dates[0]);
// 	}
// 	return dates;
// }

/// Given a table of game usage data (in the format returned by the API (shown below)),
/// pull out usage data per game and add that data to each game in the supplied list.
const transformPeriodGameUsage = (data) => {

	// Filter out "Requested" from game data
	data = data.filter(r => r.categoryId!==5)

	// data rows look like:
	// {"date":"2020-W10","categoryId":190,"sessionCount":0.01,"sessionDurationMinutes":0.01,"clipCount":0,"shareCount":0}
	console.time("transformGameUsage")

	const reportDates = buildReportDates()
	//const reportDateStrings = reportDates.map(d => moment(d).format('YYYY-MM-DD'))
	const reportDateStrings = reportDates.map(d => moment(d).format(getDateFormat()));
	setReportDates(reportDates)

	//console.log("dates", extractReportDates(data), reportDateStrings, moment(reportDates[0]), getDateFormat());

	var workingColumns = ["sessionCount", "sessionDurationMinutes", "clipCount", "shareCount", "avgSessionDurationMinutes"];
	var percentNormalizedColumns = ["sessionCount", "sessionDurationMinutes", "clipCount", "shareCount"];
	var columnMap = {
		"sessionCount": dataMembers.SESSION_PERCENTS,
		"sessionDurationMinutes": dataMembers.USAGE_PERCENTS,
		"clipCount": dataMembers.CLIP_PERCENTS,
		"shareCount": dataMembers.SHARE_PERCENTS,
		"avgSessionDurationMinutes": dataMembers.AVG_SESSION_DURATION }

	// var noCalc = (r,c)=>r[c];
	// var calcMap = { "sessionCount": noCalc, "sessionDurationMinutes": noCalc, "clipCount": noCalc, "shareCount": noCalc, "userCount": noCalc }

	// Populate our calculated columns before we normalize down to percentages, or they'll lose their meaning.
	// data.forEach(r => {
	// 	r.avgSessionDurationMinutes = r["sessionCount"] > 0 ? r["sessionDurationMinutes"] / r["sessionCount"] : 0;
	// 	r.avgUserDurationMinutes = r["userCount"] > 0 ? r["sessionDurationMinutes"] / r["userCount"] : 0;
	// });

	data = calculatePeriodShares(data, percentNormalizedColumns);

	var count = getPeriodCount();
	categoryData.forEach(game => {
		var rows = data.filter(r => r.categoryId === game.id);

		holeFillGameData(game.id, rows, reportDateStrings, workingColumns);
		workingColumns.forEach((c, i) => {
			// sort by date descending, grab the first N values for the column,
			// (thus giving us the most recent N),
			// reverse (so it's back in date order) and stuff it into the game.
			// [20.98, 20.35, 19.2, 23.81, 23.73, 21.64]
			var games = rows
				.sort((a, b) => a.date > b.date ? -1 : 1)	// sort in reverse order here (see above)
				.map(r => r[c])
				//.map(r => calcMap[c](r,c))
				.slice(0, count)
				.reverse();

			game[columnMap[c]] = games;
		})

		// if ([10,27,62,76].indexOf(game.id) > -1) {
		// 	console.log("calc'd", game, rows, columnMap["userCount"])
		// }
	});

	// All we're doing here is transforming a copy of our current array of game objects
	// into a Map with a lookup key of categoryId.  We'll keep different versions of it
	// for each reportingPeriod
	const gameMap = categoryData.reduce((c, v) => {
		const copy = JSON.parse(JSON.stringify(v))
		c.set(v.id, copy);
		return c;
	}, new Map());

	//console.log("transformed", gameMap)

	console.timeEnd("transformGameUsage")
	return gameMap;
}

const extractColorList = (data, nameField="platform", idField="platform") => {
	const lookup = data
		.reduce((c, v) => {
			c[v[idField]] = { id: v[idField], name: v[nameField], thumb: v.thumb, avatar: `./${('' + v[nameField]).toLowerCase()}.png`, data: [] }; return c;
		}, {})

	Object.values(lookup).forEach((p, i) => {
		const colorIndex = i;
		var hue;
		if (colorIndex < orderedHues.length) {
			hue = orderedHues[colorIndex];
		}
		else {
			// NOTE: The "+1" below is to jump us off of starting with 0 (red).
			hue = (63 * (colorIndex - orderedHues.length + 1)) % 360;
		}

		p.color = colorFromHue(hue);
		gameHues[p.name] = hue;
	})

	return lookup;
}

const extractPlatformList = (data, sortBy = "shares") => {
	return extractColorList(data.sort((a, b) => b[sortBy] - a[sortBy]));
}



const transformPlatformShares = (platformData, platformCompareData, platformLookup, loadCount = 32, otherThreshold = 10) => {
	var addCalculatedColumns = (data) => {
		return data.map(d => {
			d.attention = d.shares === 0 ? 0 : parseFloat((d.viewers / d.shares).toFixed(2));
			return d;
		})
	}
	console.time("transformPlatformData")

	const topIds = getTopNGames("sharePercents", loadCount);

	platformData = addCalculatedColumns(platformData);
	platformCompareData = addCalculatedColumns(platformCompareData);

	setReportDates(buildReportDates());

	var combinedRaw = platformData.reduce((c, v) => {
		if (!c[v.platform]) {
			c[v.platform] = v;
		} else {
			c[v.platform].shares += v.shares;
			c[v.platform].viewers += v.viewers;
			c[v.platform].attention += v.attention;
		}
		return c;
	}, {});

	platformData = calculatePeriodShares(platformData, ["shares", "viewers"])
		.sort((a, b) => a.date > b.date ? 1 : -1);	// 1:-1 sorts in date order


	console.log("platformData", platformData)

	var platformShares = calculatePeriodShares(Object.values(combinedRaw), ["shares", "viewers"], "undefined")	// since there's nothing left to group on, group on nothing.
		.sort((a, b) => b.shares - a.shares)


	// Sum same dates across category/platform combos
	var summedByCategoryPlatform = Object.values(platformCompareData.reduce((c, v) => {
		var key = v.platform + v.category_id;
		if (c[key]) {
			c[key].viewers += v.viewers;
			c[key].shares += v.shares;
		} else {
			c[key] = JSON.parse(JSON.stringify(v));
			delete c[key].date;
		}
		return c;
	}, {}));

	// Turn into percents, rolled up by category
	var normalizedByCategory = calculatePeriodShares(summedByCategoryPlatform, ["shares", "viewers"], "category_id");

	// Flip into a per game view, complete with game info and a platforms collection.
	var categoryPlatforms = Object.values(normalizedByCategory.reduce((c, v) => {
		if (!c[v.category_id]) {
			const cat = getCategoryById(v.category_id);
			if (cat) {
				c[v.category_id] = JSON.parse(JSON.stringify(cat));
			} else {
				c[v.category_id] = {};
			}
			c[v.category_id].platforms = [];
		}

		const p = platformLookup[v.platform];
		v.color = p ? p.color : "#999";
		//v.color = platformLookup[v.platform].color;
		c[v.category_id].platforms.push(v);

		return c;
	}, {}));


	// Turn into percents, rolled up by platform
	var normalizedByPlatform = calculatePeriodShares(summedByCategoryPlatform, ["shares", "viewers"], "platform");

	// Flip into a per platform view, with some fake "game info" so that it'll look right in our list.
	// This is a bit of a hack to reuse PlatformGameList. Apologies for naming games "platform" and the fake "sharePercents", etc.
	const topSlice = topIds.slice(0, otherThreshold);
	var platformCategories = Object.values(normalizedByPlatform.reduce((c, v) => {
		var game = getCategoryById(v.category_id);
		if (game) {
			if (!c[v.platform]) {
				c[v.platform] = { id: v.platform, name: v.platform, abv: v.platform, thumb: "", avatar: `./${v.platform.toLowerCase()}.png` };

				// HACK: we're calling this list "platforms" for use in the "games" list.
				// TODO: fix that
				c[v.platform].games = [{ week: 0, category_name: "Other", category_id: 0, platform: v.platform, color: "#cccccc", shares: 0, viewers: 0, attention: 0 }];

				const platform = platformShares.find(p => p.platform === v.platform)
				c[v.platform].sharePercents = [platform ? platform.shares : 0]
			}
			v.color = game.color;
			v.category_name = game.abv;

			// We're rolling up everything outside the top N into a home-rolled "Other category".  Hence this branching:
			if (topSlice.indexOf(v.category_id) > -1) {
				c[v.platform].games.push(v);
			} else {
				var otherPlatform = c[v.platform].games[0];
				otherPlatform.shares += v.shares;
				otherPlatform.viewers += v.viewers;
				otherPlatform.attention += v.attention;
			}
		}

		return c;
	}, {}));

	// sort games in overall popularity order
	var sortIds = topIds.slice();
	sortIds.push(0)
	platformCategories.forEach(p => {
		p.games.sort((a, b) => {
			return sortIds.indexOf(a.category_id) - sortIds.indexOf(b.category_id);
		})
	});

	const platformOrder = platformShares.map(p => p.platform);

	console.timeEnd("transformPlatformData")

	return {
		platformLookup,
		platformData,
		platformCompareData,
		categoryPlatforms,
		platformCategories,
		topIds,
		platformOrder,
	}
}

const calculateRegression = (games, regressionModel, basisPeriodCount) => {
	console.time("calculateRegression");
	basisPeriodCount = basisPeriodCount || -1;

	var r = regression.linear;
	var options = {
		order: 2,
		precision: 2,
	};

	switch (regressionModel) {
		case "exp":
			r = regression.exponential;
			break;

		case "log":
			r = regression.logarithmic;
			break;

		case "power":
			r = regression.power;
			break;

		case "poly2":
			r = regression.polynomial;
			options.order = 2;
			break;

		case "poly3":
			r = regression.polynomial;
			options.order = 3;
			break;

		case "poly4":
			r = regression.polynomial;
			options.order = 4;
			break;

		case "linear":
		default:
			break;
	}

	games.forEach(game => {
		game.trend = {};
		Object.values(dataMembers).forEach(metric => {
			var start = 0;
			if (basisPeriodCount !== -1) {
				start = game[metric].length - basisPeriodCount;
			}
			const points = game[metric].slice(start);

			// convert from [5,10,12,13] to [[0,5],[1,10],[2,12],[3,13]]
			const points2d = points.map((p, i) => [start + i, p])

			var result = r(points2d, options);

			const linePoints = [];
			// Let's extrapolate way too far now, then pick a smaller set later.
			for (var i = 0; i < game[metric].length + maxExtrapolationPeriods; i++) {
				if (i < start) {
					linePoints.push(null);
				}
				else {
					linePoints.push(result.predict(i)[1]);

				}
			}
			game.trend[metric] = { r: result, points: linePoints };

		});
	})



	console.timeEnd("calculateRegression");
	return games;
}

const holefillRetentionData = (data) => {
	const ids = data.reduce((c, v) => { if (!c.includes(v.categoryId)) { c.push(v.categoryId) }; return c; }, [])
	ids.forEach(id => {

		[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].forEach(period => {
			if (!data.find(d => (d.categoryId === id && d.retentionPeriod === period))) {
				var prev = data.find(d => (d.categoryId === id && d.retentionPeriod === period - 1))

				if (prev) {
					data.push({ categoryId: id, retentionPeriod: period, userCount: prev.userCount * 0.8 });
				}
				else {
					data.push({ categoryId: id, retentionPeriod: period, userCount: 10000 });
				}
			}
		});
	});
	return data;
}

const holefillRetentionChartData = (data) => {

	if (data.length === 0) {
		// Stop us blowing up trying to calculate firstPeriod and maxRetentionPeriod
		return;
	}

	var firstPeriod = data.sort((a, b) => a.firstPeriod - b.firstPeriod)[0].firstPeriod;
	var maxRetentionPeriod = data.sort((a, b) => b.retentionPeriod - a.retentionPeriod)[0].retentionPeriod;
	const retentionPeriods = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

	const periods = retentionPeriods.map(p => p + firstPeriod);
	const ids = data.reduce((c, v) => { if (!c.includes(v.categoryId)) { c.push(v.categoryId) }; return c; }, []);

	// Let's pass through the data one time and get some day-day dropoff numbers that we can use
	// below if we need to hole-fill anything that's missing
	var dropoffs = ids.reduce((c,v) => {c[v] = retentionPeriods.map(d => []);return c;},{});
	periods.forEach(period => {
		for (var rp = 0; rp < 10 - period + firstPeriod; rp++) {
			const retentionPeriod = rp; // stop the compiler from complaining about 'no-loop-func'
			ids.forEach(id => {
				var d = data.find(d => (d.categoryId === id && d.firstPeriod === period && d.retentionPeriod === retentionPeriod))
				var prev = data.find(d => (d.categoryId === id && d.firstPeriod === period && d.retentionPeriod === retentionPeriod - 1))
				if (d && prev) {
					// found dropoff data
					dropoffs[id][retentionPeriod].push(d.userCount / prev.userCount)
				}
			});
		}
	})

	// Now we can average these together to get a single number per retention day.
	ids.forEach(id => {
		dropoffs[id] = dropoffs[id].map(d => {
			if (d.length === 0) {
				return 0.8;
			}
			const sum = d.reduce((c,v) => c+v, 0);
			return sum / d.length;
		})
	})

	// Finally, we can spin through and fill any holes in the data
	periods.forEach(period => {
		for (var rp = 0; rp < maxRetentionPeriod - period + firstPeriod; rp++) {
			const retentionPeriod = rp; // stop the compiler from complaining about 'no-loop-func'
			ids.forEach(id => {
				var d = data.find(d => (d.categoryId === id && d.firstPeriod === period && d.retentionPeriod === retentionPeriod))
				if (!d || d.userCount === 0) {
					var prev = data.find(d => (d.categoryId === id && d.firstPeriod === period && d.retentionPeriod === retentionPeriod - 1))
					if (prev) {
						const dropoff = dropoffs[id][retentionPeriod];
						const fakeUserCount = parseInt(prev.userCount * dropoff);
						if (d) {
							d.userCount = fakeUserCount
						} else {
							data.push({ categoryId: id, firstPeriod: period, retentionPeriod: retentionPeriod, userCount: fakeUserCount });
						}
					}
					else if (retentionPeriod === 0) {
						// no day zero, so stick in our known normalized starting value
						data.push({ categoryId: id, firstPeriod: period, retentionPeriod: retentionPeriod, userCount: 10000 });
					}
				}
			});
		}
	});
}

const backfillDayZeroRetention = (data) => {
	// Work around a backend issue where the first week/month's retention numbers come from day one
	data.forEach(d => {
		if (!data.find(z => (z.firstPeriod === d.firstPeriod && z.categoryId === d.categoryId && z.retentionPeriod === 0))) {
			data.push({ firstPeriod: d.firstPeriod, categoryId: d.categoryId, retentionPeriod: 0, userCount: 10000 });
		}
		if (d.retentionPeriod === 0) { d.userCount = 10000 }
	});
}

const processOverlapData = data => {
	const loadedCategories = checkIds(Object.keys(data));
	const processed = loadedCategories.map(id => {
		const category = getCategoryById(parseInt(id));
		const overlap = data[id];
		const overlapCategories = overlap.reduce((c, v) => {
			if (v.relatedCategoryId !== v.primaryCategoryId) {
				const relatedCategory = getCategoryById(v.relatedCategoryId);
				if (relatedCategory) {
					c.push(Object.assign({}, relatedCategory, v));
				}
			} else {
				// stick play percentages, etc. back into the original category.
				Object.assign(category, v);
			}
			return c;
		}, []);
		return Object.assign(category, { overlapData: overlapCategories });
	});

	return processed;
}

///
/// Data Lookup Methods ---------------------
///

/// Pull in a list of game usage data for the current reporting period,
/// and inject it into our full list of categoryData
const getGamesList = async () => {
	const reportingPeriod = getReportingPeriod();

  if (games[reportingPeriod]) {
    return games[reportingPeriod]
  }

  let dataMethod = "gameUsageByDay";
  switch (reportingPeriod) {
    case reportingPeriods.WEEKLY:
      dataMethod = "gameUsageByWeek";
      break;
    case reportingPeriods.MONTHLY:
      dataMethod = "gameUsageByMonth";
      break;
    case reportingPeriods.DAILY:
    default:
      dataMethod = "gameUsageByDay";
  }

  const res = await loadKnownDataSet(dataMethod, null, {}, true)
  games[reportingPeriod] = transformPeriodGameUsage(res.data);
  // console.log('reporting period date:', games[reportingPeriod])
  return games[reportingPeriod]
}

const getPlatformShareViewers = (ids) => {

	// HACK:
	//return loadKnownDataSet(`trends/dailyTagContentViewers`, ids)


	var dataMethod = "dailyPlatformShareViewers";
	switch (getReportingPeriod()) {
		case reportingPeriods.WEEKLY:
			dataMethod = "weeklyPlatformShareViewers";
			break;
		case reportingPeriods.MONTHLY:
			dataMethod = "monthlyPlatformShareViewers";
			break;
		case reportingPeriods.DAILY:
		default:
			dataMethod = "dailyPlatformShareViewers";
	}

	return loadKnownDataSet(`trends/${dataMethod}`, ids)
}

const getTagContentViewers = (ids) => {
	// TODO: weekly, monthly
	return loadKnownDataSet(`trends/dailyTagContentViewers`, ids, {}, false, false)
}

const getGameServerSessions = (ids) => {
	// TODO: weekly, monthly
	return loadKnownDataSet(`trends/getDailyGameServerSessions`, ids, {}, false, false)
}

const getGameServerInfo = (ids) => {
	return getCacheableData(
		`/gameServers/?ids=${ids ? ids.join(',') : ''}`,
		{exclude: 'game_server_statistics,game_server_player_count'},
		{},
		false
	)
}

const getSubgameSessions = (ids) => {
	var dataMethod = "getDailySubgameSessions";
	switch (getReportingPeriod()) {
		case reportingPeriods.WEEKLY:
			dataMethod = "getWeeklySubgameSessions";
			break;
		case reportingPeriods.MONTHLY:
			dataMethod = "getMonthlySubgameSessions";
			break;
		default:
			// Happy linter, happy life.
			break;
	}
	return loadKnownDataSet(`trends/${dataMethod}`, ids, {}, true, false)
}

export {
	transformPeriodGameUsage,
	loadKnownDataSet,
	getCacheableData,
	purgeFromCache,
	postData,
	getGamesList,
	getPlatformShareViewers,
	getTagContentViewers,
	getGameServerSessions,
	getGameServerInfo,
	getSubgameSessions,
	loadS3Data,
	loadCategoryData,
	calculateRegression,
	//extractReportDates,
	calculatePeriodShares,
	extractColorList,
	extractPlatformList,
	transformPlatformShares,
	holefillRetentionData,
	holefillRetentionChartData,
	backfillDayZeroRetention,
	processOverlapData
};

