import React from 'react';
import MarketSharePie from "./MarketSharePie.jsx";

// for games list
import { buildCompareUrl, dataMembers, valueFormatMap } from '../../../data/App';
import DashboardSection from '../../../components/Dashboard/DashboardSection'
import LoadMoreButton from '../../../components/LoadMoreButton'
import {ChartList} from '../../../components/styles'
import ChartListItem from '../../../components/Dashboard/ChartListItem'
import GameThumbnailAndRank from '../../../components/Game/GameThumbnailAndRank'

class GameList extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			title: "",
			games: new Map(),
			dataMember: dataMembers.USAGE_PERCENTS,
			loading: true,

			moreButtonDisplay: true,
			pageIndex: 1,
			showCount: 10,
		}
	}

	static defaultProps = {
		blurOnSelect: false,
	};

	componentDidMount() {
		var games = this.props.games;
		var sorted = [...games.values()].sort((a, b) => {
			var aData = a[this.props.dataMember];
			var bData = b[this.props.dataMember];
			return aData[aData.length - 1] < bData[bData.length - 1] ? 1 : -1
		});

		if (this.props.dataMember === "avgSessionDurationMinutes") {
			// These values will be in minutes instead of percents.  We'll need to get % values
			var max = 0;
			var total = 0;
			sorted.forEach(g => {
				const val = g[this.props.dataMember][g[this.props.dataMember].length - 1]
				max = Math.max(max, val);
				total += val;
			})
			console.log("max", max, total, sorted)
			sorted = sorted.map(g => {
				const val = g[this.props.dataMember][g[this.props.dataMember].length - 1]
				var copy = JSON.parse(JSON.stringify(g));
				//console.log("copy", copy, JSON.stringify(g))
				//copy[this.props.dataMember] = [(100 * val / max).toFixed(2)]
				copy[this.props.dataMember] = [(100 * val / total).toFixed(2)]
				copy.avgDurationPercent = (100 * g[this.props.dataMember][g[this.props.dataMember].length - 1] / max).toFixed(2);
				return copy;
			})
		}

		this.setState({ processedGames: sorted })

	}

	loadMore = (e) => {
		const index = this.state.pageIndex + 1;
		this.setState({ pageIndex: index })
		if (index * this.state.showCount >= this.props.games.size) {
			this.setState({ moreButtonDisplay: false });
		}
	}

	isGameNew = (item) => {
		return item[this.props.dataMember][0] === 0;
	}

	process() {
		const sorted = [...this.props.games.values()].sort((a, b) => {
			var aData = a[this.props.dataMember];
			var bData = b[this.props.dataMember];
			return aData[aData.length - 1] < bData[bData.length - 1] ? 1 : -1
		});


		//console.log("sorted", sorted, this.props.games.values())
		var gameDataDisplay = sorted.slice(0, this.state.showCount * this.state.pageIndex);

		if (this.props.dataMember === "avgSessionDurationMinutes") {
			// These values will be in minutes instead of percents.  We'll need to get % values
			var total = 0;
			gameDataDisplay.forEach(g => {
				const val = g[this.props.dataMember][g[this.props.dataMember].length - 1]
				total += val;
			})

//			console.log("total", total, sorted)
			gameDataDisplay = gameDataDisplay.map(g => {
				const val = g[this.props.dataMember][g[this.props.dataMember].length - 1]
				var copy = JSON.parse(JSON.stringify(g));
				copy[this.props.dataMember] = [(100 * val / total).toFixed(2)]
				copy.latestAvgDurationMinutes = val;
				return copy;
			})
		}

		return gameDataDisplay;
	}

	render() {
		const latestPercent = (item) => {
			return item[this.props.dataMember][item[this.props.dataMember].length - 1]
		}

		const displayValue = (item) => {
			if (this.props.dataMember === "avgSessionDurationMinutes") {
				return valueFormatMap[this.props.dataMember](item.latestAvgDurationMinutes);
			}
			return latestPercent(item) + "%"
		}

		const showPie = (this.props.dataMember !== "avgSessionDurationMinutes")

		// TODO: move this out of render
		const gameDataDisplay = this.process();
		//console.log("gameDataDisplay", gameDataDisplay)

		const linkPath = this.props.linkPath || "compare-page"

    return (
      <DashboardSection
        title={this.props.title}
        description={this.props.subtitle}
      >
        {showPie && <MarketSharePie games={this.props.games} dataMember={this.props.dataMember} />}
        <ChartList>
          {
            gameDataDisplay.map((item, index) => {
              return (
                <ChartListItem
                  key={item.id}
                  chart={{
                    color: item.color,
                    percentage: latestPercent(item),
                    label: displayValue(item)
                  }}
                  icon={
                    <GameThumbnailAndRank
                      key={item.id}
                      game={item}
                      rank={index + 1}
                      size="48px"
                    />
                  }
                  index={index}
                  isNew={this.isGameNew(item)}
                  label={item.abv}
                  linkTo={{
                    pathname: buildCompareUrl(linkPath, item.id)
                  }}
                  showRank={false}
                  tooltip={`${displayValue(item)} % of total`}
                />
              )
            })
          }
        </ChartList>
        {
          this.state.moreButtonDisplay &&
          <LoadMoreButton onClick={this.loadMore}/>
        }
      </DashboardSection>
    )
	}
}

export default GameList
