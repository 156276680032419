import React, {Fragment, useState} from 'react'
import styled from 'styled-components'
import {mobileWidthMediaQuery, pageContentWidth} from '../styles'
import {CloseButton, colors} from '@get-wrecked/simple-components'
import HashtagIcon from '../Icons/HashtagIcon'
import {adjustBrightness} from '../../data/Chart'
import PropTypes from 'prop-types'
import TagSelectionModal from '../../presentations/Modals/TagSelectionModal'

const propTypes = {
  tags: PropTypes.arrayOf(PropTypes.object),
  onAddTags: PropTypes.func.isRequired,
  onClickRemove: PropTypes.func.isRequired,
}

const TagComparison = ({tags, selectedTags, onAddTags, onClickRemove}) => {
  const [isSelecting, setIsSelecting] = useState(false)
  return (
    <Fragment>
      <FixedContainer>
        <Container>
          {
            selectedTags.map(tag => {
              return (
                <Hashtag
                  key={tag.id}
                  {...tag}
                  onClickRemove={selectedTags.length > 1 ? () => onClickRemove(tag) : undefined}
                />
              )
            })
          }
          <AddButtonContainer onClick={() => setIsSelecting(!isSelecting)}>
            <AddButton>
              +
            </AddButton>
          </AddButtonContainer>
        </Container>
      </FixedContainer>
      {
        isSelecting &&
        <TagSelectionModal
          id="select-tags-modal"
          tags={tags}
          excludedIds={selectedTags.map(tag => tag.id)}
          onClickAdd={ids => onAddTags(...ids)}
          onClose={() => setIsSelecting(false)}
        />
      }
    </Fragment>
  )
}

TagComparison.propTypes = propTypes

export default TagComparison

export const Hashtag = ({id, color, name, onClickRemove}) => {
  return (
    <Tag
      key={id}
      $color={color}
    >
      <HashtagIcon/>
      {name}
      {
        typeof onClickRemove === 'function' &&
        <CloseButton
          className="close"
          onClick={onClickRemove}
        />
      }
    </Tag>
  )
}

const FixedContainer = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 87px;
  left: 0;
  width: 100%;
  z-index: 1;
`

const Container = styled.div`
  display: flex;
  padding: 20px 0 19px;
  background-color: ${colors.background['first-layer']};
  gap: 12px;
  align-items: center;
  width: ${pageContentWidth};
  border-bottom: 1px solid ${colors.stroke['0A16']};
  transition: padding 200ms ease-in-out;
  overflow-x: auto;

  @media (max-width: 1420px) {
    padding: 12px;
  }
`

const Tag = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  gap: 10px;
  padding: 0 16px;
  height: 58px;
  min-width: 58px;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 500;
  color: ${({$color}) => $color ? $color : colors.text['30']};
  border: 1px solid ${({$color}) => $color ? adjustBrightness($color, -30) : colors.stroke['0A8']};
  background-color: ${({$color}) => $color ? adjustBrightness($color, -44) : colors.neutral['0A4']};
  
  .close {
    display: none;
    top: -5px;
    right: -5px;
  }
  
  svg path {
    fill: ${({$color}) => $color ? $color : colors.text['30']};
  }
  
  &:hover {
    color: ${({$color}) => $color ? adjustBrightness($color, 20) : colors.text['0']};
    border-color: ${({$color}) => $color ? adjustBrightness($color, -15) : colors.stroke['0A16']};
    background-color: ${({$color}) => $color ? adjustBrightness($color, -40) : colors.stroke['0A16']};
    
    .close {
      display: flex;
    }

    svg path {
      fill: ${({$color}) => $color ? adjustBrightness($color, 20) : colors.text['0']};
    }
  }

  @media (max-width: 1420px) {
    height: 48px;
    min-width: 48px;
    padding: 0 11px;
  }
`

const AddButton = styled.div`
  border: 1px solid ${colors.stroke['0A16']};
  background-color: ${colors.neutral['0A4']};
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: 500;
  color: ${colors.text['50']};
  opacity: 1;
  transition: width 100ms ease, height 100ms ease, border-radius 100ms ease;
  display: flex;
  border-radius: 12px;
  border: 1px solid ${colors.stroke['50']};
  position: relative;
  width: ${({$size}) => $size ?? '58px'};
  height: ${({$size}) => $size ?? '58px'};
  
  @media (max-width: 1420px) {
    width: ${({$size}) => $size ?? '48px'};
    height: ${({$size}) => $size ?? '48px'};
  }
`

const AddButtonContainer = styled.div`
  padding: 0;
  border-radius: 12px;
  cursor: pointer;
  transition: padding 100ms ease;
  position: relative;
  
  &.selecting {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  &:hover, &.selecting {
    padding: 8px;
    background-color: ${colors.background['third-layer']};
    
    ${AddButton} {
      background-color: ${colors.background['third-layer']};
      border: 1px solid ${colors.brand.primary['70']};
      color: ${colors.brand.primary['70']};
      width: 42px;
      height: 42px;
    }
  }

  @media (max-width: 1420px) {
    &:hover, &.selecting {
      padding: 6px;
      ${AddButton} {
        width: 36px;
        height: 36px;
      }
    }
  }
  
  @media (${mobileWidthMediaQuery}) {
    &:hover, &.selecting {
      //padding: 6px;
      
      ${AddButton} {
        border-radius: 8px;
      }
    }
  }
`
