import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import {colors, Tooltip} from '@get-wrecked/simple-components'
import {NewBadge, Rank} from '../styles'

const propTypes = {
  chart: PropTypes.shape({
    border: PropTypes.string,
    color: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    percentage: PropTypes.number,
  }),
  children: PropTypes.node,
  icon: PropTypes.node,
  // href: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  index: PropTypes.number,
  isNew: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  linkTo: PropTypes.object,
  onClick: PropTypes.func,
  showRank: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

const ChartListItem = ({chart, children, linkTo, icon, id, index, isNew, label, onClick, showRank, tooltip, style}) => {
  return (
    <Component linkTo={linkTo} style={style} onClick={onClick}>
      <ItemLabel>
        {
          icon &&
          icon
        }
        {
          showRank &&
          <Rank
            $rank={index + 1}
          >
            {index + 1}
          </Rank>
        }
        {/*<HashtagIcon size={22}/>*/}
        {label}
        {
          isNew &&
          <NewBadge>New</NewBadge>
        }
      </ItemLabel>
      {
        chart &&
        <Chart>
          {
            chart.label &&
            <ChartLabel>
              {chart.label}
            </ChartLabel>
          }
          <Tooltip
            tooltip={tooltip}
            position={'left'}
          >
            <Bar>
              <div
                style={{ position: 'absolute', right: 0, width: `${chart.percentage}%`, height: '100%', margin: 0, background: chart.color, border: chart.border, overflow: 'hidden' }}
              />
            </Bar>
          </Tooltip>
        </Chart>
      }
      {children}
    </Component>
  )
}

ChartListItem.propTypes = propTypes

export default ChartListItem

const Component = ({children, linkTo, onClick, style}) => {
  if (linkTo) {
    return (
      <Link
        style={style}
        to={linkTo}
      >
        {children}
      </Link>
    )
  } else {
    return (
      <Container
        style={style}
        onClick={onClick}
      >
        {children}
      </Container>
    )
  }
}

const ItemLabel = styled.span`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  font-weight: 500;
  color: ${colors.text['0']};
`

const Chart = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const ChartLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.text['0']};
`

const Bar = styled.div`
  position: relative;
  height: 32px;
  width: 200px;
  background-color: ${colors.neutral['0A4']};
  border-radius: 6px;
  overflow: hidden;
`

const Container = styled.div`
  ${({onClick}) => typeof onClick !== 'function' ? 'cursor: default;' : ''}
`
