import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import MUIAvatar from '@material-ui/core/Avatar';
import { getCategoryById } from '../../data/App';

//todo replace reference to local images
const images = require.context('../../../src/assets/img/avatars', true);

const styles = {
	row: {
		display: 'flex',
		justifyContent: 'center',
	},
	avatar: {
		margin: 0,
	},
	small: {
		width: 40,
		height: 40,
	},
	medium: {
		width: 60,
		height: 60,
	},
	large: {
		width: 90,
		height: 90,
	}
};

function Avatar(props) {
	const { classes } = props;
	const src = props.src || "";
	if (src === './0.png' || src === '') return <div />

	var thumb = null;
	var imageKeys = images.keys();
	if (imageKeys.indexOf(src) !== -1)
	{
		thumb = images(src);
	}
	else if (imageKeys.indexOf(src.replace(".png", ".jpg")) !== -1)
	{
		thumb = images(src.replace(".png", ".jpg"));
	}
	else
	{
		var category = getCategoryById(props.id * 1);
		if (category) {
			thumb = getCategoryById(props.id * 1).thumb;
		} else if (props.allowRawSrc) {
			thumb = src;
		}
	}
	return (
		<div className={classes.row}>
			<MUIAvatar
				alt={props.name}
				// src={images(props.src)}
				src={thumb}
				className={classNames(classes.avatar, classes[props.size])}
			/>
		</div>
	);
}

Avatar.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Avatar);
