import React, {Fragment} from "react";
import PropTypes from 'prop-types'
import SubgameRankingMethodPicker from "./Sections/SubgameRankingMethodPicker";

const propTypes = {
  children: PropTypes.node,
  subgameRankingMethod: PropTypes.string,
  includeNoneSubgame: PropTypes.bool,
  onSubgameRankingMethodChange: PropTypes.func,
}

const SubgamesSectionContainer = ({children, subgameRankingMethod, includeNoneSubgame, onSubgameRankingMethodChange}) => {
  const subgameRankingMethodChange = (subgameRankingMethod, includeNoneSubgame) => {
    if (typeof onSubgameRankingMethodChange === 'function') {
      onSubgameRankingMethodChange(subgameRankingMethod, includeNoneSubgame)
    }
  }

  return (
    <Fragment>
      <SubgameRankingMethodPicker
        value={subgameRankingMethod}
        includeNoneSubgame={includeNoneSubgame}
        onChange={subgameRankingMethodChange}
      />
      {children}
    </Fragment>
  );
}

SubgamesSectionContainer.propTypes = propTypes


export default SubgamesSectionContainer
