import React, {Fragment} from "react";
import { Redirect } from "react-router-dom";
import Search from "../../components/Search/Search.jsx";

import { checkId, getCategoryById, getReportingPeriodQueryString,
	getSubgameRankingTitle,
	groupMultiSummed, groupSummed, isEnterprise,
	joinLookup,
	subgameRankingMethods
} from "../../data/App";

import { calculatePeriodShares, extractColorList, loadCategoryData } from "../../data/Data";
import { loadSubgameData, sectionDescriptions, zeroFillMissingSubgameDays } from "./SubgamesLandingPage";
import MedalAppBar from "../../components/MedalAppBar/MedalAppBar";
import StackableCompareChart from "../../components/Charts/StackableCompareChart.jsx";
import {PageHeader} from '../../components/styles'
import SectionLoadingPlaceholder from '../../components/Dashboard/SectionLoadingPlaceholder'

const transformSubgameDetail = (processed, id, subgames, metric, loadCount = 50, otherThreshold = 10) => {
	// TODO
	const period = "day";
	const subgameIds = subgames.map(s=>parseInt(s))


	const {
		countColumns,
	} = processed;

	var subgameData = processed.subgameData;
	const game = getCategoryById(id);

	// TODO: move this up?  We're using it in more than one place now
	// group down to unique subgameIds.
	const subgameNames = subgameData.reduce((c, v) => { c.find(s => s.subgameId === v.subgameId) || c.push({ subgameId: v.subgameId, subgameName: v.subgameName }); return c; }, [])
	const emptySubgame = subgameNames.find(s => s.subgameName === "");
	if (emptySubgame) {
		emptySubgame.subgameName = `${game.name} (no subgame)`;
	}

	// Subgame popularity list over the period, expandable
	const groupedBySubgame = groupSummed(subgameData, "subgameId", countColumns)
		.sort((a, b) => b[metric] - a[metric]);
	//const groupedBySubgameAsPercents = calculatePeriodShares(groupedBySubgame, countColumns, "")
	const groupedBySubgameAsPercents = joinLookup(calculatePeriodShares(groupedBySubgame, countColumns, ""), subgameNames, "subgameId", "subgameId");
	const groupedBySubgameJoined = joinLookup(groupedBySubgame, subgameNames, "subgameId", "subgameId");

	const filteredForColorList = groupedBySubgame.filter(t => subgameIds.indexOf(t.subgameId) > -1);

	console.log("groupedBySubgame detail", subgameIds, groupedBySubgame, groupedBySubgameAsPercents, filteredForColorList)

	// create a new color lookup just for these subgames.
	// Don't add an Other color because we're in control of how many we show
	// NOTE: We sort by contentCount here so that colors are consistent between metrics.
	const subgameColorLookup = extractColorList(filteredForColorList.sort((a, b) => b.contentCount - a.contentCount), "subgameName", "subgameId");

	// Top subgames by session count
	// NOTE that we sort again to account for the above.
	const topSubgames = groupedBySubgame
		.filter(t => subgameIds.indexOf(t.subgameId) > -1)
		.sort((a, b) => b[metric] - a[metric])
		.slice(0, loadCount).map(g => g.subgameId);


	// Note that we grouped by subgame & content before zero filling.
	subgameData = zeroFillMissingSubgameDays(subgameData, period, "subgameId", countColumns, id);

	const groupedBySubgamePeriod = groupMultiSummed(subgameData, ["subgameId", period], countColumns);

	// Top subgames per day chart
	var groupedBySubgamePeriodAsPercents = calculatePeriodShares(groupedBySubgamePeriod, countColumns, period)
	const topSubgameSlice = topSubgames.slice(0, otherThreshold);

	const filteredSubgamePeriodAsPercents = groupedBySubgamePeriodAsPercents.filter(t => subgameIds.indexOf(t.subgameId) > -1);

	// Here, we're rearranging our data so that it'll make it into a stacked chart in the right order.
	// Not actually necessary.
	const subgameChartOrderWithColors = topSubgameSlice.map(t => subgameColorLookup[t]).reverse();

	const subgameSearch = groupedBySubgameJoined.sort((a, b) => a.subgameName > b.subgameName ? 1 : -1)
		.map(t => ({ label: t.subgameName, value: `${id}/${t.subgameId}` }))

	return {
		groupedBySubgameAsPercents,
		subgameChartOrderWithColors,
		filteredSubgamePeriodAsPercents,
		subgameColorLookup,
		subgameSearch,
	}
}

class SubgameDetailPage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			dataLoaded: false,
			search: false,
			games: new Map(),
			platforms: new Map(),
			categories: [],

			// 20231214: not needed if we're not going to use the wrapper/selector
			// subgameRankingMethod: retreiveSubgameRankingMethodFromQueryString(),
			// includeAutoSubgames: retreiveSubgameIncludeAutoFromQueryString(),
		}
	}

	retreiveId() {
		var loc = this.props.location;
		if (loc.id) {
			console.log("found id on location", this.props.location)
			return loc.id;
		}

		// NOTE: Ids survive page reloads by nature of us having pushed our state into the browser history.
		if (loc.state && loc.state.id) {
			console.log("found id in state", loc.state)
			return loc.state.id;
		}

		if (this.props && this.props.match && this.props.match.params && this.props.match.params.id) {
			const id = parseInt(this.props.match.params.id, 10)

			console.log("found id in props", this.props.match.params, id)

			return id;
		}

		// 20191107: We should no longer ever make it this far, now that we pass id around instead
		// of (yikes!) eCharts option objects.
		console.warn("NO IDs FOUND. Redirecting to landing page.")
		return 0;
	}

	retreiveSubgames() {
		if (this.props && this.props.match && this.props.match.params && this.props.match.params.subgames) {
			const subgames = this.props.match.params.subgames.split(",").map(s => s.trim());
			console.log("found subgames in props", this.props.match.params, subgames)

			return subgames;
		}

		console.warn("NO SUBGAMES FOUND. Redirecting to landing page.")
		return [];
	}

	componentDidMount() {
		if (!isEnterprise()) {
			this.setState({ redirectTo: '/trends/' });
			return;
		}

		loadCategoryData().then(() => {
			const id = this.retreiveId();
			const subgames = this.retreiveSubgames();

			console.log("id?", id)
			if (!id || id === 0 || !checkId(id)) {
				this.setState({ redirect: true })
				return;
			}

			this.setState({ id, subgames, loaded: true }, () => {
				//this.populateFromIds(false);
				this.populate(id, subgames);
			})
		})
	}

	componentDidUpdate() {
		if (this.state.dataLoaded && this.state.lastReportingDescription !== this.buildUrl()) {
			this.populate(this.state.id, this.state.subgames);
		}
	}

	buildQueryString = () => {
		return getReportingPeriodQueryString(window.location.search, {
			// tr: getSubgameRankingMethodKey(this.state.subgameRankingMethod),
			// ti: this.state.includeAutoSubgames ? 1 : 0,
		});
	}
	isEmbed() {
		return window.location.pathname.indexOf("/embed/") === 0;
	}

	populate(id, subgames) {

		this.setState({ dataLoaded: false })

		loadSubgameData(id).then((processed) => {

			console.log("detail processed", processed)

			const { subgameRankingMethod } = this.props;
			const detail = transformSubgameDetail(processed, id, subgames, subgameRankingMethod)

			this.setState({
				...processed,
				...detail,
				dataLoaded: true,
				lastReportingDescription: this.buildUrl(),
			},
				() => {
					// This causes componentDidUpdate to fire, so let's make sure we wait until the state change finishes before calling it:
					this.props.history.replace(this.buildUrl());
				});
		});
	}

	buildUrl() {
		var id = this.state.id;
		var subgames = this.state.subgames || "";
		if (this.isEmbed()) {
			return `/embed/subgame-detail/${id}/${subgames.join(",")}?${this.buildQueryString()}`;
		}
		return `/subgame-detail/${id}/${subgames.join(",")}?${this.buildQueryString()}`;
	}

	buildTitle(id) {
		return "Subgames | Medal Trends";
	}

	showSearch = () => {
		this.setState({ search: true })
	}

	// Search was built for categories, hence the handler name
	removeCategory = (subgame) => {
		const subgames = this.state.subgames.filter(t => parseInt(t) !== subgame.id);
		console.log("removeCategory", subgame, this.state.subgames, subgames)

		this.setState({ subgames });
	}

	getSearchHandler() {
		return (key) => {
			const subgame = key.split('/')[1];
			console.log("searchHandler", key, this.state, subgame, this.state.subgames.toSpliced(0, 0, subgame))
			this.setState({ subgames: this.state.subgames.toSpliced(0, 0, subgame), search: false })
		}
	}

	renderSubgameComparisonChart = (metric) => {
		var game = getCategoryById(this.state.id);
		//console.log("renderSubgameComparisonChart", metric, game, this.state.filteredSubgamePeriodAsPercents, this.state.subgameChartOrderWithColors)
		const title = `${game.name} Subgames by ${getSubgameRankingTitle(metric)}`
		return <StackableCompareChart
			data={this.state.filteredSubgamePeriodAsPercents}
			seriesList={this.state.subgameChartOrderWithColors}
			isEmbed={this.isEmbed()}
			metric={metric}
			idField="subgameId"
			title={title}
			subtitle={sectionDescriptions[metric]}
			section={metric}
			useLogScale={false}
			stacked={false}
			//toolTipThreshold={0.5}
			preserveTooltipOrder={true}
			key={`${this.state.id}_${metric}`}
		/>
	}

	render() {
		if (this.state.redirectTo !== undefined) {
			return <Redirect push to={this.state.redirectTo} />;
		}

		if (!this.state.dataLoaded) {
			return (
        <Fragment>
          <SectionLoadingPlaceholder minHeight="400px"/>
          <SectionLoadingPlaceholder minHeight="400px"/>
          <SectionLoadingPlaceholder minHeight="400px"/>
          <SectionLoadingPlaceholder minHeight="400px"/>
        </Fragment>
      )
		}

		return (
      <Fragment>
        {this.state.subgameSearch && this.state.search && <Search
          autoFocus={true}
          //itemsList={getCategoryData().map((game) => ({ label: game.name, value: game.id }))}
          itemsList={this.state.subgameSearch}
          handler={this.getSearchHandler()}
        />}

        <MedalAppBar
          hideAvatar={true}
          categories={this.state.subgameChartOrderWithColors}
          removeCategory={this.removeCategory}
          addCategory={this.showSearch}
        />

        <PageHeader>
          Subgame Trends
          <span>
            NOTE: Percentage shares shown below represent observations within the Medal.tv platform, and therefore may differ from numbers generated from other sources.
          </span>
        </PageHeader>

        {subgameRankingMethods.map(m => this.renderSubgameComparisonChart(m.dataMember))}
      </Fragment>
    )
	}
}

export default SubgameDetailPage
