const releaseAdminRoles = ['H-qdRGEkA', '1BnblabfN']
const staffRoles = ['1BnblabfN', '2t7AEfTl9', '3j26Dgv2q', '4Nau3hi1r', '5COwXzpdb', 'cY-HKKxeP'];

let _user = null

/**
 * Get the cached user from localStorage.
 * @returns {object|null}
 */
export const getCachedUser = () => {
  if (!_user) {
    const cachedUser = localStorage.getItem('medal-cached-user')
    if (cachedUser) {
      _user = JSON.parse(cachedUser)
    }
  }
  return _user
}

/**
 * Update the cached user in localStorage.
 * @param {object} user
 */
export const updateCachedUser = (user) => {
  _user = user
  return localStorage.setItem('medal-cached-user', JSON.stringify(user))
}

/**
 * Delete the cached user in localStorage.
 */
export const deleteCachedUser = () => {
  _user = null
  localStorage.removeItem('medal-cached-user')
}


export const isMedalStaff = user => {
  return user?.roles?.some?.(role => staffRoles.includes(role.id));
}

export const isMedalAdmin = user => {
  return user?.roles?.some?.(role => releaseAdminRoles.includes(role.id));
}
