/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import {colors} from '@get-wrecked/simple-components'

// core components
import CompareChart from '../../../components/Charts/CompareChart'

// data components
import {getGamesList} from '../../../data/Data';
import {buildGameDataArray} from '../../../data/Chart';
import {reportingPeriod} from '../../../data/App'
import DashboardSection from '../../../components/Dashboard/DashboardSection'
import styled from 'styled-components'
import SectionLoadingPlaceholder from '../../../components/Dashboard/SectionLoadingPlaceholder'

class CompareGames extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      gameDataArray: [],   // all data for each game
      gameDataDisplay: [], // data currently displayed
      gameUsageByDayIndex: 0,
      loading: true,
      moreButtonDisplay: "",
      lastReportingPeriod: reportingPeriod,
    }
  }

  static defaultProps = {
    blurOnSelect: false,
  };


  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    if (this.state.lastReportingPeriod !== reportingPeriod) {
      this.loadData();
    }
  }

  loadData() {
    getGamesList().then(games => {
      return buildGameDataArray(games, this.props.dataMember);
    }).then(result => {
      this.setState({gameDataArray: result, gameDataDisplay: result[0], lastReportingPeriod: reportingPeriod});
    })
  }


  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.gameDataDisplay !== nextState.gameDataDisplay) {
      return true;
    }
    if (this.state.lastReportingPeriod !== reportingPeriod) {
      return true;
    }
    return false;
  }

  loadMore = (e) => {
    const index = this.state.gameUsageByDayIndex + 1;
    this.setState({
      gameDataDisplay: this.state.gameDataDisplay.concat(this.state.gameDataArray[index]),
      gameUsageByDayIndex: index,
    })
    if (index === this.state.gameDataArray.length - 1) {
      this.setState({moreButtonDisplay: "none"});
    }
  }

  render() {
    const {gameDataDisplay} = this.state;

    if (!gameDataDisplay) return null;
    if (gameDataDisplay.length === 0) {
      return (
        <SectionLoadingPlaceholder/>
      )
    }

    return (
      <DashboardSection
        title="Popular Comparisons"
        description="Compare the trends of popular games"
        icon={
          <svg width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM9.91 16.19C9.91 16.83 9.39 17.35 8.74 17.35C8.1 17.35 7.58 16.83 7.58 16.19V12.93C7.58 12.29 8.1 11.77 8.74 11.77C9.39 11.77 9.91 12.29 9.91 12.93V16.19ZM16.42 16.19C16.42 16.83 15.9 17.35 15.26 17.35C14.61 17.35 14.09 16.83 14.09 16.19V7.81C14.09 7.17 14.61 6.65 15.26 6.65C15.9 6.65 16.42 7.17 16.42 7.81V16.19Z"
              fill={colors.brand.secondary['50']}
            />
          </svg>
        }
        onLoadMore={this.loadMore}
      >
        <Container>
          <Games>
            {
              gameDataDisplay.map((option, key) => {
                if (!option || !option.series || option.series[0].name === '') return null;
                return (
                  <CompareChart
                    key={key}
                    option={option}
                  />
                )
              })
            }
          </Games>
        </Container>
      </DashboardSection>
    )
  }
}

export default CompareGames

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px
`

const Games = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(313px, 1fr));
  gap: 16px;
`
