import React, { Fragment } from 'react';
import { Link } from "react-router-dom";

// for games list
import ReactEcharts from 'echarts-for-react'

import {dataMembers} from "../../../data/App";
import { buildGameDataSet } from "../../../data/Chart";
import { buildCompareUrl, displayUnitMap, valueFormatMap } from '../../../data/App';
import {colors} from '@get-wrecked/simple-components'
import GameCard from '../../../components/Card/GameCard'
import styled from 'styled-components'
import {calculatePercentageChange} from '../../../utils/data'
import LoadMoreButton from '../../../components/LoadMoreButton'

class TrendingGameList extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			title: "",
			games: new Map(),
			dataMember: dataMembers.USAGE_PERCENTS,
			loading: true,
			popularityThreshold: this.props.popularityThreshold || 0.5,

			pageIndex: 1,
			showCount: 10,
			gameDataDisplay: [],
			charts: {},
		}
	}

	static defaultProps = {
		blurOnSelect: false,
	};

	componentDidMount() {
		this.updateDisplayList();
	}

	componentDidUpdate = (prevProps, prevState) => {
		if (this.state.pageIndex !== prevState.pageIndex || this.state.showCount !== prevState.showCount) {
			this.updateDisplayList();
		}
	}

	loadMore = (e) => {
		const index = this.state.pageIndex + 1;
		this.setState({ pageIndex: index })
	}

	changePercent = (item) => {
		const oldestPercent = (item) => {
			return item[this.props.dataMember][0]
		}
		const latestPercent = (item) => {
			return item[this.props.dataMember][item[this.props.dataMember].length - 1]
		}

		// NOTE: we don't need to zero-safen the denominator here, because we'll always be filtering out low-share items before we get here.
		let changeValue = (((latestPercent(item) / oldestPercent(item) - 1) * 100).toFixed(1)) * 1; // relative percentage, whole period
		if (changeValue > 999) {
			changeValue = 100;	// don't show 0 plays -> 5 plays as 5,000,000,000%
		}
		if (changeValue < -999) {
			changeValue = -100;
		}

		return changeValue;
	}

	formatValue = (value) => {
		const formatter = valueFormatMap[this.props.dataMember];
		return formatter ? formatter(value) : value.toFixed(2);

	}

	oldValue = (item) => {
		return this.formatValue(item[this.props.dataMember][0])
	}

	newValue = (item) => {
		return this.formatValue(item[this.props.dataMember][item[this.props.dataMember].length - 1]);
	}

	displayUnit = () => {
		const unit = displayUnitMap[this.props.dataMember];
		return unit !== undefined ? unit : `% of total`;

	}

	updateDisplayList = () => {
		const gameDataDisplay = this.buildDisplayList(this.state.pageIndex, this.state.showCount);

		const charts = {};
		gameDataDisplay.forEach(item => {

			const chart = buildGameDataSet([item], this.props.dataMember, "spark");


			// chart.xAxis[0].data.push("","","")
			// console.log("trend", chart, item)

			// var copy = JSON.parse(JSON.stringify(chart.series[0]));
			// copy.data = item.trend[this.props.dataMember].points;
			// copy.lineStyle.width = 2;
			// copy.symbol = "none";
			// copy.color = "rgba(255,255,255,0.2)"
			// copy.id += "t";
			// chart.series[2] = copy;

			charts[item.id] = chart;
		})

		this.setState({ gameDataDisplay, charts })
	}

	buildDisplayList = (pageIndex, showCount) => {
		const changePercent = this.changePercent;

		const isPopularEnough = (item, threshold) => {
			// For average session duration, filter popularity by total session duration
			var marketShareMethod = this.props.dataMember !== dataMembers.AVG_SESSION_DURATION ? this.props.dataMember : dataMembers.USAGE_PERCENTS;

			var popularityChange = item[marketShareMethod].slice().sort((a, b) => a > b ? 1 : -1);
			return popularityChange[0] > threshold || popularityChange[popularityChange.length - 1] > threshold
		}

		const popularGames = [...this.props.games.values()].filter(item => isPopularEnough(item, this.props.popularityThreshold));
		const sorted = popularGames.sort((a, b) => {
			if (this.props.ascending) {
				return changePercent(a) > changePercent(b) ? 1 : -1;
			}
			return changePercent(a) < changePercent(b) ? 1 : -1;
		});

		const gameDataDisplay = sorted.filter(item => this.props.ascending ? (changePercent(item) < 0) : (changePercent(item) > 0)).slice(0, showCount * pageIndex);

		return gameDataDisplay;
	}

	isGameNew = (item) => {
		return item[this.props.dataMember][0] === 0;
	}


	render() {
		const gameDataDisplay = this.state.gameDataDisplay;
		return (
			<Container>
				{/*{(this.props.renderShareIcon && this.props.renderShareIcon === true) && <EmbedOverlay {...this.props} />}*/}
        <Games>
          {
            gameDataDisplay.map((item, index) => {
              const chart = this.state.charts[item.id];
              const oldValue = this.oldValue(item)
              const newValue = this.newValue(item)
              const displayUnit = this.displayUnit()

              const rawOldValue = item[this.props.dataMember][0]
              const rawNewValue = item[this.props.dataMember][item[this.props.dataMember].length - 1];
              const percentageChange = calculatePercentageChange(rawOldValue, rawNewValue).toFixed(0)

              return (
                <Link
                  key={item.id}
                  params={{ ids: [item.id]}}
                  to={{
                    pathname: buildCompareUrl('compare-page', item.id)
                  }}
                >
                  <GameCard
                    id={item.id}
                    rank={index + 1}
                    name={item.abv.toUpperCase().replace(/ /g, "\u00a0")}
                    thumbnail={item.thumb}
                    unit={
                      <React.Fragment>
                        {oldValue} to {newValue}{displayUnit} <span style={{color: percentageChange > 0 ? colors.success['10'] : colors.error['10'], marginInlineEnd: '12px'}}>{percentageChange === 'Infinity' ? <Fragment>+<InfinityIcon size={16} color={colors.success['10']}/></Fragment> : `${percentageChange > 0 ? '+' : ''}${percentageChange}%`}</span>
                      </React.Fragment>
                    }
                    isNew={this.isGameNew(item)}
                  >
                    <ChartContainer>
                      <ChartGrid>
                        <Line/>
                        <Line/>
                        <Line/>
                        <Line/>
                      </ChartGrid>
                      <ReactEcharts
                        option={chart}
                        lazyUpdate={true}
                        theme={"medal_chart_theme"}
                        style={{
                          height: '100px'
                        }}
                      />
                    </ChartContainer>
                  </GameCard>
                </Link>
              )
            })
          }
        </Games>
        <LoadMoreButton onClick={this.loadMore}/>
			</Container>
		)
	}
}

export default TrendingGameList

const InfinityIcon = ({size = 24, color = 'white'}) => {
  return (
    <svg width={size} height={size * 0.5833333333} viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6.5" cy="7" r="5.5" stroke={color} strokeWidth="2"/>
      <circle cx="17.5" cy="7" r="5.5" stroke={color} strokeWidth="2"/>
    </svg>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px
`

const Games = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(313px, 1fr));
  gap: 16px;
`

const ChartContainer = styled.div`
  padding-inline-start: 24px;
  position: relative;
`

const ChartGrid = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > :first-child, :last-child {
    visibility: hidden;
    height: 0;
  }
`

const Line = styled.div`
  height: 1px;
  border-top: 1px dashed ${colors.stroke['0A8']};
`
