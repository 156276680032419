import React from 'react'
import PropTypes from 'prop-types'
import WindowedSelect from 'react-windowed-select'
import {colors} from '@get-wrecked/simple-components'

const propTypes = {
  autoFocus: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.object,
  placeholder: PropTypes.string,
  components: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
}

const SelectInput = ({autoFocus, placeholder, value, options, components, onSelect = () => {}}) => {
  return (
    <WindowedSelect
      autoFocus={autoFocus}
      placeholder={placeholder}
      value={value}
      options={options}
      onChange={onSelect}
      windowThreshold={100}
      styles={styles}
      components={components}
    />
  )
}

SelectInput.propTypes = propTypes

export default SelectInput

const styles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: colors.neutral['100A40'],
    border: `1px solid ${colors.stroke['0A16']}`,
    borderRadius: '8px',
    cursor: 'text',
    fontFamily: '"DM Sans", sans-serif',
    fontSize: '14px',
    '&:hover': {
      borderColor: state.isFocused ? colors.brand.primary['50'] : colors.stroke['50'],
    }
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: colors.text['70'],
  }),
  input: (provided, state) => ({
    ...provided,
    color: colors.text['0'],
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: colors.neutral['95'],
    border: `1px solid ${colors.stroke['0A16']}`,
    borderRadius: '8px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontWeight: 500,
    color: state.isSelected ? colors.text['0'] : colors.text['30'],
    backgroundColor: state.isFocused ? colors.neutral['0A4'] : 'none',
    fontSize: '14px',
    cursor: 'pointer',
  }),
}
