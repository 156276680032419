import React, {useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import Modal from '../../components/Modal'
import styled from 'styled-components'
import {Button, colors} from '@get-wrecked/simple-components'
import {Hashtag} from '../../components/ComparisonSwitcher/TagComparison'
import SelectInput from '../../components/SelectInput'

const propTypes = {
  excludedIds: PropTypes.array,
  id: PropTypes.string.isRequired,
  onClickAdd: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

const TagSelectionModal = ({id, excludedIds = [], onClickAdd, onClose, tags}) => {
  const [selectedIds, setSelectedIds] = useState([])

  const selectedTags = useMemo(() => {
    if (!tags?.length) {
      return []
    }
    return selectedIds.map(id => tags.find(tag => tag.label === id))
  }, [tags, selectedIds])

  const onInputSelect = (selection) => {
    setSelectedIds([
      ...selectedIds,
      selection.label
    ])
  }

  const remove = (id) => {
    setSelectedIds(selectedIds.filter(_id => _id !== id))
  }

  const tagOptions = useMemo(() => {
    if (!tags?.length) {
      return []
    }
    return tags.filter(tag => !excludedIds.includes(tag.label) && !selectedIds.includes(tag.label))
  }, [excludedIds, selectedIds, tags])

  return (
    <Modal
      id={id}
      header="Choose Tags"
      size="small"
      position="top"
      gap="16px"
      onClose={onClose}
    >
      <SelectInput
        placeholder="Search trending tags"
        value={undefined}
        options={tagOptions}
        onSelect={onInputSelect}
      />
      {
        selectedTags.length > 0 &&
        <Selections>
          {
            selectedTags.map(tag => {
              return (
                <Hashtag
                  key={tag.value}
                  id={tag.value}
                  name={tag.label}
                  onClickRemove={() => remove(tag.label)}
                />
              )
            })
          }
        </Selections>
      }
      {
        selectedTags.length > 0 &&
        <Actions>
          <Button
            variant="secondary"
            size="large"
            onClick={() => onClickAdd(selectedIds)}
          >
            Add{selectedTags.length > 1 ? ` ${selectedTags.length} tags` : ` ${selectedTags[0].label}`} to comparison
          </Button>
        </Actions>
      }
    </Modal>
  )
}

TagSelectionModal.propTypes = propTypes

export default TagSelectionModal

const Selections = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding-top: 20px;
  border-top: 1px solid ${colors.stroke['0A8']};
`
