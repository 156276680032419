import axios from 'axios'
import {getStoredAuth} from '../user/auth'

// used for string IDs
const featureFlags = ['string_id_v2']
const userAgent = `Medal-Metrics/1.0.0 (${featureFlags.join('; ')})`


// used for numeric IDs
const legacyUserAgent = `Medal-Metrics/1.0.0`

/**
 * Wrap the options in the authentication header.
 * @param options
 * @returns {*&{headers: {'Medal-User-Agent': string, 'X-Authentication': string}}}
 */
const authenticate = options => {
  const auth = getStoredAuth()
  const headers = {
    'Medal-User-Agent': options?.useLegacyUserAgent === true ? legacyUserAgent : userAgent,
    ...(options?.headers ?? {})
  }
  if (options?.includeAuthenticationHeader !== false && auth?.userId) {
    headers['x-authentication'] = `${auth?.userId},${auth?.key}`
  }

  console.log('headers:', headers)

  return {
    ...(options ?? {}),
    headers
  }
}

class Api {
  constructor(host) {
    this._host = host
  }

  /**
   * Perform a GET request with the authentication header.
   * @param {string} path
   * @param {object} options
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  get = (path, options) => {
    return axios.get(`${this._host}${path}`, authenticate(options))
  }

  /**
   * Perform a POST request with the authentication header.
   * @param {string} path
   * @param {object} body
   * @param {object} options
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  post = (path, body, options) => {
    return axios.post(`${this._host}${path}`, body, authenticate(options))
  }

  /**
   * Perform a DELETE request with the authentication header.
   * @param {string} path
   * @param {object} options
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  delete = (path, options) => {
    return axios.delete(`${this._host}${path}`, authenticate(options))
  }
}

export default Api
