import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {mobileWidthMediaQuery, pageContentWidth} from '../../components/styles'

const propTypes = {
  children: PropTypes.node.isRequired
}

const Page = ({children}) => {
  return (
    <PageBody>
      <PageContents>
        {children}
      </PageContents>
    </PageBody>
  )
}

Page.propTypes = propTypes

export default Page

const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`

const PageContents = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  width: ${pageContentWidth};
  gap: 24px;
  transition: padding 200ms ease-in-out;

  @media (max-width: 1420px) {
    padding: 20px;
  }

  @media (${mobileWidthMediaQuery}) {
    padding: 8px;
  }  
`
