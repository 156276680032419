import React from 'react'
import {GameThumbnail} from '../ComparisonSwitcher/GameSelection'
import {Rank} from '../styles'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const propTypes = {
  game: PropTypes.object.isRequired,
  rank: PropTypes.number.isRequired,
  size: PropTypes.string,
}

const GameThumbnailAndRank = ({game, rank, size}) => {
  return (
    <GameListItemIcon>
      <GameThumbnail
        category={game}
        showColor={false}
        size={size}
      />
      <RankContainer>
        <Rank $rank={rank}>
          {rank}
        </Rank>
      </RankContainer>
    </GameListItemIcon>
  )
}

GameThumbnailAndRank.propTypes = propTypes

export default GameThumbnailAndRank

const GameListItemIcon = styled.div`
  display: flex;
  position: relative;
`

const RankContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: -3px;
  right: -3px;
`
