import React, {Fragment} from "react";

import { checkId, getCategoryById, getReportingPeriodQueryString, getTagRankingMethodKey, getTagRankingTitle, groupMultiSummed, groupSummed, isEnterprise, retreiveTagIncludeAutoFromQueryString, retreiveTagRankingMethodFromQueryString, tagRankingMethods } from "../../data/App";

import { Redirect } from "react-router-dom";
import { calculatePeriodShares, extractColorList, loadCategoryData } from "../../data/Data";
import { loadTagData, sectionDescriptions, zeroFillMissingTagDays } from "./TagsLandingPage";
import StackableCompareChart from "../../components/Charts/StackableCompareChart.jsx";
import SectionLoadingPlaceholder from '../../components/Dashboard/SectionLoadingPlaceholder'
import {PageHeader} from '../../components/styles'
import TagComparison from '../../components/ComparisonSwitcher/TagComparison'
import styled from 'styled-components'

const transformTagDetail = (processed, id, tags, metric, loadCount = 50, otherThreshold = 10) => {
	// TODO
	const period = "day";

	const {
		countColumns,
	} = processed;

	var tagData = processed.tagData;

	// Filter garbage tags out
	const game = getCategoryById(id);
	const gameNameFilter = game.name.toLowerCase().replace(/ /g, '')
	tagData = tagData.filter(t =>
		t.tag.toLowerCase() !== gameNameFilter
	);

	// Tag popularity list over the period, expandable
	const groupedByTag = groupSummed(tagData, "tag", countColumns)
		.sort((a, b) => b[metric] - a[metric]);
	const groupedByTagAsPercents = calculatePeriodShares(groupedByTag, countColumns, "")

	const filteredForColorList = groupedByTag.filter(t => tags.indexOf(t.tag) > -1);

	// create a new color lookup just for these tags.
	// Don't add an Other color because we're in control of how many we show
	// NOTE: We sort by contentCount here so that colors are consistent between metrics.
	const tagColorLookup = extractColorList(filteredForColorList.sort((a, b) => b.contentCount - a.contentCount), "tag", "tag");

	// Top tags by session count
	// NOTE that we sort again to account for the above.
	const topTags = groupedByTag
		.filter(t => tags.indexOf(t.tag) > -1)
		.sort((a, b) => b[metric] - a[metric])
		.slice(0, loadCount).map(g => g.tag);


	// Note that we grouped by tag & content before zero filling.
	tagData = zeroFillMissingTagDays(tagData, period, "tag", countColumns);

	const groupedByTagPeriod = groupMultiSummed(tagData, ["tag", period], countColumns);

	// Top tags per day chart
	var groupedByTagPeriodAsPercents = calculatePeriodShares(groupedByTagPeriod, countColumns, period)
	const topTagSlice = topTags.slice(0, otherThreshold);

	const filteredTagPeriodAsPercents = groupedByTagPeriodAsPercents.filter(t => tags.indexOf(t.tag) > -1);

	// Here, we're rearranging our data so that it'll make it into a stacked chart in the right order.
	// Not actually necessary.
	const tagChartOrderWithColors = topTagSlice.map(t => tagColorLookup[t]).reverse();

	const tagSearch = groupedByTag.sort((a, b) => a.tag > b.tag ? 1 : -1)
		.map(t => ({ label: t.tag, value: `${id}/${t.tag}` }))

	return {
		groupedByTagAsPercents,
		tagChartOrderWithColors,
		filteredTagPeriodAsPercents,
		tagColorLookup,
		tagSearch,
	}
}

class TagDetailPage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			dataLoaded: false,
			games: new Map(),
			platforms: new Map(),
			categories: [],

			// 20231214: not needed if we're not going to use the wrapper/selector
			tagRankingMethod: retreiveTagRankingMethodFromQueryString(),
			includeAutoTags: retreiveTagIncludeAutoFromQueryString(),
		}
	}

	retreiveId() {
		var loc = this.props.location;
		if (loc.id) {
			console.log("found id on location", this.props.location)
			return loc.id;
		}

		// NOTE: Ids survive page reloads by nature of us having pushed our state into the browser history.
		if (loc.state && loc.state.id) {
			console.log("found id in state", loc.state)
			return loc.state.id;
		}

		if (this.props && this.props.match && this.props.match.params && this.props.match.params.id) {
			const id = parseInt(this.props.match.params.id, 10)
			console.log("found id in props", this.props.match.params, id)
			return id;
		}

		// 20191107: We should no longer ever make it this far, now that we pass id around instead
		// of (yikes!) eCharts option objects.
		console.warn("NO IDs FOUND. Redirecting to landing page.")
		return 0;
	}

	retreiveTags() {
		if (this.props && this.props.match && this.props.match.params && this.props.match.params.tags) {
			const tags = this.props.match.params.tags.split(",").map(s => s.trim());
			console.log("found tags in props", this.props.match.params, tags)

			return tags;
		}
		console.warn("NO TAGS FOUND. Redirecting to landing page.")
		return [];
	}

	componentDidMount() {
		if (!isEnterprise()) {
			this.setState({ redirectTo: '/trends/' });
			return;
		}

		loadCategoryData().then(() => {
			const id = this.retreiveId();
			const tags = this.retreiveTags();

			console.log("id?", id)
			if (!id || id === 0 || !checkId(id)) {
				this.setState({ redirect: true })
				return;
			}

			this.setState({ id, tags, loaded: true }, () => {
				//this.populateFromIds(false);
				this.populate(id, tags);
			})
		})
	}

	componentDidUpdate() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
		if (this.state.dataLoaded && this.state.lastReportingDescription !== this.buildUrl()) {
			this.populate(this.state.id, this.state.tags);
		}
	}

	buildQueryString = () => {
		return getReportingPeriodQueryString(window.location.search, {
			tr: getTagRankingMethodKey(this.state.tagRankingMethod),
			ti: this.state.includeAutoTags ? 1 : 0,
		});
	}
	isEmbed() {
		return window.location.pathname.indexOf("/embed/") === 0;
	}

	populate(id, tags) {
		this.setState({ dataLoaded: false })
		loadTagData(id).then((processed) => {

			const { tagRankingMethod } = this.props;
			const detail = transformTagDetail(processed, id, tags, tagRankingMethod)

			this.setState({
				...processed,
				...detail,
				dataLoaded: true,
				lastReportingDescription: this.buildUrl(),
				},
				() => {
					// This causes componentDidUpdate to fire, so let's make sure we wait until the state change finishes before calling it:
					this.props.history.replace(this.buildUrl());
				});
		});
	}

	buildUrl() {
		var id = this.state.id;
		var tags = this.state.tags || "";
		if (this.isEmbed()) {
			return `/embed/tag-detail/${id}/${tags.join(",")}?${this.buildQueryString()}`;
		}
		return `/tag-detail/${id}/${tags.join(",")}?${this.buildQueryString()}`;
	}

	buildTitle(id) {
		return "Tags | Medal Trends";
	}

	// tagRankingMethodChange = (tagRankingMethod, includeAutoTags) => {
	// 	console.log("setting state", tagRankingMethod, includeAutoTags, true)
	// 	this.setState({ tagRankingMethod, includeAutoTags });
	// }

	// Search was built for categories, hence the handler name
	removeCategory = (tag) => {
		const tags = this.state.tags.filter(t => t !== tag.id);
		console.log("removeCategory", tag, this.state.tags, tags)

		this.setState({ tags });
	}

	getSearchHandler = (...keys) => {
    this.setState({
      tags: [...this.state.tags, ...keys]
    })
  }

	renderTagComparisonChart = (metric) => {
		var game = getCategoryById(this.state.id);
		//console.log("renderTagComparisonChart", metric, game, this.state.filteredTagPeriodAsPercents, this.state.tagChartOrderWithColors)
		const title = `${game.name} Tags by ${getTagRankingTitle(metric)}`
		return <StackableCompareChart
			data={this.state.filteredTagPeriodAsPercents}
			seriesList={this.state.tagChartOrderWithColors}
			isEmbed={this.isEmbed()}
			metric={metric}
			idField="tag"
			title={title}
			subtitle={sectionDescriptions[metric]}
			section={metric}
			useLogScale={false}
			stacked={false}
			//toolTipThreshold={0.5}
			preserveTooltipOrder={true}
			key={`${this.state.id}_${metric}`}
		/>
	}

	render() {
		if (this.state.redirectTo !== undefined) {
			return <Redirect push to={this.state.redirectTo} />;
		}

		if (!this.state.dataLoaded ) {
			return <Fragment>
        <SectionLoadingPlaceholder minHeight="400px"/>
        <SectionLoadingPlaceholder minHeight="400px"/>
        <SectionLoadingPlaceholder minHeight="400px"/>
      </Fragment>
		}

		return (
     <Fragment>
       <TopSpacer/>

      <TagComparison
        tags={this.state.tagSearch}
        selectedTags={this.state.tagChartOrderWithColors}
        onAddTags={this.getSearchHandler}
        onClickRemove={this.removeCategory}
      />

       <PageHeader>
         Tag Trends
         <span>
           NOTE: Percentage shares shown below represent observations within the Medal.tv platform, and therefore may differ from numbers generated from other sources.
         </span>
       </PageHeader>

       {tagRankingMethods.map(m => this.renderTagComparisonChart(m.dataMember))}

       {/*

				<TagSectionContainer
					tagRankingMethod={this.state.tagRankingMethod}
					includeAutoTags={this.state.includeAutoTags}
					onTagRankingMethodChange={this.tagRankingMethodChange}
				>
					{this.renderTagComparisonChart(this.state.tagRankingMethod)}

				</TagSectionContainer> */}
     </Fragment>
    )
	}
}

export default TagDetailPage

const TopSpacer = styled.div`
  margin-top: -24px;
  height: 97px;
`
