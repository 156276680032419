import React from 'react';
import ReactEcharts from 'echarts-for-react'

import { loadKnownDataSet } from '../../../data/Data';
import { reportingPeriod, colorFromName } from '../../../data/App';
import { eChartsCountryNames } from '../../../data/MapData';
import SectionLoadingPlaceholder from '../../../components/Dashboard/SectionLoadingPlaceholder'

require('echarts/map/js/world');

class ViewershipByRegion extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			lastReportingPeriod: reportingPeriod,
			lastCategories: this.props.categories,
		}
	}

	buildOption(data) {
		var nameLookup = this.props.categories.reduce(function (acc, cur) { acc["" + cur.id] = cur.name; return acc; }, {});

		var option = {
			backgroundColor: "transparent",
			legend:{show:false},
			showLegendSymbol: false,
			tooltip: {
				trigger: 'item',
				formatter: function (params) {
					if (!params.data || !params.data.categoryName) {
						return "";
					}

					var percentSection = `${params.data.categoryName}: ${(params.data.percent * 100.0).toFixed(2)}%`;
					Object.keys(params.data.percents).forEach(k => {
						if (k !== params.data.categoryId) {
							percentSection += `<br/>${nameLookup[k]}: ${ (params.data.percents[k] * 100.0).toFixed(2)}%`
						}

					});
					return `<b>${params.name}</b>, fraction of all views on this map:<hr/>${percentSection}`;
				}
			},
			toolbox: {
				show: false,
			},
			label: { show: false },
			dataRange: {
				show:false,

			},
			series: [
				{
					name: 'Player Share',
					type: 'map',
					mapType: 'world',
					roam: true,
					zoom: 3,
					mapLocation: {
						y: 60
					},
					center: [0, 39.71],
					itemStyle: {
						emphasis: { areaColor: "#aaa", label: { show: false } },
						areaColor: "transparent",
					},
					data: []
				}
			]
		};

		option.series[0].data = data;
		return option;
	}

	populateFromCategories() {
		var ids = this.props.categories.map(c => c.id);
		var nameLookup = this.props.categories.reduce(function (acc, cur) { acc["" + cur.id] = cur.name; return acc; }, {});
		loadKnownDataSet("trends/countryViews", ids).then(result => {
			var max = 0;
			var total = 0;
			var winners = result.data.results.reduce(function (acc, cur) {
				const countryName = eChartsCountryNames[cur.key];
				if (!acc[countryName]) {
					acc[countryName] = {
						name: countryName, countryCode: cur.key, count: cur.count, categoryId: cur.group, categoryName: nameLookup[cur.group], percents: {}
					}
				}
				if (cur.count > acc[countryName].count) {
					acc[countryName].count = cur.count;
					acc[countryName].categoryId = cur.group;
					acc[countryName].categoryName = nameLookup[cur.group];
				}
				acc[countryName].percents[cur.group] = cur.count;

				max = Math.max(max, cur.count);
				total += cur.count;
				return acc;
			}, {})

			Object.values(winners).forEach(v => {
				var fillPercent = v.count / max;
				var offset = -Math.log(fillPercent) / 30;
				var color = colorFromName(v.categoryName, 0.5 + offset);
				v.itemStyle = { areaColor: color }
				v.percent = v.count / total;

				Object.keys(v.percents).forEach(key => v.percents[key] = v.percents[key] / total);
			})

			var data = Object.values(winners);
			this.setState({ data })

		})
	}
	componentDidMount() {
		this.populateFromCategories();

	}

	componentDidUpdate() {
		if (this.state.lastReportingPeriod !== reportingPeriod || this.state.lastCategories !== this.props.categories) {
			this.setState({ lastReportingPeriod: reportingPeriod, lastCategories: this.props.categories })
			this.populateFromCategories();
		}
	}

	render() {
		if (this.state.data === undefined) {
			return <SectionLoadingPlaceholder/>
		}
		const option = this.buildOption(this.state.data);
		return (
      <ReactEcharts
        option={option}
        notMerge={false}
        lazyUpdate={true}
        key={new Date().getTime()}
      />
		)
	}
}

export default ViewershipByRegion
