import React, { Component } from "react";
import MuiDownshift from "mui-downshift";
import Snackbar from '@material-ui/core/Snackbar';
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
	root: {
		position: 'relative',
		overflow: 'hidden',
	},
	appFrame: {
		width: 360,
		height: 360,
		backgroundColor: theme.palette.background.paper,
	},
	menuButton: {
		marginLeft: -12,
		marginRight: 20,
	},
	button: {
		marginBottom: theme.spacing.unit,
	},
	fab: {
		position: 'absolute',
		bottom: theme.spacing.unit * 2,
		right: theme.spacing.unit * 2,
	},
	fabMoveUp: {
		transform: 'translate3d(0, -46px, 0)',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.enteringScreen,
			easing: theme.transitions.easing.easeOut,
		}),
	},
	fabMoveDown: {
		transform: 'translate3d(0, 0, 0)',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.leavingScreen,
			easing: theme.transitions.easing.sharp,
		}),
	},
	snackbar: {
		position: 'absolute',
	},
	snackbarContent: {
		width: 360,
	},
});

class Search extends Component {
	constructor(props) {
		super(props);

		this.state = {
			items: this.props.itemsList,
			selectedItems: ["Search Games"],
			filteredItems: this.props.itemsList,
			handler: this.props.handler,
			open: false,
			duplicateOpen: false,
			vertical: 'top',
			horizontal: 'center',
		};
		this.handleStateChange = this.handleStateChange.bind(this)
	}

	handleClose = () => {
		this.setState({ open: false, duplicateOpen: false });
	};

	static defaultProps = {
		blurOnSelect: false
	};

	componentDidMount() {
		if (this.props.autoFocus) {
			this.input.focus();
		}
	}

	handleStateChange = changes => {
		// BUGFIX: Something is misconfigured deep within MuiDownshift that results in the down
		// arrow scrolling the browser to the end of the page when the dropdown is open.  This fixes that.
		// FUN FACT: Downshift helpfully sets changes.type to a descriptive string in dev,
		// and an integer in production.  So that your code only has to work in one place.
		// Issue filed here: https://github.com/downshift-js/downshift/issues/913
		if (changes.type && (
			changes.type === 3 || changes.type === 4
			|| (changes.type.indexOf && changes.type.indexOf("keydown_arrow") > -1)
		)) {
			window.scrollTo(0, 0);
		}
		

		if (typeof changes.inputValue === "string") {
			const filteredItems = this.state.items.filter(item =>
				item.label.toLowerCase().includes(changes.inputValue.toLowerCase())
			);
			this.setState({
				filteredItems,
				selectedItems: [...this.state.selectedItems, changes.selectedItem]
			});

			if (typeof changes.selectedItem !== "undefined"
				&& changes.selectedItem !== null) {

				// don't allow the same category to be added twice
				if (this.props.currentIds && this.props.currentIds.includes(changes.selectedItem.value)) {
					this.setState({ duplicateOpen: true });
					return;
				}

				this.state.handler(changes.selectedItem.value)

			}
		}
		if (this.input && this.props.blurOnSelect) {
			this.input.blur();
		}
	};

	render() {
		const { filteredItems } = this.state;

		const { open, duplicateOpen, vertical, horizontal } = this.state;

		return (
			<div style={{
				minWidth: 300,
				width: "50%",
				height: 175,
				paddingTop: 20,
				textAlign: "center",
				margin: "auto",
			}}>
				<div style={{
					backgroundColor: "rgba(255, 255, 255, 0.15)",
					height: 75,
					margin: 20,
					borderRadius: 3,
					padding: 20
				}}>
					<MuiDownshift
						items={filteredItems}
						onStateChange={this.handleStateChange}

						// getListItemKey={rowIndex => filteredItems[rowIndex].value}
						// keyMapper={rowIndex => filteredItems[rowIndex] && filteredItems[rowIndex].label}
						inputRef={node => {
							this.input = node;
						}}

					/>
				</div>
				<Snackbar
					anchorOrigin={{ vertical, horizontal }}
					open={open}
					autoHideDuration={4000}
					onClose={this.handleClose}
					ContentProps={{
						'aria-describedby': 'message-id',
					}}
					message={<span id="message-id">No data found</span>}
				/>
				<Snackbar
					anchorOrigin={{ vertical, horizontal }}
					open={duplicateOpen}
					autoHideDuration={4000}
					onClose={this.handleClose}
					ContentProps={{
						'aria-describedby': 'message-id',
					}}
					message={<span id="message-id">Already in comparison</span>}
				/>
			</div>
		);
	}
}

export default withStyles(styles)(Search);
