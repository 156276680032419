/*eslint-disable*/
import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';

import DatePicker from "react-datepicker";
import "./react-datepicker.css";

import { format, getDay, getDate, getDaysInMonth, addWeeks, lastDayOfISOWeek, startOfISOWeek, addDays,
	getMonth, addMonths, lastDayOfMonth, startOfMonth, parseISO, lastDayOfYear, addYears,
	differenceInCalendarMonths, differenceInCalendarWeeks, differenceInCalendarDays
} from 'date-fns'
import { registerLocale, setDefaultLocale } from "react-datepicker";
import {enGB} from 'date-fns/locale';
registerLocale('enGB', enGB)

// core components
import Button from "../../components/CustomButtons/Button.jsx";

import headerLinksStyle from "../../assets/jss/material-kit-react/components/headerLinksStyle.jsx";
import {chartParams, isEnterprise} from "../../data/App";

class ReportingPeriodSelector extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			open: false,
			pickerOpen: false,
			startDate:null,
			reportingPeriod: this.props.reportingPeriod,
			endDate: parseISO(this.props.endDate),
			periodCount: parseInt(this.props.periodCount)
		};
		this.handleReportingPeriodChange = this.handleReportingPeriodChange.bind(this);
		this.rangePresets = this.getRangePresets();
	}

	handleApply = event => {
		if (this.props.onScopeChange) {
			this.props.onScopeChange(this.state.reportingPeriod, this.state.periodCount, format(this.state.endDate, 'yyyy-MM-dd'))
		}
		this.setOpen(false);
		this.setPickerOpen(false);
	};

	handleCancel = event => {
		this.setOpen(false);
		this.sniffStateFromProps();
	};

	handleReportingPeriodChange = event => {
		var value = event.target.value || event.target.innerText.toLowerCase();
		const maxCount = chartParams[value].maxCount;
		const clampedPeriodCount = Math.min(maxCount, this.state.periodCount);

		this.setState({ reportingPeriod: value, periodCount: clampedPeriodCount }, () => {
			if (!this.props.allowCustomRange) {
				this.handleApply();
			}
		});
	};

	handlePeriodCountChange = event => {
		const maxCount = chartParams[this.state.reportingPeriod].maxCount;
		const count = Math.min(Math.max(event.target.value, 1), maxCount)
		this.setState({ periodCount: count });
	};

	handlePeriodOffsetChange = event => {
		const count = Math.max(event.target.value, 0)
		const endDate = this.getStartDateFromEndDateReportingPeriodCount(this.getYesterday(), this.state.reportingPeriod, count)
		this.setState({ endDate });
	};

	handleEndDateChange = event => {
		this.setState({ endDate: event.target.value });
	};

	handleRangePresetChange = p => (event) => {
		this.setState({ periodCount: p.n, endDate: p.e, pickerOpen:false }, this.handleApply);
	};

	setOpen(val, popAnchor) {
		this.setState({ open: val, popAnchor: popAnchor || this.state.popAnchor })
	};

	setPickerOpen(val) {
		this.setState({pickerOpen:val});
	};

	getStartDate() {
		switch (this.state.reportingPeriod) {
			case 'daily':
				return addDays(this.state.endDate, 1 - parseInt(this.state.periodCount));
			case 'weekly':
				return startOfISOWeek(addWeeks(this.state.endDate, 1 - parseInt(this.state.periodCount)));
			case 'monthly':
				return startOfMonth(addMonths(this.state.endDate, 1 - parseInt(this.state.periodCount)));

		}
	};

	getReportingPeriodByCount(count) {
		var period = "";
		switch (this.state.reportingPeriod) {
			case 'daily':
				period = "day";
				break;
			case 'weekly':
				period = "week";
				break;
			case 'monthly':
				period = "month";
				break;
		}
		if (parseInt(count) !== 1) {
			period += "s";
		}

		return period;
	};

	getStartDateFromEndDateReportingPeriodCount(endDate, reportingPeriod, periodCount) {
		// This needs a better name.  It's currently only used to get _endDate_
		// by subtracting periods from _yesterday_.
		// Leaving it named like this exposes it to clobbering by somebody who'e
		// trying to ensure that startDates always begin at the 1sd day of the week/month
		var startDate;
		switch (reportingPeriod) {
			case 'daily':
				startDate = addDays(endDate, - parseInt(periodCount));
				break;
			case 'weekly':
				startDate = addWeeks(endDate, - parseInt(periodCount));
				break;
			case 'monthly':
				startDate = addMonths(endDate, - parseInt(periodCount));
				break;
		}

		return startDate;
	}

	getPeriodCountFromDateRange(reportingPeriod, startDate, endDate) {
		switch (reportingPeriod) {
			case 'daily':
				return differenceInCalendarDays(endDate, startDate) + 1;
				break;
			case 'weekly':
				return differenceInCalendarWeeks(startOfISOWeek(endDate), startOfISOWeek(startDate)) + 1;
				break;
			case 'monthly':
				return differenceInCalendarMonths(startOfMonth(endDate), startOfMonth(startDate)) + 1;
				break;
		}
	}

	getPeriodOffsetCount() {
		return this.getPeriodCountFromDateRange(this.state.reportingPeriod, this.state.endDate, this.getYesterday()) - 1;
	}

	handleClickOpen = (event) => {
		this.setOpen(true, event.target);
		event.stopPropagation();
		event.preventDefault();
	};

	handleClose = (e) => {
		this.setOpen(false);
		this.handleApply();
	};


	renderFixed() {
		const { classes } = this.props;

		return (
			<FormControl className={classes.formControl}>
				<InputLabel htmlFor="reporting-period">Date Range</InputLabel>
				<Select
					value={this.props.reportingPeriod}
					onChange={this.handleReportingPeriodChange}
					inputProps={{
						name: 'reporting-period',
						id: 'reporting-period',
					}}
				>
					<MenuItem value="daily">Daily</MenuItem>
					<MenuItem value="weekly">Weekly</MenuItem>
					<MenuItem value="monthly">Monthly</MenuItem>
				</Select>
			</FormControl>
		);
	}

	getScopeDescription() {
		const selectedPreset = this.getSelectedRangePreset();
		if (selectedPreset) {
			return <div>{selectedPreset.t}</div>
		}

		const offset = this.getPeriodOffsetCount();
		return <div>
			Last {this.state.periodCount} {this.capitalize(this.getReportingPeriodByCount(this.state.periodCount))}

		</div>;
	}

	capitalize(text) {
		return text.substr(0, 1).toUpperCase() + text.substr(1)
	}

	getYesterday() {
		return addDays(new Date(), -1);
	}

	getRangePresets() {
		var yesterday = this.getYesterday();

		const presets = {
			"daily": [
				{ n: 7, e: yesterday, t: "Last 7 Days" },
				{ n: 31, e: yesterday, t: "Last 31 Days" },
				{ n: 7, e: lastDayOfISOWeek(addWeeks(yesterday, -1)), t: "Last Week" },
				{ n: getDate(yesterday), e: yesterday, t: "Month to Date" },
				{ n: getDaysInMonth(addMonths(yesterday, -1)), e: lastDayOfMonth(addMonths(yesterday, -1)), t: "Last Month" },
			],
			"weekly": [
				{ n: 6, e: yesterday, t: "Last 6 Weeks" },
				{ n: 10, e: yesterday, t: "Last 10 Weeks" },
			],
			"monthly": [
				{ n: 6, e: yesterday, t: "Last 6 Months" },
				{ n: 12, e: yesterday, t: "Last 12 Months" },
				{ n: getMonth(yesterday) + 1, e: yesterday, t: "Year to Date" },
				{ n: 12, e: lastDayOfYear(addYears(yesterday, -1)), t: "Last Year" },
			],
		}

		return presets;
	};

	getSelectedRangePreset() {
		const endDateString = format(this.state.endDate, 'yyyy-MM-dd');

		return this.rangePresets[this.state.reportingPeriod].find(p => format(p.e, 'yyyy-MM-dd') === endDateString && p.n === this.state.periodCount);
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.reportingPeriod != this.props.reportingPeriod
			|| prevProps.endDate != this.props.endDate
			|| prevProps.periodCount != this.props.periodCount) {
			this.sniffStateFromProps();
		}
	};

	sniffStateFromProps() {
		this.setState({
			reportingPeriod: this.props.reportingPeriod,
			endDate: parseISO(this.props.endDate),
			periodCount: parseInt(this.props.periodCount)
		}, () => {
			this.setState({ pickerOpen: this.getSelectedRangePreset() === undefined })
		});
	};

	renderRangePresets() {

		const presets = this.rangePresets;
		const selectedPreset = this.getSelectedRangePreset();

		return <List>
			{presets[this.state.reportingPeriod].map(p => {
				const selected = (p === selectedPreset);
				return <ListItem button onClick={this.handleRangePresetChange(p)} key={p.t}
					style={{ background: selected ? "rgba(255,255,255,0.2)" : "" }}
				>
					<ListItemText primary={p.t} />
				</ListItem>
			})}
		</List>
	}

	renderCustom() {
		const { classes } = this.props;
		const selectedPreset = this.getSelectedRangePreset();

		const open = this.state.open;
		const pickerOpen = this.state.pickerOpen || selectedPreset === undefined && isEnterprise();

		const maxCount = chartParams[this.state.reportingPeriod].maxCount;
		const onPickerChange=(date)=> {
			var periodCount = this.getPeriodCountFromDateRange(this.state.reportingPeriod, date[0], this.state.endDate);
			periodCount = Math.min(Math.max(periodCount, 1), maxCount)
			//console.log("onPickerChange", chartParams, this.state.reportingPeriod, periodCount, maxCount)
			this.setState({periodCount});
		}

		return (
			<React.Fragment>
				<FormControl id="reportingPeriodSelectorButton" onClick={this.handleClickOpen} className={classes.formControl} aria-describedby="reportingPeriodSelector">
					<InputLabel htmlFor="reporting-period-button">Date Range</InputLabel>

					<Select
						style={{ fontSize: 15 }}
						value={this.props.reportingPeriod}
						renderValue={() => this.getScopeDescription()}
						inputProps={{
							name: 'reporting-period-button',
							id: 'reporting-period-button',
						}}
						displayEmpty={true}
						open={false}
						onOpen={() => { }}
					>
					</Select>
				</FormControl>

				<Popover
					id="reportingPeriodSelector"
					open={open}
					onClose={this.handleClose}
					anchorEl={() => { return document.getElementById("reportingPeriodSelectorButton") }}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
				>
					<div style={{ minHeight: 165, overflow: "hidden" }}>
						<Grid container direction='column' justify='flex-start'>
							<Grid item xs={12}>
								<Grid container justify="space-between" alignItems={"flex-start"} spacing={16}>
									<Grid item>
										{this.renderRangePresets()}
									</Grid>
									<Grid item>
										{isEnterprise() ? <List>
											{["Daily", "Weekly", "Monthly"].map(p =>
												<ListItem button key={p} onClick={this.handleReportingPeriodChange}
													style={{ background: p.toLowerCase() === this.state.reportingPeriod ? "rgba(255,255,255,0.2)" : "" }}
												>
													<ListItemText primary={p} />
												</ListItem>
											)}
										</List> : <List>
											{["Daily"].map(p =>
												<ListItem button key={p} onClick={this.handleReportingPeriodChange}
														  style={{ background: p.toLowerCase() === this.state.reportingPeriod ? "rgba(255,255,255,0.2)" : "" }}
												>
													<ListItemText primary={p} />
												</ListItem>
											)}
										</List>}
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								{pickerOpen && <List>
									<Divider />
									<ListItem>
										<ListItemText style={{ padding: "0 5px" }}>
										Last
										</ListItemText>
											<TextField
												id="periodCount"
												type="number"
												min={0}
												max={maxCount}
												style={{width:40}}
												value={this.state.periodCount}
												onChange={this.handlePeriodCountChange}
											/>
										<ListItemText style={{padding:"0 5px"}}>
											{ this.getReportingPeriodByCount(this.state.periodCount)}
											&nbsp;(max={maxCount})
										</ListItemText>
									</ListItem>
									<ListItem>
										<DatePicker
											locale={enGB}
											inline
											selectsRange
											monthsShown={1}
											selected={this.getStartDate()}
											startDate={this.getStartDate()}
											endDate={this.state.endDate}
											maxDate={this.getYesterday()}
											peekNextMonth
											showMonthYearPicker={this.state.reportingPeriod === "monthly"}
											onChange={onPickerChange}
										></DatePicker>
									</ListItem>
								</List>}
							</Grid>
							<Grid item xs={12}>
								<Grid container justify="space-between" alignItems={"flex-start"} spacing={16}>
									<Grid item>
										{pickerOpen && <List>
											<Button variant="contained"
												style={{ marginLeft: "8px" }}
												onClick={this.handleCancel}
											>Cancel</Button>
										</List>}
									</Grid>
									<Grid item>
										{pickerOpen && <List>
											<Button variant="contained"
												style={{ backgroundColor: "hsl(44, 100%, 40%)", marginRight:"8px" }}
												onClick={this.handleApply}
											>Apply</Button>
										</List>}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</div>
				</Popover>
			</React.Fragment>
		);
	}

	render() {
		return this.props.allowCustomRange ? this.renderCustom() : this.renderFixed();
	}
}

export default withStyles(headerLinksStyle)(ReportingPeriodSelector);
