import React, {Fragment} from "react";

import { buildCompareUrl, dateStringFromDay, dateStringFromMonth, dateStringFromWeek, gameServerData, getCategoryById, getCategoryData, getReportingPeriodQueryString, getReportingPeriodSingular, groupMultiSummed, groupSummed, isEnterprise, joinLookup, setGameServerData } from "../../data/App";
import { loadCategoryData, getGamesList, getGameServerSessions, calculatePeriodShares, getGameServerInfo, extractColorList } from "../../data/Data";
import CompoundPieList from '../../components/Charts/CompoundPieList.jsx'
import EmbedAvatarList from '../../components/Embed/EmbedAvatarList'


import { Redirect } from "react-router-dom";
import StackableCompareChart from "../../components/Charts/StackableCompareChart.jsx";
import SectionLoadingPlaceholder from '../../components/Dashboard/SectionLoadingPlaceholder'
import {PageHeader} from '../../components/styles'

const sectionDescriptions = {
	"shares": "How many times clips are shared on each platform. The higher the number, the more popular a platform is for sharing clips. The number shown is a percentage of all clips shared across all platforms.",
	"viewers": "How many times clips shared on a given platform were viewed. It's a good indication of where people are going to view clips. The number shown is a percentage of all clips viewed across all platforms.",
	"attention": "How many views a shared clip is expected to see after being shared on the platform in question. These are average counts of views per share, rather than a percentage.",
	"sessionCount": "Percent breakdown of Game Server session counts, among the 50 most popular servers."
}

export const sumFields = ["sessionCount", "userCount", "totalSeconds"];


export const populateServerData = async () => {
  if (gameServerData && gameServerData.dataLoaded) {
    return gameServerData
  }

  const period = getReportingPeriodSingular().toLowerCase();
  const values = await getGameServerSessions()
  const data = values?.[0]?.data ?? values.data;
  if (!data) {
    throw new Error('Missing server sessions data')
  }
  data.forEach(r => r[period] = r.period);
  const serverResult = transformServers(data);
  const serverInfo = await getGameServerInfo(serverResult.topIds)
  if (!serverInfo) {
    throw new Error(`Missing server info for topIds`)
  }

  const serverLookup = extractColorList(serverInfo.data.items, "name", "id");
  serverInfo.data.items.forEach(server => serverLookup[server.id].categoryId = server.category.categoryId);

  const categoryResult = transformCategories(serverResult, serverInfo.data.items, serverLookup)

  setGameServerData({
    ...serverResult,
    ...categoryResult,
    serverLookup,
    dataLoaded: true
  });

  return gameServerData
}

export const transformServers = (serverData, tagLookup, loadCount = 50, otherThreshold = 10) => {
	// TODO
	const period = "day";

	// Date + ONE additional column
	var zeroFillMissingDays = (rows, dateColumn, keyColumn, valueColumns) => {
		console.time("zeroFill")

		// 20240210: ids can come through as string or int now.  We'll force everything to string...
		var findUniques = (rows, col) => Object.keys(rows.reduce((c, v) => {
			c[v[col]] = true;
			return c;
		}, {}))
			.map(v => "" + v);	// ... here.


		// ... but we'll need to put day/week/month numbers back to ints
		var dates = findUniques(rows, dateColumn).map(v => parseInt(v));
		var keys = findUniques(rows, keyColumn);

		var emptyRow = valueColumns.reduce((c, v) => { c[v] = 0; return c }, {});
		//console.log("empty", emptyRow)

		var newRows = dates.reduce((c, date) => {
			// filtering down before the loop drops us from 12ms to 2ms
			var working = rows.filter(r => r[dateColumn] === date);

			keys.forEach(k => {
				if (!working.some(r => r[dateColumn] === date && r[keyColumn] === k)) {
					var row = JSON.parse(JSON.stringify(emptyRow));
					row[dateColumn] = date;
					row[keyColumn] = k;
					c.push(row);
				}
			});

			return c;

		}, []);

		console.timeEnd("zeroFill")
		return rows.concat(newRows);
	}
	serverData = zeroFillMissingDays(serverData, period, "gameServerId", ["sessionCount", "userCount", "totalSeconds"]);

	console.time("transformServerData")
	const groupedByServerId = groupSummed(serverData, "gameServerId", ["sessionCount", "userCount", "totalSeconds"]);

	// Top game servers by session count
	const topIds = groupedByServerId
		.sort((a, b) => b.sessionCount - a.sessionCount)
		.slice(0, loadCount).map(g => g.gameServerId);

	// We'll need to go away here and come back once we've loaded information for the top servers...
	return {
		topIds,
		serverData: serverData,
		serverCompareData: groupedByServerId,
	}
}

export const combineOtherRows = (list, topIds, idField, nameField, groupFields, sumFields) => {
	// console.log("combine", list, topIds, idField, nameField, groupFields, sumFields)
	var groupKey = (row) => {
		return groupFields.map(f => row[f]).join(",");
	}

	var reduced = list.reduce((c, row) => {
		if (topIds.indexOf(row[idField]) > -1) {
			c.rows.push(row);
		} else if (c.other[groupKey(row)]) {
			sumFields.forEach(f => c.other[groupKey(row)][f] += row[f]);
		} else {
			c.other[groupKey(row)] = JSON.parse(JSON.stringify(row));
			c.other[groupKey(row)][idField] = 0
			c.other[groupKey(row)][nameField] = "Other"

			//console.log("new other", groupKey(row), c.other[groupKey(row)])
		}
		return c;
	}, { other: {}, rows: [] });

	// tack our new "Other" rows onto the end of the list
	var otherRows = Object.keys(reduced.other).map(k => reduced.other[k]);

	return reduced.rows.concat(otherRows);
};

export const transformCategories = (serverResult, serverInfo, serverLookup, loadCount = 32, otherThreshold = 10) => {

	const addDateColumn = (list, period = "day") => {
		list.forEach(row => {
			switch (period) {
				case "week":
					row.date = dateStringFromWeek(row[period]);
					break;
				case "month":
					row.date = dateStringFromMonth(row[period]);
					break;
				case "day":
				default:
					row.date = dateStringFromDay(row[period]);
					break;
			}
		});
	};

	// TODO
	const period = "day";
	const groupByName = true;

	// unpack...
	var { topIds, serverData, serverCompareData } = serverResult;

	// Pull category id down out of its object
	serverInfo.forEach(s => { s.categoryId = s.category.categoryId });

	// Until we can get good server data for Roblox...
	serverInfo = serverInfo.filter(s => s.categoryId !== 76);

	// Group servers by name & category, in case we want to de-dupe them
	// NOTE: we don't end up using the output of this reduce, but
	// depend on writing .parentId inside.  We could do this without the
	// side effect, but it'd mean spinning through the list a 2nd time.
	// So, for the linter's benefit:
	// const serverNames =
	serverInfo.sort((a, b) => a.id - b.id)
		.reduce((c, v) => {
			var n = c.find(s => s.categoryId === v.categoryId && s.name === v.name);
			if (!n) {
				n = JSON.parse(JSON.stringify(v));
				n.subIds = [];
				c.push(n);
			}
			n.subIds.push(v.id);

			// write "parent" back to original
			v.parentId = n.id;

			return c;
		}, []);

	if (groupByName) {
		var reparent = (data) => {
			data.forEach(r => {
				r.childId = r.gameServerId;
				var server = serverInfo.find(s => s.id === r.gameServerId);
				if (server) {
					r.gameServerId = server.parentId;
				}
			})
		}
		reparent(serverData);
		reparent(serverCompareData);

		serverData = groupMultiSummed(serverData, ["gameServerId", period], ["sessionCount", "userCount", "totalSeconds"]);
		serverCompareData = groupSummed(serverCompareData, "gameServerId", ["sessionCount", "userCount", "totalSeconds"]);

		topIds = serverCompareData
			.sort((a, b) => b.sessionCount - a.sessionCount)
			.slice(0, loadCount).map(g => g.gameServerId);
	}


	// Add category info to server data
	joinLookup(serverData, serverInfo, "gameServerId", "id");
	joinLookup(serverCompareData, serverInfo, "gameServerId", "id");

	// strip anything we couldn't join, since it'll break the charts
	serverData = serverData.filter(s => (s.category));
	serverCompareData = serverCompareData.filter(s => (s.category));

	// group by categories (and date)
	const groupedByCategoryId = groupSummed(serverData, "categoryId", ["sessionCount", "userCount", "totalSeconds"]);
	const groupedByCategoryIdPeriod = groupMultiSummed(serverData, ["categoryId", period], ["sessionCount", "userCount", "totalSeconds"]);
	const groupedByCategoryIdServer = groupMultiSummed(serverData, ["categoryId", "gameServerId"], ["sessionCount", "userCount", "totalSeconds"])

	joinLookup(groupedByCategoryIdServer, serverInfo, "gameServerId", "id");


	// Normalize everything to percents
	const serverDataAsPercents = calculatePeriodShares(serverData, ["sessionCount", "userCount", "totalSeconds"], period)
		.sort((a, b) => a.date > b.date ? 1 : -1);	// 1:-1 sorts in date order

	// Each server needs to know about its "games" (of which there will happen to only be one).
	// We're doing it before calculating percents so that it will be usable in detail pages too.
	serverCompareData.forEach(s => {
		var game = getCategoryById(s.categoryId);
		if (game) {
			// copy the game info so we don't overwrite it globally
			game = JSON.parse(JSON.stringify(game));
			game.sessionCount = 100;
			s.avatar = game.avatar;
			s.color = game.color;

			// a list of games for this server.  (Yeah, there's only ever one, but the data structure wants a list)
			s.games = [game];
		}

		// daily counts for this server
		var days = serverDataAsPercents.filter(ss => ss.gameServerId === s.gameServerId);
		s.sessionCounts = days.map(d => d.sessionCount);

	});

	const serverCompareDataAsPercents = calculatePeriodShares(serverCompareData, ["sessionCount", "userCount", "totalSeconds"])

	const categoryDataAsPercents = calculatePeriodShares(groupedByCategoryIdPeriod, ["sessionCount", "userCount", "totalSeconds"], period)
		.sort((a, b) => a.date > b.date ? 1 : -1);	// 1:-1 sorts in date order

	const categoryCompareDataAsPercents = calculatePeriodShares(groupedByCategoryId, ["sessionCount", "userCount", "totalSeconds"])
		.sort((a, b) => b.sessionCount - a.sessionCount);

	const groupedByCategoryIdServerAsPercents = calculatePeriodShares(groupedByCategoryIdServer, ["sessionCount", "userCount", "totalSeconds"], "categoryId")
		.sort((a, b) => a.categoryId - b.categoryId);

	addDateColumn(serverData, period);
	addDateColumn(serverDataAsPercents, period);
	addDateColumn(categoryDataAsPercents, period);

	const topCategoryIds = groupedByCategoryId.map(c => c.categoryId);

	// Group long tail into "Other" servers
	serverLookup[0] = {
		"id": 0,
		"name": "Other",
		"avatar": "./.png",
		"data": [],
		"color": "hsl(0, 0%, 40%)"
	}

	const topServerSlice = topIds.slice(0, otherThreshold);
	const otherGroupedServerDataAsPercents = combineOtherRows(serverDataAsPercents, topServerSlice, "gameServerId", "name", ["date"], sumFields)

	// TODO: find a way to safely move this up to before we calculate percents.  Might not be able to...
	// extra info per game
	categoryCompareDataAsPercents.forEach(c => {
		var game = getCategoryById(c.categoryId);
		if (game) {
			Object.assign(c, game);
		}

		// a list of servers for this game
		var servers = groupedByCategoryIdServerAsPercents.filter(g => g.categoryId === c.categoryId);
		servers.forEach(s => { Object.assign(s, serverLookup[s.id]) });
		c.servers = servers;

		// daily counts for this game
		var days = categoryDataAsPercents.filter(r => r.categoryId === c.categoryId);
		c.sessionCounts = days.map(d => d.sessionCount);
	});

	return {
		serverData,
		serverCompareData,
		serverDataAsPercents,
		serverCompareDataAsPercents,
		categoryDataAsPercents,
		categoryCompareDataAsPercents,
		otherGroupedServerDataAsPercents,
		topCategoryIds,
		serverLookup,
		topIds
	};
};

class ServersLandingPage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			dataLoaded: false,
			search: false,
			games: new Map(),
			platforms: new Map(),
			categories: [],
		}
	}

	componentDidMount() {
		if (!isEnterprise()) {
			this.setState({ redirectTo: '/trends/' });
			return;
		}

    document.body.scrollTop = document.documentElement.scrollTop = 0;

		loadCategoryData().then(() => {
			this.populateTop();
		});
	}

	componentDidUpdate() {
		if (this.state.dataLoaded && this.state.lastReportingDescription !== this.buildUrl()) {
			this.populateTop();
		}
	}

	searchHandler = (compareId) => {
		this.props.history.push(buildCompareUrl('platform-compare', compareId))
	}

	buildQueryString = () => {
		return getReportingPeriodQueryString(window.location.search, { r: this.state.retentionPeriod });
	}
	isEmbed() {
		return window.location.pathname.indexOf("/embed/") === 0;
	}


	async populateTop() {
		this.setState({ dataLoaded: false })
    try {
      await getGamesList()
      await populateServerData()
      this.setState({
        ...gameServerData,
        dataLoaded: true,
        lastReportingDescription: this.buildUrl(),
      });
    } catch (err) {
      console.error('Error populating top list:', err)
    }
	}

	buildUrl() {
		if (this.isEmbed()) {
			return `/embed/servers/${this.props.match.params.section}?${this.buildQueryString()}`;
		}
		return `/servers/?${this.buildQueryString()}`;
	}

	buildTitle(ids) {
		return "Servers | Medal Trends";
	}

	renderServerComparisonChart = (metric) => {
		return <StackableCompareChart
			//data={this.state.serverDataAsPercents}
			data={this.state.otherGroupedServerDataAsPercents}
			seriesList={Object.values(this.state.serverLookup)}
			ids={this.state.ids}
			isEmbed={this.isEmbed()}
			metric={metric}
			idField="gameServerId"
			subtitle={sectionDescriptions[metric]}
			section={metric}
			useLogScale={false}
			stacked={true}
		/>
	}

	renderGameComparisonChart = (metric) => {
		return <StackableCompareChart
			data={this.state.categoryDataAsPercents}
			seriesList={getCategoryData()}
			ids={this.state.ids}
			isEmbed={this.isEmbed()}
			metric={metric}
			idField="categoryId"
			subtitle={sectionDescriptions[metric]}
			section={metric}
			useLogScale={false}
			stacked={true}
		/>
	}

	renderGameList(metric) {
		return <CompoundPieList
			// games={this.state.games}
			data={this.state.categoryCompareDataAsPercents}
			//gameOrder={this.state.topIds}
			gameOrder={this.state.topCategoryIds}
			dataMember="servers"
			idField="categoryId"
			toolIdField="gameServerId"
			nameField="name"
			toolNameField="name"
			//valueField="used for name in tooltip, and the value of the percent in the main list. wtf"
			reportingColumn="sessionCount"
			metric={metric}
			linkPath="server-compare"
			isEmbed={this.isEmbed()}
			section={metric}
			showTrend={true}
			title="Most Popular Servers by Game"
			subtitle="Relative game representation for game servers across the top played games during the selected date range. Percentages represent the server fraction within the game in question."
			toolTipHeader="Servers by game"
			toolTipTotal="of total game sessions"
		/>
	}

	renderPlatformList(metric) {
		return <CompoundPieList
			// games={this.state.games}
			data={this.state.serverCompareDataAsPercents}
			gameOrder={this.state.topIds}
			hideIndex={true}
			dataMember="games"
			idField="gameServerId"
			toolIdField="categoryId"
			nameField="name"
			toolNameField="name"

			//valueField="category_name"
			reportingColumn="sessionCount"
			linkPath="server-detail"
			metric={metric}
			isEmbed={this.isEmbed()}
			section={metric}
			showTrend={true}
			title="Top Game Servers"
			subtitle="Relative percentages of server sessions across all games during the selected date range."
			toolTipHeader=" "
			toolTipTotal="of total server sessions across all games"
			pageSize={10}
		/>

	}

	renderEmbedded() {
		var section = this.props && this.props.match && this.props.match.params
			&& this.props.match.params.section && this.props.match.params.section.toLowerCase
			&& this.props.match.params.section.toLowerCase();	// Javascript!


		switch (section) {
			case "shares":
			case "viewers":
			case "attention":
				return this.renderServerComparisonChart(section);

			case "platform-shares":
				return this.renderPlatformList("platform-shares");

			case "game-shares":
				return this.renderGameList("game-shares");

			default:
				return <div></div>;
		}

	}


	renderEmbeddedWithGames() {

		return (
			<div>
				<EmbedAvatarList ids={this.state.ids} />
				{this.renderEmbedded()}
			</div>
		)

	}

	render() {
		if (this.state.redirectTo !== undefined) {
			return <Redirect push to={this.state.redirectTo} />;
		}

		if (!this.state.dataLoaded) {
			return (
				<Fragment>
          <SectionLoadingPlaceholder/>
          <SectionLoadingPlaceholder/>
          <SectionLoadingPlaceholder/>
          <SectionLoadingPlaceholder/>
        </Fragment>
			)
		}

		if (this.isEmbed()) {
			return this.renderEmbedded();
		}

		return (
			<Fragment>
        {/* @todo temp disabled, was broken? */}
        {/*<Search*/}
        {/*  itemsList={getCategoryData().map((game) => ({ label: game.name, value: game.id }))}*/}
        {/*  handler={this.searchHandler}*/}
        {/*/>*/}
        <PageHeader>
          Server Trends
          <span>
            NOTE: Percentage shares shown below represent observations within the Medal.tv platform, and therefore may differ from numbers generated from other sources.
          </span>
        </PageHeader>
        {this.renderGameList("sessionCounts")}
        {this.renderGameComparisonChart("sessionCount")}
        {this.renderPlatformList("sessionCounts")}
        {this.renderServerComparisonChart("sessionCount")}
      </Fragment>
		);
	}
}

export default ServersLandingPage;
export {
	sectionDescriptions
}
