import React, {useContext, useMemo} from 'react'
import PropTypes from 'prop-types'
import SelectInput from './'
import {CategoryContext} from '../../context'

const propTypes = {
  autoFocus: PropTypes.bool,
  value: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  excludedIds: PropTypes.array,
}

const TrendingGamesInput = ({autoFocus, value, onSelect = () => {}, excludedIds = []}) => {
  const {trendingCategories} = useContext(CategoryContext)

  const options = useMemo(() => {
    return trendingCategories
      .filter(category => !excludedIds.includes(category.categoryId))
      .map(category => {
        return {
          label: category.categoryName,
          value: category.categoryId,
          image: category.categoryThumbnail
        }
      })
  }, [trendingCategories, excludedIds])

  return (
    <SelectInput
      autoFocus={autoFocus}
      placeholder="Search trending games"
      value={value}
      options={options}
      onSelect={onSelect}
    />
  )
}

TrendingGamesInput.propTypes = propTypes

export default TrendingGamesInput
