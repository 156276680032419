import React, {useContext, useState} from 'react'
import {Button, InputField} from '@get-wrecked/simple-components'
import Modal from '../../components/Modal'
import {AuthContext, ModalContext} from '../../context'
import styled from 'styled-components'
import {ErrorText, InputLabelWrapper} from '../../components/styles'

const LoginModal = () => {
  const {isLoggingIn, login} = useContext(AuthContext)
  const {closeModal} = useContext(ModalContext)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const onLogin = async () => {
    try {
      await login(username, password)
      closeModal('login-modal')
    } catch (err) {
      setErrorMessage(err.message)
      console.error(err)
    }
  }

  return (
    <Modal
      id="login-modal"
      size="xsmall"
      header="Login"
    >
      <Body>
        {
          errorMessage?.length > 0 &&
          <ErrorText style={{textAlign: 'center'}}>
            {errorMessage}
          </ErrorText>
        }
        <InputLabelWrapper>
          Username
          <InputField
            autoFocus={true}
            placeholder="Enter your Medal username"
            size="large"
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
        </InputLabelWrapper>
        <InputLabelWrapper>
          Password
          <InputField
            type="password"
            placeholder="Enter your Medal password"
            size="large"
            value={password}
            onChange={e => setPassword(e.target.value)}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                onLogin()
              }
            }}
          />
        </InputLabelWrapper>
        <Actions>
          <Button
            variant="primary"
            size="large"
            disabled={isLoggingIn || !username?.length || !password?.length}
            onClick={onLogin}
            style={{
              minWidth: '100px'
            }}
          >
            Login
          </Button>
        </Actions>
      </Body>
    </Modal>
  )
}

export default LoginModal

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`
