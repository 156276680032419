import React from 'react';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import basicsStyle from "../../../assets/jss/material-kit-react/views/componentsSections/basicsStyle.jsx";

// import Card from "components/Card/Card.jsx";
// import CardContent from '@material-ui/core/CardContent';
// import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
// import PieChart from '../../../components/Charts/PieChart'
import ReactEcharts from 'echarts-for-react';


import { getChartPresets } from '../../../data/Chart'


// enhance the style object
let style = Object.assign({
	card: {
		flex: 1,
		padding: 0,
		margin: 10,
		backgroundColor: '#212121',
	}
},
	{
		cardContent: {
			padding: 0,
			margin: 0,
		}
	},
	{
		cardHeader: {
			borderBottom: '0.01em solid grey',
		}
	},
	{
		gridContainer: {
			flexGrow: 1
		}
	},
	basicsStyle);


class MarketSharePie extends React.Component {

	process() {
		const sorted = [...this.props.games.values()].sort((a, b) => {
			var aData = a[this.props.dataMember];
			var bData = b[this.props.dataMember];
			return aData[aData.length - 1] < bData[bData.length - 1] ? 1 : -1
		});

		// NOTE: This is identical to the same method in Process, except for this hardcoded 10.
		// TODO: extract it elsewhere
		var gameDataDisplay = sorted.slice(0, 10);

		if (this.props.dataMember === "avgSessionDurationMinutes") {
			// These values will be in minutes instead of percents.  We'll need to get % values
			var total = 0;
			gameDataDisplay.forEach(g => {
				const val = g[this.props.dataMember][g[this.props.dataMember].length - 1]
				total += val;
			})

			gameDataDisplay = gameDataDisplay.map(g => {
				const val = g[this.props.dataMember][g[this.props.dataMember].length - 1]
				var copy = JSON.parse(JSON.stringify(g));
				copy[this.props.dataMember] = [(100 * val / total).toFixed(2)]
				copy.latestAvgDurationMinutes = val;
				return copy;
			})
		}

		return gameDataDisplay;
	}

	render() {
		if (this.props.games === undefined) {
			return (
				<div></div>
			)
		}

		const topGames = this.process()

		let data = []
		let graph = JSON.parse(JSON.stringify(getChartPresets().pieChart));
		let percentTotal = 0;
		for (var i = 0; i < topGames.length; i++) {
			var game = topGames[i];
			var percent = game[this.props.dataMember][game[this.props.dataMember].length - 1];	// most recent value is at the end of the list
			percentTotal += percent;
			data.push({ name: game.name, value: percent, itemStyle:{color:game.color} })
		}
		data.push({ name: "All Others", value: (100.0 - percentTotal).toFixed(2) * 1, itemStyle: { color: '#cccccc' }, })
		graph.series[0].data = data;


		return (
			<div>
				<Grid item xs={12} sm={12} md={12} style={{ "margin": "-50px 0 40px 0" }}>
					<ReactEcharts
						option={graph}
						notMerge={true}
						lazyUpdate={true}
					/>
				</Grid>
			</div>
		)
	}
}

export default withStyles(style)(MarketSharePie)
