import moment from 'moment'
import echarts from 'echarts';

import {
  reportingPeriod,
  reportingPeriods,
  getReportDates,
  getGameOrPlaceholder,
  dataMembers,
  getPeriodCount,
  valueFormatMap,
  displayUnitMap, categoryData,
} from "./App"
import {colors} from '@get-wrecked/simple-components'

export function adjustBrightness(color, amount) {
  // Convert hex to RGB
  function hexToRgb(hex) {
    let alpha = false, r, g, b;
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
      r = parseInt(hex.substring(1, 3), 16);
      g = parseInt(hex.substring(3, 5), 16);
      b = parseInt(hex.substring(5, 7), 16);
    } else if (hex.length === 9) {
      alpha = true;
      r = parseInt(hex.substring(1, 3), 16);
      g = parseInt(hex.substring(3, 5), 16);
      b = parseInt(hex.substring(5, 7), 16);
    }
    return alpha ? `rgba(${r}, ${g}, ${b}, ${parseInt(hex.substring(7, 9), 16) / 255})` : `rgb(${r}, ${g}, ${b})`;
  }

  // Clamp values between 0 and 255
  function clamp(value) {
    return Math.min(255, Math.max(0, value));
  }

  // Adjust brightness for RGB
  function adjustRgb(color, amount) {
    let [r, g, b] = color.match(/\d+/g).map(Number);
    r = clamp(r + amount);
    g = clamp(g + amount);
    b = clamp(b + amount);
    return `rgb(${r}, ${g}, ${b})`;
  }

  // Adjust brightness for RGBA
  function adjustRgba(color, amount) {
    let [r, g, b, a] = color.match(/[\d.]+/g).map(Number);
    r = clamp(r + amount);
    g = clamp(g + amount);
    b = clamp(b + amount);
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }

  // Adjust brightness for HSL
  function adjustHsl(color, amount) {
    let [h, s, l] = color.match(/[\d.]+/g).map(Number);
    l = clamp(l + amount);
    return `hsl(${h}, ${s}%, ${l}%)`;
  }

  // Adjust brightness for HSLA
  function adjustHsla(color, amount) {
    let [h, s, l, a] = color.match(/[\d.]+/g).map(Number);
    l = clamp(l + amount);
    return `hsla(${h}, ${s}%, ${l}%, ${a})`;
  }

  // Determine color format
  if (color.startsWith('#')) {
    // Hex format
    color = hexToRgb(color);
  }

  if (color.startsWith('rgb')) {
    // RGB format
    return adjustRgb(color, amount);
  } else if (color.startsWith('rgba')) {
    // RGBA format
    return adjustRgba(color, amount);
  } else if (color.startsWith('hsl')) {
    // HSL format
    return adjustHsl(color, amount);
  } else if (color.startsWith('hsla')) {
    // HSLA format
    return adjustHsla(color, amount);
  } else {
    throw new Error('Unsupported color format');
  }
}

const getChartTooltipFormatter = (valueFormatter) => function (params) {

	valueFormatter = valueFormatter || function(value){
		let percentChar = params.isNotPercent ? "" : "";
		return value + percentChar
	};

	const altTitle = getReportingPeriodNames(false);
	const colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + color + '"></span>';

	let rez = altTitle[params[0].dataIndex] + '<br>';
	let i = 0;
	// create default params object to account for missing data points and prepopulated it
	for (i = 0; i < params.length; i++) {
		if (typeof params[i] === "undefined") {
			params[i] = { "seriesName": "N/A", "data": [""], "value": "", "color": "#eee" }
		}
	}

	// let percentChar = params.isNotPercent ? "" : "";
	for (i = 0; i < params.length - 1; i = i + 2) {
		if (params[i].data[0] !== "") {
			// rez += colorSpan(params[i].color) + ' ' + (params[i].seriesName.length > 20 ? `${params[i].seriesName.substring(0, 20)}...` : params[i].seriesName) + ': ' + params[i].data + percentChar + ' <span style="font-weight: bolder">' + params[i + 1].data + '</span></br>'
			rez += colorSpan(params[i].color) + ' ' + (params[i].seriesName.length > 20 ? `${params[i].seriesName.substring(0, 20)}...` : params[i].seriesName) + ': ' + valueFormatter(params[i].data) + ' <span style="font-weight: bolder">' + params[i + 1].data + '</span></br>'
		}
	}

	return rez;
}

const getChartPresets = (periodNames) => {
	return {

		compare: {
			baseOption: {
				title: {
					text: 'text value'
				},
				tooltip: {
					trigger: 'axis'
				},
				legend: {
					data: [
						'Pubg',
						'FORTNITE'
					]
				},
				grid: {
					containLabel: true,
				},
				axisLine: {
					show: false
				},
				xAxis: [
					{
						type: 'category',
						boundaryGap: false,
						data: periodNames || getReportingPeriodNames(true)
					}
				],
				yAxis: [
					{
						show: false,
						gridIndex: 0,
						min: 0,
						max: 80
					},
					{
						type: 'value'
					}
				],
				series: [
					{
						name: '',
						type: 'line',
						color: '',
						symbol: 'circle',
						lineStyle: {
							width: 4
						},
						data: []
					},
					{
						showInLegend: false,
						name: '',
						data: [],
						type: 'custom'
					}
				]
			}
		},
		compareStacked: {
			baseOption: {
				title: {
					text: 'text value'
				},
				tooltip: {
					trigger: 'axis'
				},
				legend: {
					data: [
						'Pubg',
						'FORTNITE'
					]
				},
				grid: {
					containLabel: true,
				},
				axisLine: {
					show: false
				},
				xAxis: [
					{
						type: 'category',
						boundaryGap: false,
						data: periodNames || getReportingPeriodNames(true)
					}
				],
				yAxis: [
					{
						show: false,
						gridIndex: 0,
						min: 0,
						max: 100
					},
					{
						type: 'value'
					}
				],
				series: [
					{
						name: '',
						type: 'line',
						stack: 'Total',
						areaStyle: {opacity:0.2},
						color: '',
						symbol: 'circle',
						lineStyle: {
							width: 4
						},
						data: []
					},
					{
						showInLegend: false,
						name: '',
						data: [],
						type: 'custom'
					}
				]
			}
		},
		spark: {
			baseOption: {
				title: {
					text: 'text value'
				},
				tooltip: {
					trigger: 'axis'
				},

				grid: {
					containLabel: false,
					height: 'auto',
				},
				axisLine: {
					show: false
				},
				xAxis: [
					{
						show: false,
						axisLabel: { show: true, color:"transparent" },
						axisLine: { show: false },
						axisTick: {show: false},
						type: 'category',
						boundaryGap: false,
						data: periodNames || getReportingPeriodNames(true)

					}
				],
				yAxis: [
					{
						show: false,
						gridIndex: 0,
						min: 0,
						max: 100,
						boundaryGap: false,
					},
					{
						type: 'value'
					}
				],
				series: [
					{
						name: '',
						type: 'line',
						color: '',
						symbol: 'circle',
						//symbolSize: 8,
						lineStyle: {
							width: 4
						},
						clip: false,
						data: []
					},
					{
						showInLegend: false,
						name: '',
						data: [],
						type: 'custom'
					}
				]
			}
		},
		compareBar: {
			option: {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},
				xAxis: {
					show: false,
					data: ['Change'],
					axisTick: { show: false },
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					height: 250,
					containLabel: true
				},
				yAxis: [
					{
						type: 'value',
						show: true,
						axisTick: { show: true },
						splitLine: {           // 分隔线
							show: true,        // time/vertical increment bars
							// onGap: null,
							lineStyle: {       // 属性lineStyle（详见lineStyle）控制线条样式
								color: ['rgba(255,255,255,0.2)'],
								width: 1,
								type: 'dot'
							}
						},
						axisLine: { show: false },
						axisLabel: {
							show: true,
							borderWidth: 10,
							width: 20,
							fontSize: 15,
							color: 'grey'
						}
					}
				],
				series: [{
					name: '',
					type: 'bar',
					itemStyle: {
						normal: {
							color: 'white'
						}
					},
					barWidth: 30,
					data: [0]
				}]
			}
		},
		pieChart: {
			tooltip: {
				trigger: 'item',
				formatter: "{b} : {c}%"
			},
			calculable: true,
			series: [
				{
					type: 'pie',
					radius: '55%',
					center: ['50%', '60%'],
					data: []
				}
			]
		}
	}
}


echarts.registerTheme('medal_chart_theme', {
	// Full map default background
	backgroundColor: 'transparent',

	// Default swatch
	color: [colors.success['10'], '#45D2FF', colors.brand.primary['30'], colors.error['10'], colors.brand.secondary['10'],
		'#ff69b4', '#ba55d3', '#cd5c5c', '#ffa500', '#40e0d0',
		'#1e90ff', '#ff6347', '#7b68ee', '#00fa9a', '#ffd700',
		'#6699FF', '#ff6666', '#3cb371', '#b8860b', '#30e0e0'],

	// title
	title: {
		show: false,
		x: '50',                    // 'center' ¦ 'left' ¦ 'right'
		// ¦ {number}（x coordinate, unit px）
		y: 'top',                   // Vertical placement, the default is the top of the full image, optional：
		// 'top' ¦ 'bottom' ¦ 'center'
		// {number} (y coordinate, unit px)
		//textAlign: null           // Horizontal alignment, defaults to automatic adjustment based on x settings
		backgroundColor: 'rgba(0,0,0,0)',
		borderColor: '#ccc',        // Title border color
		borderWidth: 0,             // Title border line width, unit px, default is 0 (no border)
		padding: 5,                 // Title border line width, unit px, default is 0 (no border)，
		// Accept the array separately set the upper right lower left margin, the same css)
		itemGap: 10,                // The primary and secondary titles are vertically spaced, in units of px, with a default of 10.
		textStyle: {
			fontSize: 18,
			fontWeight: 'bolder',
			color: '#c9c9c9'          // Main title text color
		},
		subtextStyle: {
			color: '#aaa'             // Subtitle text color
		}
	},

	// legend
	legend: {
		show: false,
		orient: 'horizontal',      // 布局方式，默认为水平布局，可选为：
		// 'horizontal' ¦ 'vertical'
		x: 'center',               // 水平安放位置，默认为全图居中，可选为：
		// 'center' ¦ 'left' ¦ 'right'
		// ¦ {number}（x坐标，单位px）
		y: 'top',                  // 垂直安放位置，默认为全图顶端，可选为：
		// 'top' ¦ 'bottom' ¦ 'center'
		// ¦ {number}（y坐标，单位px）
		backgroundColor: 'rgba(0,0,0,0)',
		borderColor: '#ccc',       // 图例边框颜色
		borderWidth: 0,            // 图例边框线宽，单位px，默认为0（无边框）
		padding: 5,                // 图例内边距，单位px，默认各方向内边距为5，
		// 接受数组分别设定上右下左边距，同css
		itemGap: 10,               // 各个item之间的间隔，单位px，默认为10，
		// 横向布局时为水平间隔，纵向布局时为纵向间隔
		itemWidth: 20,             // 图例图形宽度
		itemHeight: 14,            // 图例图形高度
		textStyle: {
			color: '#333'          // 图例文字颜色
		}
	},

	// range
	dataRange: {
		orient: 'vertical',        // 布局方式，默认为垂直布局，可选为：
		// 'horizontal' ¦ 'vertical'
		x: 'left',                 // 水平安放位置，默认为全图左对齐，可选为：
		// 'center' ¦ 'left' ¦ 'right'
		// ¦ {number}（x坐标，单位px）
		y: 'bottom',               // 垂直安放位置，默认为全图底部，可选为：
		// 'top' ¦ 'bottom' ¦ 'center'
		// ¦ {number}（y坐标，单位px）
		backgroundColor: 'rgba(0,0,0,0)',
		borderColor: '#ccc',       // 值域边框颜色
		borderWidth: 0,            // 值域边框线宽，单位px，默认为0（无边框）
		padding: 5,                // 值域内边距，单位px，默认各方向内边距为5，
		// 接受数组分别设定上右下左边距，同css
		itemGap: 10,               // 各个item之间的间隔，单位px，默认为10，
		// 横向布局时为水平间隔，纵向布局时为纵向间隔
		itemWidth: 20,             // 值域图形宽度，线性渐变水平布局宽度为该值 * 10
		itemHeight: 14,            // 值域图形高度，线性渐变垂直布局高度为该值 * 10
		splitNumber: 5,            // 分割段数，默认为5，为0时为线性渐变
		color: ['#1e90ff', '#f0ffff'],//颜色
		//text:['高','低'],         // 文本，默认为数值文本
		textStyle: {
			color: '#333'          // 值域文字颜色
		}
	},

	// 提示框
	tooltip: {
		trigger: 'item',           // 触发类型，默认数据触发，见下图，可选为：'item' ¦ 'axis'
		showDelay: 0,             // 显示延迟，添加显示延迟可以避免频繁切换，单位ms
		hideDelay: 100,            // 隐藏延迟，单位ms
		transitionDuration: 0.4,  // 动画变换时间，单位s
		backgroundColor: colors.background['first-layer'],     // 提示背景颜色，默认为透明度为0.7的黑色
		borderColor: colors.stroke['0A16'],       // 提示边框颜色
		borderRadius: 8,           // 提示边框圆角，单位px，默认为4
		borderWidth: 1,            // 提示边框线宽，单位px，默认为0（无边框）
		//formatter: '{a0}: {c0} ⬇</br>{a1}: {c1} ⬇',

		// This can be replaced after creating the option
		formatter: getChartTooltipFormatter(),

		padding: 8,                // 提示内边距，单位px，默认各方向内边距为5，
		// 接受数组分别设定上右下左边距，同css
		axisPointer: {            // 坐标轴指示器，坐标轴触发有效
			type: 'line',         // 默认为直线，可选为：'line' | 'shadow'
			lineStyle: {          // 直线指示器样式设置
				color: 'rgba(255,255,255,0.5)',
				width: 1,
				type: 'solid'
			},
			shadowStyle: {                       // 阴影指示器样式设置
				width: 'auto',                   // 阴影大小
				color: 'rgba(150,150,150,0.3)'  // 阴影颜色
			}
		},
		textStyle: {
      fontFamily: '"DM Sans", sans-serif',
			color: colors.text['30'],
			align: 'left'
		}
	},

	// 网格
	grid: {
		top: 10,
		bottom: 10,
		left: 10,
		x: 0,
		y: 0,
		height: 134,
		backgroundColor: 'rgba(0,0,0,0)',
		borderWidth: 1,
		borderColor: '#ccc'
	},

	// 类目轴
	categoryAxis: {
		position: 'bottom',     // position
		nameLocation: 'end',    // Axis name position, support 'start' | 'end'
		boundaryGap: true,      // Category start and end blanks
		axisLine: {             // x axis edge
			show: false,          // The default display, the property show control is displayed or not
			lineStyle: {          // Property lineStyle controls line styles
				color: '#48b',
				width: 2,
				type: 'solid'
			}
		},
		axisTick: {            // Axis small mark
			show: true,       // 属性show控制显示与否，默认不显示
			interval: 'auto',
			// onGap: null,
			inside: false,    // grid
			length: 5,         // length
			lineStyle: {       // lineStyle
				color: '#333',
				width: 1
			}
		},
		axisLabel: {           // 坐标轴文本标签，详见axis.axisLabel
			show: true,
			interval: 'auto',
			rotate: 0,
			margin: 8,
			// formatter: null,
			textStyle: {       // 其余属性默认使用全局文本样式，详见TEXTSTYLE
				color: '#c9c9c9'
			}
		},
		splitLine: {           // 分隔线
			show: true,        // time/vertical increment bars
			// onGap: null,
			lineStyle: {       // 属性lineStyle（详见lineStyle）控制线条样式
				color: ['rgba(255,255,255,0.2)'],
				width: 1,
				type: 'dot'
			}
		},
		splitArea: {           // 分隔区域
			show: false,       // 默认不显示，属性show控制显示与否
			// onGap: null,
			areaStyle: {       // 属性areaStyle（详见areaStyle）控制区域样式
				color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)']
			}
		}
	},

	// Numeric axis default parameters
	valueAxis: {
		position: 'left',       // position
		nameLocation: 'end',    // Axis name position, support 'start' | 'end'
		nameTextStyle: {},      // Axis text style, default to global style
		boundaryGap: [0, 0],    // Value start and end blanks
		splitNumber: 5,         // Number of segments, default is 5
		axisLine: {             // Coordinate axis
			show: false,          // y axis edge
			lineStyle: {          // Property lineStyle controls line styles
				color: '#48b',
				width: 2,
				type: 'solid'
			}
		},
		axisTick: {            // Axis small mark
			show: false,       // The property show control is displayed or not, the default is not displayed.
			inside: false,    // Control whether the small mark is in the grid
			length: 5,         // Attribute length control line length
			lineStyle: {       // Property lineStyle controls line styles
				color: '#333',
				width: 1
			}
		},
		axisLabel: {           // Axis text labels, see axis.axisLabel
			show: true,
			rotate: 0,
			margin: 8,
			// formatter: null,
			textStyle: {       // The rest of the properties use the global text style by default. See TEXTSTYLE for details.
				color: '#c9c9c9'
			}
		},
		splitLine: {            // Separation line
			show: true,           // horizontal increment bars
			lineStyle: {          // Property lineStyle (see lineStyle) controls line styles
				color: ['rgba(255,255,255,0.2)'],
				width: 1,
				type: 'dot'
			}
		},
		splitArea: {            // Separate area
			show: false,          // The default is not displayed, the property show control is displayed or not
			areaStyle: {          // Property areaStyle (see areaStyle) control area style
				color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)']
			}
		}
	},

	// Line chart default parameters
	line: {
		itemStyle: {
			normal: {
				// color: 各异,
				label: {
					show: false
					// position: Default adaptive, horizontal layout is 'top', vertical layout is 'right', optional
					//           'inside'|'left'|'right'|'top'|'bottom'
					// textStyle: null      // The global text style is used by default. See TEXTSTYLE for details.
				},
				lineStyle: {
					width: 2,
					type: 'solid',
					shadowColor: 'rgba(0,0,0,0)', //Default transparency
					shadowBlur: 5,
					shadowOffsetX: 3,
					shadowOffsetY: 3
				}
			},
			emphasis: {
				// color: 各异,
				label: {
					show: false
					// position: 默认自适应，水平布局为'top'，垂直布局为'right'，可选为
					//           'inside'|'left'|'right'|'top'|'bottom'
					// textStyle: null      // 默认使用全局文本样式，详见TEXTSTYLE
				}
			}
		},
		//smooth : false,
		//symbol: null,         // 拐点图形类型
		symbolSize: 12,          // axis dot size
		//symbolRotate : null,  // 拐点图形旋转控制
		showAllSymbol: false    // 标志图形默认只有主轴显示（随主轴标签间隔隐藏策略）
	},

	textStyle: {
		decoration: 'none',
		fontFamily: 'Arial, Verdana, sans-serif',
		fontFamily2: '微软雅黑',    // IE8- The font is fuzzy and does not support different fonts, and an additional one is specified.
		fontSize: 12,
		fontStyle: 'normal',
		fontWeight: 'normal'
	},

	// Default flag graphic type list
	symbolList: [
		'circle', 'rectangle', 'triangle', 'diamond',
		'emptyCircle', 'emptyRectangle', 'emptyTriangle', 'emptyDiamond'
	],
	loadingText: 'Loading...',
	// Computable feature configuration, island, hint color
	calculable: false,              // Turn off computable features by default
	calculableColor: 'rgba(255,165,0,0.6)',       // Drag and drop prompt border color
	calculableHolderColor: '#ccc', // Calculatable placeholder tip color
	nameConnector: ' & ',
	valueConnector: ' : ',
	animation: true,
	animationThreshold: 2500,       // Animated element threshold, resulting in more than 2500 graphics primitives
	addDataAnimation: true,         // Whether the dynamic data interface starts animation effect
	animationDuration: 2000,
	animationEasing: 'ExponentialOut'    //BounceOut
});


//calculate min and max grid layout
const calculateGridMinMax = (result, xCount) => {
	let max = 0;
	let min = 100;
	result.series.forEach(series => {
		series.data.forEach((value, i) => {
			if (!isNaN(value) && (xCount === undefined || i < xCount)) {
				value = value * 1
				if (value > max) max = value;
				if (value < min) min = value;
			}
		})
	})

	result.yAxis[0].min = (min * 0.8).toFixed(2) * 1;
	result.yAxis[0].max = (max * 1.25).toFixed(2) * 1;

	return result
}

const cloneDeep = (object) => {
	return JSON.parse(JSON.stringify(object))
}
const buildGameDataSetComplexFromBase = (gameSet, calculator, baseOption, chartType, displayUnit = '% Of Total') => {
	var result = baseOption;

	// These base option objects have 2 empty series in them already.
	// [0] is a template for line data, [1] is for annotations.
	// In the loop below, we'll use clone and concat to tack
	// a copy of the two rows into the series array, then
	// we'll fill in data.
	let series = cloneDeep(result.series)
	result.series = [];

	// populate the result object with the series data points
	let seriesCounter = 0;

	// cast collection to Array
	let set = [].concat(gameSet);
	set.forEach(gameSet => {
		if (typeof gameSet !== "undefined") {
			result.series = result.series.concat(cloneDeep(series))

			// this calculator should return an array of values for the game in question.
			// As in: [10,20,30,40,50]
			let data = calculator(gameSet);
      const seriesItem = result.series[seriesCounter]
			seriesItem.data = typeof data !== 'undefined' ? data : [];

      // *- aesthetic -*
			seriesItem.color = typeof gameSet.color !== 'undefined' ? adjustBrightness(gameSet.color, -10) : '';
      seriesItem.lineStyle = { // line thickness
        width: 2,
      }
      seriesItem.symbolSize = 8 // Custom size for the markers
      seriesItem.itemStyle = {
        borderWidth: 2, // Width of the border around markers
        borderColor: adjustBrightness(seriesItem.color, 10), // Color of the border around markers
      }

			seriesItem.name = typeof gameSet.name !== 'undefined' ? gameSet.name : '';
			seriesItem.abv = typeof gameSet.abv !== 'undefined' ? gameSet.abv : '';
			seriesItem.id = gameSet.id;
			seriesItem.data.forEach((value, index) => {
				result.series[seriesCounter + 1].data[index] = index === 0 ? '' : (
					seriesItem.data[index - 1] === value ? displayUnit : (
						seriesItem.data[index - 1] < value ? `${displayUnit} (↑)` : `${displayUnit} (↓)`
					)
				)
			})
      result.series[seriesCounter + 1].name = gameSet.name !== '' ? `${gameSet.name}` : ''
      result.series[seriesCounter + 1].subsetId = gameSet.id;
		}
		seriesCounter = seriesCounter + 2
	})

	switch (chartType) {
		case "compareStacked":
			// stacked area charts need to show 100% bounds
			return result;
		default:
			// calculate grid min/max and return
			return calculateGridMinMax(result);
	}

}
const buildGameDataSetComplex = (gameSet, calculator, chartType, periodNames) => {
	chartType = chartType || "compare";

	// clone the default object
	let result = JSON.parse(JSON.stringify(getChartPresets(periodNames)[chartType].baseOption));

	return buildGameDataSetComplexFromBase(gameSet, calculator, result, chartType);
}


const buildGameDataSet = (gameSet, dataMember, chartType) => {
	dataMember = dataMember || dataMembers.USAGE_PERCENTS;
	chartType = chartType || "compare";

	const calculator = (set) => {
		let data = typeof set[dataMember] === "object" ? set[dataMember].slice() : [];
		return data;
	}

	let baseOption = JSON.parse(JSON.stringify(getChartPresets()[chartType].baseOption));
	let valueFormatter = valueFormatMap[dataMember];
	baseOption.tooltip.formatter = getChartTooltipFormatter(valueFormatter);

	return buildGameDataSetComplexFromBase(gameSet, calculator, baseOption, chartType, displayUnitMap[dataMember]);
}




/// Given our populated categoryData array of game objects, return a list
/// of ids in descending order of popularity, including only popular games.
const getTopGames = (categoryData) => {
	var filtered = categoryData.filter(g => g.usagePercents && g.usagePercents[0] >= 0.1)
	var sortedByUsage = filtered.sort((a, b) => b.usagePercents[0] - a.usagePercents[0]);
	var totalCount = Math.floor(sortedByUsage.length / 8) * 8;	// fit to a row of 4x2.

	var topGames = sortedByUsage.map(g => g.id).slice(0, totalCount)

	return topGames;
};

const buildGameDataArray = (games, dataMember) => {
	let ids = getTopGames(categoryData)

	let counter = 0;

	// a games array = the 2 games to compare
	let gameArrayCounter = -1;
	let gamesArray = [];

	// a row = 4 comparisons
	let rowGameArrayCounter = -1
	let rowArray = [];

	ids.forEach(id => {
		if ((counter % 2) === 0) {
			gameArrayCounter++;
			gamesArray[gameArrayCounter] = [];
			if (rowArray.length === 0 || (gameArrayCounter % 4) === 0) {
				rowGameArrayCounter++;
				rowArray[rowGameArrayCounter] = [];
			}
			rowArray[rowGameArrayCounter].push(gamesArray[gameArrayCounter]);
		}

		gamesArray[gameArrayCounter].push(getGameOrPlaceholder(games, id));

		counter++;
	})

	// transform the individual compare sets to 'option' objects that a chart can render
	rowArray.forEach(row => {
		row.forEach((gameSet, index) => {
			var option = buildGameDataSet(gameSet, dataMember, "compare")
			row[index] = option;
		})
	})

	return rowArray;
}


const buildCompareBarChart = (args) => {
	// init the popularity compare bar chart
	const compareBar = getChartPresets().compareBar.option;
	const barWidth = 140;

	let series = cloneDeep(compareBar.series);
	compareBar.series = [];
	if (typeof args !== "undefined"
		&& args !== null
		&& typeof args.series !== "undefined"
		&& args.series.length !== 0) {
		args.series.forEach(category => {
			if (typeof category.id !== "undefined") {
				const dataSet = cloneDeep(series[0]);
				if (dataSet !== undefined) {
					var changeValue = ((((category.data[category.data.length - 1] + .0001) / (category.data[0] + .0001) - 1) * 100).toFixed(1)) * 1; // relative percentage, whole week
					if (changeValue > 999) {
						changeValue = 100;	// don't show 0 plays -> 5 plays as 5,000,000,000%
					}
					// assign the name value
					dataSet.name = category.abv
					// assign bar values
					dataSet.data = [(changeValue.toFixed(1)) * 1]
					// assign bar colors
					dataSet.itemStyle.normal.color = category.color;
					// set bar width
					dataSet.barWidth = barWidth / args.series.length
					compareBar.series.push(dataSet);
				}
				else {
				}
			}
		})
	}

	return compareBar;
}

const addCompareEmphasisLine = (option, lineIndex) => {

	// simulate minor splitlines with extended ticks
	option.xAxis[0].axisTick = {
		show: true,
		interval: 'auto',
		inside: true,
		length: 312,
		lineStyle: {
			color: ['rgba(255,255,255,0.1)'],
			width: 1,
			type: 'dot'
		}
	}

	// Steal the major splitLine to show "today"
	option.xAxis[0].splitLine = {
		show: true,
		interval: function (index, value) {
			if (index === lineIndex) {
				return true;
			}

			return false;
		},
		lineStyle: {          // Property lineStyle (see lineStyle) controls line styles
			color: ["rgba(255,255,255,1)"],
			width: 1,
			type: 'solid'
		}
	};
}

const buildExtrapolatedLineChart = (games, chartType, dataMember, extrapolationPeriodCount) => {

	var names = getExtrapolatedReportingPeriodNames(extrapolationPeriodCount, true);
	//var option = getChartBaseOption(names);

	var option = getChartPresets(names)[chartType].baseOption

	var predictionCalculator = (gameSet) => {
		return gameSet[dataMember].slice()
	}


	option = buildGameDataSetComplexFromBase(games, predictionCalculator, option)


	// TEST: new series for each trendline
	var trendSeries = option.series.filter(s => s.showInLegend !== false).map(s => {
		var game = games.find(g => g.id === s.id);
		var series = JSON.parse(JSON.stringify(s));
		series.data = game.trend[dataMember].points.slice();
		series.id = "t" + series.id;
		series.symbol = 'none'
		series.showInLegend = false;

		s.lineStyle.width = 0;

		return series;
	});

	option.series = option.series.concat(trendSeries);
	option = calculateGridMinMax(option, getPeriodCount() + extrapolationPeriodCount);


	// Steal the major splitLine to show "today"
	option.xAxis[0].splitLine = {
		show: true,
		interval: function (index, value) {
			if (index === getPeriodCount() - 1) {
				return true;
			}

			return false;
		},
		lineStyle: {          // Property lineStyle (see lineStyle) controls line styles
			color: ["rgba(255,255,255,1)"],
			width: 1,
			type: 'solid'
		}
	};

	const altTitle = getExtrapolatedReportingPeriodNames(extrapolationPeriodCount, false);
	option.tooltip.formatter = function (params) {
		const colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + color + '"></span>';

		let i = 0;
		// create default params object to account for missing data points and prepopulated it
		for (i = 0; i < params.length; i++) {
			if (typeof params[i] === "undefined") {
				params[i] = { "seriesName": "N/A", "data": [""], "value": "", "color": "#eee" }
			}
		}

		let isPrediction = (params.length > 0 && isNaN(parseInt(params[0].seriesId)));
		let rez = `<b>${isPrediction ? "Prediction" : "Actual"}</b><br>${altTitle[params[0].dataIndex]}<br>`;

		var buildLine = function (series) {
			if (params[i].data[0] !== "") {
				return colorSpan(params[i].color) + ' '
					+ (series.seriesName.length > 20 ? `${series.seriesName.substring(0, 20)}...` : series.seriesName)
					+ ': ' + series.data.toFixed(2) + '% </br>'
			}
			return "";
		}

		for (i = 0; i < params.length; i = i + 1) {
			var series = params[i];
			if (!isPrediction && !isNaN(parseInt(series.seriesId))) {
				rez += buildLine(series);
			} else if (isPrediction && series.seriesId.substr(0, 1) === 't') {
				rez += buildLine(series);
			}
		}

		return rez;
	}

	return option;
}

const incrementDateByReportingPeriod = (date) => {
	switch (reportingPeriod) {
		case reportingPeriods.WEEKLY:
			date.setDate(date.getDate() + 7)
			break;

		case reportingPeriods.MONTHLY:
			date.setMonth(date.getMonth() + 1)
			break;

		case reportingPeriods.DAILY:
		default:
			date.setDate(date.getDate() + 1)
			break;
	}

	return date;
}

const getDateLabel = (date, asSingleCharacter) => {
	var name = "";

	switch (reportingPeriod) {
		case reportingPeriods.WEEKLY:
			if (asSingleCharacter) {
				name = `W${moment(date).format("W")}`;
			}
			else {
				var week = moment(moment(date).format("YYYY-[W]WW-1"))
				name = `Week ${week.format("[W]WW: D MMM")} - ${week.add(6, "d").format("D MMM")}`;
			}
			break;

		case reportingPeriods.MONTHLY:
			name = moment(date).format('M/Y');

			break;

		case reportingPeriods.DAILY:
		default:
			name = moment(date).format('M/D/Y');

	}

	return name;
}

const getReportingPeriodNames = (asSingleCharacter) => {
	var dates = getReportDates();

	if (!dates) {
		return [];
	}
	var names = dates.map(d => getDateLabel(d, asSingleCharacter));
	return names.slice(names.length - getPeriodCount());
}

const getExtrapolatedReportingPeriodNames = (extrapolationPeriodCount, asSingleCharacter) => {
	var names = getReportingPeriodNames(asSingleCharacter);

	var dates = getReportDates();
	var date = new Date(dates[dates.length - 1]);
	for (var i = 0; i < extrapolationPeriodCount; i++) {
		date = incrementDateByReportingPeriod(date);
		names.push(getDateLabel(date, asSingleCharacter));
	}

	return names;
}

const populateSharesByPlatform = (data) => {
	var processed = data.results.reduce((c, v) => { c[capitalizePlatform(v.key)] = v.count; return c; }, {});
	return processed;
	// return { "Bot": 19, "Discord": 20298, "Facebook": 266, "Twitter": 358, "Web": 13, "Yandex": 3 };
};


const populateCategorySharePercentageByPlatform = (data) => {
	// This builds a structure like {62: 37085, 76: 10829}
	var categoryTotals = data.results.reduce((c, r) => { c[r.group] = c[r.group] + r.count || r.count; return c }, {});

	var cats = {};
	data.results.forEach((row) => {
		var categoryKey = "" + row.group;
		var cat = cats[categoryKey] || {};
		var platform = capitalizePlatform(row.key);
		cat[platform] = (row.count / categoryTotals[row.group] * 100).toFixed(2) * 1;

		cats[categoryKey] = cat;
	});

	return cats;
}


const capitalizePlatform = function (platform) {
	return platform.substr(0, 1).toUpperCase() + platform.substr(1);
};



export {
	buildGameDataSet,
	buildGameDataSetComplex,
	buildGameDataSetComplexFromBase,
	getChartTooltipFormatter,
	calculateGridMinMax,

	getChartPresets,
	getTopGames,
	buildGameDataArray,
	buildCompareBarChart,
	buildExtrapolatedLineChart,
	addCompareEmphasisLine,
	populateSharesByPlatform,
	populateCategorySharePercentageByPlatform,
	getReportingPeriodNames,
	getExtrapolatedReportingPeriodNames,
	getDateLabel,
	incrementDateByReportingPeriod,
};

