import React from 'react'

const FacebookIcon = ({size = 32, color = '#ffffff'}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 667 665" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M667 333.667C667 149.572 517.761 0.333354 333.667 0.333354C149.572 0.333354 0.333313 149.572 0.333313 333.667C0.333313 489.993 107.953 621.163 253.141 657.184V435.528H184.405V333.667H253.141V289.775C253.141 176.319 304.485 123.732 415.872 123.732C436.989 123.732 473.428 127.872 488.335 132.013V224.352C480.467 223.524 466.803 223.109 449.825 223.109C395.168 223.109 374.049 243.813 374.049 297.643V333.667H482.928L464.224 435.528H374.049V664.559C539.097 644.624 667 504.091 667 333.667Z"
        fill={color}
      />
    </svg>
  )
}

export default FacebookIcon
