import React from "react";
// nodejs library that concatenates classes
// @material-ui/core components

import { buildCompareUrl, dataMetrics, getCategoryById, getReportingPeriodQueryString, getReportingPeriodSingular, getSubgameRankingMethodKey, groupMultiSummed, groupSummed, isEnterprise, joinLookup } from "../../../data/App";
import { loadCategoryData, calculatePeriodShares, extractColorList } from "../../../data/Data";
import EmbedAvatarList from '../../../components/Embed/EmbedAvatarList'


import { Redirect } from "react-router-dom";
import { loadSubgameData, zeroFillMissingSubgameDays } from "../SubgamesLandingPage";
import { combineOtherRows } from "../../Servers/ServersLandingPage";
import PieList from "../../../components/Charts/PieList";
import StackableCompareChart from "../../../components/Charts/StackableCompareChart.jsx";
import LoadMoreButton from '../../../components/LoadMoreButton'
import SectionLoadingPlaceholder from '../../../components/Dashboard/SectionLoadingPlaceholder'
//import { combineOtherRows } from "../Servers/ServersLandingPage";

const sectionDescriptions = {
	"shares": "How many times clips are shared on each platform. The higher the number, the more popular a platform is for sharing clips. The number shown is a percentage of all clips shared across all platforms.",
	"viewers": "How many times clips shared on a given platform were viewed. It's a good indication of where people are going to view clips. The number shown is a percentage of all clips viewed across all platforms.",
	"attention": "How many views a shared clip is expected to see after being shared on the platform in question. These are average counts of views per share, rather than a percentage.",
	"sessionCount": "Percent breakdown of clip subgame counts, among the 50 most popular subgames.",
	"views": "Percent breakdown of clip subgame counts, among the 50 most popular subgames.",
	"likes": "Percent breakdown of clip subgame counts, among the 50 most popular subgames.",
}

export const transformSubgameCompare = (processed, id, metric = "sessionCount", includeNoneSubgame = false, loadCount = 50, otherThreshold = 10) => {
	// TODO
	//const period = "day";
	const period = getReportingPeriodSingular().toLowerCase();

	console.log("period?", period, getReportingPeriodSingular())

	const {
		countColumns,
	} = processed;
	//const categoryFilteredSubgameData = processed.subgameData.filter(t => t.categoryId === id)
	var subgameData = processed.subgameData.filter(t => t.categoryId === id)

	// Ditch emojis
	subgameData.forEach(t => { t.subgameName = t.subgameName.replace(/[^0-9a-z_ ]/gi, '') })

	// Filter garbage subgames out
	const game = getCategoryById(id);
	const gameNameFilter = game.name.toLowerCase().replace(/ /g, '')
	//const autos = autoSubgames[id];
	subgameData = subgameData.filter(t =>
		t.subgameName.toLowerCase() !== gameNameFilter
		&& (includeNoneSubgame || t.subgameName !== "")
	);

	//const subgameNames = subgameData.map(s => ({ subgameId: s.subgameId , subgameName:s.subgameName}));

	// group down to unique subgameIds.
	const subgameNames = subgameData.reduce((c, v) => { c.find(s => s.subgameId === v.subgameId) || c.push({ subgameId: v.subgameId, subgameName: v.subgameName }); return c; }, [])
	const emptySubgame = subgameNames.find(s => s.subgameName === "");
	if (emptySubgame) {
		emptySubgame.subgameName = `${game.name} (no subgame)`;
	}

	// console.log("subgameData", subgameData, subgameNames)

	// Subgame popularity list over the period, expandable
	const groupedBySubgame = groupSummed(subgameData, "subgameId", countColumns)
		.sort((a, b) => b[metric] - a[metric]);
	const groupedBySubgameAsPercents = joinLookup(calculatePeriodShares(groupedBySubgame, countColumns, ""), subgameNames, "subgameId", "subgameId");

	const groupedBySubgameJoined = joinLookup(groupedBySubgame, subgameNames, "subgameId", "subgameId");
	// TODO: sort by id or something to do colors, so that they stay consistent when we switch metric

	// console.log("groupedBySubgameAsPercents", groupedBySubgameJoined, groupedBySubgameAsPercents)
	// create a new color lookup just for these subgames
	// NOTE: We sort by sessionCount here so that colors are consistent between metrics.
	const subgameColorLookup = extractColorList(groupedBySubgameJoined.sort((a, b) => b.sessionCount - a.sessionCount), "subgameName", "subgameId");
	subgameColorLookup["Other"] = {
		"id": "Other",
		"name": "Other",
		"avatar": "./.png",
		"data": [],
		"color": "hsl(0, 0%, 40%)"
	}

	// const emptySubgame = Object.values(subgameColorLookup).find(s => s.subgameName === "");
	// if (emptySubgame) {
	// 	emptySubgame.subgameName = "[none]"
	// }


	// Top subgames by session count
	// NOTE that we sort again to account for the above.
	const topSubgames = groupedBySubgameJoined
		.sort((a, b) => b[metric] - a[metric])
		.slice(0, loadCount).map(g => g.subgameId);


	// Note that we grouped by subgame & content before zero filling.
	subgameData = zeroFillMissingSubgameDays(subgameData, period, "subgameId", countColumns, id);

	const groupedBySubgamePeriod = groupMultiSummed(subgameData, ["subgameId", period], countColumns);

	// Top subgames per day chart
	var groupedBySubgamePeriodAsPercents = calculatePeriodShares(groupedBySubgamePeriod, countColumns, period)
	const topSubgameSlice = topSubgames.slice(0, otherThreshold);

	const otherGroupedSubgamePeriodAsPercents = combineOtherRows(groupedBySubgamePeriodAsPercents, topSubgameSlice, "subgameId", "subgameId", [period], countColumns)
		.sort((a, b) => a[period] < b[period] ? 1 : -1)	// 1:-1 sorts in date order

	// Here, we're rearranging our data so that it'll make it into a stacked chart in the right order.
	const subgameChartOrderWithColors = topSubgameSlice.map(t => subgameColorLookup[t]).reverse();
	subgameChartOrderWithColors.unshift(subgameColorLookup["Other"]);


	return {
		groupedBySubgameAsPercents,
		subgameChartOrderWithColors,
		otherGroupedSubgamePeriodAsPercents,
		subgameColorLookup,
	}
}

class SubgamesGameCompareSection extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			dataLoaded: false,
			search: false,
			games: new Map(),
			platforms: new Map(),
			categories: [],
			expandSubgames: false,
		}
	}

	componentDidMount() {
		if (!isEnterprise()) {
			this.setState({ redirectTo: '/trends/' });
			return;
		}

		//console.log("section mount", this.props.id, this.props.ids, this.props, this.state)

		loadCategoryData().then(() => {
			this.populateById(this.props.id);
		});
	}

	componentDidUpdate() {
		//console.log("section update", this.state.dataLoaded, this.props.id, this.props, this.state, this.state.lastReportingDescription,this.buildUrl(), this.state.lastReportingDescription !== this.buildUrl())
		if (this.state.dataLoaded && this.state.lastReportingDescription !== this.buildUrl()) {
			//console.log("should update")
			this.populateById(this.props.id);
		}
		else {
			//console.log("SHOULD NOT UPDATE")
		}
	}

	searchHandler = (compareId) => {
		this.props.history.push(buildCompareUrl('platform-compare', compareId))
	}

	expandSubgamesClick = () => {
		this.setState({ expandSubgames: true })
	}

	buildQueryString = () => {
		//console.log("subgame section build query", this.state.includeNoneSubgame, this.props.includeNoneSubgame, this.props.includeNoneSubgame ? 1 : 0, true, false)
		return getReportingPeriodQueryString(window.location.search, {
			sr: getSubgameRankingMethodKey(this.props.subgameRankingMethod),
			si: this.props.includeNoneSubgame ? 1 : 0,
		});
	}
	isEmbed() {
		return window.location.pathname.indexOf("/embed/") === 0;
	}

	populateById(id) {
		this.setState({ dataLoaded: false })

		loadSubgameData(id).then((processed) => {
			const { includeNoneSubgame, subgameRankingMethod } = this.props;
			console.log("populateById", includeNoneSubgame, subgameRankingMethod, this.props)
			const compare = transformSubgameCompare(processed, id, subgameRankingMethod, includeNoneSubgame)
			//console.log("compare", id, compare)
			this.setState({
				...processed,
				...compare,
				dataLoaded: true,
				lastReportingDescription: this.buildUrl(),
			});
		});
	}

	buildUrl() {
		if (this.isEmbed()) {
			return `/embed/platforms/${this.props.match.params.section}?${this.buildQueryString()}`;
		}
		return `/platforms/?${this.buildQueryString()}`;
	}

	buildTitle(ids) {
		return "Platforms | Medal Trends";
	}

	renderSubgameComparisonChart = (metric) => {
		var game = getCategoryById(this.props.id);
		// console.log("game", game, this.state.subgameColorLookup, metric)
		console.log("renderSubgameComparisonChart", this.state.otherGroupedSubgamePeriodAsPercents, this.state.subgameChartOrderWithColors)
		const title = `${game.name} Top Subgames`
		return <StackableCompareChart
			data={this.state.otherGroupedSubgamePeriodAsPercents}
			seriesList={this.state.subgameChartOrderWithColors}
			ids={this.state.ids}
			isEmbed={this.isEmbed()}
			metric={metric}
			idField="subgameId"
			title={title}
			subtitle={sectionDescriptions[metric]}
			tooltipTitle={dataMetrics[metric].title}
			section={metric}
			useLogScale={false}
			stacked={true}
			toolTipThreshold={0.5}
			preserveTooltipOrder={true}
		>
			{this.state.expandSubgames ? this.renderSubgameList(metric) : this.renderSubgameExpander()}
		</StackableCompareChart>
	}

	renderSubgameExpander() {
		return (
      <LoadMoreButton
        label="View List"
        onClick={this.expandSubgamesClick}
      />
    )
	}
	renderSubgameList(metric) {
		//console.log("subgame list", this.state.groupedBySubgameAsPercents, this.state.topIds, this.state)

		const orderedIds = this.state.groupedBySubgameAsPercents.map(t => t.subgameId);

		return <PieList
			data={this.state.groupedBySubgameAsPercents}
			orderedIds={orderedIds}
			hideIndex={true}
			dataMember="games"
			idField="subgameId"
			colorLookup={this.state.subgameColorLookup}
			toolIdField="categoryId"
			nameField="subgameName"
			toolNameField="subgameName"
			//valueField="category_name"
			reportingColumn={metric}
			linkDisabled={false}
			linkPath="subgame-detail"
			linkBuilder={(path, subgame, qs) => `/${path}/${this.props.id}/${subgame}/?${this.buildQueryString()}`}
			metric={metric}
			isEmbed={this.isEmbed()}
			section={metric}
			showTrend={false}
			toolTipHeader=" "
			toolTipTotal="of total subgames for this game"
			pageSize={10}
		/>

	}

	renderEmbedded() {
		var section = this.props && this.props.match && this.props.match.params
			&& this.props.match.params.section && this.props.match.params.section.toLowerCase
			&& this.props.match.params.section.toLowerCase();	// Javascript!

		switch (section) {
			default:
				return <div></div>;
		}
	}


	renderEmbeddedWithGames() {
		return (
			<div>
				<EmbedAvatarList ids={this.state.ids} />
				{this.renderEmbedded()}
			</div>
		)
	}

	render() {
		const { subgameRankingMethod } = this.props;

		if (this.state.redirectTo !== undefined) {
			return <Redirect push to={this.state.redirectTo} />;
		}

		if (!this.state.dataLoaded) {
			return (
				<SectionLoadingPlaceholder minHeight="400px"/>
			)
		}

		if (this.isEmbed()) {
			return this.renderEmbedded();
		}

		return <div>
			{/* {this.renderSubgameComparisonChart("sessionCount")} */}
			{this.renderSubgameComparisonChart(subgameRankingMethod || "sessionCount")}
			{/* {this.renderSubgameList("sessionCount")} */}
			{/* subgames by count, subgames by views, chart & list of each */}
		</div>

	}
}

export default SubgamesGameCompareSection
export {
	sectionDescriptions
}
