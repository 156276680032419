import React from 'react'

const TextLogo = ({width = 970, height = 200}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 3883 801" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1564.5 384.9L1415.2 187.1H1360.4V604.1H1407.6V258.9L1564.5 457.3L1718.9 258.9V604.1H1766.1V187H1711.3L1564.5 384.9Z" fill="white"/>
      <path d="M1994.2 604.1H2301V558.7H2041.4V418.3H2273.3V372.3H2041.4V232.4L2301 232.5V187.1H1994.2V604.1Z" fill="white"/>
      <path d="M2750.8 201.2C2728 191.8 2703.6 187 2679 187H2503.2V604H2679C2703.6 604 2728 599.2 2750.8 589.8C2773.8 580.4 2794.6 566.4 2811.9 548.5C2850.9 508.6 2870.5 457.6 2870.5 395.4C2870.5 333.2 2851 282.2 2811.9 242.3C2794.6 224.7 2773.8 210.6 2750.8 201.2ZM2783.6 510.9C2756.3 543.2 2720.8 559.4 2677.1 559.4H2550.4V231.8H2677.1C2720.7 231.8 2756.2 248 2783.6 280.3C2810 311.4 2823.3 349.8 2823.3 395.6C2823.3 441.4 2810 479.8 2783.6 510.9Z" fill="white"/>
      <path d="M3192.4 187.1L3015.4 604.1H3067.7L3118.7 483.1H3325.4L3376.4 604.1H3428.7L3251.6 187.1H3192.4ZM3137 439.7L3222 236.2L3307.1 439.7H3137Z" fill="white"/>
      <path d="M3654.8 558.7V187L3607.6 187.1V604.1H3882.3V558.7H3654.8Z" fill="white"/>
      <g clipPath="url(#clip0_2265_69855)">
        <path d="M604.067 557.526L1002.51 328.397V793.068L1203.99 666.546V210.291L1007.04 92.7206L604.067 323.854L200.955 92.7206L4.00574 210.291V666.546L205.495 793.068V328.397L604.067 557.526Z" fill="url(#paint0_linear_2265_69855)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1007.09 88.0448L1208.03 208V668.777L998.469 800.368V335.373L604.067 562.181L209.53 335.372V800.368L-0.0299072 668.777V208L200.914 88.045L604.066 319.202L1007.09 88.0448ZM200.996 97.3962L8.04136 212.582V664.315L201.459 785.769V321.422L604.066 552.871L1006.54 321.42V785.769L1199.96 664.315V212.582L1007 97.3964L604.067 328.506L200.996 97.3962Z" fill="#C42C7E"/>
        <path d="M272.925 507.959V747.109L569.884 578.502L362.921 456.923L272.925 507.959Z" fill="url(#paint1_linear_2265_69855)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M362.956 452.263L577.953 578.561L268.889 754.041V505.608L362.956 452.263ZM276.961 510.31V740.177L561.815 578.442L362.885 461.582L276.961 510.31Z" fill="#C42C7E"/>
        <path d="M935.075 747.109V507.959L845.079 456.923L638.116 578.502L935.075 747.109Z" fill="url(#paint2_linear_2265_69855)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M845.044 452.263L939.111 505.608V754.041L630.047 578.561L845.044 452.263ZM845.115 461.582L646.185 578.442L931.039 740.177V510.31L845.115 461.582Z" fill="#C42C7E"/>
        <path d="M604.067 242.891L205.495 472.02V7.21457L4.00574 133.737V590.125L200.955 707.562L604.067 476.429L1007.04 707.562L1203.99 590.125V133.737L1002.51 7.21457V472.02L604.067 242.891Z" fill="url(#paint3_linear_2265_69855)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M209.53 -0.0849304V465.045L604.067 238.235L998.469 465.044V-0.0849298L1208.03 131.506V592.418L1007.08 712.237L604.066 481.081L200.915 712.237L-0.0299072 592.418V131.506L209.53 -0.0849304ZM1006.54 14.5141V478.996L604.066 247.546L201.459 478.995V14.514L8.04136 135.968V587.833L200.995 702.887L604.067 471.777L1007.01 702.887L1199.96 587.833V135.968L1006.54 14.5141Z" fill="#794EF4"/>
        <path d="M953.501 292.324V53.3077L656.542 221.781L863.506 343.36L953.501 292.324Z" fill="url(#paint4_linear_2265_69855)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M957.537 46.3782V294.675L863.47 348.02L648.471 221.72L957.537 46.3782ZM664.613 221.842L863.541 338.701L949.466 289.973V60.2371L664.613 221.842Z" fill="#794EF4"/>
        <path d="M251.561 53.3077V292.324L341.557 343.36L548.52 221.781L251.561 53.3077Z" fill="url(#paint5_linear_2265_69855)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M247.525 46.3782L556.592 221.72L341.592 348.02L247.525 294.675V46.3782ZM255.597 60.2371V289.973L341.521 338.701L540.449 221.842L255.597 60.2371Z" fill="#794EF4"/>
        <path d="M602.865 500.745L952.7 299.538V707.562L1129.62 596.538V195.996L956.706 92.7206L602.865 295.664L249.024 92.7206L76.1093 195.996V596.538L253.03 707.562V299.538L602.865 500.745Z" fill="#FFB84B"/>
        <path d="M296.025 457.324V667.214L556.799 519.182L375.072 412.433L296.025 457.324Z" fill="#FFB84B"/>
        <path d="M912.376 667.214V457.324L833.329 412.433L651.602 519.182L912.376 667.214Z" fill="#FFB84B"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_2265_69855" x1="1143.77" y1="978.51" x2="781.042" y2="615.99" gradientUnits="userSpaceOnUse">
          <stop offset="0.21" stopColor="#E03390"/>
          <stop offset="0.86" stopColor="#E03390" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint1_linear_2265_69855" x1="228543" y1="-21527.6" x2="149778" y2="59075.5" gradientUnits="userSpaceOnUse">
          <stop offset="0.21" stopColor="#E03390"/>
          <stop offset="0.86" stopColor="#E03390" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint2_linear_2265_69855" x1="280742" y1="33143.5" x2="201978" y2="113747" gradientUnits="userSpaceOnUse">
          <stop offset="0.21" stopColor="#E03390"/>
          <stop offset="0.86" stopColor="#E03390" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint3_linear_2265_69855" x1="64.2388" y1="-178.16" x2="426.971" y2="184.36" gradientUnits="userSpaceOnUse">
          <stop offset="0.27" stopColor="#794EF4"/>
          <stop offset="1" stopColor="#794EF4" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint4_linear_2265_69855" x1="80048.6" y1="320848" x2="158776" y2="240246" gradientUnits="userSpaceOnUse">
          <stop offset="0.27" stopColor="#794EF4"/>
          <stop offset="1" stopColor="#794EF4" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint5_linear_2265_69855" x1="21317.9" y1="263882" x2="100045" y2="183280" gradientUnits="userSpaceOnUse">
          <stop offset="0.27" stopColor="#794EF4"/>
          <stop offset="1" stopColor="#794EF4" stopOpacity="0"/>
        </linearGradient>
        <clipPath id="clip0_2265_69855">
          <rect width="1208" height="800.417" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default TextLogo
