import React from 'react'
import styled from 'styled-components'
import {colors} from '@get-wrecked/simple-components'
import PropTypes from 'prop-types'
import {NewBadge, Rank} from '../styles'

const propTypes = {
  rank: PropTypes.number,
  thumbnail: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  isNew: PropTypes.bool,
  unit: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
}

const GameCard = ({id, rank, thumbnail, name, description, isNew, unit, children }) => {
  return (
    <Container>
      <Header>
        <GameArt>
          <img src={thumbnail} alt={`${name} cover`}/>
        </GameArt>
        <HeaderPadded>
          <Title>
            <NameRow>
              <Name>{name.toLowerCase()}</Name>
              {
                isNew &&
                <NewBadge>New</NewBadge>
              }
            </NameRow>
            {
              unit &&
              <Subtext>
                {unit}
              </Subtext>
            }
          </Title>
          {
            rank &&
            <Rank $rank={rank}>
              {rank}
            </Rank>
          }
        </HeaderPadded>
      </Header>
      {children}
    </Container>
  )
}

GameCard.propTypes = propTypes

export default GameCard

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.neutral['0A4']};
  border: 1px solid ${colors.stroke['0A8']};
  border-radius: 8px;
  flex: 1;
  cursor: pointer;
  
  &:hover {
    border-color: ${colors.neutral['0A24']};
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.stroke['0A8']};
  height: 60px;
`

const HeaderPadded = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  height: 100%;
  flex: 1;
  line-height: 1;
  min-width: 0;
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: bold;
  color: ${colors.text['0']};
  text-transform: capitalize;
  gap: 5px;
  min-width: 0;
`

const NameRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const Name = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Subtext = styled.span`
  font-size: 13px;
  font-weight: normal;
  text-transform: none;
  color: ${colors.text['30']};
`

const GameArt = styled.div`
  display: flex;
  width: auto;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-inline-end: 1px solid ${colors.stroke['0A8']};
  height: 100%;
  position: relative;
  border-top-left-radius: 8px;
  flex-shrink: 0;
  
  img {
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: 100%;
  }
`
