import React from 'react'
import {colors} from '@get-wrecked/simple-components'
import styled from 'styled-components'

const primaryColor = colors.brand.primary['50']
const secondaryColor = colors.brand.secondary['50']
const tertiaryColor = '#C42C7E'
const lighterTertiaryColor = '#E03390'

const Logo = ({width = 200, height}) => {
  return (
    <StyledSvg
      width={width}
      height={height}
      viewBox="0 0 898 595"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8981_78789)">
        <path d="M449.05 414.444L745.24 244.118V589.538L895.022 495.486V156.323L748.615 68.9251L449.05 240.741L149.385 68.9251L2.97778 156.323V495.486L152.76 589.538V244.118L449.05 414.444Z" fill="url(#paint0_linear_8981_78789)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M748.645 65.4493L898.022 154.62V497.144L742.24 594.964V249.304L449.05 417.905L155.76 249.303V594.964L-0.0222168 497.144V154.62L149.355 65.4494L449.049 237.283L748.645 65.4493ZM149.416 72.4007L5.97778 158.026V493.827L149.76 584.112V238.933L449.049 410.984L748.24 238.932V584.112L892.022 493.827V158.026L748.584 72.4009L449.05 244.199L149.416 72.4007Z" fill={tertiaryColor}/>
        <path d="M202.886 377.598V555.373L423.639 430.037L269.787 339.659L202.886 377.598Z" fill="url(#paint1_linear_8981_78789)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M269.813 336.196L429.637 430.081L199.886 560.526V375.851L269.813 336.196ZM205.886 379.346V550.22L417.641 429.992L269.761 343.123L205.886 379.346Z" fill={tertiaryColor}/>
        <path d="M695.114 555.373V377.598L628.213 339.659L474.361 430.037L695.114 555.373Z" fill="url(#paint2_linear_8981_78789)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M628.186 336.196L698.114 375.851V560.526L468.363 430.081L628.186 336.196ZM628.239 343.123L480.359 429.992L692.114 550.22V379.346L628.239 343.123Z" fill={tertiaryColor}/>
        <path d="M449.05 180.556L152.76 350.882V5.36307L2.97778 99.415V438.677L149.385 525.976L449.05 354.16L748.615 525.976L895.022 438.677V99.415L745.24 5.36307V350.882L449.05 180.556Z" fill="url(#paint3_linear_8981_78789)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M155.76 -0.0631104V345.697L449.05 177.095L742.24 345.696V-0.0631099L898.022 97.7564V440.381L748.644 529.451L449.049 357.618L149.356 529.451L-0.0222168 440.381V97.7564L155.76 -0.0631104ZM748.24 10.7892V356.068L449.049 184.016L149.76 356.067V10.7892L5.97778 101.074V436.973L149.415 522.5L449.05 350.701L748.585 522.5L892.022 436.973V101.074L748.24 10.7892Z" fill={secondaryColor}/>
        <path d="M708.812 217.303V39.6269L488.059 164.864L641.911 255.241L708.812 217.303Z" fill="url(#paint4_linear_8981_78789)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M711.812 34.4758V219.05L641.884 258.705L482.059 164.819L711.812 34.4758ZM494.059 164.909L641.937 251.777L705.812 215.555V44.778L494.059 164.909Z" fill={secondaryColor}/>
        <path d="M187.005 39.6269V217.303L253.906 255.241L407.758 164.864L187.005 39.6269Z" fill="url(#paint5_linear_8981_78789)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M184.005 34.4758L413.758 164.819L253.932 258.705L184.005 219.05V34.4758ZM190.005 44.778V215.555L253.879 251.777L401.758 164.909L190.005 44.778Z" fill={secondaryColor}/>
        <path d="M448.156 372.235L708.216 222.666V525.976L839.735 443.444V145.696L711.194 68.925L448.156 219.786L185.119 68.925L56.5779 145.696V443.444L188.097 525.976V222.666L448.156 372.235Z" fill={primaryColor}/>
        <path d="M220.058 339.957V495.982L413.912 385.941L278.82 306.587L220.058 339.957Z" fill={primaryColor}/>
        <path d="M678.24 495.982V339.957L619.478 306.587L484.386 385.941L678.24 495.982Z" fill={primaryColor}/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_8981_78789" x1="850.256" y1="727.388" x2="580.614" y2="457.899" gradientUnits="userSpaceOnUse">
          <stop offset="0.21" stopColor={lighterTertiaryColor}/>
          <stop offset="0.86" stopColor={lighterTertiaryColor} stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint1_linear_8981_78789" x1="169893" y1="-16002.8" x2="111343" y2="43915.6" gradientUnits="userSpaceOnUse">
          <stop offset="0.21" stopColor={lighterTertiaryColor}/>
          <stop offset="0.86" stopColor={lighterTertiaryColor} stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint2_linear_8981_78789" x1="208697" y1="24637.6" x2="150147" y2="84556.1" gradientUnits="userSpaceOnUse">
          <stop offset="0.21" stopColor={lighterTertiaryColor}/>
          <stop offset="0.86" stopColor={lighterTertiaryColor} stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint3_linear_8981_78789" x1="47.7537" y1="-132.437" x2="317.396" y2="137.052" gradientUnits="userSpaceOnUse">
          <stop offset="0.27" stopColor={secondaryColor}/>
          <stop offset="1" stopColor={secondaryColor} stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint4_linear_8981_78789" x1="59506.4" y1="238507" x2="118029" y2="178589" gradientUnits="userSpaceOnUse">
          <stop offset="0.27" stopColor={secondaryColor}/>
          <stop offset="1" stopColor={secondaryColor} stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint5_linear_8981_78789" x1="15847.2" y1="196160" x2="74370.3" y2="136242" gradientUnits="userSpaceOnUse">
          <stop offset="0.27" stopColor={secondaryColor}/>
          <stop offset="1" stopColor={secondaryColor} stopOpacity="0"/>
        </linearGradient>
        <clipPath id="clip0_8981_78789">
          <rect width="898" height="595" fill="white"/>
        </clipPath>
      </defs>
    </StyledSvg>
  )
}

export default Logo

const StyledSvg = styled.svg`
  path {
    transition: fill 200ms ease-in-out;
  }
`
