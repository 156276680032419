import React from "react";
import {createRoot} from 'react-dom/client'
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import './index.css';
import "./assets/scss/material-kit-react.css";
import indexRoutes from "./routes/index.jsx";

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Footer from "./presentations/Footer";

import {
  getReportingPeriod,
  setReportingPeriod,
  getPeriodCount,
  setPeriodCount,
  getEndDate,
  setEndDate,
  invalidateGameData,
  retreiveReportingPeriodFromQueryString,
  retrievePeriodCountFromQueryString,
  retrieveEndDateFromQueryString,
  sanitizePeriodCount,
  sanitizeEndDate,
  getReportingPeriodQueryString, allowCustomDateRange,
} from "./data/App";

import AuthProvider from './context/providers/AuthProvider'
import TopNavigation from './presentations/Navigation/TopNavigation'
import ModalProvider from './context/providers/ModalProvider'
import ModalContainer from './presentations/ModalContainer'
import Page from './presentations/Page'
import CategoryProvider from './context/providers/CategoryProvider'
import BookmarkProvider from './context/providers/BookmarkProvider'

const browserHistory = createBrowserHistory();

const theme = createMuiTheme({
	palette: {
		type: 'dark',
	},
});

const App = () => {
  return (
    <AuthProvider>
      <CategoryProvider>
        <ModalProvider>
          <BookmarkProvider>
            <Router history={browserHistory}>
              <Switch>
                <Route path="/embed/" component={EmbedApp} />
                <Route component={MainApp}/>
              </Switch>
            </Router>
          </BookmarkProvider>
        </ModalProvider>
      </CategoryProvider>
    </AuthProvider>
  )
}

class MainApp extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			reportingPeriod: getReportingPeriod(),
			periodCount: getPeriodCount(),
			endDate: getEndDate(),
		}
	}

	scopeChange = (reportingPeriod, periodCount, endDate) => {
		//console.log("scopeChange", reportingPeriod, periodCount, endDate)

		if (reportingPeriod === getReportingPeriod() && periodCount === getPeriodCount() && endDate === getEndDate()) {
			//console.log("nothing changed")
			return;
		}

		invalidateGameData(getReportingPeriod());
		setReportingPeriod(reportingPeriod);
		setPeriodCount(getReportingPeriod(), periodCount);
		setEndDate(endDate);

		this.setState({ reportingPeriod, periodCount, endDate }, () => {
			this.props.history.push(`${window.location.pathname}?${getReportingPeriodQueryString()}`, this.state)
		})
	}

	periodChange = (value) => {
		if (value === getReportingPeriod()) {
			return;
		}

		setReportingPeriod(value);
		this.props.history.replace(`${window.location.pathname}?${getReportingPeriodQueryString()}`)
		this.setState({ reportingPeriod: value })
	}

	periodCountChange = (value) => {
		value = sanitizePeriodCount(value);
		if (value === getPeriodCount()) {
			return;
		}

		invalidateGameData(getReportingPeriod());
		setPeriodCount(getReportingPeriod(), value);
		this.props.history.replace(`${window.location.pathname}?${getReportingPeriodQueryString()}`)
		this.setState({ periodCount: value })
	}

	endDateChange = (value) => {
		value = sanitizeEndDate(new Date(value));
		if (value === getEndDate()) {
			return;
		}
		invalidateGameData(getReportingPeriod());
		setEndDate(value);
		this.props.history.replace(`${window.location.pathname}?${getReportingPeriodQueryString()}`)
		this.setState({ endDate: value })
	}

	componentDidMount() {
		const propsReportingPeriod = retreiveReportingPeriodFromQueryString();
		const propsPeriodCount = retrievePeriodCountFromQueryString();
		const propsEndDate = retrieveEndDateFromQueryString();

		setReportingPeriod(propsReportingPeriod);
		setPeriodCount(propsReportingPeriod, propsPeriodCount);
		setEndDate(propsEndDate);

		this.props.history.replace(`${window.location.pathname}?${getReportingPeriodQueryString()}`)

		this.setState({ reportingPeriod: propsReportingPeriod, periodCount: propsPeriodCount, endDate: propsEndDate });
	}

	componentDidUpdate() {
		setTimeout(() => {
			const propsReportingPeriod = retreiveReportingPeriodFromQueryString();
			const propsPeriodCount = retrievePeriodCountFromQueryString();
			const propsEndDate = retrieveEndDateFromQueryString();

			if (propsReportingPeriod !== this.state.reportingPeriod
				|| propsPeriodCount !== this.state.periodCount
				|| propsEndDate !== this.state.endDate
				) {
				// We'll only get here when the user presses the back/forward button.
				// In any other case, state will match the url by now.

				// Force game data to rebuild for the current view
				invalidateGameData(propsReportingPeriod);
				setReportingPeriod(propsReportingPeriod);
				setPeriodCount(propsReportingPeriod, propsPeriodCount);
				setEndDate(propsEndDate);

				// Ensure date picker matches the info on the url.
				this.setState({ reportingPeriod: propsReportingPeriod, periodCount: propsPeriodCount, endDate: propsEndDate })
			}
		}, 1)
	}

	render() {
		return <MuiThemeProvider theme={theme}>
      <TopNavigation
        reportingPeriod={this.state.reportingPeriod}
        endDate={this.state.endDate}
        periodCount={this.state.periodCount}
        onScopeChange={this.scopeChange}
        onPeriodChange={this.periodChange}
        onPeriodCountChange={this.periodCountChange}
        onEndDateChange={this.endDateChange}
        allowCustomRange={allowCustomDateRange()}
      />
			<Page>
        <Switch>
          {indexRoutes.map((prop, key) => {
            return <Route exact={prop.exact} path={prop.path} key={key} component={prop.component} />;
          })}
        </Switch>
      </Page>
			<Footer />
      <ModalContainer/>
		</MuiThemeProvider>
	}
}


class EmbedApp extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			reportingPeriod: getReportingPeriod()
		}
	}


	periodChange = (value) => {
		if (value === getReportingPeriod()) {
			return;
		}

		setReportingPeriod(value);
	}

	componentDidMount() {
		document.body.className = "embed";
		const propsReportingPeriod = retreiveReportingPeriodFromQueryString();
		const propsPeriodCount = retrievePeriodCountFromQueryString();
		const propsEndDate = retrieveEndDateFromQueryString();

		if (propsReportingPeriod !== getReportingPeriod()) {
			this.periodChange(propsReportingPeriod);
		}

		setPeriodCount(propsReportingPeriod, propsPeriodCount);
		setEndDate(propsEndDate);
	}

	render() {
		return <MuiThemeProvider theme={theme}>
			<Switch>
				{indexRoutes.map((prop, key) => {
					return <Route exact={prop.exact} path={prop.path} key={key} component={prop.component} />;
				})}
			</Switch>
		</MuiThemeProvider>
	}
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)
