import React from 'react';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ReactEcharts from 'echarts-for-react'

import {
  array0toN,
  getReportingPeriod,
  getReportingPeriodSingular,
  getPeriodCount,
} from "../../../data/App";
import { buildGameDataSetComplexFromBase, addCompareEmphasisLine } from "../../../data/Chart";
import DashboardSection from '../../../components/Dashboard/DashboardSection'

const styles = {
	card: {
		flex: 1,
		padding: 0,
		margin: 10,
		backgroundColor: '#1F1F1F',
		color: '#ffffff',
	},
	cardContent: {
		padding: 0,
		margin: 0,
	},
	cardHeader: {
		borderBottom: '0.01em solid grey',
		marginBottom: '20px',
	},

}

const getChartBaseOption = (periodNames) => {
	return {
		title: {
			text: 'text value'
		},
		tooltip: {
			trigger: 'axis'
		},
		legend: {
			data: [
				'Pubg',
				'FORTNITE'
			]
		},
		grid: {
			containLabel: true,
				},
		axisLine: {
			show: false
		},
		xAxis: [
			{
				type: 'category',
				boundaryGap: false,
				data: periodNames
			}
		],
			yAxis: [
				{
					show: false,
					gridIndex: 0,
					min: 0,
					max: 80
				},
				{
					type: 'value'
				}
			],
				series: [
					{
						name: '',
						type: 'line',
						color: '',
						symbol: 'circle',
						lineStyle: {
							width: 4
						},
						data: []
					},
					{
						showInLegend: false,
						name: '',
						data: [],
						type: 'custom'
					}
				]
	}
}


class RetentionChart extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			ids:this.props.ids,
			title: "",
			games: new Map(),
		}
	}

	getfirstPeriod = () => {
		// Will fail in 2162. Refactor before then:
		return this.props.data.reduce((c, v) => Math.min(c, v.firstPeriod), 10000)
	}

	buildChartOption = () => {
		var option = getChartBaseOption(array0toN(9));

		this.props.categories.forEach(game => {
			game.data = [];
		})

		var retentionCalculator = (gameSet) => {
			// this.props.data is an array of records that looks like this:
			// [{categoryId: 11, retentionPeriod: 4, userCount: 3522},...].
			// From that array, we'll return userCount for each record that matches our category,
			// as in: [10000, 6382, 3522, 4659]
			// They should be ordered by retentionPeriod, but we'll sort anyway.
			var result = this.props.data && this.props.data
				.filter(d => d.categoryId === gameSet.id)
				.sort((a, b) => a.retentionPeriod - b.retentionPeriod)
				.map(d => d.userCount / 100);
			return result;
		}

		option = buildGameDataSetComplexFromBase(this.props.categories, retentionCalculator, option)

		option.tooltip.formatter = (params) => {
			const colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + color + '"></span>';

			let rez = `<b>${getReportingPeriodSingular()} ${params[0].axisValue} Retention</b><br>`;
			let i = 0;

			// create default params object to account for missing data points and prepopulate it
			for (i = 0; i < params.length; i++) {
				if (typeof params[i] === "undefined") {
					params[i] = { "seriesName": "N/A", "data": [""], "value": "", "color": "#eee" }
				}
			}

			// List series in descending value order, rather than the order they were declared.
			var ordered = params.filter(p => !isNaN(p.value)).sort((a, b) => b.value - a.value);
			for (i = 0; i < ordered.length; i++) {
				const param = ordered[i];
				const arrow = params.find(p => p.componentSubType === "custom" && p.seriesName === param.seriesName).data;
				rez += colorSpan(param.color) + ' '
					+ (param.seriesName.length > 20 ? `${param.seriesName.substring(0, 20)}...` : param.seriesName)
					+ ': ' + param.data + '% <span style="font-weight: bolder">' + arrow + '</span></br>'

			}

			return rez;
		};

		if (this.props.useLogScale) {
			option.yAxis[0].type = 'log';
			option.yAxis[0].min = -10;
			option.yAxis[0].max = 100;
			// option.yAxis[0].minorTick= {
			// 	show: true
			// }
			// 	option.yAxis[0].minorSplitLine = {
			// 	show: true
			// }

			// option.yAxis[0].splitLine = {            // Separation line
			// 	show: true,           // horizontal increment bars
			// 		lineStyle: {          // Property lineStyle (see lineStyle) controls line styles
			// 		color: ['rgba(255,255,255,0.2)'],
			// 			width: 1,
			// 				type: 'dot'
			// 	}
			// }
		}

		if (this.props.emphasisLineIndex !== undefined){
			addCompareEmphasisLine(option, this.props.emphasisLineIndex);
		}

		this.setState({option, ids: this.props.ids})
	}

	componentDidMount() {
		this.buildChartOption()
	}

	componentDidUpdate(props, state) {
		if (props.ids !== this.state.ids || props.emphasisLineIndex !== this.props.emphasisLineIndex) {
			this.buildChartOption()
		}
	}

	onChartReady = (chart) => {
		var o = {
			grid:{height:330}
		}
		chart.setOption(o);

		const handleClick = this.props.onClick;
		chart.on("click", (params) => {
			handleClick && handleClick(params.dataIndex);
		})
	}

	render() {
		// Brand new games might not have 10 periods of data, which will cause
		// the API to return an empty dataset for us.
		const hasData = this.state.option
			&& this.state.option.series.find(s => s.data?.length > 0)
		if (!hasData) {
			return <div></div>
		}

		let title = `How often Medal creators play ${getReportingPeriod()}`;
		// if (isCustomEndDate()) {
		// 	title = `${getReportingPeriod().toUpperCase()} RETENTION AS OF ${new Date(getEndDate()).toString().substr(4, 11).toUpperCase()}`
		// }

    return (
      <DashboardSection
        title={title}
        description={`in the last ${getPeriodCount()} ${getReportingPeriodSingular().toLowerCase()}s`}
      >
        <ReactEcharts
          option={this.state.option}
          notMerge={true}
          lazyUpdate={true}
          theme={"medal_chart_theme"}
          onChartReady={this.onChartReady}
          style={{ height: 350 }}
          key={new Date().getTime()}
        />
      </DashboardSection>
    )
	}
}

export default withStyles(styles)(RetentionChart)
