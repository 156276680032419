import React, {useState} from 'react'
import {ModalContext} from '../index'

const ModalProvider = ({children}) => {
  const [modals, setModals] = useState([])

  const openModal = (id, context) => {
    setModals([
      ...modals,
      {id, context}
    ])
  }

  const closeModal = (id) => {
    setModals(modals.filter(modal => modal.id !== id))
  }

  return (
    <ModalContext.Provider value={{modals, openModal, closeModal}}>
      {children}
    </ModalContext.Provider>
  )
}

export default ModalProvider
