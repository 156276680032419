import React, {Fragment} from "react";
import {
	retreiveShareCalculationFromQueryString,
	getReportingPeriodQueryString,
	getMarketShareCalculationKey,
	buildCompareUrl,
} from "../../data/App";
import { loadCategoryData } from "../../data/Data";
import GameList from "./Sections/GameList.jsx";
import MarketShareMethodPicker from "../../components/CommonControls/MarketShareMethodPicker";
import { shareCalculations, getReportingPeriod } from '../../data/App';
import { getGamesList } from '../../data/Data';
import SectionLoadingPlaceholder from '../../components/Dashboard/SectionLoadingPlaceholder'

// rearrange shareCalculations list into a lookup:
const sections = shareCalculations.reduce((c, v) => { c[v.sectionKey] = v; return c; }, {});
const sectionsByDataMember = shareCalculations.reduce((c, v) => { c[v.dataMember] = v; return c; }, {});

class MarketSharePage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			dataLoaded: false,
			games: new Map(),
			lastReportingPeriod: getReportingPeriod(),
			marketShareMethod: retreiveShareCalculationFromQueryString(),
		}
	}

	updateGameList() {
		getGamesList().then(games => {
			this.setState({ games, dataLoaded: true, lastReportingDescription: this.buildQueryString() },
				() => {
					// This causes componentDidUpdate to fire, so let's make sure we wait until the state change finishes before calling it:
					this.props.history.replace(this.buildUrl());
				});
		})
	}

	buildQueryString = () => {
		return getReportingPeriodQueryString(window.location.search, { c: getMarketShareCalculationKey(this.state.marketShareMethod) });
	}

	buildUrl(ids) {
		if (this.isEmbed()) {
			return `/embed/player-share/${this.props.match.params.section}?${this.buildQueryString()}`;
		}
		return `/player-share/?${this.buildQueryString()}`;
	}

	componentDidMount() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
		loadCategoryData().then(() => {
			this.updateGameList();
			window.document.title = "Popular Games By Market & Player Share | Medal Trends";
		});
	}

	componentDidUpdate() {
		if (this.state.dataLoaded && this.state.lastReportingDescription !== "" && this.state.lastReportingDescription !== this.buildQueryString()) {
			this.updateGameList();
		}
	}

	searchHandler = (compareId) => {
		this.props.history.push(buildCompareUrl('compare-page', compareId))
	}

	marketShareMethodChange = event => {
		this.setState({ marketShareMethod: event.target.value });
	}

	isEmbed() {
		return window.location.pathname.indexOf("/embed/") === 0;
	}

	renderSection(section) {
		return <GameList
      games={this.state.games}
      dataMember={sections[section].dataMember}
			isEmbed={this.isEmbed()}
			section={section}
			title={(this.isEmbed() ? "PLAYER SHARE BY " : "") + sections[section].title}
			subtitle={sections[section].subtitle}
		/>
	}

	renderEmbedded() {
		var sectionKey = this.props && this.props.match && this.props.match.params
			&& this.props.match.params.section && this.props.match.params.section.toLowerCase
			&& this.props.match.params.section.toLowerCase();	// Javascript!

		var section = sections[sectionKey];
		if (section) {
			return this.renderSection(sectionKey);
		}
		return null;
	}

	render() {
		if (!this.state.dataLoaded) {
			return (
				<SectionLoadingPlaceholder minHeight="500px"/>
			)
		}

		if (this.isEmbed()) {
			return this.renderEmbedded();
		}

		return (
			<Fragment>
        {/* <MarketSharePie games={this.state.games} dataMember={dataMembers.SESSION_PERCENTS} title="UNIQUE PLAYERS"/> */}
        <div>
          <MarketShareMethodPicker
            value={this.state.marketShareMethod}
            onChange={this.marketShareMethodChange}
          />
        </div>
        {this.renderSection(sectionsByDataMember[this.state.marketShareMethod].sectionKey)}
      </Fragment>
		);
	}
}

export default MarketSharePage;
