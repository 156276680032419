/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";

import classNames from "classnames";
import Typography from '@material-ui/core/Typography';
import Avatar from '../../components/Avatar/Avatar'
import { getCategoryById, } from "../../data/App";

class EmbedAvatarList extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			open: false,
			includeFrame: true
		}
	}

	renderAvatar = (category, index) => {
		const avatar = "./" + category.id + ".png";

		return (
			<div key={index}>
				<div style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: 108,
					position: 'relative',
					marginBottom: 8
				}}>
					<Avatar
						name={category.name}
						size={'large'}
						src={avatar}
						id={category.id}
					/>
					<div style={{ position: 'absolute', bottom: '35%' }}>
						<Typography variant="title" component="h1" style={{
							fontFamily: 'Rajdhani',
							color: 'white',
							fontSize: 18,
							fontWeight: 700,
							textShadow: '-1px 0 DimGray, 0 1px DimGray, 1px 0 DimGray, 0 -1px DimGray'
						}}>{category.abv.toUpperCase()}</Typography>
					</div>
					<div className={classNames("dot")}
						style={{
							backgroundColor: category.color,
							position: 'absolute',
							right: 0,
							bottom: 0
						}} />
					<div>
					</div>
				</div>
				{this.renderVS(index)}
			</div>
		)
	}

	renderVS = (index) => {
		if (index > 0) {
			return (
				<div key={index} style={{
					width: 25,
					paddingLeft: 4,
					paddingRight: 2,
					fontWeight: 'bolder',
					position: 'relative'
				}}>
					<Typography variant="title" component="h1" style={{
						fontSize: 15,
						position: 'absolute',
						bottom: 0,
						fontFamily: 'Rajdhani',
						color: 'white',
						fontWeight: 700
					}}>VS</Typography>
				</div>
			)
		}
	}

	render() {

		return (
			<div style={{
				display: 'flex',
				justifyContent: 'left',
				flexWrap: 'wrap',
				padding: '8px 16px'
			}}>
				{
					this.props.ids.map((id, index) => {
						const category = getCategoryById(id);
						if (!category || category.id === undefined) return null;
						return (
							this.renderAvatar(category, index)
						)
					})
				}
			</div>
		);
	}

}


export default EmbedAvatarList;
