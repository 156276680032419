import React from 'react'

const TikTokIcon = ({size = 32, color = '#ffffff'}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_68_209)">
        <path
          d="M336.728 80.1808C315.187 66.1309 299.63 43.6567 294.779 17.4512C293.731 11.7824 293.157 5.9558 293.157 0H224.386L224.271 275.603C223.109 306.472 197.721 331.243 166.579 331.243C156.906 331.243 147.793 328.817 139.77 324.598C121.372 314.911 108.772 295.623 108.772 273.436C108.772 241.561 134.704 215.629 166.579 215.629C172.534 215.629 178.232 216.604 183.642 218.298V148.091C178.06 147.331 172.377 146.857 166.579 146.857C96.7882 146.857 40 203.645 40 273.436C40 316.26 61.3978 354.162 94.0327 377.067C114.598 391.504 139.598 400 166.579 400C236.369 400 293.157 343.226 293.157 273.421V133.668C320.123 153.028 353.175 164.437 388.838 164.437V95.6659C369.636 95.6659 351.739 89.9541 336.742 80.1665L336.728 80.1808Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_68_209">
          <rect width="400" height="400" fill={color}/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default TikTokIcon
