import React from "react";
import {Link} from "react-router-dom";
import ReactEcharts from 'echarts-for-react'
import {buildCompareUrl, retreiveIdsFromChartOption} from "../../data/App";
import GameCompareCard from '../Card/GameCompareCard'

const CompareChart = ({option}) => {
  const ids = retreiveIdsFromChartOption(option)
  return (
    <Link to={{
      pathname: buildCompareUrl('compare-page', ids),
    }}>
      <GameCompareCard
        games={option.series.filter(series => series.id)}
      >
        <ReactEcharts
          option={option}
          notMerge={true}
          lazyUpdate={true}
          theme={"medal_chart_theme"}
          style={{height: 155}}
          key={Date.now()}
        />
      </GameCompareCard>
    </Link>
  )
}

export default CompareChart
