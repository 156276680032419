import React from 'react'
import {BookmarkContext} from '../index'
import useLocalStorage from '../../hooks/useLocalStorage'

const BookmarkProvider = ({children}) => {
  const [bookmarks, setBookmarks] = useLocalStorage('bookmarks', [])
  return (
    <BookmarkContext.Provider value={{bookmarks, setBookmarks}}>
      {children}
    </BookmarkContext.Provider>
  )
}

export default BookmarkProvider
