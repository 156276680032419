import React from "react";


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';

// core components
import landingPageStyle from "../../../assets/jss/material-kit-react/views/landingPage.jsx";

import { tagRankingMethods } from "../../../data/App";

const autoOptions = [
	{ title: "All Tags", value: true },
	{ title: "Exclude Auto Tags", value: false },
]

class TagRankingMethodPicker extends React.Component {



	render() {
		const { classes } = this.props;

		const value = this.props.value || tagRankingMethods[0].dataMember;
		const includeAuto = this.props.includeAutoTags || false;

		const onChange = this.props.onChange || function (method, auto) { console.log("onChange not registered", method, auto) };
		const onChangeMethod = (event) => {
			console.log("onChangeMethod", event, event.target.value)
			onChange(event.target.value, includeAuto);

		}

		const onChangeAuto = (event) => {
			// NOTE: This caused a long debugging session.
			// Our awesome custom select list was returning "true" or "false"
			// as values.  And, of course, if("false") is true in javascript.
			onChange(value, event.target.value === "true");

		}

		return (
			<div>
				<FormControl className={classes.formControl} style={{ minWidth: 180, margin: "32px 48px 0px 0" }}>
					<InputLabel htmlFor="tagRankingMethod">Tag Ranking Method</InputLabel>
					<Select
						native
						style={{ color: "#eeeeee" }}
						value={value}
						onChange={onChangeMethod}
						inputProps={{
							name: 'tagRankingMethod',
							id: 'tagRankingMethod',
						}}
					>
						{tagRankingMethods.map((c, i) => {
							return <option style={{ background: "#303030" }} value={c.dataMember} key={i}>{c.title}</option>
						})}
					</Select>
				</FormControl>
				<FormControl className={classes.formControl} style={{ minWidth: 180, margin: "32px 48px 0px 0" }}>
					<InputLabel htmlFor="tagIncludeAuto">Tags to show</InputLabel>
					<Select
						native
						style={{ color: "#eeeeee" }}
						value={includeAuto}
						onChange={onChangeAuto}
						inputProps={{
							name: 'tagIncludeAuto',
							id: 'tagIncludeAuto',
						}}
					>
						{autoOptions.map((c, i) => {
							return <option style={{ background: "#303030" }} value={c.value} key={i}>{c.title}</option>
						})}
					</Select>
				</FormControl>
			</div>
		);
	}
}


export default withStyles(landingPageStyle)(TagRankingMethodPicker);
